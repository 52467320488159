import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { history } from "util/history";
import SecurityModal from "../common/SecurityModal";
import { FormattedMessage, injectIntl } from "react-intl";

const mapStateToProps = state => ({
    otpType: state.currentOTPType.otpType
});

class NoContactDetails extends Component {
    componentDidMount() {
        window.tealiumHub.PT002();
        console.log("NoContactDetails with otpType ", this.props.otpType);
    }

    handleClick = () => {
        window.tealiumHub.ET003("noContactDetails.label.backToLogon");
        history.push("/logon");
    };

    render() {
        const isOtpTypeSMS = this.props.otpType === "SMS";
        const titleIcon = <i className="icon exclamation circle warning" />;
        const title = isOtpTypeSMS
            ? "noContactDetails.title"
            : "EmailOTPNoContact.title";
        const ariaTitle = isOtpTypeSMS
            ? "noContactDetails.ariaTitle"
            : "EmailOTPNoContact.ariaTitle";
        const confirmationBtnTitle = isOtpTypeSMS
            ? "noContactDetails.label.backToLogon"
            : "EmailOTPNoContact.btn";
        const id = isOtpTypeSMS
            ? "noContactDetails.label.container"
            : "EmailOTPNoContact.body";

        return (
            <SecurityModal
                title={title}
                ariaTitle={ariaTitle}
                titleIcon={titleIcon}
                confirmationBtnTitle={confirmationBtnTitle}
                onConfirmationClick={this.handleClick}
            >
                <NoContactDetailsContainer>
                    <FormattedMessage id={id} />
                </NoContactDetailsContainer>
            </SecurityModal>
        );
    }
}
export default connect(mapStateToProps)(injectIntl(NoContactDetails));

const NoContactDetailsContainer = styled.div`
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 16px;
    font-weight: 300;
    line-height: 19px;
`;
