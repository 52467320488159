import { tc2 } from "./T&C2";
export default `<html><head>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8">
<meta name="Generator" content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"MS Mincho";
	panose-1:2 2 6 9 4 2 5 8 3 4;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:"Segoe UI";
	panose-1:2 11 6 4 2 2 2 2 2 4;}
@font-face
	{font-family:UniversNextForHSBC;
	panose-1:2 11 6 4 2 2 2 2 2 4;}
@font-face
	{font-family:"\@MS Mincho";
	panose-1:2 2 6 9 4 2 5 8 3 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin:0cm;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
h1
	{mso-style-link:"Heading 1 Char";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:21.6pt;
	text-indent:-21.6pt;
	line-height:110%;
	page-break-after:avoid;
	font-size:16.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:normal;}
h2
	{mso-style-link:"Heading 2 Char";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:28.8pt;
	text-indent:-28.8pt;
	line-height:110%;
	font-size:14.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:normal;}
h3
	{mso-style-link:"Heading 3 Char";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:36.0pt;
	text-indent:-36.0pt;
	line-height:110%;
	page-break-after:avoid;
	font-size:12.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:normal;}
h4
	{mso-style-link:"Heading 4 Char";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:43.2pt;
	text-indent:-43.2pt;
	line-height:110%;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:normal;}
h5
	{mso-style-link:"Heading 5 Char";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:50.4pt;
	text-indent:-50.4pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:normal;}
h6
	{mso-style-link:"Heading 6 Char";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:57.6pt;
	text-indent:-57.6pt;
	font-size:8.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:normal;}
p.MsoHeading7, li.MsoHeading7, div.MsoHeading7
	{mso-style-link:"Heading 7 Char";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:64.8pt;
	text-indent:-64.8pt;
	font-size:8.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoHeading8, li.MsoHeading8, div.MsoHeading8
	{mso-style-link:"Heading 8 Char";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:72.0pt;
	text-indent:-72.0pt;
	font-size:8.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoHeading9, li.MsoHeading9, div.MsoHeading9
	{mso-style-link:"Heading 9 Char";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:79.2pt;
	text-indent:-79.2pt;
	font-size:8.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoHeader, li.MsoHeader, div.MsoHeader
	{mso-style-link:"Header Char";
	margin:0cm;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoFooter, li.MsoFooter, div.MsoFooter
	{mso-style-link:"Footer Char";
	margin:0cm;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
a:link, span.MsoHyperlink
	{color:#005B9D;
	text-decoration:none none;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:6pt;
	margin-left:36.0pt;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
span.HeaderChar
	{mso-style-name:"Header Char";
	mso-style-link:Header;
	font-family:"Calibri",sans-serif;}
span.FooterChar
	{mso-style-name:"Footer Char";
	mso-style-link:Footer;
	font-family:"Calibri",sans-serif;}
span.Heading1Char
	{mso-style-name:"Heading 1 Char";
	mso-style-link:"Heading 1";
	font-family:"Arial",sans-serif;
	color:black;}
p.TNCBulletOne, li.TNCBulletOne, div.TNCBulletOne
	{mso-style-name:TNC_BulletOne;
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:18.0pt;
	text-indent:-14.0pt;
	line-height:110%;
	font-size:10.0pt;
	font-family:"Calibri",sans-serif;
	color:black;}
.MsoChpDefault
	{font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
.MsoPapDefault
	{
	}
 /* Page Definitions */
 @page WordSection1
	{size:595.3pt 841.9pt;
	margin:72.0pt 72.0pt 72.0pt 72.0pt;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}
-->
</style>

</head>

<body lang="en-CN" link="#005B9D" vlink="#954F72" style="word-wrap:break-word">

<div class="WordSection1">

<h1 style="margin-left:0cm;text-indent:0cm"><span lang="EN">Terms and Conditions</span></h1>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><b><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">Please read these
terms and conditions carefully before applying to Access, nominating a user for
and/or using, the website and/or app including the applications which you can
access via the website and/or app</span></b></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif">(Collectively <span style="color:black">this websit</span>e </span>
<span lang="EN-GB"><span lang="EN-US" style="font-size:10.0pt;line-height:110%;
color:windowtext">https://www.privatebanking.expat.hsbc.com/online-investments/</span></span>
<span lang="EN-US" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif;
color:black">, the mobile app and the underlying applications which you can
access via the website and mobile app shall be referred to as the “Digital
Services”). </span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">By using these
Digital Services, you signify your agreement to these terms and conditions and
if you are using these Digital Services on behalf of any other accountholder(s)
(including, without limitation, any other individual(s), a partnership, a
company or any other legal entity), you are confirming that you have full
authority to bind that person to these terms and conditions. If you do not
agree to these terms and conditions, please do not use the Digital Services.</span></p>

<p class="MsoListParagraph" style="margin-top:0cm;text-indent:
-14.0pt;line-height:107%"><span lang="EN" style="font-size:12.0pt;line-height:
107%;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;
font-family:&quot;Arial&quot;,sans-serif;color:black">References to ‘Terms and
Conditions’ are references to these terms and conditions, as amended from time
to time;</span></p>

<p class="MsoListParagraph" style="text-indent:-14.0pt;line-height:107%">
<span lang="EN" style="font-size:12.0pt;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>
<span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:black">References to ‘you’, ‘your’ and ‘yours’ are references to the
person accessing the Digital Services or where that person is acting on behalf
of any other accountholder(s), then to the person accessing the website and/or
mobile app and to the relevant accountholder(s); </span></p>

<p class="MsoListParagraph" style="text-indent:-14.0pt;line-height:107%">
<span lang="EN" style="font-size:12.0pt;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>
<span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:black">References to “we”, “us” and “our” are references to HSBC Private
Banking (C.I.);</span></p>

<p class="MsoListParagraph" style="text-indent:-14.0pt;line-height:107%">
<span lang="EN" style="font-size:12.0pt;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>
<span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black">References to “HSBC” are references
to HSBC Private Banking (C.I.), together with its affiliates and associated
entities including other members of the HSBC Group;</span></p>


<p class="MsoListParagraph" style="text-indent:-14.0pt;line-height:107%"><span lang="EN" style="font-size:12.0pt;font-family:Symbol">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:black">References to the “HSBC Group” are to HSBC Holdings plc and its
direct and indirect subsidiaries;</span></p>

<p class="MsoListParagraph" style="text-indent:-14.0pt;line-height:107%"><span lang="EN" style="font-size:12.0pt;font-family:Symbol">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:black">References to your “Relationship Manager” are to the individual
from time to time notified by us to you (or, where you are accessing the
Digital Services on behalf of any other accountholder, notified by us to them)
as your (or their) Relationship Manager.</span></p>

<p class="MsoNormal" style="margin-top:12.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:21.6pt;text-indent:-21.6pt;line-height:110%;page-break-after:
avoid"><b><span lang="EN-GB" style="font-family:UniversNextForHSBC;color:black">1.&nbsp;
Company information</span></b></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><a name="_Hlk126153469"><span lang="EN-US" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif;
color:black">The Digital Services are owned by HSBC Private Banking (C.I.), the trading name of HSBC 
Private Bank (Suisse) SA, Guernsey Branch which is licensed by the Guernsey Financial Services Commission 
for Banking, Credit, Insurance Intermediary and Investment Business. HSBC Private Bank (Suisse) 
SA is registered in Switzerland number UID CHE-101.727.921. Registered office Quai des Bergues 9-17, 1201 
Geneva (GE), Switzerland. Authorised and supervised by the Swiss Financial Market Supervisory Authority FINMA.</span></a></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">The information
contained on the Digital Services is for information purposes only. HSBC does
not hold itself out as providing legal, financial or other advice via the
Digital Services.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">Please note that
we will use your personal information in accordance with our Privacy Policy.</span></p>

<p class="MsoNormal" style="margin-top:12.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:21.6pt;text-indent:-21.6pt;line-height:110%;page-break-after:
avoid"><b><span lang="EN-GB" style="font-family:UniversNextForHSBC;color:black">2.&nbsp;
Digital services terms</span></b></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">These Terms and
Conditions govern your access and use of the Digital Services and each time you
access or use the Digital Services you agree to be bound by them. If you do not
accept these Terms and Conditions in full you should not access or use these
Digital Services and should leave the Digital Services immediately. These Terms
and Conditions refer to the following additional terms, which also apply to
your access or use of our Digital Services:</span></p>

<p class="MsoListParagraph" style="margin-top:0cm;text-indent:
-14.0pt;line-height:107%"><span lang="EN" style="font-size:12.0pt;line-height:
107%;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;
font-family:&quot;Arial&quot;,sans-serif;color:black">our Privacy Policy, which sets out
the terms on which we process any personal information we collect from you, or
that you provide to us </span></p>

<p class="MsoListParagraph" style="text-indent:-14.0pt;line-height:107%">
<span lang="EN" style="font-size:12.0pt;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>
<span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:black">our Cookie Policy, which sets out information about the cookies on
the Digital Services</span></p>

<p class="MsoNormal" style="margin-top:12.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:21.6pt;text-indent:-21.6pt;line-height:110%;page-break-after:
avoid"><b><span lang="EN-GB" style="font-family:UniversNextForHSBC;color:black">3.&nbsp;
Accessing the digital services</span></b></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%;page-break-after:avoid">
<span lang="EN-US" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif;
color:black">These Digital Services are made available free of charge.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%;page-break-after:avoid">
<span lang="EN-US" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif;
color:black">We do not guarantee that the Digital Services, or any content on
it, will always be available or be uninterrupted. Access to the Digital
Services is permitted on a temporary basis. We may at any time suspend,
withdraw, or discontinue all or any part of the Digital Services including
under sections 15 (Suspension and Termination of Access) and 17 (Ending this
Agreement).</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">You are
responsible for making all arrangements necessary for you to have access to our
Digital Services.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">You are also
responsible for ensuring that all persons who access the Digital Services
through your Internet connection are aware of these Terms and Conditions and
other applicable terms and conditions (including the Privacy Policy), and that
they comply with them.</span></p>

<p class="MsoNormal" style="margin-top:12.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:21.6pt;text-indent:-21.6pt;line-height:110%;page-break-after:
avoid"><b><span lang="EN-GB" style="font-family:UniversNextForHSBC;color:black">4.&nbsp;
No warranty</span></b></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">This section 4 and
sections 7 (Linked Websites/Frames/Use) and 12 (Limitation of Liability) below
limit or exclude our legal liability for the Digital Services and your use of
the Digital Services. You should read them carefully.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">Other than the
warranties and representations contained in these Terms and Conditions all
other warranties and representations, whether express, implied or statutory
are, to the fullest extent permitted by applicable law, excluded.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">Whilst we have
taken reasonable steps to ensure the accuracy, currency, availability,
correctness and completeness of the information contained on the Digital Services
the information (including text, graphics, and functionality) is presented “As
Is” and “As Available.”</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">In particular,
HSBC makes no warranties or representations regarding:</span></p>

<p class="MsoListParagraph" style="margin-top:0cm;text-indent:
-14.0pt;line-height:107%"><span lang="EN" style="font-size:12.0pt;line-height:
107%;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;
font-family:&quot;Arial&quot;,sans-serif;color:black">the accuracy, completeness or
timeliness of the content, information and material available on the Digital
Services; or</span></p>

<p class="MsoListParagraph" style="text-indent:-14.0pt;line-height:107%">
<span lang="EN" style="font-size:12.0pt;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>
<span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:black">the error-free use of the Digital Services; or</span></p>

<p class="MsoListParagraph" style="text-indent:-14.0pt;line-height:107%">
<span lang="EN" style="font-size:12.0pt;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>
<span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:black">the availability of the Digital Services or that it will meet any
requirements you might have, that access will be uninterrupted or that there
will be no delays, failures, errors or omissions of information available
through the Digital Services</span></p>

<p class="MsoListParagraph" style="text-indent:-14.0pt;line-height:107%">
<span lang="EN" style="font-size:12.0pt;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
</span><span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:black">the adequate protection and back up of data on the Digital
Services, and you acknowledge that you have sole responsibility for adequate
protection and back up of your own data and/or equipment and for undertaking
reasonable and appropriate precautions to scan for computer viruses or other
destructive properties when accessing or using the Digital Services; or</span></p>

<p class="MsoListParagraph" style="text-indent:-14.0pt;line-height:107%">
<span lang="EN" style="font-size:12.0pt;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>
<span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black">the accuracy, functionality or
performance of any third party software that may be used in connection with the
Digital Services</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">We accept no
liability for the accuracy, functionality or performance of any links or data
that may be supplied from another HSBC entity or member of the HSBC Group
within the Digital Services.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">Please see section
16 (Important Information) for further information concerning the content of
the Digital Services and certain limitations which may be applicable to it</span></p>

<p class="MsoNormal" style="margin-top:12.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:21.6pt;text-indent:-21.6pt;line-height:110%;page-break-after:
avoid"><b><span lang="EN-GB" style="font-family:UniversNextForHSBC;color:black">5.&nbsp;
Intellectual property rights</span></b></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">All intellectual
property rights (including, without limitation, copyright, database rights,
design rights, patents and trade-marks) in these Digital Services (including
text and “look and feel” attributes) are owned by HSBC or licensed to HSBC by
third party licensors unless otherwise stated. Your access to or use of the
Digital Services does not grant you any intellectual property rights in the Digital
Services. You may print, copy, download or temporarily store extracts from the
Digital Services for your personal use or to help you use our products and
services. You may not alter or otherwise make any changes to any material
obtained from the Digital Services in any form, including, without limitation,
removing any proprietary information, identifying marks or legends from such
material. Any other use is prohibited unless you first request and obtain our
written permission. Without limiting the above, unless you first obtain our
written consent, you may not reproduce, modify, adapt, transmit, publish,
broadcast, create derivative works of, store, archive or in any way exploit all
or any part of these Digital Services. All rights in such content are hereby
reserved.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">Content from a
third party provider available on or through the Digital Services may not be
duplicated, distributed, published, transferred, transmitted, copied, altered,
sold, used to create derivative works or otherwise misused. You must comply
with all terms disclosed to you as required from time to time by any third
party supplier of data or services to the Digital Services, including but not
limited to, where necessary, entering into a direct agreement with such third
party in respect of your use of their data or complying with any terms and
conditions such third party supplier of data or services may require you to
comply with.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">HSBC and the
hexagon logo are trade-marks of HSBC Group Management Services Limited and all
rights in and to them vest in HSBC Group Management Services Limited. Other
than as provided above you may not use or reproduce the HSBC trade mark,
hexagon logo or brand name.</span></p>

<p class="MsoNormal" style="margin-top:12.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:21.6pt;text-indent:-21.6pt;line-height:110%;page-break-after:
avoid"><b><span lang="EN-GB" style="font-family:UniversNextForHSBC;color:black">6.&nbsp;
Viruses</span></b></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">Because of the
marked increase in the fabrication and proliferation of computer viruses
affecting the Internet, HSBC wants to warn you about infections or viral
contamination on your system. It is your responsibility to undertake reasonable
and appropriate precautions to scan any and all downloaded materials received
from the Internet or submitted to you via the Digital Services.</span></p>

<p class="MsoNormal" style="margin-top:12.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:21.6pt;text-indent:-21.6pt;line-height:110%;page-break-after:
avoid"><b><span lang="EN-GB" style="font-family:UniversNextForHSBC;color:black">7.&nbsp;
Linked websites/frames/use</span></b></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">These Digital
Services may provide links to or data from third party websites for your
convenience in locating related information and services. HSBC does not
maintain any of these other websites and has no control over the organisations
that maintain these websites or the information, products, or services these
organisations provide.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">HSBC cannot
guarantee such websites' accuracy, completeness or suitability for any purpose.
Accordingly, HSBC expressly disclaims any responsibility for the content of
these other websites, the accuracy of the information on these websites and/or
the quality of products or services provided by the organisations that maintain
them. </span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">HSBC does not
recommend or endorse these organisations or their products or services in any
way and access to them is at the user’s own risk.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">You are prohibited
from linking to these Digital Services from any other website; from framing any
of the materials on these Digital Services; and/or from suggesting any
affiliation or endorsement between us, without our prior written authorisation.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">You may print
copies of the material contained in these Digital Services solely for your
internal use in connection with the services provided to you by HSBC and solely
in accordance with these Terms and Conditions.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">You are expressly
prohibited from selling; distributing; copying, amending; modifying; posting;
transmitting; uploading; or similar action regarding the material in these
Digital Services including: graphics; text; content; logos or the like.
Further, you are prohibited from using these Digital Services unlawfully and
will be responsible for any and all of your unlawful use of these Digital
Services. </span></p>

<p class="MsoNormal" style="margin-top:12.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:21.6pt;text-indent:-21.6pt;line-height:110%;page-break-after:
avoid"><b><span lang="EN-GB" style="font-family:UniversNextForHSBC;color:black">8.&nbsp;
Responsibility for use and control of security details and your information</span></b></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">The method by
which you access HSBC Private Banking Digital Investment Services may vary over
time and you will be fully informed as these changes occur.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">With your Security
Details, you will have access to the Digital Services and the tools and
information available through the Digital Services and we hereby grant you a
limited right to use the Digital Services and such tools and information, which
right we may revoke at any time.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">Your Security
Details and any other non-public personal information and other financial
information which you may provide to HSBC about yourself in connection with the
use of the Digital Services, will be stored and used in accordance with our
Privacy Policy and, where relevant, our Investment Services Terms and
Conditions. If you require a further copy of our Investment Services Terms and
Conditions please contact your Relationship Manager. </span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">You hereby declare
that you have all required permission to provide HSBC with this information and
will be solely liable to pay any costs reasonably incurred by HSBC for any
claims that you did not have such authority.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">You further
acknowledge that you are fully responsible for the use of your Security
Details, whether authorised by or known by you or not, and the protection of
your Security Details and subject to any obligations we may have under
applicable law or regulation you shall pay any costs reasonably incurred by
HSBC in respect of any claims related to any unauthorised access or use of the
Digital Services or other HSBC systems, resulting from negligence on your part.
</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">You agree to
immediately notify us if you become aware of any of the following: (a) loss or
theft of your Security Details; (b) unauthorised use of your Security Details
or any unauthorised use of these Digital Services; and (c) any other information
which you believe compromises the security of your information available
through these Digital Services.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">Where applicable
you consent to the provision of these Terms and Conditions through the Digital
Services.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">For the purposes
of this agreement, Security Details, means your user name, temporary password,
any password selected thereafter, a mobile PIN that will consist of a six digit
pin and/or biometric, where relevant. </span></p>

<p class="MsoNormal" style="margin-top:12.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:21.6pt;text-indent:-21.6pt;line-height:110%;page-break-after:
avoid"><b><span lang="EN-GB" style="font-family:UniversNextForHSBC;color:black">9.&nbsp;
Online fraud advisory</span></b></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">HSBC will never
send emails that require customers to send your information to it via email,
website link or pop-up windows. Any unsolicited request for HSBC account
information you receive through emails, websites, or pop-up windows should be
considered fraudulent. Online fraud occurs when someone poses as a legitimate
company to obtain sensitive personal data and then illegally conducts
transactions on your existing accounts. Often called "phishing" or
"spoofing," the most pervasive methods of online fraud are emails,
counterfeit websites and pop-up windows, or any combination of these. </span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">Fraudulent emails
often: </span></p>

<p class="MsoListParagraph" style="margin-top:0cm;text-indent:-14.0pt;line-height:107%">
<span lang="EN" style="font-size:12.0pt;line-height:107%;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>
<span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black">appear to be from a legitimate,
trusted source. You should not rely on the name or address in the
"From" field, as this can be easily altered;</span></p>

<p class="MsoListParagraph" style="text-indent:-14.0pt;line-height:107%">
<span lang="EN" style="font-size:12.0pt;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
</span><span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:black">ask you for personal information. These emails often claim that
your information has been compromised or frozen, or ask you to confirm your
identity;</span></p>

<p class="MsoListParagraph" style="text-indent:-14.0pt;line-height:107%">
<span lang="EN" style="font-size:12.0pt;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
</span><span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:black;letter-spacing:-.1pt">link to counterfeit websites. These sites may
appear legitimate, but actually collect personal information for illegal use.
They may also include a link to the real website in an attempt to make the link
seem legitimate; and </span></p>

<p class="MsoListParagraph" style="text-indent:-14.0pt;line-height:107%">
<span lang="EN" style="font-size:12.0pt;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
</span><span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:black">contain fraudulent phone numbers. These telephone numbers are
usually tied directly to the fraud perpetrators. Never call a number featured
on an email you suspect is fraudulent, and be sure to double-check any numbers
you do call. In addition, a legitimate number may be included in an attempt to
authenticate the email. </span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">To help protect
yourself from these fraudulent emails and websites:</span></p>

<p class="MsoListParagraph" style="margin-top:0cm;text-indent:
-14.0pt;line-height:107%"><span lang="EN" style="font-size:12.0pt;line-height:
107%;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;
font-family:&quot;Arial&quot;,sans-serif;color:black">never provide sensitive account or
personal information in response to an email;</span></p>

<p class="MsoListParagraph" style="text-indent:-14.0pt;line-height:107%">
<span lang="EN" style="font-size:12.0pt;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>
<span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:black">delete suspicious emails without opening them. If you do open a
suspicious email, do not open any attachments or click on any links it may
contain;</span></p>

<p class="MsoListParagraph" style="text-indent:-14.0pt;line-height:107%">
<span lang="EN" style="font-size:12.0pt;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>
<span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:black">bookmark any sites that house personal account information and use
those bookmarks to navigate directly to those sites;</span></p>

<p class="MsoListParagraph" style="text-indent:-14.0pt;line-height:107%">
<span lang="EN" style="font-size:12.0pt;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
</span><span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:black">install and regularly update virus protection software; and</span></p>

<p class="MsoListParagraph" style="text-indent:-14.0pt;line-height:107%">
<span lang="EN" style="font-size:12.0pt;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>
<span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:black">keep your computer operating system and Web browser current.</span></p>

<p class="MsoNormal" style="margin-top:12.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:21.6pt;text-indent:-21.6pt;line-height:110%;page-break-after:
avoid"><b><span lang="EN-GB" style="font-family:UniversNextForHSBC;color:black">10.&nbsp;&nbsp;
Changes</span></b></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">HSBC may change
the Digital Services or update information, material or content at any time
without notice. While we shall make reasonable efforts to keep the Digital
Services information accurate, HSBC is not obligated to update or correct
information within any specified time period. HSBC is not responsible for
information provided by third parties, whether the information is part of these
Digital Services, is in any linked website, or is information about us that is
provided on any website other than this one.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">We reserve the
right, to change these Terms and Conditions at any time where we reasonably
believe the change is to your advantage or is needed for any one or more of the
following reasons:</span></p>

<p class="MsoListParagraph" style="margin-top:0cm;text-indent:
-14.0pt;line-height:107%"><span lang="EN" style="font-size:12.0pt;line-height:
107%;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;
font-family:&quot;Arial&quot;,sans-serif;color:black">to respond proportionately to
changes in general law or decisions of the Financial Ombudsman Service;</span></p>

<p class="MsoListParagraph" style="text-indent:-14.0pt;line-height:107%">
<span lang="EN" style="font-size:12.0pt;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>
<span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:black">to meet our regulatory requirements;</span></p>

<p class="MsoListParagraph" style="text-indent:-14.0pt;line-height:107%">
<span lang="EN" style="font-size:12.0pt;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
</span><span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:black">to reflect new industry guidance and codes of practice;</span></p>

<p class="MsoListParagraph" style="text-indent:-14.0pt;line-height:107%">
<span lang="EN" style="font-size:12.0pt;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
</span><span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:black">to respond to the making of a relevant recommendation, requirement
or decision of any court, ombudsman, regulator or similar organisation; or</span></p>

<p class="MsoListParagraph" style="text-indent:-14.0pt;line-height:107%">
<span lang="EN" style="font-size:12.0pt;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
</span></span><span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:black">to allow us to make changes to the way in which we manage the
Digital Services or the service available through it as a result of changes to
technology or the systems we use including introducing new services or
facilities or replacing an existing service or facility with a new one.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">We have the right
to make changes to these Terms and Conditions for reasons not stated above as
long as we give you prior notice and you are able to end this service before
the change takes effect without charge. We need this flexibility because we
need to be able to manage how we provide our services over a long period of
time and it is very difficult for us to anticipate all the circumstances when
we may need to make a change to how the service delivered to you via the
Digital Services works. If you do not like any change we make you can exercise
your right to end this service and these Terms and Conditions between us. </span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">Where you are an
accountholder, we will provide at least 30 days’ notice of any change to these
Terms and Conditions to you. </span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">Where you are a
user of the Digital Services on behalf of an account holder we may notify you
of changes to these Terms and Conditions via the Digital Services including by
requesting your agreement to the amended Terms and Conditions when you access
the Digital Services.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">The current
version of these Terms and Conditions from time to time in force will be
available via the Digital Services. Where these Terms and Conditions have been
changed and you need a further copy of any prior version which is not available
on the Digital Services, please ask your Relationship Manager for a hard copy.</span></p>

<p class="MsoNormal" style="margin-top:12.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:21.6pt;text-indent:-21.6pt;line-height:110%;page-break-after:
avoid"><b><span lang="EN-GB" style="font-family:UniversNextForHSBC;color:black">11.&nbsp;&nbsp;
Privacy</span></b></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">We are committed
to protecting your privacy. All information gathered from you in connection
with your use of the Digital Services will be maintained in accordance with our
Privacy Policy.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">These Digital
Services also use cookies in accordance with the applicable cookie policies
disclosed for the Digital Services.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN" style="font-size:12.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;
color:white">&nbsp;</span><b><span lang="EN-GB" style="font-family:UniversNextForHSBC;color:black">12.&nbsp;&nbsp;
Limitation of liability</span></b></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%;page-break-after:avoid">
<span lang="EN-US" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif;
color:black">We will not be responsible for any loss of or damage to your data,
software, computer, telecommunications or other equipment caused by you using
the Digital Services unless such loss or damage is directly and solely caused
by our negligence or deliberate default.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black;letter-spacing:
-.1pt">Subject to the foregoing, and to the extent permitted by law, neither we
nor any member of the HSBC Group shall be liable for any damage, loss or
liability (whether arising in contract, tort, including negligence, or
otherwise) arising out of or in connection with (a) your use of these Digital
Services ; (b) your reliance on any information or materials on the Digital
Services or your inability to use or access the information or materials on the
Digital Services; and/or (c) any failure in performance, error, omission,
interruption, defect, delay in operation or transmission, computer virus or
line or system failure to these Digital Services or your use or attempted use
of it.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">The above
exclusions of liability apply to any damage, loss or liability which is direct,
indirect, special, incidental or consequential or consists of loss of profits,
business, goodwill, opportunity or data. All of the above exclusions apply even
if you have advised us or any other member of the HSBC Group of the possibility
of the above types of damage, loss or liability.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">Nothing in these
terms shall exclude or limit our liability in relation to </span></p>

<p class="MsoListParagraph" style="text-indent:-14.0pt;
line-height:107%"><span lang="EN" style="font-size:12.0pt;
font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;
font-family:&quot;Arial&quot;,sans-serif;color:black">fraud, fraudulent
misrepresentation, personal injury or death.</span></p>

<p class="MsoNormal" style="margin-top:12.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:21.6pt;text-indent:-21.6pt;line-height:110%;page-break-after:
avoid"><b><span lang="EN-GB" style="font-family:UniversNextForHSBC;color:black">13.&nbsp;&nbsp;
Complaints and disputes</span></b></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">If at any time you
are not satisfied with the service you have received through the Digital
Services and </span><span lang="EN-US" style="font-size:10.0pt;line-height:110%;
font-family:&quot;Arial&quot;,sans-serif">wish to make a complaint, you should write,
with full details to:</span></p>

<p class="MsoNormal"><span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">Chief
Executive Officer&nbsp; </span></p>

<p class="MsoNormal"><span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">HSBC
Private Banking (C.I.)&nbsp;&nbsp; </span></p>

<p class="MsoNormal"><span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">Arnold
House St. Julian’s Avenue&nbsp; </span></p>

<p class="MsoNormal"><span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">St.
Peter Port&nbsp;&nbsp; </span></p>

<p class="MsoNormal"><span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">Guernsey
GY1 3NF&nbsp; </span></p>

<p class="MsoNormal"><span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">Channel
Islands&nbsp; </span></p>

<p class="MsoNormal"><span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">Telephone
number: +44 (0)1481 759000&nbsp; </span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:110%"><span lang="EN-US" style="font-size:10.0pt;
line-height:110%;font-family:&quot;Arial&quot;,sans-serif;color:black">For further
details about how we handle complaints, you can ask for a copy of our
complaints handling procedure at any time.</span></p>

<p class="MsoNormal"><span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:black">If you are still not satisfied with any of our responses to your
complaint, or if after you first raised the matter with us three months have
passed without any final response, you can refer your complaint to the Channel
Islands Financial Ombudsman (“CIFO”).&nbsp; If we have sent you a final
response you must contact CIFO within six (6) months of the date on that
letter, or CIFO may not be able to review your complaint.&nbsp; In any event
you must contact CIFO within six (6) years of the event complained about or (if
later) two (2) years of when you could reasonably have been expected to become
aware that you had a reason to complain.&nbsp; You can contact CIFO at:&nbsp; </span></p>

<p class="MsoNormal"><span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:black">Channel Islands Financial Ombudsman (CIFO)&nbsp; </span></p>

<p class="MsoNormal"><span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:black">PO Box 114&nbsp; </span></p>

<p class="MsoNormal"><span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:black">Jersey, Channel Islands&nbsp; </span></p>

<p class="MsoNormal"><span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:black">JE4 9QG&nbsp; </span></p>

<p class="MsoNormal"><span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:black">Tel:&nbsp; 01534 748610 (Jersey office)&nbsp; </span></p>

<p class="MsoNormal"><span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:black">Tel:&nbsp; 01481 722218 (Guernsey office)&nbsp; </span></p>

<p class="MsoNormal"><span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:black">Email:&nbsp; </span><span lang="EN-GB"><a href="mailto:enquiries@ci-fo.org"><u><span lang="EN-US" style="font-size:10.0pt;
font-family:&quot;Arial&quot;,sans-serif;color:windowtext">enquiries@ci-fo.org</span></u></a></span><u>
<span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp; </span></u></p>

<p class="MsoNormal"><span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:black">Website:&nbsp; </span><span lang="EN-GB"><a href="http://www.ci-fo.org"><u><span lang="EN-US" style="font-size:10.0pt;
font-family:&quot;Arial&quot;,sans-serif;color:windowtext">www.ci-fo.org</span></u></a></span>
<span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp; </span></p>

<p class="MsoNormal"><span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:black">If your complaint concerns any activities relating to investment
services and you are not satisfied with responses to your complaint three
months or more after first complaining, you may contact the Guernsey Financial
Services Commission (“GFSC”) directly about the complaint at:&nbsp; </span></p>

<p class="MsoNormal"><span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:black">Guernsey Financial Services Commission&nbsp; </span></p>

<p class="MsoNormal"><span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:black">Glategny Court&nbsp; </span></p>

<p class="MsoNormal"><span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:black">Glategny Esplanade&nbsp; </span></p>

<p class="MsoNormal"><span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:black">St. Peter Port&nbsp;&nbsp; </span></p>

<p class="MsoNormal"><span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:black">Guernsey&nbsp; </span></p>

<p class="MsoNormal"><span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:black">GY1 3HQ&nbsp; </span></p>

<p class="MsoNormal"><span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:black">Tel: 01481 712706&nbsp; </span></p>

<p class="MsoNormal"><span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:black">Email: </span><span lang="EN-GB"><a href="mailto:info@gfsc."><u><span lang="EN-US" style="font-size:10.0pt;
font-family:&quot;Arial&quot;,sans-serif;
color:windowtext">info@gfsc.</span></u></a></span><span class="MsoHyperlink"><u><span lang="EN-US" style="font-size:10.0pt;font-family:
&quot;Arial&quot;,sans-serif;color:windowtext">gg</span></u></span><span lang="EN-US" style="font-size:10.0pt;
font-family:&quot;Arial&quot;,sans-serif">&nbsp; <span style="color:black
">Website: </span></span><span lang="EN-GB"><a href="http://www.gfsc.gg"><u><span lang="EN-US" style="font-size:10.0pt;
font-family:&quot;Arial&quot;,sans-serif;color:windowtext">www.gfsc.gg</span></u></a></span><span lang="EN-US" style="font-size:10.0pt;
font-family:&quot;Arial&quot;,sans-serif">&nbsp; </span></p>

` + tc2;
