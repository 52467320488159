import { tc2 } from "./T&C_LUX2";
export default `<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:����;
	panose-1:2 1 6 0 3 1 1 1 1 1;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:"Univers HSBCPB Light Con";}
@font-face
	{font-family:"Univers HSBCPB Light";}
@font-face
	{font-family:"Segoe UI";
	panose-1:2 11 5 2 4 2 4 2 2 3;}
@font-face
	{font-family:"Arial Narrow";
	panose-1:2 11 6 6 2 2 2 3 2 4;}
@font-face
	{font-family:"\@����";
	panose-1:2 1 6 0 3 1 1 1 1 1;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin:0cm;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
h1
	{mso-style-link:"Heading 1 Char";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:6.5pt;
	margin-bottom:.0001pt;
	text-indent:-8.6pt;
	font-size:10.0pt;
	font-family:"Univers HSBCPB Light Con",sans-serif;
	font-weight:normal;}
p.MsoCommentText, li.MsoCommentText, div.MsoCommentText
	{mso-style-link:"Comment Text Char";
	margin:0cm;
	margin-bottom:.0001pt;
	font-size:10.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoHeader, li.MsoHeader, div.MsoHeader
	{mso-style-link:"Header Char";
	margin:0cm;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoFooter, li.MsoFooter, div.MsoFooter
	{mso-style-link:"Footer Char";
	margin:0cm;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoBodyText, li.MsoBodyText, div.MsoBodyText
	{mso-style-link:"Body Text Char";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:123.4pt;
	margin-bottom:.0001pt;
	font-size:8.0pt;
	font-family:"Univers HSBCPB Light",sans-serif;}
a:link, span.MsoHyperlink
	{color:#0563C1;
	text-decoration:underline;}
a:visited, span.MsoHyperlinkFollowed
	{color:#954F72;
	text-decoration:underline;}
p.MsoCommentSubject, li.MsoCommentSubject, div.MsoCommentSubject
	{mso-style-link:"Comment Subject Char";
	margin:0cm;
	margin-bottom:.0001pt;
	font-size:10.0pt;
	font-family:"Calibri",sans-serif;
	font-weight:bold;}
p.MsoAcetate, li.MsoAcetate, div.MsoAcetate
	{mso-style-link:"Balloon Text Char";
	margin:0cm;
	margin-bottom:.0001pt;
	font-size:9.0pt;
	font-family:"Segoe UI",sans-serif;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:36.0pt;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:36.0pt;
	margin-bottom:.0001pt;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:36.0pt;
	margin-bottom:.0001pt;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:36.0pt;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
span.Heading1Char
	{mso-style-name:"Heading 1 Char";
	mso-style-link:"Heading 1";
	font-family:"Univers HSBCPB Light Con",sans-serif;}
span.BodyTextChar
	{mso-style-name:"Body Text Char";
	mso-style-link:"Body Text";
	font-family:"Univers HSBCPB Light",sans-serif;}
span.CommentTextChar
	{mso-style-name:"Comment Text Char";
	mso-style-link:"Comment Text";}
span.BalloonTextChar
	{mso-style-name:"Balloon Text Char";
	mso-style-link:"Balloon Text";
	font-family:"Segoe UI",sans-serif;}
span.HeaderChar
	{mso-style-name:"Header Char";
	mso-style-link:Header;}
span.FooterChar
	{mso-style-name:"Footer Char";
	mso-style-link:Footer;}
span.CommentSubjectChar
	{mso-style-name:"Comment Subject Char";
	mso-style-link:"Comment Subject";
	font-weight:bold;}
.MsoChpDefault
	{font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
.MsoPapDefault
	{margin-bottom:8.0pt;
	line-height:107%;}
 /* Page Definitions */
 @page WordSection1
	{size:595.3pt 841.9pt;
	margin:72.0pt 72.0pt 72.0pt 72.0pt;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}
-->
</style>
<style>
p{
	text-align: inherit !important;
}
.title-p{
	text-align: center !important;
}
</style>

</head>

<body lang=ZH-CN link="#0563C1" vlink="#954F72">

<div class=WordSection1>

<p class='MsoNormal title-p' align=center style='margin-top:4.7pt;text-align:center'><b><span
lang=EN-US style='font-size:10.0pt;font-family:"Arial",sans-serif'>Digital
Services <span style='letter-spacing:-.4pt'>Terms and Conditions</span></span></b></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:10.0pt;font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>The following terms and conditions, as amended from time to time, will govern all relations between HSBC Private Bank (Luxembourg) S.A. 
(the “Bank”) and the Client and/or any one or more agents or representatives (each, including the Client, are referred to as the “User”, as 
better defined in article 2.3 below) in connection with Digital Services.</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>These terms and conditions should be read carefully by the User before applying to access and/or use, the Private 
Banking Digital Services website and/or app including the applications which the User can access via the website and/or app (collectively this website </span><span lang=EN-US><a
href="https://www.privatebanking.hsbc.lu/login/"><span style='font-size:10.0pt;
line-height:110%;font-family:"Arial",sans-serif'>https://www.privatebanking.hsbc.lu/login/</span></a></span><span
lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:"Arial",sans-serif'>
and the underlying applications which the User can access via the website and/or app shall be referred to as the “Digital Services”). </span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>By using these Digital Services, the User agrees to these
terms and conditions and in case of use of these Digital Services on behalf of
any other accountholder(s) (including, without limitation, any other
individual(s), a partnership, a company or any other legal entity), the Client
confirms </span><span lang=EN-US style='font-size:10.0pt;line-height:110%;
font-family:"Arial",sans-serif'>that</span><span lang=EN-US style='font-size:
10.0pt;line-height:110%;font-family:"Arial",sans-serif'> he has full authority
to bind that person to these terms and conditions. If the User does not agree
to these terms and conditions, he should not use the Digital Services.</span><span
lang=EN-US> </span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>These terms and conditions will apply to the access or use
of our Digital Services, together with</span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:3.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:36.0pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt;line-height:110%'><span lang=EN-GB style='font-size:10.0pt;
line-height:110%'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:110%;
font-family:"Arial",sans-serif'>   The Bank’s General Terms and Conditions </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:3.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:36.0pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt;line-height:110%'><span lang=EN-GB style='font-size:10.0pt;
line-height:110%'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:110%;
font-family:"Arial",sans-serif'>   The Bank’s Privacy Policy</span></p>

<p class=MsoListParagraphCxSpLast style='margin-top:3.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:36.0pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt;line-height:110%'><span lang=EN-GB style='font-size:10.0pt;
line-height:110%'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:110%;
font-family:"Arial",sans-serif'>	The Cookie Policy</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>(collectively, the “Terms and Conditions”). In the event of any inconsistency between the terms of these terms and conditions for Digital 
Services and the terms of the General Terms and Conditions, these terms and conditions for Digital Services shall prevail.</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Types of
available services</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>The Digital Services shall include the following services:</span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:3.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:36.0pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt;line-height:110%'><span lang="EN-GB" style="
FONT-FAMILY: Symbol; COLOR: red; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; mso-fareast-language: EN-GB"><span style="
mso-list: Ignore">¨<span style="FONT: 7pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;</span></span></span><span lang=EN-GB style='font-size:10.0pt;line-height:110%;
font-family:"Arial",sans-serif'>   Obtain account balances and consolidated information about the account as of the close of business on the preceding business day</span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:3.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:36.0pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt;line-height:110%'><span lang="EN-GB" style="
FONT-FAMILY: Symbol; COLOR: red; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; mso-fareast-language: EN-GB"><span style="
mso-list: Ignore">¨<span style="FONT: 7pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;</span></span></span><span lang=EN-GB style='font-size:10.0pt;line-height:110%;
font-family:"Arial",sans-serif'>   Review transaction information for the last three months</span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:3.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:36.0pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt;line-height:110%'><span lang="EN-GB" style="
FONT-FAMILY: Symbol; COLOR: red; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; mso-fareast-language: EN-GB"><span style="
mso-list: Ignore">¨<span style="FONT: 7pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;</span></span></span><span lang=EN-GB style='font-size:10.0pt;line-height:110%;
font-family:"Arial",sans-serif'>   For HSBC Prism Advisory clients, access 
to the Insights & Research Platform (please refer to the Supplement below applicable to Insights & Research).</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>The Bank reserves the right to modify these services at any
time without prior notice.</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>2. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Access to
Digital Services</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>2.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Technical Access</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>To access Digital Services, the User will have to ensure a connection 
to the Internet with an Internet Service Provider (ISP) of the User’s choice. It
 is the User’s responsibility to procure the appropriate equipment, especially computer, telecommunications and security equipment to access the 
 Digital Services, and to maintain that equipment.</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>All the costs and expenses of equipment, communications, telecommunications and other expenses necessary for the connection and use of the 
Digital Services together with any relevant authorization required are to be borne by the User.</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>Should it be necessary to download a communication software program to its computer, or an application 
to its connection device, the User undertakes to comply with the recommendations and the directions of the Bank.</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>2.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Access and User ID</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>The User requests the Bank to issue the necessary user identification details (hereinafter “User ID”) and instructions on how to register.  </span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>Unless otherwise agreed, the Bank may only proceed to the transmission of the User ID by mail or e-mail address. </span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>2.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Users</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>The Client expressly authorises the Bank to issue to the Client’s present or future duly authorised Users, on 
any one of its accounts, the User ID permitting their access to Digital Services 
upon their express written request. The User shall include any authorized signatories, holders of a power of attorney, power 
of administration or right of inspection.  </span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>The Client acknowledges that if any of its accounts are joint accounts or have additional authorised signatories, each account holder, as well as 
each account holder’s authorised signatory, must have its own User ID. </span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>Only persons whose details have been provided to and who have been approved by the Bank in writing as Users may use the Digital Services. The 
use of the Digital Services by any User will constitute the agreement by that User as well as the Client to be bound by the Terms and Conditions.  </span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>2.4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Access to the Digital
Services </span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>Any access to the Digital Services by a User gained by
using a password or User ID belonging to that User shall be deemed to have been
made by that User in the name and on behalf of the Client.</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>2.5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Barring access</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>The User may be barred from accessing the Digital Services if
the User enters the password incorrectly three times. </span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>2.6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Misuse of the Digital
Services by an authorized person and revocation of access to the Digital
Services</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>All account holders shall be jointly and severally liable
for any and all use, misuse or unauthorised use of Digital Services by any
account holder or attorney or holder of a right of inspection and the Client
agrees that he will be bound by any message processed through the Digital
Services whether or not the action, or the person carrying out the action, was
duly authorised or not.</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>It is hereby expressly agreed that the revocation of an account holder’s or proxy holder’s authority 
to sign or the revocation of a right of 
inspection does not automatically render the User ID or password invalid. Nor does the death or loss of the capacity to act 
of the account holder or the holder of a 
power of attorney or right of inspection automatically result in the User IDs or passwords being rendered invalid.  </span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
lang=EN style='font-size:10.0pt;font-family:"Arial",sans-serif;color:#020202'>3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Security
and confidentiality of the User ID</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>3.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Security</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>There are risks associated with the use of services
provided through the Internet, including, but not limited to, unauthorized
access, system outages, delays and disruption in telecommunications services
and the Internet. Access to the Digital Services is designed to provide a
commercially reasonable level of security. However, the confidentiality of
communications transmitted through the Internet is not absolute and cannot be
guaranteed. </span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>The User shall use the Digital Services in accordance with
the technical requirements, security instructions and any other instructions
that may be provided by the Bank from time to time. Such instructions will be
given through the agreed means of communication.</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>The User shall take all necessary measures to ensure that
the technical characteristics of its personal computer, its software and its
Internet connection allow him to access the Digital Services of the Bank in a
secured manner.</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>The User is fully liable for the proper functioning of its
own IT devices, modem and telephone or Internet access. The User shall ensure
that such devices do not have any apparent problems or viruses and provide
sufficient security to prevent a risk of any third party gaining access to data
pertaining to the Digital Services. The User will use its best endeavours to
maintain such security. The User shall further ensure that there is no risk of
any hostile programs or viruses invading and disrupting the Digital Services.
In particular, the User will ensure that the security of its personal computer
is sufficient and will regularly update the antivirus and antispyware software
as well as its personal firewall.</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>The User shall bear all technical risks such as the
disruption of electric power transmission, non-availability of communication
lines, improper functioning or overloading of the systems or networks.</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>The Bank will not be held liable for the risks created by
the access to the Internet and by the transmission of data from or to the User,
in particular in case of conflict between the User and the ISP in relation to
the personal and/or confidential nature of the User's data.</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>The User shall be responsible for the performance and
protection of any browser used in connection with Digital Services, including
the prompt adoption of all security patches and other security measures issued
or recommended from time to time by the suppliers of such browsers.</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>3.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Confidentiality of
the User ID</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>The User ID details shall under no circumstances be
divulged or shared with any third part. For the protection of each User, the User
should memorize his password and should not keep any notation of his password
or any other User ID details on or with the device used to access the Digital
Services. </span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>The User must log off when ceasing to use the Digital Services to prevent unauthorized access to it. The 
User must inform the Bank immediately in writing 
if he believes that a third party may have seen or had access to the User ID details. The User agrees to accept all risks of unauthorized access, not 
directly caused by the Bank’s gross negligence. If 
the User provides inaccurate information to the Bank or fails to update information provided to the Bank, the Bank has the right to suspend or 
terminate his access to the Digital Services.</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>It is highly recommended that the User periodically changes
its password. When the User changes its passwords, it shall ensure that its
passwords do not consist of easily identifiable combinations (such as its
identifier, name or first name or date of birth or those of someone close
(spouse, child, etc.) and more generally a word or combination of words of the
dictionary, a word spelled backwards, a word followed by a digit or a year, a
password used for other purposes (including for personal e-mail, etc.). The User
shall in particular choose to use a password of sufficient length and composed,
whenever possible, of a combination of letters, numbers and punctuation marks
or special characters, as well as using uppercase and lowercase characters. The
Bank may, at its discretion, impose an expiry date for passwords after which
the User will not be able to access the Digital Services without a prior
modification of his password(s).</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>The User confirms that he understands the functioning of
the Digital Services as described in these terms and conditions and, as the
case may be, in any other relevant document brought to the attention of the User
in this context. The User may contact its Relationship Manager for any question
relating to the instructions and conditions for the use of the Digital Services.
</span></p>

<p class=MsoNormal style='margin-bottom:7.15pt;text-align:justify;text-justify:
inter-ideograph;line-height:14.4pt'><span lang=EN style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:#020202'>4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Incident
and suspension of service</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>In case an incident, such as the loss or theft of the User ID, the disclosure to a third party (even if involuntary or merely suspected) of any of the 
User ID, the misappropriation or any other unauthorised (actual or suspected) use of the Digital Services by a third party (the “Incident”), the User shall immediately 
notify the Bank in writing (mail or email).</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>The Bank reserves the right to block the Digital Services:</span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:3.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:36.0pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt;line-height:110%'><span lang="EN-GB" style="
FONT-FAMILY: Symbol; COLOR: red; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; mso-fareast-language: EN-GB"><span style="mso-list: Ignore">¨<span style="
FONT: 7pt &quot;Times New Roman&quot;">&nbsp;&nbsp;</span></span></span><span lang=EN-GB style='font-size:10.0pt;line-height:110%;
font-family:"Arial",sans-serif'> Where the security of the Digital Services is compromised, e.g. because of a problem or technical failure 
of the Digital Services itself or because of hacking attacks</span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:3.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:36.0pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt;line-height:110%'><span lang="EN-GB" style="
FONT-FAMILY: Symbol; COLOR: red; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; mso-fareast-language: EN-GB"><span style="mso-list: Ignore">¨<span style="
FONT: 7pt &quot;Times New Roman&quot;">&nbsp;&nbsp;</span></span></span><span lang=EN-GB style='font-size:10.0pt;line-height:110%;
font-family:"Arial",sans-serif'> Where the Bank has reason to suspect (for example where it has 
identified suspicious activity) or has received notification that an Incident has occurred</span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:3.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:36.0pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt;line-height:110%'><span lang="EN-GB" style="
FONT-FAMILY: Symbol; COLOR: red; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; mso-fareast-language: EN-GB"><span style="mso-list: Ignore">¨<span style="
FONT: 7pt &quot;Times New Roman&quot;">&nbsp;&nbsp;</span></span></span><span lang=EN-GB style='font-size:10.0pt;line-height:110%;
font-family:"Arial",sans-serif'> Where the Bank is obliged by law to effect such blocking</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>Should any one of the aforementioned scenarios occur, the
Bank shall inform the User thereof, where possible, before the Digital Services
is blocked in accordance with the General Terms and Conditions of the Bank,
unless the provision of such information is legally prohibited.</span></p>

<p class=MsoNormal style='margin-bottom:7.15pt;text-align:justify;text-justify:
inter-ideograph;line-height:14.4pt'><span lang=EN style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:#020202'>5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Official
statements</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>The Bank shall take reasonable care to ensure that any
information provided through Digital Services is an accurate reflection of the
information contained in its computer systems. Any information provided on the Digital
Services is however indicative only.</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>The User therefore expressly agrees that only periodic
statements issued by the Bank will constitute the official record or statement
of the relevant account(s).</span></p>

<p class=MsoNormal style='margin-bottom:7.15pt;text-align:justify;text-justify:
inter-ideograph;line-height:14.4pt'><span lang=EN style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:#020202'>6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; System availability and limits on the Bank’s liability</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>The User acknowledges that response times may vary due to
market conditions, system performance, access device location or other factors,
and that access to Digital Services may be limited or unavailable during
periods of peak demand, market volatility, system upgrades, maintenance, or for
any other reason whatsoever.</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>The User acknowledges (i) that interruption or malfunction of communications, failure of software or equipment, or malfunction of its or 
the Bank’s computer system for any reason may result in the 
Bank not receiving instructions or its not receiving the Bank’s communications, and (ii) that the Bank shall have no liability in respect of any 
such interruption or malfunction. The Bank shall only 
be liable in its relations with the User in the event of gross misconduct on its part.</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>The Bank shall not be liable for any damages or adverse consequences that may arise in relation with the User’s Internet connection, for 
which the User will bear the exclusive risk.</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>The User undertakes to comply with the statutory and regulatory prescriptions in force in the country from which the Internet Digital Services 
access is effected and releases the Bank from all liabilities which he may incur in that respect. More 
particularly, the Bank shall not be liable and cannot be blamed in the event of a third party gaining access to the Client’s accounts or obtaining any information 
concerning the relationship of the Client with the Bank through the Digital Services.</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>Except to the extent that the Bank is expressly stated to be liable under these terms, the Client agrees, individually and severally, to indemnify 
the Bank, their directors, managers, officers, employees, service providers and agents from all 
actions, loss, liability, claims, demands, damages, judgements, costs and expenses (including legal expenses) (the “Claim”) by or to anyone arising directly or 
indirectly out of or in any way connecting with Digital Services, related services, and any 
act or failure to act by any of them in connection therewith.</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>Without in any way limiting the generality of the preceding provision, except as specifically provided otherwise in these terms and conditions 
for Digital Services or where required by law, 
none of the Bank, their directors, officers, employees, service providers or agents will be liable for any Claim resulting in whole or in part from any act or 
failure to act of the Bank’s, the User’s, or any third party’s 
equipment or software, of any Internet browser provider, of any Internet access provider, or of an affiliate, agent or contractor of any of them.</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>In no event will the Bank or its directors, officers,
employees, service providers or agents be responsible for any direct or
indirect, special or consequential, economic or other damages to anyone arising
directly or indirectly out of or in any way connected with the Digital Services,
related services, or any act or failure to act by anyone in connection
therewith.</span></p>

<p class=MsoNormal style='margin-bottom:7.15pt;text-align:justify;text-justify:
inter-ideograph;line-height:14.4pt'><span lang=EN style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:#020202'>7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Monitoring
and Recording</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>The User expressly authorises the Bank to record, store and
archive e-mail communications. The User further acknowledges and agrees that
the Bank will store and archive log-in to the Digital Services made using the User
ID and access time of the User to the Digital Services.</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>The User specifically agrees that the record of log-in and
access time kept by the Bank shall constitute evidence of the existence, the
contents and the exact date and time of any such access or connection.&#8195;</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>The User shall treat the messages or information exchanged
or received through Digital Services like private correspondence.</span></p>

<p class=MsoNormal style='margin-bottom:7.15pt;text-align:justify;text-justify:
inter-ideograph;line-height:14.4pt'><span lang=EN style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:#020202'>8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Location
of communication</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>Any access or connection to Digital Services by the User and all communications from User with the Bank 
through Digital Services are reputed to 
have been effected directly at the offices of the Bank on the date and on the time indicated on the Bank’s Internet records.</span></p>

<p class=MsoNormal style='margin-bottom:7.15pt;text-align:justify;text-justify:
inter-ideograph;line-height:14.4pt'><span lang=EN style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:#020202'>9.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Protection
of intellectual property rights</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>The copyright concerning the Digital Services belongs to
the Bank, its affiliates within the HSBC Group or its service providers. All
representation or reproduction of all or part of the site, brand names, logos
or elements appearing on the site, in any form whatsoever, is forbidden without
the prior written authorization of the Bank. Software and programs and
applications that are provided by the Bank are the property of the Bank or its
licensors.</span></p>

<p class=MsoNormal style='margin-bottom:7.15pt;text-align:justify;text-justify:
inter-ideograph;line-height:14.4pt'><span lang=EN style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:#020202'>10.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Change
in terms and conditions</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>The Bank may amend these terms and conditions for Digital Services, at any time to take into account, in particular, changes 
in the law or regulations, local customs, the market situation or the Bank’s policies.</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>The Bank reserves the right, at any time, to inform the Client
by any means of the changes made to these terms and conditions for Digital
Services. &nbsp;Such amendments shall be deemed to have been approved if the Client
does not provide the Bank with any objection in writing within 30 days of the
dispatch of the notification.</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>Should the Client give notice of its objection in
accordance with this paragraph, the Client is entitled to terminate these terms
and conditions for Digital Services.</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>11.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Termination</span></p>

<p class=MsoNormal style='margin-bottom:8.0pt;text-align:justify;text-justify:
inter-ideograph;line-height:107%'><span lang=EN-US style='font-size:10.0pt;
line-height:107%;font-family:"Arial",sans-serif'>The Bank may terminate any User’s access to the Digital Services without notice if, it 
determines in its discretion that any of the 
Terms and Conditions have been breached or if there has been no Digital 
Services activity for a period of 3 (three) consecutive months. For any other reason, the Bank may terminate any User’s access to the 
Digital Services subject to a 30-day prior notice.  </span></p>



<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><b><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif;color:black;letter-spacing:.55pt'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><b><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif;color:black;letter-spacing:.55pt'>&nbsp;</span></b></p>


<p class='MsoNormal title-p' align=center style='margin-top:3.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:0cm;text-align:center;line-height:110%'><b><span
lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:"Arial",sans-serif;
color:black;letter-spacing:.55pt'>Supplement to the Digital Services: HSBC
Insights and Research (I&amp;R)</span></b></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Scope of
the Supplement</span></p>

<p class=MsoNormal style='margin-top:2.55pt;margin-right:7.2pt;margin-bottom:
0cm;margin-left:0cm;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;
line-height:115%;vertical-align:baseline'><span lang=EN-US style='font-size:
10.0pt;line-height:115%;font-family:"Arial",sans-serif'>These supplemental terms govern  the access to and use the HSBC Private 
Bank Insights and Research (hereinafter the “I&R Platform”), a 
digital platform that enables Users to access HSBC and/or third party economic and financial reports and publications (hereinafter the “Publications”) 
including, but not limited to, (i) Global 
Private Bank macro commentary on financial markets, (ii) HSBC Global Research reports (“HSBC Research”) issued by Global Research, a division of 
HSBC Global Markets (“HSBC Global Research”) and/ or (iii) 
non-HSBC third party publications. The Publications may discuss financial markets and investment products across several asset classes and may 
include financial research material covering specific financial instruments and issuers.</span></p>

<p class=MsoNormal style='margin-top:2.55pt;margin-right:7.2pt;margin-bottom:
0cm;margin-left:0cm;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;
line-height:115%;vertical-align:baseline'><span lang=EN-US style='font-size:
10.0pt;line-height:115%;font-family:"Arial",sans-serif'>The I&amp;R Platform is
made available by the Bank for Clients who entered into a Prism Advisory
agreement with the Bank and to Users.</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>The types of Publications accessible to each category of
User will be determined by the Bank in its discretion. </span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Distribution
channels of Publications</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>2.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; I&amp;R Platform</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>The Bank will provide each User with a direct online access to the Publications via the I&R Platform through the Bank’s 
Digital Services channels.</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>2.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Email Alerts</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>In addition to the above, the Bank will provide each User with the ability to receive via email certain alerts 
related to the Publications, such 
as certain updates of the Publications. As part of the services rendered under this Agreement, each User may choose to receive email 
alerts generated by the I&R Platform (hereinafter the “Email Alerts”). Each 
User will be requested to disclose and communicate his valid email address if and when requesting Email Alerts.</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>Accessing the I&amp;R Platform: In order to access the
I&amp;R Platform and the Publications, each User will be provided by the Bank
with User ID details (username and password) to access the Digital Services channels.
</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>Email Alerts: If a User elects to receive the Email Alerts,
he will be requested to communicate to the Bank his email address. The User
undertakes to inform immediately the Bank of any change in its email address. </span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>In this context, each User should be aware that the communications sent to, and the use of, each User’s email 
address is unsecured. Therefore, the transmission of any 
documentation, such as the Publications or the Email Alerts, may result in revealing to third parties the existence of the 
Client’s relationship with the Bank. In this context, the 
Bank cannot guarantee that the confidentiality of the Client’s relationship with the Bank may be preserved and expressly disclaims 
any liability in this regard.</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Monitoring
of use by the Bank</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>Each User’s access to, and use of, the Publications, including any Email Alerts, may be monitored by the 
Bank and its subcontractors, and the resulting 
information may be collected and used by the Bank for internal business considerations (see below) or for any applicable 
regulatory requirement. By using the I&R Platform, each User 
consents to the monitoring and collection of information regarding the access to, and use of, the Publications by each User.</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>The internal business considerations for monitoring access to, and use of, the Publications by the Bank include, but 
are not limited to: (i) reviewing 
whether each User is accessing the Publications, including any Email Alerts, most directly related to the Client’s portfolio holdings 
and trading patterns and (ii) ensuring 
that the Bank, to all extent reasonably possible, is making the most appropriate recommendations, as the case may be, for portfolio 
allocation/trading in light of the Publications, including 
any Email Alerts, accessed by the User.</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Language
of Publications</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>The Publications and the Email Alerts are provided to each
User exclusively in English language. By using the I&amp;R Platform, the User confirms
that he possesses a sufficient level of proficiency in the English language to
fully understand the content of the Publications and the Email Alerts, as well
as any important disclaimer or risk warning which they may contain.</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Financial
Knowledge, Experience, and Literacy</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>The Publications each User will access may cover different
financial product categories including, but not limited to, Climate Change,
Asset Allocation, Commodities, Convertibles, Credit High Grade, Credit High
Yield, Credit Strategy, Currencies, Economics, Equity Indexation, Equity
Strategy, Fixed Income, Structured and Asset Backed Securities.</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>The Publications make reference to market, economic and
financial data, concepts and ratios, as well as other technical information or
concepts which require each User to possess a significant degree of financial
knowledge, experience and literacy.</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>By using the I&amp;R Platform, the User confirms (i) that he
possesses a sufficient level of financial knowledge, experience and literacy to
read and understand the Publications and their limitations, in particular those
mentioned in Sections 9.1 and 9.2 below, and (ii) that he will exercise
independent judgment in evaluating the Publications.</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>Should any User experience any difficulty in understanding
any of the Publications or Email Alerts, such User should contact the Relationship
Manager or the Investment Counsellor in charge of the Account to obtain further
clarifications.</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>The Client confirms that he is able to bear the financial
risk associated with trading in the relevant financial markets and financial
instruments covered in the Publications.</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; No
Contact with the issuer of the Publications</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>No User shall try to contact the issuer of any Publication
directly, including but not limited to any HSBC Global Research analyst or any
other third party (including their representatives or employees).</span></p>

<p class=MsoNormal style='margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
110%'><span lang=EN-US style='font-size:10.0pt;line-height:110%;font-family:
"Arial",sans-serif'>7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
Restricted Access</span></p>
` + tc2;
