import React, { Component } from "react";
import { addLocaleData, IntlProvider } from "react-intl";
import { connect } from "react-redux";
import en from "react-intl/locale-data/en";
import zh from "react-intl/locale-data/zh";
import fr from "react-intl/locale-data/fr";

import messages from "util/messages";

addLocaleData([...en, ...zh, ...fr]);

const mapStateToProps = state => {
    return {
        selectedLang: state.languageSession.selectedLang
    };
};

class Intlization extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { selectedLang, children } = this.props;
        return (
            <IntlProvider
                locale={selectedLang}
                messages={messages[this.props.selectedLang].lang}
                key={selectedLang}
            >
                {children}
            </IntlProvider>
        );
    }
}

export default connect(mapStateToProps)(Intlization);
