export default `
<html><head>
<meta http-equiv="Content-Type" content="text/html; charset=gb2312">
<meta name="Generator" content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"MS Mincho";
	panose-1:2 2 6 9 4 2 5 8 3 4;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:"Segoe UI";
	panose-1:2 11 5 2 4 2 4 2 2 3;}
@font-face
	{font-family:"\@MS Mincho";
	panose-1:2 2 6 9 4 2 5 8 3 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin:0cm;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
h1
	{mso-style-link:"Heading 1 Char";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:21.6pt;
	text-indent:-21.6pt;
	line-height:110%;
	page-break-after:avoid;
	font-size:16.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:normal;}
h2
	{mso-style-link:"Heading 2 Char";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:28.8pt;
	text-indent:-28.8pt;
	line-height:110%;
	font-size:14.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:normal;}
h3
	{mso-style-link:"Heading 3 Char";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:36.0pt;
	text-indent:-36.0pt;
	line-height:110%;
	page-break-after:avoid;
	font-size:12.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:normal;}
h4
	{mso-style-link:"Heading 4 Char";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:43.2pt;
	text-indent:-43.2pt;
	line-height:110%;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:normal;}
h5
	{mso-style-link:"Heading 5 Char";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:50.4pt;
	text-indent:-50.4pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:normal;}
h6
	{mso-style-link:"Heading 6 Char";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:57.6pt;
	text-indent:-57.6pt;
	font-size:8.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:normal;}
p.MsoHeading7, li.MsoHeading7, div.MsoHeading7
	{mso-style-link:"Heading 7 Char";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:64.8pt;
	text-indent:-64.8pt;
	font-size:8.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoHeading8, li.MsoHeading8, div.MsoHeading8
	{mso-style-link:"Heading 8 Char";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:72.0pt;
	text-indent:-72.0pt;
	font-size:8.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoHeading9, li.MsoHeading9, div.MsoHeading9
	{mso-style-link:"Heading 9 Char";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:79.2pt;
	text-indent:-79.2pt;
	font-size:8.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoCommentText, li.MsoCommentText, div.MsoCommentText
	{mso-style-link:"Comment Text Char";
	margin:0cm;
	margin-bottom:.0001pt;
	font-size:10.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoHeader, li.MsoHeader, div.MsoHeader
	{mso-style-link:"Header Char";
	margin:0cm;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoFooter, li.MsoFooter, div.MsoFooter
	{mso-style-link:"Footer Char";
	margin:0cm;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
a:link, span.MsoHyperlink
	{color:#005B9D;
	text-decoration:none none;}
a:visited, span.MsoHyperlinkFollowed
	{color:#954F72;
	text-decoration:underline;}
p
	{margin-top:7.5pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:0cm;
	margin-bottom:.0001pt;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
p.MsoCommentSubject, li.MsoCommentSubject, div.MsoCommentSubject
	{mso-style-link:"Comment Subject Char";
	margin:0cm;
	margin-bottom:.0001pt;
	font-size:10.0pt;
	font-family:"Calibri",sans-serif;
	font-weight:bold;}
p.MsoAcetate, li.MsoAcetate, div.MsoAcetate
	{mso-style-link:"Balloon Text Char";
	margin:0cm;
	margin-bottom:.0001pt;
	font-size:9.0pt;
	font-family:"Segoe UI",sans-serif;}
p.MsoNoSpacing, li.MsoNoSpacing, div.MsoNoSpacing
	{margin:0cm;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoRMPane, li.MsoRMPane, div.MsoRMPane
	{margin:0cm;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:36.0pt;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
span.BalloonTextChar
	{mso-style-name:"Balloon Text Char";
	mso-style-link:"Balloon Text";
	font-family:"Segoe UI",sans-serif;}
span.y0nh2b
	{mso-style-name:y0nh2b;}
span.HeaderChar
	{mso-style-name:"Header Char";
	mso-style-link:Header;
	font-family:"Calibri",sans-serif;}
span.FooterChar
	{mso-style-name:"Footer Char";
	mso-style-link:Footer;
	font-family:"Calibri",sans-serif;}
span.CommentTextChar
	{mso-style-name:"Comment Text Char";
	mso-style-link:"Comment Text";
	font-family:"Calibri",sans-serif;}
span.CommentSubjectChar
	{mso-style-name:"Comment Subject Char";
	mso-style-link:"Comment Subject";
	font-family:"Calibri",sans-serif;
	font-weight:bold;}
p.Default, li.Default, div.Default
	{mso-style-name:Default;
	margin:0cm;
	margin-bottom:.0001pt;
	text-autospace:none;
	font-size:12.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
span.Heading1Char
	{mso-style-name:"Heading 1 Char";
	mso-style-link:"Heading 1";
	font-family:"Arial",sans-serif;
	color:black;}
span.Heading2Char
	{mso-style-name:"Heading 2 Char";
	mso-style-link:"Heading 2";
	font-family:"Arial",sans-serif;
	color:black;}
span.Heading3Char
	{mso-style-name:"Heading 3 Char";
	mso-style-link:"Heading 3";
	font-family:"Arial",sans-serif;
	color:black;}
span.Heading4Char
	{mso-style-name:"Heading 4 Char";
	mso-style-link:"Heading 4";
	font-family:"Arial",sans-serif;
	color:black;}
span.Heading5Char
	{mso-style-name:"Heading 5 Char";
	mso-style-link:"Heading 5";
	font-family:"Arial",sans-serif;
	color:black;}
span.Heading6Char
	{mso-style-name:"Heading 6 Char";
	mso-style-link:"Heading 6";
	font-family:"Arial",sans-serif;
	color:black;}
span.Heading7Char
	{mso-style-name:"Heading 7 Char";
	mso-style-link:"Heading 7";
	font-family:"Arial",sans-serif;
	color:black;}
span.Heading8Char
	{mso-style-name:"Heading 8 Char";
	mso-style-link:"Heading 8";
	font-family:"Arial",sans-serif;
	color:black;}
span.Heading9Char
	{mso-style-name:"Heading 9 Char";
	mso-style-link:"Heading 9";
	font-family:"Arial",sans-serif;
	color:black;}
p.BulletOne, li.BulletOne, div.BulletOne
	{mso-style-name:BulletOne;
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:21.6pt;
	text-indent:-21.6pt;
	line-height:110%;
	font-size:10.0pt;
	font-family:"Calibri",sans-serif;
	color:black;}
p.BodyText1, li.BodyText1, div.BodyText1
	{mso-style-name:"Body Text1";
	mso-style-link:"Body text Char";
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:0cm;
	line-height:110%;
	font-size:10.0pt;
	font-family:"Calibri",sans-serif;
	color:black;}
span.BodytextChar
	{mso-style-name:"Body text Char";
	mso-style-link:"Body Text1";
	font-family:"MS Mincho";
	color:black;}
.MsoChpDefault
	{font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
.MsoPapDefault
	{margin-bottom:8.0pt;
	line-height:107%;}
 /* Page Definitions */
 @page WordSection1
	{size:595.3pt 841.9pt;
	margin:72.0pt 72.0pt 72.0pt 72.0pt;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}
-->
</style>

</head>

<body lang="ZH-CN" link="#005B9D" vlink="#954F72">

<div class="WordSection1">

<p class="MsoNormal" style="margin-bottom:7.15pt;text-align:justify;text-justify:
inter-ideograph;line-height:14.4pt"><span lang="FR">Confidentialité</span></p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:105%"><span lang="FR">Nous savons que la manière dont vos
informations personnelles et financières sont traitées est importante pour
vous. La présente déclaration de confidentialité énonce nos politiques
actuelles et témoigne de notre engagement envers votre vie privée financière.</span></p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:105%"><span lang="FR">&nbsp;</span></p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:105%"><span lang="FR">En accédant à ce site internet (ci-après le
«&nbsp;<b>Site</b>&nbsp;») et à l’une de ses pages, vous acceptez la
déclaration de confidentialité décrite ci-dessous.</span></p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:105%"><span lang="FR">&nbsp;</span></p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:105%"><span lang="FR">Avant d’utiliser le Site, vous devez lire
attentivement cette déclaration de confidentialité, ainsi que nos <a href="https://www.hsbc.lu/en-gb/terms-conditions"><span style="color:windowtext">conditions
générales et</span></a> notre <a href="https://www.hsbc.lu/en-gb/hyperlink-policy"><span style="color:windowtext">politique
en matière de</span></a> liens hypertextes. Vous devez également lire notre <a href="https://www.hsbc.lu/en-gb/cookie-policy"><span style="color:windowtext">politique
en matière de cookies</span></a> pour en savoir plus sur la façon dont HSBC et
nos partenaires de confiance utilisent des cookies, qui concernent votre
expérience sur le Site.</span></p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:105%"><span lang="FR">&nbsp;</span></p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:105%"><span lang="FR">Les informations, les supports et le contenu
figurant dans les pages du Site peuvent être modifiés à tout moment sans
préavis. Par conséquent, cette déclaration de confidentialité peut également
être modifiée. Vous acceptez de consulter régulièrement cette déclaration de
confidentialité, et votre accès ou votre utilisation continu(e) du Site
signifiera que vous acceptez toute modification.</span></p>

<p class="MsoNormal" style="line-height:105%"><span lang="FR">&nbsp;</span></p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:105%"><span lang="FR">Le groupe HSBC fournit des produits et des
services financiers par l’intermédiaire de ses sociétés affiliées et
succursales dans le monde entier. Les principes de confidentialité et de
protection des données à caractère personnel sont différents selon les pays.
Lorsque vous accédez à un autre site Web ou que vous êtes renvoyés vers un
autre site Web, veuillez lire les déclarations de confidentialité et de
protection des données ou les accords émis par cet autre site internet afin de
déterminer les politiques qui s’appliquent aux informations ou aux données
conservées par ce site internet.</span></p>

<p class="MsoNormal" style="line-height:105%"><span lang="FR">&nbsp;</span></p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:105%"><span lang="FR">Notre activité s’est construite sur une
relation de confiance entre nous et nos clients. Afin de préserver la
confidentialité de toutes les informations que vous nous fournissez, nous avons
adopté les principes de confidentialité suivants&nbsp;:</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:36.0pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt;line-height:110%"><span lang="FR" style="font-family:Symbol;
color:red">¨<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="FR">Nous ne collecterons que les informations que nous
estimons pertinentes et nécessaires à la compréhension de vos besoins
financiers et à l’exercice de nos activités</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:36.0pt;text-justify:inter-ideograph;
text-indent:-18.0pt;line-height:110%"><span lang="FR" style="font-family:Symbol;
color:red">¨<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="FR">Nous utiliserons vos informations pour vous fournir
de meilleurs services et produits</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:36.0pt;text-justify:inter-ideograph;
text-indent:-18.0pt;line-height:110%"><span lang="FR" style="font-family:Symbol;
color:red">¨<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="FR">Nous pourrons communiquer vos informations à
d’autres sociétés ou mandataires du groupe HSBC, conformément à la loi</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:36.0pt;text-justify:inter-ideograph;
text-indent:-18.0pt;line-height:110%"><span lang="FR" style="font-family:Symbol;
color:red">¨<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="FR">Nous ne divulguerons pas vos informations à toute
organisation extérieure, sauf si nous obtenons votre consentement, si la loi
l’exige ou si nous vous en avons informé au préalable</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:36.0pt;text-justify:inter-ideograph;
text-indent:-18.0pt;line-height:110%"><span lang="FR" style="font-family:Symbol;
color:red">¨<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="FR">Nous pouvons être contraints, parfois, de
communiquer vos informations aux autorités gouvernementales ou judiciaires ou
aux autorités de tutelle, mais nous ne le ferons que dans les limites de nos
obligations</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:36.0pt;text-justify:inter-ideograph;
text-indent:-18.0pt;line-height:110%"><span lang="FR" style="font-family:Symbol;
color:red">¨<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="FR">Nous ferons en sorte de tenir à jour vos
informations</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:36.0pt;text-justify:inter-ideograph;
text-indent:-18.0pt;line-height:110%"><span lang="FR" style="font-family:Symbol;
color:red">¨<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="FR">Nous possédons des systèmes de sécurité stricts
destinés à éviter tout accès non autorisé à vos informations, y compris par
notre personnel</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:36.0pt;text-justify:inter-ideograph;
text-indent:-18.0pt;line-height:110%"><span lang="FR" style="font-family:Symbol;
color:red">¨<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="FR">Toutes les sociétés du groupe HSBC, tout notre
personnel, et tous les tiers bénéficiant d’un accès autorisé à vos informations
sont expressément tenus de respecter nos obligations de confidentialité</span></p>

<p class="MsoNormal" text-justify:inter-ideograph;
line-height:105%"><span lang="FR">En nous engageant à respecter ces principes,
nous entendons respecter la confiance que vous nous accordez.</span></p>

<h1 style="margin-left:0cm;text-indent:0cm"><span lang="FR">Politique en matière
de cookies </span></h1>

<p class="MsoNormal" style="margin-bottom:7.15pt;line-height:14.4pt"><span lang="FR" style="font-size:13.5pt;font-family:&quot;Times New Roman&quot;,serif;color:#020202">Comprendre
comment et pourquoi nous utilisons des cookies</span></p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:105%"><span lang="FR">Nous utilisons des cookies sur notre site internet
pour créer le site le plus efficace possible pour nos utilisateurs. Les cookies
que nous utilisons nous permettent, entre autres, d’améliorer votre expérience
lors de l’utilisation de notre site internet et de s’assurer que le site internet
fonctionne comme prévu.</span></p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:105%"><span lang="FR">&nbsp;</span></p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:105%"><span lang="FR">Cette politique en matière de cookies explique
ce que sont les cookies, comment nous les utilisons sur notre site internet et
quels sont leurs avantages.</span></p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:105%"><span lang="FR">&nbsp;</span></p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:105%"><span lang="FR"><a href="https://www.hsbc.lu/en-gb/cookie-policy#faqs"><span style="color:windowtext">Questions
fréquemment posées concernant les cookies</span></a></span></p>

<p class="MsoNormal" style="line-height:105%"><span lang="FR">&nbsp;</span></p>

<p class="MsoNormal" style="margin-bottom:7.15pt;line-height:14.4pt"><span lang="FR" style="font-size:13.5pt;font-family:&quot;Times New Roman&quot;,serif;color:#020202">Que
sont les cookies&nbsp;?</span></p>

<p class="MsoNormal" style="margin-bottom:11.25pt;text-align:justify;text-justify:
inter-ideograph;background:white"><span lang="FR">Les cookies sont de petits
fichiers texte qui sont placés et stockés sur votre ordinateur, votre
smartphone ou tout autre appareil optimisé pour internet lorsque vous visitez
un site internet. Les cookies sont utiles parce qu’ils permettent au site internet
de reconnaître l’appareil de l’utilisateur.</span></p>

<p class="MsoNormal" style="margin-bottom:11.25pt;text-align:justify;text-justify:
inter-ideograph;background:white"><span lang="FR">Les sites internet du groupe
HSBC utilisent des cookies à plusieurs fins&nbsp;: déterminer les préférences
d’utilisation, permettre aux utilisateurs de naviguer de manière efficace d’une
page à l’autre, vérifier leur identité et effectuer d’autres contrôles de
sécurité indispensables. La politique en matière de cookies sur chaque site internet
du groupe HSBC explique la manière dont les cookies sont utilisés.</span></p>

<p class="MsoNormal" style="margin-bottom:11.25pt;text-align:justify;text-justify:
inter-ideograph;background:white"><span lang="FR">Certaines des fonctions
qu’offrent les cookies sont également disponibles via l’utilisation de
technologies similaires. Bien que la présente politique utilise exclusivement
le terme «&nbsp;cookies&nbsp;», elle couvre également les technologies
susmentionnées.</span></p>

<p class="MsoNormal" style="margin-bottom:11.25pt;background:white"><span lang="FR">Pour en savoir plus sur les cookies, consultez 
le site <a href="http://www.allaboutcookies.org/fr/" title="En savoir plus sur les cookies"><span style="
color:windowtext">http://www.allaboutcookies.org/fr/</span></a></span></p>

<p class="MsoNormal" style="margin-bottom:7.15pt;line-height:14.4pt"><span lang="FR" style="font-size:13.5pt;font-family:&quot;Times New Roman&quot;,serif;color:#020202">Comment
les cookies rendent-ils le site internet plus sûr et plus facile à
utiliser&nbsp;?</span></p>

<p class="MsoNormal" style="margin-bottom:11.25pt;text-align:justify;text-justify:
inter-ideograph;background:white"><span lang="FR">Les cookies sont utilisés pour
diverses raisons, par exemple pour améliorer la fonctionnalité et les
performances du site internet et pour protéger votre vie privée lorsque vous
visitez notre site internet.</span></p>

<p class="MsoNormal" style="margin-bottom:11.25pt;text-align:justify;text-justify:
inter-ideograph;background:white"><span lang="FR">Nous utilisons des cookies
«&nbsp;analytiques&nbsp;» pour nous aider à améliorer la navigation des
visiteurs fréquents sur notre site internet. Ils nous aident à déterminer ce
que les utilisateurs aiment et n’aiment pas et la manière dont nous pouvons
l’améliorer pour vous.</span></p>

<p class="MsoNormal" style="margin-bottom:11.25pt;text-align:justify;text-justify:
inter-ideograph;background:white"><span lang="FR">Si nous incluons des liens vers
d’autres sites internet, nous vous rappelons que ces sites appliquent leurs
propres politiques en matière de confidentialité et de cookies, qui régissent
l’utilisation des informations que vous pourriez être amenés à leur
transmettre. Nous vous recommandons de lire leurs politiques car HSBC n’est pas
responsable de leurs pratiques en matière de confidentialité.</span></p>

<p class="MsoNormal" style="margin-bottom:7.15pt;text-align:justify;text-justify:
inter-ideograph;line-height:14.4pt"><span lang="FR" style="font-size:13.5pt;
font-family:&quot;Times New Roman&quot;,serif;color:#020202">Comment travaillons-nous avec
des tiers de confiance&nbsp;?</span></p>

<p class="MsoNormal" style="margin-bottom:11.25pt;text-align:justify;text-justify:
inter-ideograph;background:white"><span lang="FR">Ces tiers sont des fournisseurs
de confiance de HSBC. Il s’agit notamment de partenaires qui fournissent des
fonctionnalités importantes à notre site internet et de partenaires qui nous
aident à améliorer notre site internet, tels que Webtrends, qui fournit des
statistiques sur les pages les plus consultées sur notre site.</span></p>

<p class="MsoNormal" style="margin-bottom:11.25pt;text-align:justify;text-justify:
inter-ideograph;background:white"><span lang="FR">Nous ne partagerons aucune
information personnelle recueillie par le biais des cookies sur le Site avec
des tiers.</span></p>

<p class="MsoNormal" style="margin-bottom:7.15pt;text-align:justify;text-justify:
inter-ideograph;line-height:14.4pt"><a name="faqs"></a><span lang="FR" style="font-size:13.5pt;font-family:&quot;Times New Roman&quot;,serif;color:#020202">Plus
d’informations sur votre vie privée</span></p>

<p class="MsoNormal" style="margin-bottom:11.25pt;text-align:justify;text-justify:
inter-ideograph;background:white"><span lang="FR">Pour en savoir plus sur la
manière dont HSBC protège votre vie privée lors de l’utilisation de notre site internet,
veuillez lire nos <a href="https://www.hsbc.lu/en-gb/terms-conditions"><span style="
color:windowtext">conditions générales et</span></a> notre <a href="https://www.hsbc.lu/en-gb/privacy-statement"><span style="color:windowtext">déclaration
de confidentialité</span></a>.</span></p>

<p class="MsoNormal" style="margin-bottom:8.0pt;line-height:107%"><span lang="FR" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
color:black">&nbsp;</span></p>

<p class="MsoNormal"><span lang="FR">&nbsp;</span></p>

</div>




</body></html>
`;
