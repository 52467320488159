export const initialState = {
    error: {}
};

export function lockedAccount(state = initialState, action) {
    switch (action.type) {
        case "UPDATE_LOCKED_ACCOUNT_INFO":
            return {
                ...state,
                error: action.error
            };
        default:
            return state;
    }
}
