import React, { Component } from "react";
import { connect } from "react-redux";
import { history } from "util/history";
import _ from "lodash";
import { injectIntl, FormattedMessage } from "react-intl";
import { envConfig } from "env-config";
import { lockedTypeActions } from "../../actions/lockedTypeAction";
import OptionsListPhone from "./OptionsListPhone";
import OptionsListEmail from "./OptionsListEmail";
import EnterCode from "./EnterOTP";
import { currentOTPTypeActions } from "../../actions/currentOTPTypeAction";
import emailOTPcfg from "emailOTP-config";

const isRealmCH = envConfig.realm === emailOTPcfg.realm;

const mapStateToProps = state => {
    return {
        otpType: state.currentOTPType.otpType,
        target: state.currentOTPType.target,
        isMockLogin: state.logon.isMockLogin,
        isLastAttempt: state.logon.isLastAttempt
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateUserLockedType: lockedType => {
            dispatch(lockedTypeActions.updateLockedType(lockedType));
        },
        updateCurrentOTPType: otpType => {
            dispatch(currentOTPTypeActions.updateCurrentOTPType(otpType));
        },
        updateSelectedTarget: target => {
            dispatch(currentOTPTypeActions.updateSelectedTarget(target));
        }
    };
};

class GenericOTP extends Component {
    constructor(props) {
        super(props);

        this.state = {
            phoneTargets: null,
            emailTargets: null,
            activeOption: "",
            selectedOption: "",
            validationError: false,
            resolveInputFromTransmit: () => {
                console.log("resolveInputFromTransmit");
            },
            rejectInputFromTransmit: null,
            currentStep: "optionsList",
            btnSubmitIsLoading: false,
            submitErrorFromTransmit: null
        };
        if (props.storeThis) {
            props.storeThis("GenericOTP", this);
        }
    }

    sendCodeToContact = () => {
        window.tealiumHub.ET007("contactOptions.label.sendCode");
        if (this.state.selectedOption === "") {
            this.setState({
                validationError: true
            });
            setTimeout(() => {
                document.getElementById("selectWarning").focus();
            }, 100);
            return;
        }
        const selectedTarget = this.props.target;
        console.log("selectedTarget: ", selectedTarget);

        const input = window.com.ts.mobile.sdk.TargetBasedAuthenticatorInput.createTargetSelectionRequest(
            selectedTarget
        );
        this.state.resolveInputFromTransmit(
            window.com.ts.mobile.sdk.InputOrControlResponse.createInputResponse(
                input
            )
        );
        this.setState({
            currentStep: "enterCode",
            submitErrorFromTransmit: null
        });
    };

    updateSelectedOption = item => {
        const value = `${item._targetIdentifier};${item._channel}`;
        this.setState({
            activeOption: value,
            selectedOption: value,
            validationError: false
        });
        this.props.updateSelectedTarget(item);
    };

    handleChange = (e, value) => {
        if (value) {
            window.tealiumHub.ET006();
            const { phoneTargets, emailTargets } = this.state;
            const targets =
                this.props.otpType === "SMS"
                    ? phoneTargets || this.props.testPhoneTargets
                    : emailTargets;
            const index = Number(value[0]) - 1;
            const itemSelected = targets[index];
            this.updateSelectedOption(itemSelected);
        }
    };

    submitOTP = digitCodeString => {
        const input = window.com.ts.mobile.sdk.OtpInputOtpSubmission.createOtpSubmission(
            digitCodeString
        );
        const inputTargetBased = window.com.ts.mobile.sdk.TargetBasedAuthenticatorInput.createAuthenticatorInput(
            input
        );

        this.setState({
            btnSubmitIsLoading: true
        });
        if (this.props.isMockLogin) {
            const formInput = window.com.ts.mobile.sdk.FormInput.createFormInputSubmissionRequest(
                {
                    otpvalue: digitCodeString
                }
            );
            this.acceptFN(formInput);
        } else {
            this.state.resolveInputFromTransmit(
                window.com.ts.mobile.sdk.InputOrControlResponse.createInputResponse(
                    inputTargetBased
                )
            );
        }
    };

    resentOTP = () => {
        window.tealiumHub.ET009("countdown.requestButton");
        if (!this.props.isMockLogin) {
            const resend = window.com.ts.mobile.sdk.OtpInputRequestResend.createOtpResendRequest();
            const inputTargetBased = window.com.ts.mobile.sdk.TargetBasedAuthenticatorInput.createAuthenticatorInput(
                resend
            );
            this.state.resolveInputFromTransmit(
                window.com.ts.mobile.sdk.InputOrControlResponse.createInputResponse(
                    inputTargetBased
                )
            );
        }
    };

    clickEmailOTPEntryBtn = () => {
        this.props.updateCurrentOTPType("EMAIL");
        this.setState({
            currentStep: "optionsList"
        });
    };

    prechooseEmailOption = () => {
        const emailItem = this.state.emailTargets[0];
        this.updateSelectedOption(emailItem);
    };

    promiseRecoveryForError = (
        errorObject,
        validRecoveries,
        defaultRecovery
    ) => {
        this.setState({
            btnSubmitIsLoading: false,
            submitErrorFromTransmit: errorObject
        });

        const isRegistrationJourney =
            window.location.href.indexOf("registration") !== -1;
        if (isRegistrationJourney) {
            return Promise.resolve(
                window.com.ts.mobile.sdk.AuthenticationErrorRecovery.Fail
            );
        }

        const errorCode = _.get(errorObject, "_errorCode", false);
        if (errorCode === 11) {
            return history.push("/logon?forcedLogout");
        } else if (errorCode === 1) {
            const locked = _.get(
                errorObject,
                "_data.additional_data.locked",
                false
            );
            if (locked) {
                history.push({
                    pathname: "/commonLockedAccount/globalGenericAccountLock",
                    state: { errorCode: "globalGenericAccountLock" }
                });
                return Promise.resolve(
                    window.com.ts.mobile.sdk.AuthenticationErrorRecovery.Fail
                );
            }
        }

        if (errorCode === 16) {
            history.push("/noContactDetails");
            return Promise.resolve(
                window.com.ts.mobile.sdk.AuthenticationErrorRecovery.Fail
            );
        }
        this.props.updateUserLockedType("OTP");
        const errorRecoveryMethod = isRegistrationJourney
            ? window.com.ts.mobile.sdk.AuthenticationErrorRecovery.Fail
            : window.com.ts.mobile.sdk.AuthenticationErrorRecovery
                  .SelectAuthenticator;
        return Promise.resolve(errorRecoveryMethod);
    };

    setAvailableTargets(mixRawTargets) {
        const targets = mixRawTargets.slice(0);
        const length = targets.length;
        if (length !== 0) {
            console.log("otpType: ", this.props.otpType);
            const lastItem = targets[length - 1];
            const isLastItemEmailAddress = lastItem && lastItem._channel === 2;
            if (isLastItemEmailAddress) {
                this.setState({
                    emailTargets: [targets.pop()]
                });
                console.log("emailTargets::", [lastItem]);
            } else {
                this.setState({
                    emailTargets: []
                });
                console.log("emailTargets::", []);
            }
            const phoneTargets = [...targets].splice(0, 5);
            this.setState({
                phoneTargets
            });

            console.log("phoneTargets::", phoneTargets);
        }
    }
    startSession = () => {
        console.log("startSession");
    };

    endSession = () => {
        console.log("endSession");
    };

    setGeneratedOtp = (format, target) => {
        console.log("setGeneratedOtp::", format, target);
    };

    promiseInput = () => {
        return new Promise((resolve, reject) => {
            this.setState({
                resolveInputFromTransmit: resolve,
                rejectInputFromTransmit: reject
            });
        });
    };

    promiseFormInput = payload => {
        return new Promise((accept, reject) => {
            this.setPromiseHandlers(accept, reject);
        });
    };

    setPromiseHandlers(acceptFN, rejectFN) {
        this.acceptFN = acceptFN;
        this.rejectFN = rejectFN;
    }

    render() {
        const concatName = this.props.isMockLogin
            ? "enterCode-SMS"
            : `${this.state.currentStep}-${this.props.otpType}`;
        const lastAttemptTip = (
            <p className="statement--validation">
                <span className="icon--container">
                    <i className="icon exclamation triangle" />
                </span>
                <span className="error--content">
                    <FormattedMessage id="otp.last.attampt.tip" />
                </span>
            </p>
        );
        if (concatName === "optionsList-SMS") {
            return (
                <OptionsListPhone
                    targets={
                        this.state.phoneTargets || this.props.testPhoneTargets
                    }
                    validationError={this.state.validationError}
                    activeOption={this.state.activeOption}
                    handleChange={this.handleChange}
                    sendCodeToContact={this.sendCodeToContact}
                    isLastAttempt={this.props.isLastAttempt}
                    lastAttemptTip={lastAttemptTip}
                />
            );
        } else if (concatName === "enterCode-SMS") {
            return (
                <EnterCode
                    resentOTP={this.resentOTP}
                    submitOTP={this.submitOTP}
                    clickEmailOTPEntryBtn={this.clickEmailOTPEntryBtn}
                    isRealmCH={isRealmCH}
                    isLoading={this.state.btnSubmitIsLoading}
                    isLastAttempt={this.props.isLastAttempt}
                    submitErrorFromTransmit={this.state.submitErrorFromTransmit}
                />
            );
        } else if (concatName === "optionsList-EMAIL") {
            return (
                <OptionsListEmail
                    targets={this.state.emailTargets}
                    activeOption={this.state.activeOption}
                    handleChange={this.handleChange}
                    sendCodeToContact={this.sendCodeToContact}
                    prechooseEmailOption={this.prechooseEmailOption}
                    isLastAttempt={this.props.isLastAttempt}
                    lastAttemptTip={lastAttemptTip}
                />
            );
        } else if (concatName === "enterCode-EMAIL") {
            return (
                <EnterCode
                    resentOTP={this.resentOTP}
                    submitOTP={this.submitOTP}
                    isRealmCH={isRealmCH}
                    isLoading={this.state.btnSubmitIsLoading}
                    isLastAttempt={this.props.isLastAttempt}
                    submitErrorFromTransmit={this.state.submitErrorFromTransmit}
                />
            );
        } else {
            return null;
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(GenericOTP));
