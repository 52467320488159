import { tc2 } from "./T&C2";

export default `
<html><head>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8">
<title>T&amp;C_sc</title>
    <style>
		.level1{
		margin-left:1.5cm;
	    text-indent:-30pt;
		}
        .level1 .left{		
		    display: inline-block;
			width: 1em;
			text-align:right}
        .level1 .right{
		    margin-left:0.58cm;
			text-align:left}	
	
        .list{
		list-style: none;		 
		margin-left:0.4cm;
	    text-indent:-24pt;
		text-align:left;
		}

		.item .left{	
		    display: inline-block;	
			text-align:right;}
        .item .right{
		    margin-left:0.9cm;
			text-align:left}
			
        .list2{
		list-style: none;		 
		margin-left:1.5cm;
	    text-indent:-24pt;
		text-align:left;
		}
		
		.item2 .left{	
		    display: inline-block;			
			width: 1em;
			text-align:right}
        .item2 .right{	
		    margin-left:0.38cm;
			text-align:left}

        .list3{
		list-style: none;		 
		margin-left:0.4cm;
	    text-indent:-20pt;
		text-align:left;
		}

		.item3 .left{
		    width:1em;
			text-align:left;}
        .item3 .right{	
		    margin-left:0.2cm;	
			text-align:left}
        h3 span:last-child {
            margin-left: 0.7em !important;
        }

        h2 {
            font-size: 16px;
            font-weight: 700;
        }
        
        h3 {
            font-size: 14px;
            font-weight: 700;
        }
    </style>
</head>
<body>  
      
      <br>
      <section>
        <p style="margin-left:0.8cm;">本行网上银行平台所提供服务的条款载列如下。在前往下一页之前，请仔细阅读有关条款：
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">
                <b>A.</b>
              </span>
              <span class="right">
                <b>网上银行服务的条款及细则</b>，当中载列阁下存取及使用汇丰环球私人银行网上银行平台的条款及细则<b>；</b>
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">
                <b>B.</b>
              </span>
              <span class="right">
                <b>汇丰私人银行视野及研究的条款及细则</b>，当中载列阁下可通过汇丰环球私人银行网上银行平台存取及使用的汇丰私人银行视野及研究的额外条款及细则<b>；</b>
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">
                <b>C.</b>
              </span>
              <span class="right">
                <b>推播通知服务的条款及细则</b>，当中载列本行向阁下提供并且与阁下可通过汇丰环球私人银行网上银行平台存取或使用的产品及服务有关的推播通知服务的额外条款及细则<b>；</b>及
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">
                <b>D.</b>
              </span>
              <span class="right">
                <b>电子签署服务的条款及细则</b>，当中载列任何使用电子签署签立的文件适用的额外条款及细则。
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">
                <b>E.</b>
              </span>
              <span class="right">
                <b>可疑委托警示模式的条款及细则</b>，此乃与潜在可疑转账交易的警示有关。
              </span>
            </p>
          </li>
        </ol>
      </section>
      <section>
        <br>
        <div>
          <h2 align="center">A.	网上银行服务的条款及细则</h2>
        </div>
        <br>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">1</span>
          <span class="right">定义及解释</span>
        </h3>
        <p class="level1">
          <span class="left">1.1</span>
          <span class="right">除本文另有界定外，本行的标准条款与条件（可经不时修订）（按<a href="https://www.privatebanking.hsbc.com/content/dam/privatebanking/gpb/utilities/mobile/Standard%20Terms%20and%20Conditions%20-%20November%2022%20edition%20-%20Simplified%20Chinese%20version.pdf">此</a>查阅，“<b>标准条款与条件</b>”）中界定的表述于本网上银行服务的条款及细则（“<b>网上银行条款</b>”）中使用时具有相同涵义。如本网上银行条款与标准条款与条件之间有任何分歧，概以本网上银行条款为准。
          </span>
        </p>
        <p class="level1">
          <span class="left">1.2</span>
          <span class="right">
            于本网上银行条款中，除文义另有所指外，以下词汇具有以下涵义：
          </span>
        </p>
        <ol class="list">
          <li class="item">
            <p>
              <span class="right">
                “<b>指定网上用户</b>”指获阁下授权使用阁下账户的网上银行服务的指定人士（包括阁下）。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                “<b>信息</b>”指本行通过任何互联网网站提供由任何信息提供商提供的金融、市场或其他信息及数据，包括B部中界定的“刊物”所包含的任何有关信息。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">“<b>信息提供商</b>”指提供信息的任何人士或实体，包括提供信息的本行及任何集团办事处，以及提供任何信息给信息提供商的任何其他人士或实体。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">“<b>互联网网站</b>”指由本行或任何其他人士代表本行建立、运作及╱或维护以提供网上银行服务的互联网网站或其他平台，包括移动银行应用程序。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">“<b>网上银行服务</b>”指经不时修订的下文第2条（<i>网上银行服务</i>）所介绍的服务，包括根据B部提供的视野及研究服务、移动银行应用程序服务及根据D部提供的电子签署。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">“<b>网上银行条款</b>”指网上银行服务的条款及细则。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">“<b>移动银行应用程序</b>”指本行提供的应用程序，下载至阁下的移动装置即可通过该程序使用移动银行应用程序服务。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">“<b>移动银行应用程序服务</b>”指向阁下提供的服务，藉以让阁下通过移动银行应用程序使用网上银行服务，包括C部中界定的推播通知服务。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">“<b>密码</b>”指指定网上用户使用的密码，以便使用网上银行服务。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">“<b>报告</b>”指以任何形式、媒介或途径利用信息编制的任何报告，包括B部中界定的任何“刊物”。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">“<b>安全码</b>”指由本行提供并用作使用网上银行服务的任何代码。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">“<b>标准条款与条件</b>”具有本网上银行条款第1条（<i>定义及解释</i>）所赋予的涵义。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">“<b>本行</b>”或具有该或类似涵义的词语指本行。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">“<b>用户名</b>”指本行接受的指定网上用户的用户识别代码，以便使用网上银行服务。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">“<b>阁下</b>”或具有该或类似涵义的词语指客户，如文义许可，包括经客户授权就使用网上银行服务向本行发出指示或要求的任何人士，包括任何指定网上用户。
              </span>
            </p>
          </li>
        </ol>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">2</span>
          <span class="right">网上银行服务</span>
        </h3>
        <p class="level1">
          <span class="left">2.1</span>
          <span class="right">本行可不时通过任何互联网网站提供网上服务以便阁下向本行发出通信，从而操作客户账户、进行银行事务、投资、理财及任何性质的买卖及交易，以及获取本行不时提供的服务、产品、信息、报告、利益及优惠。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.2</span>
          <span class="right">本行有权不时决定并修订所提供的网上银行服务范围及类别，包括但不限于：
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">随时增加、修改或减少网上银行服务；</span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">制订或更改使用网上银行服务（或该服务任何部分）的限制，例如限制阁下使用网上银行服务每日进行任何买卖或交易的类别或任何买卖或交易的最高或最低交易额；及
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">指定及更改网上银行服务（或该服务任何部分）的正常服务时间，以及任何类别的网上银行服务、买卖或交易的每日截止时间。本行在适用的每日截止时间后收到阁下的任何指示，将被视为在下一营业日收到。本行可根据不同时区的市场运作时间，指定网上银行服务的营业日及每日截止时间。
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">2.3</span>
          <span class="right">本行可酌情全面或就任何客户账户或一组客户账户暂停或终止网上银行服务，但在任何有关情况下，如阁下受到影响，本行须尽可能通知阁下，但在有合理必要保障本行合法权益的情况下，或根据任何法律或为了金融犯罪管理活动（包括但不限于为防止诈骗之目的），则可无须事先通知而暂停或终止。如阁下就网上银行服务的使用遭暂停、限制或终止，本行可结束任何只能通过网上银行服务使用的账户、产品或服务，阁下将不再收到电子账单，阁下也应从阁下的装置删除本行的移动银行应用程序（如适用）。</span>
        </p>
        <p class="level1">
          <span class="left">2.4</span>
          <span class="right">本行可要求阁下就使用网上银行服务而指定或登记特定客户账户。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.5</span>
          <span class="right">阁下可向本行发出事先书面通知而随时终止使用网上银行服务及╱或终止对任何指定网上用户的授权。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.6</span>
          <span class="right">为使其涵义得以施行，本网上银行条款中所有需要在网上银行服务暂停或终止及／或阁下暂停或终止使用网上银行服务后维持有效的条文（包括但不限于第4条（<i>一般免责声明</i>）、第7条（<i>用户名及密码与安全</i>）、第9条（<i>信息及信息提供商</i>）、第11条（<i>本行的责任</i>）及第12条（<i>客户的责任</i>））于暂停或终止后仍具有十足效力及作用。尽管有此等暂停或终止，只要该等条文与阁下仍未履行或解除的任何义务或法律责任有关，阁下仍须继续受本网上银行条款约束。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">3</span>
          <span class="right">适用条款</span>
        </h3>
        <p class="level1">
          <span class="left"></span>
          <span class="right">使用网上银行服务进行任何买卖及交易，须受制于本网上银行条款、标准条款与条件及所有其他管辖有关客户账户、交易、买卖、服务、产品、信息、报告、利益或优惠的账户文件。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">4</span>
          <span class="right">一般免责声明</span>
        </h3>
        <p class="level1">
          <span class="left">4.1</span>
          <span class="right">所有阁下可通过网上银行服务进行的交易（不时提供者）纯属执行性质并根据阁下自身的判断进行。本行并无责任评估或确保阁下通过网上银行服务买卖的产品或进行的交易是否合适。如本行以书面形式明示将本第4.1条摒除于适用范围之外，则本第4.1条并不适用。
          </span>
        </p>
        <p class="level1">
          <span class="left">4.2</span>
          <span class="right">如果适用法律要求为其作出合适程度评估，则本行可能已向或为阁下作出的招揽或推荐仅在作出时有效，且本行无法确保有关产品是否持续合适。通过使用网上银行服务执行某个产品的交易，即表示阁下明白并接受，如果与阁下、该产品或其发行人或市况相关的情况有变，则该产品可能不再适合阁下，而阁下使用网上银行服务进行交易的风险概由阁下承担。
          </span>
        </p>
        <p class="level1">
          <span class="left">4.3</span>
          <span class="right">阁下有责任自行通过阁下惯常的买卖渠道，独立决定买卖的市价及利率；并有责任在依赖任何信息及╱或报告或据此行事前，自行予以核证。阁下也有责任就使用网上银行服务、信息及报告以及进行的任何买卖及交易在所有适用法律下对阁下可能产生的影响，寻求法律、税务及其他方面的独立专业意见。
          </span>
        </p>
        <p class="level1">
          <span class="left">4.4</span>
          <span class="right">本行通过互联网网站或为回复网上查询而提供的任何汇率、利率、买卖报价及其他价格和信息仅供参考，而不具任何约束力。尽管本行曾提供不同的利率、报价或信息，阁下一经确认接受本行就有关交易而提供的任何利率、报价及信息，即对阁下有约束力。
          </span>
        </p>
        <p class="level1">
          <span class="left">4.5</span>
          <span class="right">阁下明白通过互联网或电子邮件使用服务有相关风险，包括但不限于未经授权使用、系统故障、电信服务及互联网延误及中断。网上银行服务的反应时间可能会因市场状况、系统性能、存取设备位置或其他因素而有所不同，而信息也可能在需求高峰、市场波动、系统升级、维护期间或因任何其他原因而遭限制或无法存取。阁下确认，通信中断或故障、软件设备故障、阁下或本行的计算机系统因任何原因发生故障，或未有更新阁下的装置浏览器或作业系统或移动银行应用程序均可能会妨碍或限制阁下使用网上银行服务的能力，如中断或故障超出本行的合理控制范围，本行不会对任何有关中断或故障承担任何责任。
          </span>
        </p>
        <p class="level1">
          <span class="left">4.6</span>
          <span class="right">网上银行服务只在选定的司法管辖区提供。可使用网上银行服务存取的信息不拟在任何司法管辖区分发给有关分发将违反该司法管辖区的任何法例或规例的任何人士或供其使用。本行并不保证网上银行服务或其中的信息符合其他司法管辖区的法律或适合在其他司法管辖区使用。
          </span>
        </p>
        <p class="level1">
          <span class="left">4.7</span>
          <span class="right">阁下确认，阁下可在或通过网上银行服务检视的任何产品或服务可能未经本行获发牌或授权提供有关产品或服务的任何司法管辖区以外或阁下的居住或所在地的任何中央银行、政府或监管机构注册或授权。因此，就阁下可在或通过网上银行服务检视的产品或服务而言，阁下可能不受本行获发牌或授权提供有关产品或服务的任何司法管辖区以外或阁下的居住地的证券法、银行法、保险法或其他相关法律保障。
          </span>
        </p>
        <p class="level1">
          <span class="left">4.8</span>
          <span class="right">如阁下通过移动应用程序商店存取及／或下载本行的移动银行应用程序，阁下确认：
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">本行对移动银行应用程序的效能负责，阁下应就与本行的移动银行应用程序相关的任何维护及支持事宜、内容事宜、故障或其他申索与本行联系；及
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">当阁下使用本行的移动银行应用程序时，就本网上银行条款而言，有关移动应用程序商店被视为第三方受益人，其可依赖本网上银行条款。
              </span>
            </p>
          </li>
        </ol>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">5</span>
          <span class="right">指定网上用户</span>
        </h3>
        <p class="level1">
          <span class="left">5.1</span>
          <span class="right">阁下须：</span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">指定阁下授权的人士就客户账户使用网上银行服务，并按本行不时指定的方式及形式限制各有关人士所获授权范围；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">就指定网上用户的邮寄地址及╱或电邮地址的任何更改知会本行；及
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">确保所有指定网上用户遵守本网上银行条款及标准条款与条件。
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">5.2</span>
          <span class="right">阁下及各指定网上用户须提供本行为提供网上银行服务而不时合理要求的信息。
          </span>
        </p>
        <p class="level1">
          <span class="left">5.3</span>
          <span class="right">阁下须就使用网上银行服务负责，有责任采取适当措施以监控网上银行服务的使用情况，并负责指定和更换指定网上用户，也须采取恰当的安全措施，以免网上银行服务被未经授权人士使用或被用作未经授权用途，包括但不限于本网上银行条款第7条（<i>用户名及密码</i>）中载明的措施。
          </span>
        </p>
        <p class="level1">
          <span class="left">5.4</span>
          <span class="right">阁下明白，如阁下已委任指定网上用户使用任何网上银行服务，该指定网上用户为及代表阁下接受并同意本网上银行条款，而阁下也同意受指定网上用户的有关接受及／或同意约束。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">6</span>
          <span class="right">使用网上银行服务</span>
        </h3>
        <p class="level1">
          <span class="left">6.1</span>
          <span class="right">网上银行服务只供网上银行服务订用客户使用。</span>
        </p>
        <p class="level1">
          <span class="left">6.2</span>
          <span class="right">阁下不得进行或试图进行以下任何活动：</span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">反编译、反向工程、翻译、转换、改编、改变、修改、提升、增添、删除或以任何方式干扰或进入网上银行服务的任何部分或任何互联网网站或其中组成的任何软件；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">使用或在知情下容许任何其他人士使用网上银行服务、信息及╱或报告作任何非法目的或活动；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">移除或试图移除网上银行服务中的任何标题或商标、版权或限制权利通知；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(D)</span>
              <span class="right">使用任何自动化工具（即机器人）存取网上银行服务或执行活动；及／或
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(E)</span>
              <span class="right">允许使用任何安全测试工具以提供、扫描或试图渗透或评估网上银行服务的安全性，
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left"></span>
          <span class="right">阁下如察觉任何有关活动，须尽快通知本行。</span>
        </p>
        <p class="level1">
          <span class="left">6.3</span>
          <span class="right">阁下不得上传或散布包含病毒或其他恶意程式码的档案到网上银行服务，也不得作出任何其他行为破坏或试图破坏用于提供网上银行服务的系统及网络。一旦发现网上银行服务实际或怀疑遭病毒或其他恶意程式码入侵，阁下必须立即通知本行并与本行合作以减低有关病毒或恶意程式码的影响。
          </span>
        </p>
        <p class="level1">
          <span class="left">6.4 </span>
          <span class="right">阁下确认经由互联网传送的信息或通信，可能会出现时间延迟。
          </span>
        </p>
        <p class="level1">
          <span class="left">6.5</span>
          <span class="right">阁下确认：</span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">阁下就使用网上银行服务提供的任何信息可能会向本行的业务部门、任何集团办事处及／或可能位于其他司法管辖区的授权服务提供商披露或供其存取；及
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">阁下使用网上银行服务可能会受到本行、任何集团办事处及／或授权服务提供商监控，而本行可能出于内部业务考虑或任何适用法律而收集及使用由此产生的任何信息，
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left"></span>
          <span class="right">
            一切均按照标准条款与条件的条款进行，阁下并且同意有关披露、监控及／或使用。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">7</span>
          <span class="right">用户名及密码和安全</span>
        </h3>
        <p class="level1">
          <span class="left">7.1</span>
          <span class="right">各指定网上用户须按照本行不时规定的程序指定其用户名及密码。
          </span>
        </p>
        <p class="level1">
          <span class="left">7.2</span>
          <span class="right">各指定网上用户可随时更改用户名及密码，但任何更改须在本行接受后方为有效。
          </span>
        </p>
        <p class="level1">
          <span class="left">7.3</span>
          <span class="right">本行可行使绝对酌情权要求阁下使用安全码，以便使用网上银行服务。
          </span>
        </p>
        <p class="level1">
          <span class="left">7.4</span>
          <span class="right">各指定网上用户须真诚行事，在合理谨慎尽责的原则下采取一切合理措施，将用户名、密码及安全码保密以防盗用情况发生。无论任何时间或情况，指定网上用户均不得向任何其他人士披露用户名、密码及╱或安全码。
          </span>
        </p>
        <p class="level1">
          <span class="left">7.5</span>
          <span class="right">如果指定网上用户的用户名、密码及╱或安全码不慎或在未经授权的情况下被泄露予其他人士知悉，阁下须负全责，并须承担指定网上用户的用户名、密码、安全码或移动装置被未经授权人士使用或被用作未经授权用途的风险。
          </span>
        </p>
        <p class="level1">
          <span class="left">7.6</span>
          <span class="right">
            阁下如在移动银行应用程序中激活生物识别凭据使用功能（例如指纹认证或人脸识别），并允许使用有关生物识别凭据登录移动银行应用程序或本行可能不时支持的任何其他网上银行服务（只面向兼容装置），便须确保只在装置上登记阁下自己的生物识别凭据。阁下确认并同意：
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">本行无法控制移动装置的技术、可用性、性能、验证方法、安全性及可靠性，因此概不就任何未经授权人士通过生物识别凭据登录网上银行服务的情况承担责任；及
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">阁下不得采取任何行动以停用阁下装置提供的任何功能或同意装置的任何设置，从而危及使用生物识别凭据进行验证的安全性（例如停用人脸识别的“注视感知功能”）。
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">7.7</span>
          <span class="right">阁下或有关指定网上用户如发现或怀疑用户名、密码及╱或安全码被披露给未经授权人士，或网上银行服务被未经授权使用，须尽快亲身通知本行，或根据本行不时指定的电话号码致电通知本行。本行可要求阁下或有关指定网上用户以书面形式确认所提供的信息。在本行实际收到该等通知前，阁下须就任何及所有因未经授权人士使用网上银行服务或网上银行服务被用作未经授权用途负责。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">8</span>
          <span class="right">客户指示</span>
        </h3>
        <p class="level1">
          <span class="left">8.1</span>
          <span class="right">各指定网上用户须先输入其用户名、密码、安全码及本行不时规定的任何其他标识符，方可登录互联网网站使用网上银行服务及代表阁下在网上向本行发出通信。本行获授权按各指定网上用户的通信行事。
          </span>
        </p>
        <p class="level1">
          <span class="left">8.2</span>
          <span class="right">指定网上用户须在本行不时指定的网址及联系点向本行发出通信。除非本行另行明确同意，否则指定网上用户不得向本行的个别主管人员发出任何通信。
          </span>
        </p>
        <p class="level1">
          <span class="left">8.3</span>
          <span class="right">通信须按本行不时指定的方式发出及在本行实际收到后，始视为经由本行收妥。
          </span>
        </p>
        <p class="level1">
          <span class="left">8.4</span>
          <span class="right">任何通信一经发出，如未得本行同意，概不得废除或撤回。除核对有关指定网上用户的用户名、密码、安全码及其他有关标识符（如有）外，本行并无责任核证作出任何通信人士的身分或授权，或此等通信的真实性。
          </span>
        </p>
        <p class="level1">
          <span class="left">8.5</span>
          <span class="right">本行只会执行本行认为合理可行的通信，并将遵照正常业务惯例及程序行事。如本行得悉或怀疑存在与使用网上银行服务相关的安全漏洞或其他可疑情况，本行有权延迟或拒绝按通信行事。
          </span>
        </p>
        <p class="level1">
          <span class="left">8.6</span>
          <span class="right">所有经由本行按照通信代阁下办理的交易均对阁下具有约束力。
          </span>
        </p>
        <p class="level1">
          <span class="left">8.7</span>
          <span class="right">若阁下选择并同意，本行可在网上向阁下发出已收讫通信及╱或已执行交易或买卖的通知或确认。阁下同意查核有关通知或确认以确保其准确及完整。如果阁下未有收到通知或确认，或阁下相信有关通知或确认中的任何信息为不准确或不完整，阁下将向本行查询。
          </span>
        </p>
        <p class="level1">
          <span class="left">8.8</span>
          <span class="right">互联网网站上提供有关任何账户、交易或买卖的信息均仅供参考。本行对该等账户、交易或买卖纪录，如无欺诈或明显错误情况，均为该等纪录的证据。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">9</span>
          <span class="right">信息提供商</span>
        </h3>
        <p class="level1">
          <span class="left">9.1</span>
          <span class="right">信息提供商可就其提供的任何信息不时制订有关的条款及细则，并将事先通知该等条款及细则的生效日期。阁下在该等条款及细则生效当日或之后使用该等信息，即表示阁下接纳该等条款及细则。
          </span>
        </p>
        <p class="level1">
          <span class="left">9.2</span>
          <span class="right">信息及报告只供参考。</span>
        </p>
        <p class="level1">
          <span class="left">9.3</span>
          <span class="right">除在适用法律规定或任何有关文件订明的范围内，本行及任何信息提供商对任何信息或报告的次序、准确性、真实性、可靠性、充足性、及时性或完整性，或其是否适宜作任何用途，概不作出保证、声明或担保。阁下同意独立评估及核实任何信息或报告中的有关事宜，除在适用法律规定或任何有关文件订明的范围内，本行及任何信息提供商不会就阁下或任何其他人士因依赖信息或报告而承担任何法律责任（不论是侵权或合约或其他责任）。然而，本条款并不排除本行或信息提供商根据适用法律就行为、声明或陈述对阁下或任何其他人士可能承担的任何责任。
          </span>
        </p>
        <p class="level1">
          <span class="left">9.4</span>
          <span class="right">信息将按其由信息提供商所提供的形式提供。本行不会就任何其他信息提供商提供的信息作出批注或表示任何意见，也无责任对有关信息进行检查或核证。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">10</span>
          <span class="right">保密信息</span>
        </h3>
        <p class="level1">
          <span class="left">10.1</span>
          <span class="right">保密信息属本行及有关信息提供商的商业秘密、机密及专有财产。
          </span>
        </p>
        <p class="level1">
          <span class="left">10.2</span>
          <span class="right">除本网上银行条款或本行明文许可外，阁下不得进行或试图进行以下任何活动：
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">将任何保密信息向任何其他人士出售、转移、披露、出让、转达、出租、再授权、分享、借出、分派、传输、广播、电缆广播、传阅、复印，或以其他方式以任何形式或任何手段提供或散布，或将任何保密信息作商业用途；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">下载或复制任何保密信息作阁下个人参考及使用以外的其他用途；及／或
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">将保密信息上显示的任何所有权标记（包括但不限于任何商标或版权通告）以任何方式移除、涂掉、擦去、迁移或更改。
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">10.3</span>
          <span class="right">在下列情况下，披露限制并不适用于下列任何保密信息：
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">法律强制规定作出披露，但只限于法律规定的范围，及只在阁下已经向本行发出书面通知，说明要求披露的保密信息；或
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">本行已书面明确同意披露的保密信息。</span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">10.4</span>
          <span class="right">有关保密信息的一切权利、所有权及权益，以及任何及所有有关版权、专利权、商标、服务标记、专有财产、商业秘密及专用作品，均属本行及╱或有关信息提供商的独家财产。此等权利、所有权或权益（除根据本网上银行条款有权使用信息及报告外）均没有转移或转让予阁下。阁下不得作出显示阁下拥有任何此等权利、所有权或权益的任何声明或作为。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">11</span>
          <span class="right">本行的责任</span>
        </h3>
        <p class="level1">
          <span class="left">11.1</span>
          <span class="right">本行将根据不时适用于本行的任何法律、通告、行为守则及现行市场惯例，采取合理可行的步骤，以确保与网上银行服务有关的系统已装置足够的安全设施，并在系统运作时，对有关风险予以监控。
          </span>
        </p>
        <p class="level1">
          <span class="left">11.2</span>
          <span class="right">本行、任何集团办事处或任何信息提供商，概不保证或声明网上银行服务、信息及报告不含有任何对阁下硬件、软件或设备造成不利影响的病毒或其他破坏性程序。
          </span>
        </p>
        <p class="level1">
          <span class="left">11.3</span>
          <span class="right">除非引用下文第12.2条，或由于本行、任何集团办事处或本行╱其各自的主管人员或雇员的欺诈、疏忽或故意失责所引致，本行或任何集团办事处概不就以下事项所引起或与之有关的后果，而向阁下或任何其他人士承担任何责任：
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">由于阁下或任何其他不论是否获授权的人士使用网上银行服务及╱或取得任何信息；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">由于在提供网上银行服务、传送与网上银行服务有关的信息或通信或与互联网网站连线时因任何本行的作为、不作为或本行所能合理控制范围以外的情况，包括但不限于通信网络失灵、提供服务的第三方的作为或不作为、机械故障、电力故障、失灵、操作故障，或设备、装置或设施不足，或因任何法律、守则、指令、监管指引或政府命令（不论是否具法律效力）而出现任何干扰、截取、中断、延误、损失、无法提供信息、毁坏或其他故障（包括但不限于任何干扰、截取、中断、延误或无法显示安全码）；及
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">通过或在任何通信网络提供商的系统、设备或仪器传送及╱或储存任何与阁下及╱或网上银行服务有关的信息及╱或数据。
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">11.4</span>
          <span class="right">本行、任何集团办事处或任何信息提供商，概无需对阁下或任何其他人士就任何偶发性、间接、特殊、相应或惩罚性损害赔偿负责，包括但不限于有关使用、收入、利润或储蓄方面的任何损失责任。本条款不适用于本行／彼等欺诈、疏忽或故意失责的情况。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">12</span>
          <span class="right">客户的责任</span>
        </h3>
        <p class="level1">
          <span class="left">12.1</span>
          <span class="right">除非引用下文第12.2条或除非本行存在欺诈、疏忽或故意失责的行为，否则因阁下或任何其他人士（不论是否获得授权）使用网上银行服务及／或因使用网上银行服务取得任何信息或报告或任何其他信息而引起或与之有关的后果，概由阁下全部承担。
          </span>
        </p>
        <p class="level1">
          <span class="left">12.2</span>
          <span class="right">根据第7条（<i>用户名及密码</i>）下阁下责任的限制，及如果本行合理地认为阁下并无严重疏忽或欺诈，则阁下无需就使用网上银行服务进行未经授权交易而产生的损失或资金错置负责。
          </span>
        </p>
        <p class="level1">
          <span class="left">12.3</span>
          <span class="right">除非引用上文第12.2条，或由于本行、任何集团办事处、任何信息提供商或本行╱其各自的主管人员或雇员的欺诈、疏忽或故意失责所致，否则阁下须弥偿本行、任何集团办事处、任何信息提供商及其各自的主管人员及雇员因提供网上银行服务、信息及╱或报告或行使或保存本行在本网上银行条款及标准条款与条件赋予的权力及权利或因提起或招致的任何法律行动或诉讼所合理及适当地招致的一切法律责任、索偿、申索、损失、损害赔偿、讼费、任何形式的费用及开支（包括但不限于按全数赔偿原则支付法律费用）。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">13</span>
          <span class="right">修订</span>
        </h3>
        <p class="level1">
          <span class="left"></span>
          <span class="right">本行可不时对本网上银行条款进行修订及／或增补。网上银行条款的任何修订及╱或增补在本行通过互联网网站刊登或按照本行认为合适的其他方式向阁下发出事先合理通知后，即告生效。如果阁下在修订生效当日或之后继续维持或使用网上银行服务，则有关修订将对阁下具有约束力。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">14</span>
          <span class="right">通知及通信形式</span>
        </h3>
        <p class="level1">
          <span class="left">14.1</span>
          <span class="right">本行可不时根据本网上银行条款就各类通知订明其通知形式（不论书面通知或其他形式）及通信模式。
          </span>
        </p>
        <p class="level1">
          <span class="left">14.2</span>
          <span class="right">以电邮发出的通信在传送至阁下最近以书面形式通知本行的电邮邮址后，即视作阁下已收悉。
          </span>
        </p>
      </section>
      <section>
        <br>
        <div>
          <h2 align="center">B.	汇丰私人银行视野及研究的条款及细则</h2>
        </div>
        <br>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">1</span>
          <span class="right">本条款及细则的简介</span>
        </h3>
        <p class="level1">
          <span class="left">1.1</span>
          <span class="right">阁下使用由本行提供的汇丰私人银行视野及研究服务（“<b>视野及研究服务</b>”）受以下条款规限：
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">本汇丰私人银行视野及研究的条款及细则（“<b>视野及研究条款</b>”）；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">网上银行服务的条款及细则（“<b>网上银行条款</b>”）；及
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">本行的标准条款与条件（按<a href="https://www.privatebanking.hsbc.com/content/dam/privatebanking/gpb/utilities/mobile/Standard%20Terms%20and%20Conditions%20-%20November%2022%20edition%20-%20Simplified%20Chinese%20version.pdf">此</a>查阅），
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left"></span>
          <span class="right">以上条款统称为“<b>条款</b>”。如本视野及研究条款与网上银行条款或标准条款与条件有任何冲突，则须按以上次序决定优先顺序（从高到低）。本行可在向阁下发出合理通知后不时修订视野及研究条款。
          </span>
        </p>
        <p class="level1">
          <span class="left">1.2</span>
          <span class="right">视野及研究条款中使用但未有另行界定的词汇与网上银行条款及标准条款与条件所赋予的涵义相同。
          </span>
        </p>
        <p class="level1">
          <span class="left">1.3</span>
          <span class="right">为免生疑问，网上银行条款中界定的“网上银行服务”及“互联网网站”将分别包括视野及研究服务及视野及研究网站（“<b>视野及研究</b>”）。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">2</span>
          <span class="right">存取及使用刊物</span>
        </h3>
        <p class="level1">
          <span class="left">2.1</span>
          <span class="right">本行可向阁下及／或阁下的指定网上用户提供视野及研究的存取权限（各自称为“<b>用户</b>”），这是让用户（其中包括）存取以下若干事项的电子平台：
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">环球私人银行宏观金融市场评论（“<b>环球私银宏观报告</b>”）；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">由本行的环球市场部门下属的环球研究部（“<b>汇丰环球研究</b>”）提供的汇丰环球研究报告（“<b>汇丰研究报告</b>”）及／或
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">非汇丰的第三方刊物（“<b>第三方刊物</b>”）
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left"></span>
          <span class="right">（本文统称为“<b>刊物</b>”）。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.2</span>
          <span class="right">刊物可能会讨论多个资产类别的金融市场及投资产品，并可能包括涵盖特定金融工具及发行人的金融研究材料。各类用户可存取的刊物种类将由本行酌情决定。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.3</span>
          <span class="right">只有已向本行提供详细信息并已获本行批准为用户的人士方可使用视野及研究服务。阁下有责任确保阁下指定的各用户均已阅读并明白条款。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.4</span>
          <span class="right">本行将让各用户通过网上银行服务经由视野及研究存取刊物。用户也可通过电邮从其客户关系经理或投资顾问收取刊物，或使用视野及研究的订阅提示功能。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.5</span>
          <span class="right">刊物只提供英文版本予各用户。阁下提名任何用户，即确认各用户均具备充分的英语水平，能够完全明白刊物的内容及当中可能包含的任何重要免责声明或风险警告。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.6</span>
          <span class="right">用户不得尝试直接联系任何刊物的发行人，包括但不限于任何汇丰环球研究分析师或第三方，或其任何代表或雇员。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">3</span>
          <span class="right">金融知识、经验及阅读能力</span>
        </h3>
        <p class="level1">
          <span class="left"></span>
          <span class="right">刊物可能涵盖不同的主题及产品类别，包括但不限于气候变化、商品、可转换证券、高等级信用、高收益信用、信用策略、货币、经济、股票策略、固定收益、利率、结构性及资产抵押证券、股权及结构性产品。刊物会涉及市场、经济及财务数据、概念及比率，以及其他技术信息或概念，各用户需要具备相当程度的金融知识、经验及阅读能力。阁下提名任何用户，即确认各用户均具备足分的金融知识、经验及阅读能力以阅读及明白刊物及其限制，特别是下文第6.1及6.2条所述者，并将在评估通过视野及研究定期提供的材料时行使独立判断。在理解任何刊物时遇到困难的用户应联系负责客户账户的客户关系经理或投资顾问。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">4</span>
          <span class="right">限制存取、安全性及系统可用性</span>
        </h3>
        <p class="level1">
          <span class="left"></span>
          <span class="right">刊物并非给予也不拟分发予任何身为有关分发、出版、提供或使用会违反法例或规例的任何司法管辖区的公民或居民或位于有关司法管辖区的人士或实体的用户或供其使用。本行将根据各用户居住记录的司法管辖区以及本行对各有关司法管辖区适用的法例及监管规定的评估，向各用户提供刊物的存取权。各用户确认其并非任何存取刊物可能会违反当地法例或规例的国家的居民。各用户均有责任在其改变居籍的司法管辖区时立即通知本行。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">5</span>
          <span class="right">知识产权</span>
        </h3>
        <p class="level1">
          <span class="left"></span>
          <span class="right">刊物只供各用户个人专用。刊物仍属本行、本行的部门（不包括私人银行部门）、任何集团办事处的其他成员（例如汇丰环球研究）或制作及出版刊物的第三方的财产，未经本行明确书面同意，用户并无获得任何权利以任何方式或形式复制或分发全部或部分刊物或其内容，并且只有在明确承诺确保作允许用途时充分注明来源的情况下方可复制或分发刊物。
          </span>
        </p>
      </section>

` + tc2;
