import { pp2 } from "./PP2";
export default `<html><head>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8">
<meta name="Generator" content="Microsoft Word 15 (filtered)">

<style id="dynCom" type="text/css"><!-- --></style>
<script language="JavaScript"><!--
function msoCommentShow(anchor_id, com_id)
{
	if(msoBrowserCheck()) 
		{
		c = document.all(com_id);
		a = document.all(anchor_id);
		if (null != c && null == c.length && null != a && null == a.length)
			{
			var cw = c.offsetWidth;
			var ch = c.offsetHeight;
			var aw = a.offsetWidth;
			var ah = a.offsetHeight;
			var x  = a.offsetLeft;
			var y  = a.offsetTop;
			var el = a;
			while (el.tagName != "BODY") 
				{
				el = el.offsetParent;
				x = x + el.offsetLeft;
				y = y + el.offsetTop;
				}
			var bw = document.body.clientWidth;
			var bh = document.body.clientHeight;
			var bsl = document.body.scrollLeft;
			var bst = document.body.scrollTop;
			if (x + cw + ah / 2 > bw + bsl && x + aw - ah / 2 - cw >= bsl ) 
				{ c.style.left = x + aw - ah / 2 - cw; }
			else 
				{ c.style.left = x + ah / 2; }
			if (y + ch + ah / 2 > bh + bst && y + ah / 2 - ch >= bst ) 
				{ c.style.top = y + ah / 2 - ch; }
			else 
				{ c.style.top = y + ah / 2; }
			c.style.visibility = "visible";
}	}	}
function msoCommentHide(com_id) 
{
	if(msoBrowserCheck())
		{
		c = document.all(com_id);
		if (null != c && null == c.length)
		{
		c.style.visibility = "hidden";
		c.style.left = -1000;
		c.style.top = -1000;
		} } 
}
function msoBrowserCheck()
{
	ms = navigator.appVersion.indexOf("MSIE");
	vers = navigator.appVersion.substring(ms + 5, ms + 6);
	ie4 = (ms > 0) && (parseInt(vers) >= 4);
	return ie4;
}
if (msoBrowserCheck())
{
	document.styleSheets.dynCom.addRule(".msocomanchor","background: infobackground");
	document.styleSheets.dynCom.addRule(".msocomoff","display: none");
	document.styleSheets.dynCom.addRule(".msocomtxt","visibility: hidden");
	document.styleSheets.dynCom.addRule(".msocomtxt","position: absolute");
	document.styleSheets.dynCom.addRule(".msocomtxt","top: -1000");
	document.styleSheets.dynCom.addRule(".msocomtxt","left: -1000");
	document.styleSheets.dynCom.addRule(".msocomtxt","width: 33%");
	document.styleSheets.dynCom.addRule(".msocomtxt","background: infobackground");
	document.styleSheets.dynCom.addRule(".msocomtxt","color: infotext");
	document.styleSheets.dynCom.addRule(".msocomtxt","border-top: 1pt solid threedlightshadow");
	document.styleSheets.dynCom.addRule(".msocomtxt","border-right: 2pt solid threedshadow");
	document.styleSheets.dynCom.addRule(".msocomtxt","border-bottom: 2pt solid threedshadow");
	document.styleSheets.dynCom.addRule(".msocomtxt","border-left: 1pt solid threedlightshadow");
	document.styleSheets.dynCom.addRule(".msocomtxt","padding: 3pt 3pt 3pt 3pt");
	document.styleSheets.dynCom.addRule(".msocomtxt","z-index: 100");
}
// --></script>
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"MS Mincho";
	panose-1:2 2 6 9 4 2 5 8 3 4;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:"Segoe UI";
	panose-1:2 11 6 4 2 2 2 2 2 4;}
@font-face
	{font-family:"\@MS Mincho";
	panose-1:2 2 6 9 4 2 5 8 3 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin:0cm;
	margin-bottom: 5pt;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
h1
	{mso-style-link:"Heading 1 Char";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:21.6pt;
	text-indent:-21.6pt;
	line-height:110%;
	page-break-after:avoid;
	font-size:16.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:normal;}
h2
	{mso-style-link:"Heading 2 Char";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:28.8pt;
	text-indent:-28.8pt;
	line-height:110%;
	font-size:14.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:normal;}
h3
	{mso-style-link:"Heading 3 Char";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:36.0pt;
	text-indent:-36.0pt;
	line-height:110%;
	page-break-after:avoid;
	font-size:12.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:normal;}
h4
	{mso-style-link:"Heading 4 Char";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:43.2pt;
	text-indent:-43.2pt;
	line-height:110%;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:normal;}
h5
	{mso-style-link:"Heading 5 Char";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:50.4pt;
	text-indent:-50.4pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:normal;}
h6
	{mso-style-link:"Heading 6 Char";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:57.6pt;
	text-indent:-57.6pt;
	font-size:8.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:normal;}
p.MsoHeading7, li.MsoHeading7, div.MsoHeading7
	{mso-style-link:"Heading 7 Char";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:64.8pt;
	text-indent:-64.8pt;
	font-size:8.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoHeading8, li.MsoHeading8, div.MsoHeading8
	{mso-style-link:"Heading 8 Char";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:72.0pt;
	text-indent:-72.0pt;
	font-size:8.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoHeading9, li.MsoHeading9, div.MsoHeading9
	{mso-style-link:"Heading 9 Char";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:79.2pt;
	text-indent:-79.2pt;
	font-size:8.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoCommentText, li.MsoCommentText, div.MsoCommentText
	{mso-style-link:"Comment Text Char";
	margin:0cm;
	font-size:10.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoHeader, li.MsoHeader, div.MsoHeader
	{mso-style-link:"Header Char";
	margin:0cm;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoFooter, li.MsoFooter, div.MsoFooter
	{mso-style-link:"Footer Char";
	margin:0cm;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
a:link, span.MsoHyperlink
	{color:#005B9D;
	text-decoration:none none;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:4pt;
	margin-left:36.0pt;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
span.HeaderChar
	{mso-style-name:"Header Char";
	mso-style-link:Header;
	font-family:"Calibri",sans-serif;}
span.FooterChar
	{mso-style-name:"Footer Char";
	mso-style-link:Footer;
	font-family:"Calibri",sans-serif;}
span.CommentTextChar
	{mso-style-name:"Comment Text Char";
	mso-style-link:"Comment Text";
	font-family:"Calibri",sans-serif;}
span.Heading1Char
	{mso-style-name:"Heading 1 Char";
	mso-style-link:"Heading 1";
	font-family:"Arial",sans-serif;
	color:black;}
.MsoChpDefault
	{font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
.MsoPapDefault
	{
	line-height:107%;}
 /* Page Definitions */
 @page WordSection1
	{size:595.3pt 841.9pt;
	margin:72.0pt 72.0pt 72.0pt 72.0pt;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}
-->
</style>

</head>

<body lang="en-CN" link="#005B9D" vlink="#954F72" style="word-wrap:break-word">

<div class="WordSection1">

<p class="MsoNormal"><b><span lang="EN-US" style="font-size:13.5pt;font-family:
&quot;Times New Roman&quot;,serif">Before we begin </span></b></p>

<p class="MsoNormal" style="line-height:105%"><span lang="EN-US" style="font-size:
10.0pt;line-height:105%;font-family:&quot;Arial&quot;,sans-serif;color:black">This notice
(Privacy Notice) applies to personal information held by members of the HSBC
Group as data controllers, as described below. It explains what information we
collect about you, how we'll use that information, who we'll share it with, the
circumstances when we'll share it and what steps we'll take to make sure it
stays private and secure. It continues to apply even if your agreement for
banking or other products and services with us ends. It should also be read
alongside the terms and conditions for any services provided by us, as these
may include sections relating to the use and disclosure of information. </span></p>

<p class="MsoNormal" style="line-height:105%"><span lang="EN-US" style="font-size:
10.0pt;line-height:105%;font-family:&quot;Arial&quot;,sans-serif;color:black">This
Privacy Notice covers any products or services you have with us, including
investment services, custody and lending. Sometimes we may need to provide you
with separate or further information about specific products and services. This
will also apply if you have a relationship with other parts of the HSBC Group
(e.g. HSBC business banking, first direct, M&amp;S Bank or HSBC in any other
countries), who will provide you with information separately where required. </span></p>

<p class="MsoNormal" style="line-height:105%"><span lang="EN-US" style="font-size:
10.0pt;line-height:105%;font-family:&quot;Arial&quot;,sans-serif;color:black">Some of the
links on our websites lead to other HSBC or non-HSBC websites with their own
privacy notices, which may be different to this notice. You'll need to make
sure you're happy with their privacy notices when using those other sites. </span></p>

<p class="MsoNormal" style="line-height:105%"><span lang="EN-US" style="font-size:
10.0pt;line-height:105%;font-family:&quot;Arial&quot;,sans-serif;color:black">Wherever
we've said 'you' or 'your', this means you (as a present, former or even
prospective client), any authorised person on your existing or proposed
account(s), anyone who does or will do your banking or deals or will deal with
us for you (e.g. trustees or executors, attorneys under a Power of Attorney,
employees or representatives) and any other persons related or otherwise
connected to your existing or proposed account(s) (including authorised signatories,
partners, members, trustees, settlors and beneficiaries (where applicable)). </span></p>

<p class="MsoNormal" style="line-height:105%"><span lang="EN-US" style="font-size:
10.0pt;line-height:105%;font-family:&quot;Arial&quot;,sans-serif;color:black">When we say
'we', we mean HSBC Group companies which act as a data controller in respect of
your personal data. The primary data controller for the purposes of this notice
will be HSBC Private Bank (Suisse) SA, operating through its locally regulated
branch in Guernsey. </span></p>

<p class="MsoNormal" style="line-height:105%"><span lang="EN-US" style="font-size:
10.0pt;line-height:105%;font-family:&quot;Arial&quot;,sans-serif;color:black">HSBC
Private Bank (Suisse) SA's registered office Quai des Bergues 9-17, 1201 Geneva
(GE), Switzerland. </span><span lang="EN-GB" style="font-size:10.0pt;line-height:
105%;font-family:&quot;Arial&quot;,sans-serif;color:black">Authorised</span><span lang="EN-US" style="font-size:10.0pt;line-height:105%;font-family:&quot;Arial&quot;,sans-serif;
color:black"> and supervised by the Swiss Financial Market Supervisory
Authority FINMA. The address for HSBC Private Bank (Suisse) SA, Guernsey Branch
is Arnold House, St Julian's Avenue, St Peter Port, Guernsey, GY1 3NF, Channel
Islands. If you'd like to get in touch with us, you can also find contact
details in the 'More details about your information' section below. </span></p>

<p class="MsoNormal"><b><span lang="EN-US" style="font-size:13.5pt;font-family:
&quot;Times New Roman&quot;,serif">What information we collect </span></b></p>

<p class="MsoNormal" style="line-height:105%"><span lang="EN-US" style="font-size:
10.0pt;line-height:105%;font-family:&quot;Arial&quot;,sans-serif;color:black">We'll only
collect your information in line with relevant regulations and law. We may
collect it from a range of sources and it may relate to any of our products or
services you apply for, currently hold or have held in the past. We may also
collect information about you when you interact with us, e.g. when (i) you
visit our websites or mobile channels, (ii) you call us or we call you, (iii)
you visit one of our branches or we visit you, or (iv) you ask about any of our
products and services. </span></p>

<p class="MsoNormal" style="line-height:105%"><span lang="EN-US" style="font-size:
10.0pt;line-height:105%;font-family:&quot;Arial&quot;,sans-serif;color:black">Some of it
will come directly from you, e.g. when you provide personal information to open
an account. It can also come from your financial advisor, broker or mortgage
intermediary, other HSBC companies, or other sources you've asked us to obtain
information from. We might also get some of it from publicly available sources.
The information we collect may include: </span></p>

<p class="MsoNormal"><b><span lang="EN-US" style="font-size:13.5pt;font-family:
&quot;Times New Roman&quot;,serif">Information that you provide to us, e.g.: </span></b></p>

<p class="MsoListParagraph" style="margin-top:0cm;text-indent:
-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:
107%;font-family:Symbol;color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">personal details, e.g. name,
previous names, gender, date and place of birth;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">contact details, e.g. address,
email address, landline and mobile numbers;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">information concerning your
identity e.g. photo ID, passport information, National Insurance number (or the
equivalent social security details applicable in the Channel Islands or Isle of
Man), National ID card and nationality;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">market research, e.g. information
and opinions expressed when participating in market research;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">user login and subscription data,
e.g. login credentials for phone and online banking and mobile banking apps;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">other information about you that
you give us by filling in forms or by communicating with us, whether
face-to-face, by phone, email, online, or otherwise.</span></p>

<p class="MsoNormal"><b><span lang="EN-US" style="font-size:13.5pt;font-family:
&quot;Times New Roman&quot;,serif">Information we may collect or generate about you,
e.g.: </span></b></p>

<p class="MsoListParagraph" style="margin-top:0cm;text-indent:
-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:
107%;font-family:Symbol;color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">your financial information and
information about your relationship with us, including the products and
services you hold, the channels you use and your ways of interacting with us,
your ability to get and manage your credit, your payment history, transactions
records, market trades, payments into your account including salary details and
information concerning complaints and disputes;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">information we use to identify and
authenticate you, e.g. your signature and your biometric information (if
applicable), such as your voice for voice ID, additional information that we
receive from external sources that we need for compliance purposes;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">geographic information, e.g. about
which branches or ATMs you use;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">information included in customer
documentation, e.g. a record of advice that we may have given you; marketing
and sales information, e.g. details of the services you receive and your
preferences;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">cookies and similar technologies we
use to recognise you, remember your preferences and tailor the content we
provide to you – our cookie policy contains more details about how we use
cookies and can be found at</span><a><span lang="EN-US"> </span></a><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
color:black">https://www.privatebanking.hsbc.com/cookie-policy;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">risk rating information, e.g.
credit risk rating, transactional behaviour and underwriting information;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">investigations data, e.g. due
diligence checks, sanctions and anti-money laundering checks, external
intelligence reports, content and metadata related to relevant exchanges of
information between and among individuals, organisations, including emails,
voicemail, live chat;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">records of correspondence and other
communications between us, including email, live chat, instant messages and
social media communications;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">information that we need to support
our regulatory obligations, e.g. information about transaction details,
detection of any suspicious and unusual activity and information about parties
connected to you or these activities.</span></p>

<p class="MsoNormal"><b><span lang="EN-US" style="font-size:13.5pt;font-family:
&quot;Times New Roman&quot;,serif">Information we collect from other sources, e.g.: </span></b></p>

<p class="MsoListParagraph" style="margin-top:0cm;text-indent:
-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:
107%;font-family:Symbol;color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">information you've asked us to
collect for you, e.g. information about your accounts or holdings with other
companies including transaction information;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">information from third party
providers, e.g. information that helps us to combat fraud or that relates to
your relevant social interactions (including your communications via social
media, between individuals, organisations, prospects and other stakeholders
acquired from companies that collect combined information).</span></p>

<p class="MsoNormal"><b><span lang="EN-US" style="font-size:13.5pt;font-family:
&quot;Times New Roman&quot;,serif">How we'll use your information </span></b></p>

<p class="MsoNormal" style="line-height:105%"><span lang="EN-US" style="font-size:
10.0pt;line-height:105%;font-family:&quot;Arial&quot;,sans-serif;color:black">We'll only
use your information where we have your consent or we have another lawful
reason for using it. These reasons include where we: </span></p>

<p class="MsoListParagraph" style="margin-top:0cm;text-indent:
-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:
107%;font-family:Symbol;color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">need to pursue our legitimate
interests;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">need to process the information to
carry out an agreement we have with you;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">need to process the information to
comply with a legal obligation;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">believe the use of your information
as described is in the public interest, e.g. for the purpose of preventing or
detecting crime.</span></p>

<p class="MsoNormal" style="line-height:105%"><span lang="EN-US" style="font-size:
10.0pt;line-height:105%;font-family:&quot;Arial&quot;,sans-serif;color:black">The reasons
we use your information include to: </span></p>

<p class="MsoListParagraph" style="margin-top:0cm;text-indent:
-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:
107%;font-family:Symbol;color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">deliver our products and services;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">carry out your instructions, e.g.
to fulfil a payment request;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">manage our relationship with you,
including (unless you tell us otherwise) telling you about products and
services we think may be relevant for you;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">understand how you use your
accounts and services;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">banking operations support;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">prevent or detect crime including
fraud and financial crime, e.g. financing for terrorism and human trafficking;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">security and business continuity;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">risk management;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">online banking, mobile apps and
other online product platforms;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">product and service improvement;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">data analytics to better understand
your circumstances and preferences so we can make sure we can provide you with
the best advice and offer you a tailored service;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">protect our legal rights and comply
with our legal obligations;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">correspond with solicitors,
surveyors, valuers, other lenders, conveyancers and third party intermediaries;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">system or product development and
planning, insurance, audit and administrative purposes.</span></p>

<p class="MsoNormal"><span lang="EN-US" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:black">Further details of how we'll use your information can be found in
the Appendix below.</span></p>

<p class="MsoNormal"><b><span lang="EN-US" style="font-size:13.5pt;font-family:
&quot;Times New Roman&quot;,serif">How we make decisions about you </span></b></p>

<p class="MsoNormal" style="line-height:105%"><span lang="EN-US" style="font-size:
10.0pt;line-height:105%;font-family:&quot;Arial&quot;,sans-serif;color:black">While we
currently do not use any fully automated decision making processes, we may do
so in future and we currently may use automated systems to help us make
decisions, e.g. when you apply for products and services, to make credit
decisions and to carry out fraud and anti-money laundering checks. We may use
technology that helps us identify the level of risk involved in customer or
account activity, e.g. for credit, fraud or financial crime reasons, or to
identify if someone else is using your card without your permission. </span></p>

<p class="MsoNormal" style="line-height:105%"><span lang="EN-US" style="font-size:
10.0pt;line-height:105%;font-family:&quot;Arial&quot;,sans-serif;color:black">You may
have a right to certain information about how we make these decisions. You may
also have a right to request human intervention and to challenge the decision.
More details can be found in the 'Your rights' section below. </span></p>

<p class="MsoNormal"><b><span lang="EN-US" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif">Tracking or recording what you say or do </span></b></p>

<p class="MsoNormal" style="line-height:105%"><span lang="EN-US" style="font-size:
10.0pt;line-height:105%;font-family:&quot;Arial&quot;,sans-serif;color:black">To help
keep you and your assets safe, we may record details of your interactions with
us. We may record and keep track of conversations you have with us including
phone calls, face-to-face meetings, letters, emails, live chats, video chats
and any other kinds of communication. We may use these recordings to check your
instructions to us, assess, analyse and improve our service, train our people,
manage risk or to prevent and detect fraud and other crimes. We may also
capture additional information about these interactions, e.g. telephone numbers
that you call us from and information about the devices or software that you
use. </span></p>

<p class="MsoNormal" style="line-height:105%"><span lang="EN-US" style="font-size:
10.0pt;line-height:105%;font-family:&quot;Arial&quot;,sans-serif;color:black">We use
closed circuit television (CCTV) in and around our sites and these may collect
photos or videos of you, or record your voice. </span></p>

<p class="MsoNormal"><b><span lang="EN-US" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif">Compliance with laws and regulatory compliance
obligations </span></b></p>

<p class="MsoNormal" style="line-height:105%"><span lang="EN-US" style="font-size:
10.0pt;line-height:105%;font-family:&quot;Arial&quot;,sans-serif;color:black">We'll use
your information to meet our compliance obligations, to comply with other laws
and regulations and to share with regulators and other authorities that HSBC
Group companies are subject to. This may include using it to help detect or
prevent crime (including terrorism financing, money laundering and other financial
crimes). We'll only do this on the basis that it's needed to comply with a
legal obligation or it's in our legitimate interests and that of others. </span></p>

<p class="MsoNormal"><b><span lang="EN-US" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif">Marketing and market research </span></b></p>

<p class="MsoNormal" style="line-height:105%"><span lang="EN-US" style="font-size:
10.0pt;line-height:105%;font-family:&quot;Arial&quot;,sans-serif;color:black">We may use
your information to provide you with details about HSBC products and services,
and also products and services from our partners and other relevant third
parties. We may send you marketing messages by post, email, telephone, text or
secure messages. You can change your mind on how you receive marketing messages
or choose to stop receiving them at any time. To make that change, please
contact us in the usual way. </span></p>

<p class="MsoNormal" style="line-height:105%"><span lang="EN-US" style="font-size:
10.0pt;line-height:105%;font-family:&quot;Arial&quot;,sans-serif;color:black">If you ask
us not to send you marketing, it may take us a short period of time to update
our systems and records to reflect your request, during which time you may
continue to receive marketing messages. Even if you tell us not to send you
marketing messages, we'll continue to use your contact details to provide you
with important information, such as changes to your terms and conditions or if
we need to tell you something to comply with our regulatory obligations. </span></p>

<p class="MsoNormal" style="line-height:105%"><span lang="EN-US" style="font-size:
10.0pt;line-height:105%;font-family:&quot;Arial&quot;,sans-serif;color:black">We may use
your information for market research and to identify trends. Market research
agencies acting on our behalf may get in touch with you by post, telephone,
email or other methods of communication to invite you to take part in research.
We won't invite you to take part in research using a communication method if
you've asked us not to get in touch that way. Any responses that you provide
whilst participating in market research will be reported back to us anonymously
unless you give us permission for your details to be shared. </span></p>

<p class="MsoNormal" style="page-break-after:avoid"><b><span lang="EN-US" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">Who we might share
your information with </span></b></p>

<p class="MsoNormal" style="line-height:105%"><span lang="EN-US" style="font-size:
10.0pt;line-height:105%;font-family:&quot;Arial&quot;,sans-serif;color:black">We may
share your information with others where lawful to do so including where we or
they: </span></p>

<p class="MsoListParagraph" style="margin-top:0cm;text-indent:
-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:
107%;font-family:Symbol;color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">need to in order to provide you with
products or services you've requested, e.g. fulfilling a payment request;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">have a public or legal duty to do
so, e.g. to assist with detecting and preventing fraud, tax evasion and
financial crime;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">need to in connection with
regulatory reporting, litigation or asserting or defending legal rights and
interests;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">have a legitimate business reason
for doing so, e.g. to manage risk, verify your identity, enable another company
to provide you with services you've requested, or assess your suitability for
products and services;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">have asked you for your permission
to share it, and you've agreed. </span></p>

<p class="MsoNormal" style="line-height:105%"><span lang="EN-US" style="font-size:
10.0pt;line-height:105%;font-family:&quot;Arial&quot;,sans-serif;color:black">We may
share your information for these purposes with others including: </span></p>

<p class="MsoListParagraph" style="margin-top:0cm;text-indent:
-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:
107%;font-family:Symbol;color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">other HSBC Group companies and any
sub-contractors, agents or service providers who work for us or provide
services to us or other HSBC Group companies (including their employees,
sub-contractors, service providers, directors and officers);</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">any joint account holders,
trustees, beneficiaries, administrators or executors;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">people who give guarantees or other
security for any amounts you owe us;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">people you make payments to and
receive payments from;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">your beneficiaries, intermediaries,
correspondent and agent banks, clearing houses, clearing or settlement systems,
market counterparties and any companies you hold securities in through us, e.g.
stocks, bonds or options;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">other financial institutions,
lenders and holders of security over any property you charge to us, tax
authorities, trade associations, credit reference agencies, payment service
providers and debt recovery agents;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">any fund managers who provide asset
management services to you and any brokers who introduce you to us or deal with
us for you;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">any entity that has an interest in
the products or services that we provide to you, including if they take on the
risk related to them;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">any people or companies where
required in connection with potential or actual corporate restructuring,
merger, acquisition or takeover, including any transfer or potential transfer
of any of our rights or duties under our agreement with you;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">law enforcement, government,
courts, dispute resolution bodies, our regulators, auditors and any party
appointed or requested by our regulators to carry out investigations or audits
of our activities;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">other parties involved in any
disputes, including disputed transactions;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">fraud prevention agencies who'll
also use it to detect and prevent fraud and other financial crime and to verify
your identity;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">anyone who provides instructions or
operates any of your accounts on your behalf, e.g. Power of Attorney,
solicitors, intermediaries, etc.;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">anybody else that we've been
instructed to share your information with by either you, a joint account holder
or anybody else who provides instructions or operates any of your accounts on
your behalf;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">our card processing supplier(s) to
carry out credit, fraud and risk checks, process your payments, issue and
manage your card;</span></p>

<p class="MsoListParagraph" style="text-indent:-15.0pt;line-height:107%"><span lang="EN-US" style="font-size:10.0pt;line-height:107%;font-family:Symbol;
color:black">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-size:10.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif;color:black">insurers so they can provide you
with a quote or cover. For further details please refer to your policy
documentation.</span></p>

<p class="MsoNormal"><b><span lang="EN-US" style="font-size:12.0pt;font-family:
&quot;Times New Roman&quot;,serif">Sharing aggregated or </span>
</b><b><span lang="EN-GB" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">anonymised</span></b>
<b><span lang="EN-US" style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">
information </span></b></p>


` + pp2;
