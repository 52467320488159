import React, { Component } from "react";
import _ from "lodash";
import styled from "styled-components";
import EnterDetails from "components/Registration/EnterDetails";
import GenericOTP from "components/OTPVerify/GenericOTP";
import AcceptTerms from "components/Logon/AcceptTerms";
import CreateUsernamePassword from "components/Registration/CreateUsernamePassword";
import CreateSecurityQuestions from "components/Registration/CreateSecurityQuestions";
import MockSuccessfulRegistrated from "components/Registration/MockSuccessfulRegistrated";
import { changeIdleTimeOutValid } from "util/service";
import Loading from "components/common/Loading";
import { languageActions } from "actions/languageAction";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { sendCodeIsLoading } from "actions/sendCodeAction";
const mapStateToProps = state => {
    return {
        error: state.logon.error,
        alreadyInitMLContainer: state.languageSession.alreadyInitMLContainer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        sendCodeIsLoading: payload => dispatch(sendCodeIsLoading(payload)),
        hideSelectOption: isHideLanguageSelect =>
            dispatch(languageActions.hideSelectOption(isHideLanguageSelect))
    };
};

class Registration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: ""
        };
        this.mappedStepToComponent = this.mappingStepToComponent();
        this.loadedComponents = {};
    }

    mappingStepToComponent = () => {
        return [
            {
                step: "ACCEPT_TERMS",
                component: (
                    <AcceptTerms
                        jumpTo={this.jumpTo}
                        storeThis={this.storeThis}
                    />
                )
            },
            {
                step: "ENTER_DETAILS",
                component: (
                    <EnterDetails
                        jumpTo={this.jumpTo}
                        storeThis={this.storeThis}
                        ref={this.myRef}
                    />
                )
            },
            {
                step: "CREATE_USERNAME_PASSWORD",
                component: (
                    <CreateUsernamePassword
                        jumpTo={this.jumpTo}
                        storeThis={this.storeThis}
                    />
                )
            },
            {
                step: "CREATE_SECURITY_QUESTION",
                component: (
                    <CreateSecurityQuestions
                        jumpTo={this.jumpTo}
                        storeThis={this.storeThis}
                    />
                )
            },
            {
                step: "REGISTRATED_SUCCESSFUL",
                component: (
                    <MockSuccessfulRegistrated
                        jumpTo={this.jumpTo}
                        storeThis={this.storeThis}
                    />
                )
            },
            {
                step: "GENERIC_OTP",
                component: (
                    <GenericOTP
                        jumpTo={this.jumpTo}
                        storeThis={this.storeThis}
                    />
                )
            }
        ];
    };

    storeThis = (name, that) => {
        this.loadedComponents[name] = that;
    };

    componentDidMount() {
        this.props.sendCodeIsLoading(false);
        this.props.afterLoad(this.loadedComponents, this);
        changeIdleTimeOutValid(true);
        sessionStorage.setItem("isRegistration", true);
    }

    componentWillUnmount() {
        this.props.hideSelectOption(true);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.currentStep !== this.state.step) {
            this.setState((prevState, props) => {
                return { ...prevState, step: nextProps.currentStep };
            });
        }
    }

    processJsonData = (jsonData, actionContext, clientContext) => {
        clientContext.jsonData = jsonData;
        return Promise.resolve(
            window.com.ts.mobile.sdk.JsonDataProcessingResult.create(true)
        );
    };

    jumpTo = step => {
        if (step === "ACCEPT_TERMS") {
            this.props.alreadyInitMLContainer &&
                this.props.hideSelectOption(false);
        }
        if (step === "ENTER_DETAILS") {
            this.props.hideSelectOption(true);
        }
        const renderedComponent = _.find(
            this.mappedStepToComponent,
            item => step === item.step
        );
        this.setState({
            renderedComponent: renderedComponent.component,
            step
        });
    };

    render() {
        if (!this.state.renderedComponent) {
            return (
                <LoadingContainer>
                    <Loading
                        dialogHeight="605px"
                        dialogWidth="768px"
                        customizedClass="reg-t-c-loading-modal"
                    />
                </LoadingContainer>
            );
        }

        return (
            <RegistrationContainer
                className={
                    this.state.step === "REGISTRATED_SUCCESSFUL"
                        ? "registration-success"
                        : ""
                }
            >
                {this.state.renderedComponent}
                {""}
            </RegistrationContainer>
        );
    }
}
const LoadingContainer = styled.div`
    .reg-t-c-loading-modal {
        @media (min-width: 768px) and (max-width: 1023px) {
            width: 650px !important;
            height: 503px;
        }
    }
`;
const RegistrationContainer = styled.div`
    position: relative;
    z-index: 2;
`;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(Registration));
