export const initialState = {
    securityQuestionList: [],
    error: {},
    securityQuestionBtn: false
};

export function securityQuestionList(state = initialState, action) {
    switch (action.type) {
        case "SECURITY_QUESTION_LIST_SUCCESS":
            return {
                ...state,
                error: {},
                securityQuestionList: action.data
            };
        case "SECURITY_QUESTION_BTN":
            return {
                ...state,
                securityQuestionBtn: action.data
            };
        default:
            return state;
    }
}
