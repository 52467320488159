import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "reducers";
import { envConfig } from "env-config";

const initialState = {};

export default function configureStore(initialStates) {
    const composeEnhancers =
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    return createStore(
        rootReducer,
        initialStates,
        composeEnhancers(applyMiddleware(thunk))
    );
}

const store = configureStore(initialState);

export function locale() {
    let defaultSelectedLang = "";
    envConfig.languageSlectList.map((item, inedx) => {
        if (item.selected) {
            defaultSelectedLang = item.locale;
        }
    });
    const selectedLang =
        localStorage.getItem("selectedLang") || defaultSelectedLang;
    return selectedLang || "WARNING: Locale not set";
}
