export const color = {
    background_color_dark: "#3e505d",
    charcoal: "#252525",
    black: "#000000",
    blue: "#177c76",
    text_color_dark: "#333333",
    hsbc_warning_red: "#af000d",
    hsbc_warning_yellow: "#e8a215",
    hsbc_success_green: "#4faf00",
    hsbc_red: "#db0011",
    white: "#ffffff",
    dark: "#f3f3f3",
    error_msg_stroke_outline: "#A8000B",
    grey: "#767676"
};
