import React, { Component } from "react";
import styled from "styled-components";
import { FormattedMessage, injectIntl } from "react-intl";
import { history } from "util/history";

class AppFooter extends Component {
    constructor(props) {
        super(props);
    }
    handleClick() {
        history.push("/cookieNotice");
    }
    render() {
        const { isCCM } = this.props;
        return (
            <Footer className="container-footer">
                <div className="left">
                    {isCCM ? (
                        <span
                            data-testid="footer-container-cookie"
                            className="cookie-notice"
                            onClick={this.handleClick}
                        >
                            <FormattedMessage id="AppFooter.hsbc.cookieNotice" />
                        </span>
                    ) : null}
                </div>
                <div className="right">
                    <label href="/hsbc" data-testid="footer-container-text">
                        &copy; <FormattedMessage id="AppFooter.hsbc.text" />{" "}
                        {new Date().getFullYear()}
                    </label>
                </div>
            </Footer>
        );
    }
}

const Footer = styled.div`
    width: 100%;
    font-size: 0.75rem;
    color: #333333;
    height: 60px;
    line-height: 60px;
    font-weight: bolder;
    background-color: #d7d8d6;
    display: flex;
    padding-right: 20px;
    padding-left: 20px;
    justify-content: space-between;
    a {
        color: #fff;
        text-decoration: none;
    }
    .cookie-notice {
        color: #333333;
    }
    .cookie-notice:hover {
        cursor: pointer;
        text-decoration: underline;
    }
`;

export default injectIntl(AppFooter);
