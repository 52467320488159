import React, { Component } from "react";
import styled from "styled-components";
import { history } from "util/history";
import SecurityModal from "../common/SecurityModal";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";

const mapStateToProps = state => {
    return {
        gvLogon: state.logon
    };
};
const mapDispatchToProps = dispatch => {
    return {};
};

class GvMessage extends Component {
    render() {
        return (
            <div>
                <GvMessageContianer>
                    <SecurityModal
                        titleIcon={
                            <i className="icon exclamation triangle error gv-icon-margin" />
                        }
                        onConfirmationClick={() => {
                            history.push("/logon");
                        }}
                        confirmationBtnTitle="gvResult.error.backToLogon"
                    >
                        <GvResultContainer>
                            <div className="gvresult-content ">
                                <p className="gvmessage-content-top">
                                    {this.props.intl.formatMessage({
                                        id: "gvResult.error.gvmessage.content1"
                                    })}
                                </p>
                                <p className="gvmessage-content-bottom">
                                    {this.props.intl.formatMessage({
                                        id: "gvResult.error.gvmessage.content2"
                                    })}
                                </p>
                            </div>
                        </GvResultContainer>
                    </SecurityModal>
                </GvMessageContianer>
            </div>
        );
    }
}

const GvMessageContianer = styled.div`
    .gv-icon-margin {
        margin-top: 38px;
        font-size: 24px !important;
    }
`;
const GvResultContainer = styled.div`
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 16px;
    font-weight: 300;
    line-height: 19px;
    .success {
        color: #4faf00;
    }
    .gv-icon-margin {
        margin-top: 38px;
        font-size: 24px !important;
    }
    .gvmessage-content-top {
        margin-bottom: 0px;
    }
    .gvmessage-content-bottom {
        margin-top: 0px;
    }
`;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(GvMessage));
