import React, { Component } from "react";
import styled from "styled-components";
import { injectIntl, FormattedMessage } from "react-intl";
import { Input } from "hsbc-pb-sdk";
import MaskedEye from "assets/image/masked.png";
import UnMaskedEye from "assets/image/unmasked.png";
import MaskedEyeError from "assets/image/masked_error.png";
import UnMaskedEyeError from "assets/image/unmasked_error.png";
class SecurityTextInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "",
            touched: false,
            iconUrl: MaskedEye,
            toggleMaskPsw: true
        };
        this.nodeRef = React.createRef();
    }

    emptyFunc = () => {
        console.log("emptyFunc");
    };

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }
    }

    componentWillUnmount() {
        if (this.props.onUnload) {
            this.props.onUnload();
        }
    }

    clearValue = () => {
        this.setState({
            value: ""
        });
    };

    getSelfFocused = () => {
        this.nodeRef.current.focus();
    };

    handleChange = event => {
        const { name, value } = event.target;
        if (
            this.props.validateFromParent &&
            !this.props.validateFromParent(name, value)
        ) {
            return;
        }
        this.setState({
            value
        });
        if (this.props.onChange) {
            this.props.onChange(event);
        }
    };

    touched = false;
    handleFocus = e => {
        if (this.props.onFocus) {
            this.props.onFocus(this.touched);
        }
        this.touched = true;
    };

    toggleMask = () => {
        let iconUrl = UnMaskedEye;
        const { toggleMaskPsw } = this.state;
        if (!toggleMaskPsw) {
            iconUrl = MaskedEye;
        }
        if (this.props.hasError || this.props.errorComp) {
            iconUrl = UnMaskedEyeError;
            if (!toggleMaskPsw) {
                iconUrl = MaskedEyeError;
            }
        }
        this.setState({
            iconUrl,
            toggleMaskPsw: !toggleMaskPsw
        });
    };

    static getDerivedStateFromProps(props, state) {
        const { errorComp, hasError } = props;
        let iconUrl;
        if (errorComp || hasError) {
            iconUrl = MaskedEyeError;
            if (!state.toggleMaskPsw) {
                iconUrl = UnMaskedEyeError;
            }
        } else {
            iconUrl = MaskedEye;
            if (!state.toggleMaskPsw) {
                iconUrl = UnMaskedEye;
            }
        }
        return { iconUrl };
    }

    onKeyDown = (event, func) => {
        if (event.keyCode === 32 || event.keyCode === 13) {
            func(event);
            event.preventDefault();
        }
    };

    render() {
        const {
            onBlur = this.emptyFunc,
            type,
            name,
            label,
            customClassName = "",
            errorComp,
            hasError,
            placeHolder = "",
            id,
            hideLabel,
            ariaDescribedby = "",
            title = "global.notext",
            disabled,
            isPswInput
        } = this.props;
        const isErrorTip = errorComp || hasError;

        const eyeClass = this.state.toggleMaskPsw ? "eye" : "eye show-eye";
        return (
            <SecurityTextInputContainer
                customClassName={customClassName}
                isErrorTip={isErrorTip}
            >
                <label htmlFor={id}>
                    <p
                        aria-hidden={hideLabel}
                        className={hideLabel ? "hide" : "security-input-label"}
                    >
                        <FormattedMessage id={label} />
                    </p>
                </label>
                <div className="input-box">
                    <Input
                        tabIndex="0"
                        isPswInput
                        type={
                            this.state.toggleMaskPsw && isPswInput
                                ? "password"
                                : "text" || type || "text"
                        }
                        value={this.state.value}
                        onChange={this.handleChange}
                        onBlur={onBlur}
                        onFocus={this.handleFocus}
                        name={name}
                        id={id}
                        aria-invalid={
                            errorComp || hasError === true ? true : false
                        }
                        aria-describedby={ariaDescribedby}
                        aria-label={this.props.intl.formatMessage({
                            id: title
                        })}
                        className={
                            errorComp || hasError === true ? `error` : ``
                        }
                        ref={this.nodeRef}
                        placeholder={placeHolder}
                        disabled={disabled}
                        autoComplete="off"
                        title="input"
                    />
                    {isPswInput && this.state.value ? (
                        <img
                            className={eyeClass}
                            onKeyDown={e => {
                                this.onKeyDown(e, this.toggleMask);
                            }}
                            id={id}
                            data-testid="eye"
                            tabIndex="0"
                            src={this.state.iconUrl}
                            onClick={this.toggleMask}
                        />
                    ) : (
                        ""
                    )}
                </div>
                {errorComp}
            </SecurityTextInputContainer>
        );
    }
}

const SecurityTextInputContainer = styled.div.attrs({
    className: props => props.customClassName
})`
    .input-box {
        position: relative;
    }

    label {
        font-size: 16px;
        font-weight: normal;
        line-height: 19px;
    }

    .hide {
        position: absolute;
        overflow: hidden;
        clip: rect(0 0 0 0);
        height: 1px;
        width: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
    }

    input {
        font-family: "UniversNextForHSBC", "Helvetica Neue", Helvetica, Arial,
            sans-serif;
        font-weight: regular;
        font-size: 14px;
        color: #000;
        width: 100%;
        padding: 12px 15px;
    }

    .hsbc-ui.input:not(.disable) input[disabled] {
        opacity: 1;
    }

    .hsbc-ui.disabled.input {
        opacity: 1;
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        .hsbc-ui.input {
            max-width: 100%;
        }
    }

    .hsbc-ui.input {
        padding: 0;
    }

    .hsbc-ui.input > input {
        padding: 12px 15px;
        line-height: 1;
    }

    input::-ms-clear {
        display: none;
    }

    input::-ms-reveal {
        display: none;
    }

    .eye {
        width: ${props => (props.isErrorTip ? "21px" : "19px")};
        position: absolute;
        top: 34%;
        right: 4%;
        @media (min-width: 1980px) {
            right: 3%;
        }
    }

    .show-eye {
        width: 22px;
        top: 26.62%;
        right: ${props => (props.isErrorTip ? "4%" : "3.7%")};
        @media (min-width: 1980px) {
            right: 3%;
        }
    }
`;
export default injectIntl(SecurityTextInput);
