import styled from "styled-components";
import React, { Component } from "react";
import { color } from "util/color";

export default class Loading extends Component {
    constructor(props) {
        super(props);
        this.state = {
            strokeDasharrayLength: 1,
            strokeDasharrayPercentage: 200,
            strokeDashoffset: 0
        };
    }

    animationLoader = () => {
        if (
            this.state.strokeDasharrayLength >= 90 &&
            this.state.strokeDashoffset < -124
        ) {
            this.setState({
                strokeDasharrayLength: 1
            });
        }
        if (this.state.strokeDashoffset <= -125) {
            this.setState({
                strokeDashoffset: 0
            });
        }
        const strokeDasharrayLength = this.state.strokeDasharrayLength + 1;
        const strokeDashoffset = this.state.strokeDashoffset - 1;
        this.setState({
            strokeDasharrayLength,
            strokeDashoffset
        });
        requestAnimationFrame(this.animationLoader);
    };

    componentDidMount() {
        this.animationLoader();
    }

    render() {
        return (
            <Showbox
                dialogHeight={this.props.dialogHeight}
                dialogWidth={this.props.dialogWidth}
                currentStep={this.props.currentStep}
                className={
                    this.props.customizedClass ? this.props.customizedClass : ""
                }
            >
                <div className="loader">
                    <svg className="circular" viewBox="25 25 50 50">
                        <circle
                            className="path"
                            cx="50"
                            cy="50"
                            r="20"
                            fill="none"
                            strokeWidth="3"
                            strokeMiterlimit="10"
                            style={{
                                strokeDasharray: `${this.state.strokeDasharrayLength},${this.state.strokeDasharrayPercentage}`,
                                strokeDashoffset: `${this.state.strokeDashoffset}px`
                            }}
                        />
                    </svg>
                </div>
            </Showbox>
        );
    }
}

const Showbox = styled.div`
    box-shadow: 0px 16px 24px 2px rgba(0, 0, 0, .14);
    background-color: #fff;
    color: ${color.text_color_dark};
    height: ${props => props.dialogHeight || "auto"}
    position: relative;
    @media (max-width: 320px) {
        margin: 20px;
        padding: 14px;
        width: 280px;
    }
    @media (min-width: 321px) and (max-width: 413px) {
        margin: 20px;
        padding: 14px
        width: 335px;
    }
    @media (min-width: 414px) and (max-width: 768px) {
        margin: 20px;
        padding: 50px 50px 40px 50px;
        width: 374px;
    }
    @media (min-width: 769px) {
        padding: 50px 50px 40px 50px;
        margin-left: 0px;
        width: ${props => props.dialogWidth || "454px"};
        max-width:  ${props => props.dialogWidth || "454px"};
    }
    @media (min-width: 769px) and (max-width: 2898px) {
        margin: 20px 0px 0px 0px;
    }
    @media (min-width: 898px) and (max-width: 907px) {
        margin-bottom: 0px;
    }
`;
