import { tc2 } from "./T&C_CH2";
export default `<html><head>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8">
<meta name="Generator" content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:"Calibri Light";
	panose-1:2 15 3 2 2 2 4 3 2 4;}
@font-face
	{font-family:SymbolMT;
	panose-1:2 11 6 4 2 2 2 2 2 4;}
@font-face
	{font-family:"Segoe UI";
	panose-1:2 11 6 4 2 2 2 2 2 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
h1
	{mso-style-link:"Heading 1 Char";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:36.0pt;
	
	text-indent:-18.0pt;
	line-height:15.0pt;
	font-size:11.0pt;
	font-family:"Calibri Light",sans-serif;}
h1.CxSpFirst
	{mso-style-link:"Heading 1 Char";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:36.0pt;
	
	text-indent:-18.0pt;
	line-height:15.0pt;
	font-size:11.0pt;
	font-family:"Calibri Light",sans-serif;}
h1.CxSpMiddle
	{mso-style-link:"Heading 1 Char";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:36.0pt;
	
	text-indent:-18.0pt;
	line-height:15.0pt;
	font-size:11.0pt;
	font-family:"Calibri Light",sans-serif;}
h1.CxSpLast
	{mso-style-link:"Heading 1 Char";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:36.0pt;
	
	text-indent:-18.0pt;
	line-height:15.0pt;
	font-size:11.0pt;
	font-family:"Calibri Light",sans-serif;}
p.MsoHeader, li.MsoHeader, div.MsoHeader
	{mso-style-link:"Header Char";
	margin:0cm;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoFooter, li.MsoFooter, div.MsoFooter
	{mso-style-link:"Footer Char";
	margin:0cm;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
span.MsoFootnoteReference
	{vertical-align:super;}
p.MsoBodyText, li.MsoBodyText, div.MsoBodyText
	{mso-style-link:"Body Text Char";
	margin:0cm;
	text-autospace:none;
	font-size:11.0pt;
	font-family:"Calibri Light",sans-serif;}
a:link, span.MsoHyperlink
	{color:blue;
	text-decoration:underline;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:36.0pt;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:36.0pt;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:36.0pt;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:36.0pt;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
span.FooterChar
	{mso-style-name:"Footer Char";
	mso-style-link:Footer;}
span.HeaderChar
	{mso-style-name:"Header Char";
	mso-style-link:Header;}
span.BodyTextChar
	{mso-style-name:"Body Text Char";
	mso-style-link:"Body Text";
	font-family:"Calibri Light",sans-serif;}
span.Heading1Char
	{mso-style-name:"Heading 1 Char";
	mso-style-link:"Heading 1";
	font-family:"Calibri Light",sans-serif;
	font-weight:bold;}
.MsoChpDefault
	{font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
.MsoPapDefault
	{margin-bottom:10.0pt;
	line-height:115%;}
 /* Page Definitions */
 @page WordSection1
	{size:595.3pt 841.9pt;
	margin:70.9pt 70.85pt 70.85pt 70.85pt;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}
-->
</style>

</head>

<body lang="en-CN" link="blue" vlink="purple" style="word-wrap:break-word">

<div class="WordSection1">

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><b><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">
Terms and Conditions for the Digital Services (the "Terms and
Conditions")</span></b></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><b><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></b></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">By
accepting these Terms and Conditions you will benefit from the electronic
services (comprised of the Basic Digital Services and the Additional Digital
Services, jointly, the “<b>Digital Services</b>”) offered by HSBC Private Bank
(Suisse) SA (the “<b>Bank</b>”). Generally speaking, if there are several
account holders in respect of an account, all of them need to accept these
Terms and Conditions before the Digital Services are made available.</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><i><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">Table
of contents</span></i></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><b><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></b></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="FR-CH"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">Part A: &nbsp;&nbsp;&nbsp; Introduction</span></span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="FR-CH"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">Part B: &nbsp;&nbsp;&nbsp; General provisions
applicable to all Digital Services</span></span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="FR-CH"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">Part C: &nbsp;&nbsp;&nbsp; Privacy notice
applicable to the Digital Services</span></span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="FR-CH"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">Part D: &nbsp;&nbsp; Information regarding
the Additional Digital Services (“Transmission of Personal Data”)</span></span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><b><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></b></p>

<p class="MsoNormal" align="center" style="margin-bottom:0cm;text-align:center;
line-height:15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">*
* *</span></p>

<h1 style="margin-left:0cm;text-indent:0cm;font-weight:bold;"><a name="_Part_A:_"></a><span lang="EN-US"> Part A: &nbsp;&nbsp; Introduction</span></h1>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><b><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></b></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">These
Terms and Conditions govern the relationship between the Client and the Users,
on the one hand, and the Bank, on the other hand, regarding the use of the
Digital Services.</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">The
Digital Services comprise of:</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoListParagraphCxSpFirst" style="margin-bottom:0cm;
text-indent:-18.0pt;line-height:15.0pt"><span lang="EN-US" style="font-family:
&quot;Calibri Light&quot;,sans-serif">-<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">the
possibility to view certain information pertaining to the Client’s account
relationship(s) with the Bank (the “<b>Basic Digital Services</b>”); and</span></p>

<p class="MsoListParagraphCxSpLast" style="margin-bottom:0cm;
text-indent:-18.0pt;line-height:15.0pt"><span lang="EN-US" style="font-family:
&quot;Calibri Light&quot;,sans-serif">-<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">certain
additional services (the “<b>Additional Digital Services</b>”), which are
listed in Part D and which are, or will become, available. <span style="background:lightgrey">The terms highlighted in grey only apply to the
Additional Digital Services.</span></span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">A
description of the functionalities offered in the context of the Digital
Services is made available on the website of the Bank.</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">These
Terms and Conditions apply as soon as a User (as defined below) uses the
Digital Services. In the event of any conflict or inconsistency among the
following, the contractual documentation which govern the relationship between
the Bank and the Client (and the User), the order of precedence shall be as
follows: (i) the Bank’s General Terms and Conditions, (ii) these Terms and
Conditions for the Digital Services, (iii) <span style="background:lightgrey">the
form “Access to the Additional Digital Services waiver”</span> and (iv) any
other document related to the Digital Services.</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">The
term “<b>Client</b>” refers to the account holder(s) of the banking
relationship(s) for which the Bank makes the Digital Services available to the
Client and the Users.</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">The
User is the actual user of the Digital Services, as Client or as a user
appointed by the Client (jointly referred to as “<b>Users</b>”). The duties
that are incumbent upon the Client under these Terms and Conditions also apply
to each User (to the extent applicable). The Client shall be entirely
responsible vis-à-vis the Bank for ensuring that each User fully complies with
the obligations specified herein. The Client shall indemnify and hold the Bank
harmless from and against any and all losses, damages, liabilities,
obligations, claims, judgments, costs and expenses (including reasonable
attorney’s fees) incurred by the Bank by reason of or resulting from any claim
lodged by a User in connection with the Digital Services, including, but not
limited to, the collection and processing, by the Bank, of information pertaining
to a User.</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">The
term “<b>User IT Equipment</b>” refers to hardware and software (including
mobile devices and other technical means) used, by the User, to access the
Digital Services.</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">The
Bank makes no warranties or representations regarding:</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoListParagraphCxSpFirst" style="margin-bottom:0cm;
text-indent:-18.0pt;line-height:15.0pt"><span lang="EN-US" style="font-family:
&quot;Calibri Light&quot;,sans-serif">-<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">the
availability of the Digital Services or that they will meet any requirement the
User might have, that access will be uninterrupted or that there will be no
delays, failures, errors or omissions of information available through the
Digital Services;</span></p>

<p class="MsoListParagraphCxSpMiddle" style="margin-bottom:0cm;
text-indent:-18.0pt;line-height:15.0pt"><span lang="EN-US" style="font-family:
&quot;Calibri Light&quot;,sans-serif">-<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">the
adequate protection and back up of data on the Digital Services, and each User
acknowledges that the User has sole responsibility for adequate protection and
back up of his/her own data and/or User IT Equipment and for undertaking
reasonable and appropriate precautions to scan for computer viruses or other
destructive properties when accessing or using the Digital Services; or</span></p>

<p class="MsoListParagraphCxSpLast" style="margin-bottom:0cm;
text-indent:-18.0pt;line-height:15.0pt"><span lang="EN-US" style="font-family:
&quot;Calibri Light&quot;,sans-serif">-<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">the
accuracy, functionality or performance of any third party software that may be
used in connection with the Digital Services.</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><b><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></b></p>

<h1 style="margin-left:0cm;text-indent:0cm;font-weight:bold"><span lang="EN-US"> Part B: &nbsp;&nbsp; General provisions applicable to all Digital
Services</span></h1>

<p class="MsoListParagraphCxSpFirst" style="margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:1.0cm;line-height:15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoListParagraphCxSpMiddle" style="margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:1.0cm;text-indent:-1.0cm;
line-height:15.0pt"><a name="_Ref107842523"><b><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">1.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="FR-CH"><b></b> </span></a><b><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">Means of Authentication</span></b></p>

<p class="MsoListParagraphCxSpLast" style="margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:1.0cm;line-height:15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">Access
to the Digital Services is granted once the User has authenticated
himself/herself using the Means of Authentication (namely the username, the
password and the verification code communicated during the registration
process, the “<b>Means of Authentication</b>”). The Bank may substitute or
modify the Means of Authentication at any time. The Means of Authentication
provided to the User by the Bank may only be used in accordance with these
Terms and Conditions.</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">The
Client bears the responsibility for ensuring that each User fully complies with
the obligations set forth herein and with any further instructions from the
Bank.</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">The
Client and the Users are aware of and, by using the Digital Services accept
that the Bank cooperates with other HSBC Group entities, third party service
providers, including sub-contractors (the “Recipients”) for purposes of the
authentication process in order to access the Digital Services. The Recipients
will have access to the mobile number the Client and the User(s) provide to the
Bank and will process this information for purposes of the authentication
process are able to conclude that there is a banking relationship with the
Bank. </span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoListParagraphCxSpFirst" style="margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:1.0cm;text-indent:-1.0cm;
line-height:15.0pt;page-break-after:avoid"><b><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">2.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span lang="FR-CH"><b></b> </span><b><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">Due care obligations of the User</span></b></p>

<p class="MsoListParagraphCxSpLast" style="margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:1.0cm;line-height:15.0pt;
page-break-after:avoid"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">For the
use of the Digital Services, the Bank does not verify the identity of the User
by means of a signature or an ID verification. The verification of the User’s
authorisation is established exclusively on the basis of the Means of
Authentication (see Section 1).</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><b><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">Any
person successfully gaining access to the Digital Services using the Means of
Authentication shall be regarded by the Bank as being authorised to access and
use the Digital Services and all instructions received by the Bank through the
Digital Services shall be deemed to have been issued by the Client (represented
by the User). The Bank shall be authorised to execute such instructions which
are binding upon the Client. This shall apply irrespective of whether the
person accessing the Digital Services is actually a User and/or has been
authorised by the Client accordingly.</span></b></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">Any
User may request the blocking of such User’s access to the Digital Services.
Any User may also block his/her own access/ authorisation by entering the Means
of Authentication incorrectly until the system shows it to be blocked. The
Client and the User shall bear the risk of the use of the Means of
Authentication until the blocking takes effect. Means of Authentication do not
become invalid automatically, e.g., as a result of death or incapacity,
cancellation of the authority to sign or deletion from an official register.
Therefore, the Client, the Users or their legal successors must explicitly
request the Bank to block the Means of Authentication. In the case of non-use,
the Bank reserves the right, on its own initiative, to block or permanently
deactivate the access to the Digital Services.</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoListParagraph" style="margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:1.0cm;text-indent:-1.0cm;line-height:15.0pt;
page-break-after:avoid"><b><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">3.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span lang="FR-CH"><b></b> </span><b><span lang="EN-US" style="
font-family:&quot;Calibri Light&quot;,sans-serif">Security
measures to be taken by the User</span></b></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt;page-break-after:avoid"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">The
Client must promptly follow the instructions of the Bank regarding the use of
the Digital Services, especially those regarding security measures. The Bank
may provide these instructions on the Bank’s website, in the Digital Services
or through another channel deemed suitable by the Bank.</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">The
User undertakes to store the Means of Authentication carefully and separately
from each other. Means of Authentication (especially PIN/passwords) may not be
passed on or otherwise made available to other persons. PIN/passwords must be
modified immediately upon receipt and kept secret. PIN/passwords may not be
noted down physically and must be encrypted if stored electronically. PIN/
passwords must be chosen in such a way that they are not easy to determine
(e.g., no phone numbers, dates of birth, simple numeric sequences).</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">If
there are reasons to suspect that any other person has obtained knowledge of a
Means of Authentication, the User must ensure that they are changed
immediately. The loss of a Means of Authentication must be reported to the Bank
immediately. No replies must be sent to emails, SMS or other messages allegedly
sent by the Bank and requesting disclosure of the Means of Authentication. The
Bank must be informed immediately if any such request has been received.</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">Unauthorised
third parties may attempt to gain access to the User IT Equipment. The User
undertakes to implement appropriate security measures in order to minimize the
security risks. In particular, the operating system and browser must be kept up
to date and the User must install the security patches supplied and recommended
by the respective providers. It is the responsibility of the User to obtain
information about the required security precautions in respect of the User IT
Equipment and to implement them.</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<h1 style="font-weight: bold;margin-left:1.0cm;text-indent:-1.0cm;page-break-after:avoid"><span lang="EN-US">4.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US"><b></b> Risks</span></h1>

<p class="MsoListParagraph" style="margin-bottom:0cm;
line-height:15.0pt;page-break-after:avoid"><b><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></b></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">Any
risk which results from (i) a manipulation of the User IT Equipment, (ii) the
fraudulent utilization of Means of Authentication, (iii) breaches of duties of
care by a User or a third party or (iv) interference by unauthorised third
parties during data transmission are borne by the Client.</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">The
Client and the User are aware of and, by using the Digital Services accept, the
risks of exchanging information and data over public and private networks. The
risk of targeted manipulation of the User IT Equipment falls within the area of
responsibility of the User and must accordingly be borne by the User and/or
Client. The Bank hereby excludes all liability in respect of loss or damage
suffered due to transmission errors, misrouting, technical faults or defects, breakdowns
or illegal/fraudulent intrusions into a User IT Equipment (including systems
and transmission networks that are generally accessible to the public) or the
Digital Services, unless the Bank failed to exercise the standard of care
customary in the industry.</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoListParagraph" style="margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:1.0cm;text-indent:-1.0cm;line-height:15.0pt;
page-break-after:avoid"><b><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">5.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span lang="FR-CH"><b></b> </span><b><span lang="EN-US" style="
font-family:&quot;Calibri Light&quot;,sans-serif">Information
provided through the Digital Services </span></b></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt;page-break-after:avoid"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">The
Digital Services provide rates and a variety of information on financial
products, currencies and companies. All rates and information displayed in the
Digital Services reflect the rates and information of the prior business day.</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">The
Bank receives the rates and information displayed in the Digital Services from
third parties. Although the Bank carefully selects the data sources and
technical systems, there may be time delays and/or the rates and information
may contain errors or be incomplete. Actual prices when transacting in any
relevant investment may vary and the value of financial instruments may
fluctuate. It should be noted that any stated performance figures are
calculated on the basis of total return and include gross unrealised gains and
losses. The Bank exercises the standards of care and diligence customary in the
industry when displaying information via the Digital Services. The Bank
excludes any further guarantee and accepts no further liability for timeliness,
accuracy and completeness of the information provided via the Digital Services.
The information and messages displayed shall be regarded as purely indicative
and shall not be legally binding unless expressly stipulated otherwise. Other
than the warranties and representations expressly set out herein, all other
warranties and representations, whether express, implied or statutory are, to
the fullest extent permitted by applicable law, excluded.</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">For the
avoidance of doubt, the rates and information provided in the Digital Services
as well as the information supplied by the “Alerts and Notifications” tool (see
Part D below) do not constitute an offer to sell or a solicitation, or offer to
buy, any investment or advice or a recommendation in respect of any investment.
The information on the Digital Services is not intended for distribution to, or
use by, any person in any jurisdiction where such distribution would be
contrary to law or regulation. Persons interested in acquiring (or disposing
of) any investment should inform themselves as to: (i) the legal requirements
within the countries of their nationality, residence, ordinary residence or
domicile for such acquisition; (ii) any foreign exchange control requirement
which they might encounter on the acquisition or sale of investments and (iii)
the income tax and other tax consequences which might be relevant to the
acquisition, holding or disposal of any investment.</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">Information
available on the Digital Services about the Client’s holdings may be limited to
investments and assets in the Client’s investment accounts maintained with the
Bank. This means that details of some investments held with or through the
Bank, such as (i) any amount blocked to cover a guarantee issued by the Bank,
(ii) futures on indexes and equities, (iii) futures on commodities and (iv)
limits on credit facility, might not be displayed in the Digital Services.
These items will however be reflected on the periodic bank statements, which
are also made available through the Digital Services.</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">Where
information is provided regarding the tax characteristics of particular
investments, this will reflect the Bank’s understanding of current tax laws and
practices. However, this information does not constitute tax advice and should
not be relied upon as such. The tax treatment of an investment may vary
depending upon individual circumstances. There can be no guarantee that the
nature, basis or incidence of taxation will not change. The Client is reminded
that it is his/her responsibility to complete tax returns including all
information required by the tax authorities in the country or countries in
which the Client is subject to tax in accordance with all applicable laws and
tax rules in those countries. The Client is solely responsible for
understanding and complying with his/her tax obligations including identifying
all the Client’s taxable income, gains and assets, as applicable. The
information made available through the Digital Services has not been designed
and should not be used for tax reporting purposes. The Bank does not provide
legal or tax advice and if the Client or a User is in any doubt about the tax
consequences of any investment and/or obligations with respect to tax, the Client
or User should consult an independent tax and/or legal adviser.</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;text-indent:
35.4pt;line-height:15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<h1 style="font-weight:bold;margin-left:1.0cm;text-indent:-1.0cm"><span lang="EN-US">6.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-US"><b></b> Costs and fees</span></h1>

<p class="MsoListParagraph" style="margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:1.0cm;line-height:15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">The use
of the Digital Services does not trigger any additional costs for the Client or
any User.</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">The
Bank reserves the right to change the financial conditions (e.g., costs, fees,
discounts, perimeter of services) of the Digital Services at any time and with
immediate effect. Modifications are communicated to the Client through a
channel deemed suitable by the Bank.</span></p>

<p class="MsoListParagraphCxSpFirst" style="margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:1.0cm;line-height:15.0pt"><b><span lang="EN-US" style="font-family:" calibri="">&nbsp;</span></b></p>

<p class="MsoListParagraphCxSpLast" style="margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:1.0cm;text-indent:-1.0cm;
line-height:15.0pt"><b><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">7.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span lang="FR-CH"><b></b> </span><b><span lang="EN-US" style="
font-family:&quot;Calibri Light&quot;,sans-serif">Electronic
tools</span></b></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><b><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></b></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">To use
the Digital Services, the User may use the electronic tools provided by the
Bank directly (including a mobile application) or through a third party
(application “Live Sign”). The Bank grants the User the nonexclusive,
non-transferable right to use the electronic tools provided by the Bank at no
additional charge for purposes of the Digital Services.</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">Provided
the Bank exercises a level of care which is in line with industry standards,
the Bank offers no guarantee for the provision of uninterrupted, fault-free access
to its services. It will therefore accept no liability for any financial
prejudice as a result of faults, interruptions (including system-related
maintenance work) or the overloading of the User IT Equipment.</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">By
using the electronic tools provided by the Bank, the User acknowledges that
this use is at his/her own risk. As far as is legally permissible, the Bank
gives no guarantee that the electronic tools are provided entirely free of
errors. Moreover, the Bank gives no guarantee that all elements of the
electronic tools correspond to the User’s expectations or will function without
error in relation to all applications and in conjunction with any other
programs and device/network configurations selected by the User. </span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">In the
event that defects or errors impair or hinder the functionality of the Digital
Services, the User must refrain from using the Digital Services and immediately
notify the Bank. The use of the electronic tools provided by the Bank on
devices not controlled by the Bank, especially the use of applications on a
mobile device, may mean that third parties (e.g., device manufacturers,
providers of app distribution platforms or network providers) are able to
conclude that there is a banking relationship with the Bank or to access bank
client information (e.g., when bank client information is saved on the devices
or the device is lost). </span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoListParagraph" style="margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:1.0cm;text-indent:-1.0cm;line-height:15.0pt"><a name="_Hlk107836820"><b><span lang="EN-US" style="
font-family:&quot;Calibri Light&quot;,sans-serif">8.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span lang="FR-CH"><b></b> </span></a><b><span lang="EN-US" style="
font-family:&quot;Calibri Light&quot;,sans-serif">Intellectual
property rights</span></b></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">All
intellectual property rights (including, without limitation, copyright,
database rights, design rights, patents and trademarks) in the Digital Services
(including text and “look and feel” attributes) are owned by the Bank or
licensed to the Bank by third party licensors.</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">The
access to or use of the Digital Services does not grant the User any
intellectual property rights in the Digital Services. The User may print, copy,
download or temporarily store extracts of material obtained through the Digital
Services for personal use or to help the User use the Bank’s products and
services. The User may not alter or otherwise make any changes to any material
obtained through the Digital Services in any form, including, without
limitation, removing any proprietary information, identifying marks or legends
from such material. Any other use is prohibited unless the User first requests
and obtains the Bank’s written permission. Without limiting the above, unless
the User first obtains the Bank’s written consent, the User may not reproduce,
modify, adapt, transmit, publish, broadcast, create derivative works of, store,
archive or in any way exploit all or any part of the material obtained through
the Digital Services. All rights in such content are hereby reserved.</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">Content
from a third party provider available on or through the Digital Services may
not be duplicated, distributed, published, transferred, transmitted, copied,
altered, sold, used to create derivative works or otherwise misused. The User
must comply with all terms disclosed to the User as required from time to time
by any third party supplier of data or services to the Digital Services,
including but not limited to, where necessary, entering into a direct agreement
with such third party in respect of the use of their data/information or
complying with any terms and conditions such third party supplier of data or services
may require the User to comply with.</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">HSBC
and the hexagon logo are trademarks of HSBC Group Management Services Limited
and all rights in and to them vest in HSBC Group Management Services Limited.
Other than as provided above, the User may not use or reproduce the HSBC
trademark, hexagon logo or brand name.</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoListParagraph" style="margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:1.0cm;text-indent:-1.0cm;line-height:15.0pt"><b><span lang="EN-US" style="
font-family:&quot;Calibri Light&quot;,sans-serif">9.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span lang="FR-CH"><b></b> </span><b><span lang="EN-US" style="
font-family:&quot;Calibri Light&quot;,sans-serif">Third
party websites</span></b></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">The
Digital Services may provide links to, or data from, third party websites for
the User’s convenience in locating related information and services. The Bank
does not maintain any of these other websites and has no control over the
organisations that maintain these websites or the information, products or
services these organisations provide. The Bank cannot guarantee such websites’
accuracy, completeness or suitability for any purpose. Accordingly, the Bank
expressly disclaims any responsibility for the content of these other websites,
the accuracy of the information on these websites and/or the quality of
products or services provided by the organisations that maintain them. The Bank
does not recommend or endorse these organisations or their products or services
in any way and access to them is at the User’s own risk.</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoListParagraph" style="margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:1.0cm;text-indent:-1.0cm;line-height:15.0pt;
page-break-after:avoid"><b><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">10.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span lang="FR-CH"><b></b> </span><b><span lang="EN-US" style="
font-family:&quot;Calibri Light&quot;,sans-serif">Country-specific
restrictions</span></b></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt;page-break-after:avoid"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt;page-break-after:avoid"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">The
range of financial services offered to Users outside of Switzerland may be
subject to local legal/regulatory restrictions. If the Bank does not have the
necessary local approvals, the scope of the services available to Users from
the country concerned will be restricted. These restrictions are constantly
changing in line with legal/regulatory developments in the respective country.
The Bank shall be authorised to adjust or restrict, at any time and without
prior notice, the range of functionalities available. The Means of
Authentication provided by the Bank may be subject to specific import/export
restrictions as well as restrictions of use. Furthermore, export/import and the
use of the Means of Authentication by the User in third countries (i.e., in
countries other than the country to which the Bank originally delivered them)
may be subject to additional country-specific laws. The User shall be responsible
for being aware of and complying with all relevant restrictions and laws. The
Bank accepts no liability whatsoever in this regard and cannot and does not
provide any advice in this respect.</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoListParagraph" style="margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:1.0cm;text-indent:-1.0cm;line-height:15.0pt"><b><span lang="EN-US" style="
font-family:&quot;Calibri Light&quot;,sans-serif">11.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span lang="FR-CH"><b></b> </span><b><span lang="EN-US" style="
font-family:&quot;Calibri Light&quot;,sans-serif">Amendments
</span></b></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><i><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></i></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">The
Bank shall be entitled to amend these Terms and Conditions at any time. The
Bank shall announce the amendments in advance in any manner it deems suitable.
The amendments shall be deemed to be approved, unless an objection is raised in
writing within one month of the announcement, but in any event, when using the
Digital Services for the first time after the announcement.</span></p>

<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:1.0cm;line-height:15.0pt"><i><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></i></p>

<p class="MsoListParagraph" style="margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:1.0cm;text-indent:-1.0cm;line-height:15.0pt"><b><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">12.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span lang="FR-CH"><b></b> </span><b><span lang="EN-US" style="
font-family:&quot;Calibri Light&quot;,sans-serif">Termination</span></b></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><i><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></i></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">The
Client and the Bank may terminate the use of Digital Services or individual
functionalities offered by Digital Services at any time and with immediate
effect. Following the complete termination of Digital Services, the User must
render the physical Means of Authentication (if any) unusable and return them
at once to the Bank. Notwithstanding such termination, the Bank shall still be
authorised to execute, with legally binding effect for the Client, all
transactions initiated before the Means of Authentication were returned.</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><b><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></b></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><b><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></b></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><b><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></b></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><b><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></b></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><b><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></b></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><b><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></b></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><b><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></b></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><b><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></b></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><b><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></b></p>

<h1 style="font-weight: bold;margin-left:0cm;text-indent:0cm"><a name="_Part_C:_"></a><span lang="EN-US"> Part C: &nbsp;&nbsp; Privacy notice applicable to the Digital
Services</span></h1>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:15.0pt"><b><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></b></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">The
purpose of this Part C is to describe the manner in which the personal data of
the Users is processed by the Bank in the context of the Digital Services. For
purposes of this Part C and Part D below, the term “User” shall comprise, to
the extent relevant, the “Connected Persons” (as defined in the General Privacy
Notice), for example the beneficiary of a payment made through the Payments
Services (as defined in Part D below). </span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><b><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">By
accepting these Terms and Conditions, the Client confirms having notified every
User of, and that every User has understood and is aware of, the collection and
processing of information pertaining to such User, as described herein
(including this Part C).</span></b></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><b><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">&nbsp;</span></b></p>

<p class="MsoNormal" style="margin-bottom:0cm;line-height:
15.0pt"><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">The
information below supplements, but does not replace, the information contained
in the Bank’s privacy notice, which is available on the following link:
https://www.privatebanking.hsbc.com/privacy-notice/privacy-notice-for-hsbc-private-bank-suisse-sa/
(the “General Privacy Notice”). To the extent there is any conflict between
these documents, the order of precedence is as follows: (i) the General Terms
and Conditions of the Bank, (ii) the General Privacy Policy and (iii) these
Terms and Conditions.</span></p>

<p class="MsoBodyText" style="line-height:15.0pt"><span lang="EN-US" style="font-size:9.5pt">&nbsp;</span></p>

<h1 style="font-weight: bold;margin-left:1.0cm;text-indent:-1.0cm;page-break-after:avoid"><span lang="EN-US">1.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US"><b></b> Information the Bank may collect
about the User</span></h1>

<p class="MsoBodyText" style="line-height:15.0pt;page-break-after:avoid"><span lang="EN-US" style="font-size:13.5pt">&nbsp;</span></p>

<p class="MsoBodyText" style="margin-right:5.65pt;line-height:
15.0pt;page-break-after:avoid"><span lang="EN-US">The Bank may collect
information in order to provide the User with access to the Digital Services
and in order to verify the User is authorised to do so. The Bank may also
collect information during the visit of the Digital Services, including the
pages the User views, the links the User clicks and any other action taken in
connection with the Digital Services. The Bank also collects certain standard
information that the User’s web browser sends to every operator of a website
the User visit, such as the User’s IP address, browser type, access times and
referring website addresses. The Bank has ensured that third parties with whom
the Bank contracts to assist the Bank in providing the Digital Services have
agreed to maintain the confidentiality of the User’s data and process the
User’s information in line with the instructions that the Bank gives them. The
contractual relationships entered into with such third parties are structured
in accordance with applicable data protection rules.</span></p>

<p class="MsoBodyText" style="line-height:15.0pt"><span lang="EN-US" style="font-size:12.0pt">&nbsp;</span></p>

<h1 style="font-weight:bold;margin-left:1.0cm;text-indent:-1.0cm"><span lang="EN-US">2.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-US"><b></b> Purposes for which the Bank processes the User's information
in the context of the Digital Services</span></h1>

<p class="MsoBodyText" style="line-height:15.0pt"><span lang="EN-US" style="font-size:13.5pt">&nbsp;</span></p>

<p class="MsoBodyText" style="line-height:15.0pt"><span lang="EN-US">The Bank
processes the User's information for the following purposes:</span></p>

<p class="MsoBodyText" style="line-height:15.0pt"><span lang="EN-US" style="font-size:13.0pt">&nbsp;</span></p>

<p class="MsoListParagraph" style="margin-top:0cm;margin-right:1.0cm;margin-bottom:
0cm;margin-left:1.0cm;text-indent:-1.0cm;line-height:15.0pt;
text-autospace:none"><span lang="EN-US" style="font-family:Symbol">-<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">to
ensure that the User is authorised to access the Digital Services;</span></p>

<p class="MsoListParagraph" style="margin-top:0cm;margin-right:1.0cm;margin-bottom:
0cm;margin-left:1.0cm;text-indent:-1.0cm;line-height:15.0pt;
text-autospace:none"><span lang="EN-US" style="font-family:Symbol">-<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">to
ensure that the Digital Services are presented in the most effective manner for
the User on the User IT Equipment, including by eliminating the need for the
User to repeatedly enter the same information and by displaying content
customised to the User’s interests and preferences;</span></p>

<p class="MsoListParagraph" style="margin-top:0cm;margin-right:1.0cm;margin-bottom:
0cm;margin-left:1.0cm;text-indent:-1.0cm;line-height:15.0pt;
text-autospace:none"><span lang="EN-US" style="font-family:Symbol">-<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">to
perform research and analysis aimed at improving the Digital Services and, more
generally, the Bank’s services;</span></p>

<p class="MsoListParagraph" style="margin-top:0cm;margin-right:1.0cm;margin-bottom:
0cm;margin-left:1.0cm;text-indent:-1.0cm;line-height:15.0pt;
text-autospace:none"><span lang="EN-US" style="font-family:Symbol">-<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="font-family:&quot;Calibri Light&quot;,sans-serif">to
provide the User with products, services or information that the User requests
from the Bank or to carry out the Bank’s obligations arising from any contracts
entered into between the User and the Bank;</span></p>

` + tc2;
