export const resetPasswordActions = {
    validateUsernameIsLoading,
    storeUserName
};

function validateUsernameIsLoading(bool) {
    return {
        type: "VALIDATE_USERNAME_LOADING",
        isLoading: bool
    };
}

function storeUserName(userName) {
    return {
        type: "RESET_PASSWORD_USERNAME",
        payload: userName
    };
}

export function passwordSavedStatusClear() {
    return {
        type: "RESET_PASSWORD_CREATE_PASSWORD_STATUS_CLEAR"
    };
}
