import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Loader, Link, Button } from "hsbc-pb-sdk";
import _ from "lodash";
import { history } from "util/history";
import { FormattedMessage, injectIntl } from "react-intl";
import { color } from "util/color";
import CountDown from "components/OTPVerify/CountDown";
import { updateLockedAccountInfo } from "actions/lockedAccountAction";
import { isTextSelected, handleMaskNumber } from "util/service";
import { envConfig } from "env-config";

const mapStateToProps = state => {
    return {
        authenticateError: state.logon.error,
        selectedLang: state.languageSession.selectedLang,
        otpType: state.currentOTPType.otpType,
        target: state.currentOTPType.target || { _description: "unit test" },
        isMockLogin: state.logon.isMockLogin,
        emailOtpList: state.contactOptions.emailOtpList
    };
};
const mapDispatchToProps = dispatch => {
    return {
        updateLockedAccountInfo: error =>
            dispatch(updateLockedAccountInfo(error))
    };
};

class EnterOTP extends Component {
    constructor(props) {
        super(props);
        this.state = {
            verificationCode: "",
            verificationCodelegal: true,
            otpFinished: false,
            inputType: "text",
            isIe: false,
            "0": "",
            "1": "",
            "2": "",
            "3": "",
            "4": "",
            "5": ""
        };
        if (props.storeThis) {
            props.storeThis("APP", this);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.authenticateError) {
            this.failCallback(nextProps.authenticateError);
        }
        if (nextProps.submitErrorFromTransmit) {
            this.failCallback(nextProps.submitErrorFromTransmit);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.verificationCode !== this.state.verificationCode) {
            if (this.state.verificationCode) {
                window.tealiumHub.ET010(this.state.verificationCode);
            }
        }
    }
    componentDidMount() {
        this.invokePageEvent();
        setTimeout(() => {
            document.getElementById("hsbcLogo").focus();
        }, 500);
        document.addEventListener("keydown", this.handleEnterHit);

        const inputBox = document.getElementsByTagName("input")[0];
        const style = window.getComputedStyle(inputBox);

        if (!style.webkitTextSecurity) {
            this.setState({
                inputType: "password"
            });
        }
        this.clearCode();
    }

    componentWillUnmount = () => {
        document.removeEventListener("keydown", this.handleEnterHit);
    };

    invokePageEvent() {
        const pathname = window.location.hash;
        switch (pathname) {
            case "#/logon":
                window.tealiumHub.PT006();
                break;
            case "#/registration":
                window.tealiumHub.PT011();
                break;
            case "#/resetPassword":
                window.tealiumHub.PT018();
                break;
            default:
                return;
        }
    }

    handleChange = e => {
        const { value, dataset } = e.target;
        if ("0123456789".indexOf(value) < 0) {
            return;
        }

        const otpindex = Number(dataset.otpindex);
        if (value.length <= 1) {
            this.setState((prevState, props) => {
                return { [otpindex]: value };
            });
        }
        if (value.length === 1 && otpindex !== 5) {
            document
                .querySelector(`input[data-otpindex="${otpindex + 1}"]`)
                .select();
        }

        this.setState((prevState, props) => {
            const otpFinished = [0, 1, 2, 3, 4, 5].every(item => {
                return prevState[item] !== "";
            });
            return {
                otpFinished: otpFinished
            };
        });
    };

    caseKeyCode8 = (otpindex, isAtTheOnePostion, isAtTheZeroPostion) => {
        if (
            otpindex !== 0 &&
            !isAtTheOnePostion &&
            isTextSelected() === false
        ) {
            const isAtTheFirstInputBox = otpindex !== 0;
            if (isAtTheZeroPostion || isAtTheFirstInputBox) {
                document.querySelector(
                    `input[data-otpindex="${otpindex - 1}"]`
                ) &&
                    document
                        .querySelector(`input[data-otpindex="${otpindex - 1}"]`)
                        .select();
            }
        }
    };

    caseKeyNumber = (value, otpindex, e) => {
        if (value.length === 1) {
            if (otpindex !== 5 && isTextSelected() === false) {
                this.setState({
                    [otpindex + 1]: e.key
                });
                document.querySelector(
                    `input[data-otpindex="${otpindex - 1}"]`
                ) &&
                    document
                        .querySelector(`input[data-otpindex="${otpindex + 1}"]`)
                        .select();
            }
        }
    };

    caseArrowLeft = (otpindex, value, isAtTheZeroPostion) => {
        if (otpindex !== 0) {
            if (
                (value.length === 1 && isAtTheZeroPostion) ||
                value.length === 0
            ) {
                const lastInputBox = document.querySelector(
                    `input[data-otpindex="${otpindex - 1}"]`
                );
                setTimeout(() => lastInputBox.select(), 1);
            }
        }
    };

    caseArrowRight = (otpindex, value, isAtTheOnePostion) => {
        if (otpindex !== 5) {
            if (
                (value.length === 1 && isAtTheOnePostion) ||
                value.length === 0
            ) {
                const nextInputBox = document.querySelector(
                    `input[data-otpindex="${otpindex + 1}"]`
                );
                setTimeout(() => nextInputBox.select(), 1);
            }
        }
    };

    handleKeyDown = e => {
        const { value, dataset } = e.target;
        const otpindex = Number(dataset.otpindex);
        const isAtTheOnePostion =
            document.querySelector(`input[data-otpindex="${otpindex}"]`)
                .selectionStart === 1;
        const isAtTheZeroPostion =
            document.querySelector(`input[data-otpindex="${otpindex}"]`)
                .selectionStart === 0;
        if (e.keyCode === 8) {
            this.caseKeyCode8(otpindex, isAtTheOnePostion, isAtTheZeroPostion);
        } else if ("0123456789".indexOf(e.key) >= 0) {
            this.caseKeyNumber(value, otpindex, e);
        } else if (e.key === "ArrowLeft") {
            this.caseArrowLeft(otpindex, value, isAtTheZeroPostion);
        } else if (e.key === "ArrowRight") {
            this.caseArrowRight(otpindex, value, isAtTheZeroPostion);
        }
    };

    clearCode = () => {
        this.setState({
            otpFinished: false,
            "0": "",
            "1": "",
            "2": "",
            "3": "",
            "4": "",
            "5": ""
        });
    };

    failCallback = submitErrorFromTransmit => {
        console.log("=====OTP FAil call back=====");
        const errorCode = _.get(submitErrorFromTransmit, "_errorCode", false);

        const message = _.get(submitErrorFromTransmit, "_message", false);

        if (errorCode) {
            const isRegistrationJourney =
                window.location.href.indexOf("registration") !== -1;
            if (isRegistrationJourney) {
                const otpErrorCounter =
                    sessionStorage.getItem("otpErrorCounter") || 0;
                if (otpErrorCounter < 2) {
                    history.push({
                        pathname: "/commonLockedAccount/registrationOtpError",
                        state: { errorCode: "registrationOtpError" }
                    });
                } else {
                    history.push({
                        pathname:
                            "/commonLockedAccount/registrationOtpErrorThreeTimes",
                        state: { errorCode: "registrationOtpErrorThreeTimes" }
                    });
                }
                sessionStorage.setItem(
                    "otpErrorCounter",
                    parseInt(otpErrorCounter) + 1
                );
                sessionStorage.setItem("isOtpError", true);
            }
        }

        if (
            errorCode === 11 &&
            message.indexOf("Auth session expired") !== -1
        ) {
            this.setState(previousState => {
                const id = this.props.isRealmCH
                    ? "enterOtp.error.expired-CH"
                    : "enterOtp.error.expired";
                return {
                    ...previousState,
                    verificationCode: this.props.intl.formatMessage({
                        id
                    }),
                    verificationCodelegal: false
                };
            });
        }
    };

    handleEnterHit = e => {
        if (e.keyCode === 13) {
            this.validateAndSubmitOTP();
        }
    };

    validateAndSubmitOTP = () => {
        let digitCode = "";
        [0, 1, 2, 3, 4, 5].forEach(i => {
            digitCode = digitCode + this.state[i];
        });
        if (digitCode.length !== 6) {
            this.setState(previousState => {
                const id = this.props.isRealmCH
                    ? "enterOtp.error.notMatch-CH"
                    : "enterOtp.error.notMatch";
                return {
                    ...previousState,
                    verificationCode: this.props.intl.formatMessage({
                        id
                    }),
                    verificationCodelegal: false
                };
            });
            return;
        }
        window.tealiumHub.ET009("enterOtp.label.submit");
        this.props.submitOTP(digitCode);
    };

    resendOTPAndRestartCountdown = () => {
        this.props.resentOTP();
    };

    getExtraComponentAfterResentBtnClicked = () => {
        if (this.props.isRealmCH) {
            if (this.props.otpType === "SMS") {
                return (
                    <Link
                        className={"link--emailOTP-entry"}
                        icon={true}
                        alignment="right"
                        type="s4"
                        size="small"
                        text={this.props.intl.formatMessage({
                            id: "EmailOTPContactOptions.entry"
                        })}
                        onClick={this.props.clickEmailOTPEntryBtn}
                    />
                );
            } else {
                return (
                    <div className={"note--emailOTP-resent"}>
                        <i className="icon exclamation triangle error" />
                        <FormattedMessage id="EmailOTPEnterVerificationCode.resendTip" />
                    </div>
                );
            }
        } else {
            return null;
        }
    };

    getErrorComp = () => {
        const isRegistrationJourney =
            window.location.href.indexOf("registration") !== -1;
        const errorContent = isRegistrationJourney ? (
            <FormattedMessage id="registration.otp.final.tip" />
        ) : (
            <FormattedMessage id="otp.last.attampt.tip" />
        );
        const otpErrorCounter = parseInt(
            sessionStorage.getItem("otpErrorCounter")
        );
        return otpErrorCounter >= 2 || this.props.isLastAttempt ? (
            <p className="statement--validation">
                <span className="icon--container">
                    <i className="icon exclamation triangle" />
                </span>
                <span className="error--content">{errorContent}</span>
            </p>
        ) : null;
    };

    getCountdownComp = extraComponent => {
        return (
            <CountDown
                otpType={this.props.otpType}
                clickFn={this.resendOTPAndRestartCountdown}
                clearFn={this.clearCode}
                shouldShowBtnAtTheBeginning={true}
                duration={60}
                compShownAfterResentClicked={extraComponent}
                isRealmCH={this.props.isRealmCH}
            />
        );
    };

    shouldShowHeader = () => {
        const currentUrlPathIsNotLogon =
            window.location.href.indexOf("logon") === -1;

        return (
            currentUrlPathIsNotLogon ||
            (envConfig.languageSlectList &&
                envConfig.languageSlectList.length > 1)
        );
    };

    getMainTextAndAriaLabel = isAriaLabel => {
        let finalText = "";
        const description =
            this.props.otpType === "EMAIL"
                ? this.props.emailOtpList
                : this.props.target._description;
        const des = isAriaLabel ? handleMaskNumber(description) : description;
        const target = this.props.isMockLogin ? "" : des;

        const getIntlText = id => {
            return this.props.intl.formatMessage({
                id
            });
        };

        if (this.props.selectedLang === "zh-sim-HK") {
            finalText = this.props.isMockLogin
                ? `${getIntlText("login.mock.otp")}`
                : `${getIntlText(
                      "enterOtp.label.sendToFirstPart"
                  )} ${target} ${getIntlText(
                      "enterOtp.label.sendToSecondPart"
                  )}`;
        } else {
            finalText = this.props.isMockLogin
                ? `${getIntlText("login.mock.otp")}`
                : `${getIntlText("enterOtp.label.sendTo")} ${target}`;
        }

        if (!isAriaLabel) {
            if (
                this.props.selectedLang === "zh-trd-HK" ||
                this.props.selectedLang === "zh-sim-HK"
            ) {
                finalText += "。";
            } else {
                finalText += ".";
            }
        }

        return finalText;
    };

    touched = false;

    render() {
        const showHeader = this.shouldShowHeader();
        const errorComp = this.getErrorComp();
        const extraComponent = this.getExtraComponentAfterResentBtnClicked();
        const countDownComponent = this.getCountdownComp(extraComponent);
        const takeUp =
            this.props.otpType === "SMS" && this.props.isRealmCH ? (
                <FormattedMessage id="enterOtp.label.takeUp.CH" />
            ) : null;
        const isDisable =
            this.props.isLoading || !this.state.otpFinished ? true : false;
        const isLoading = this.props.isLoading ? "disabled" : "";
        const otpFinished = !this.state.otpFinished ? "otpNotFinished" : "";
        return (
            <ModalContent showHeader={showHeader} otpType={this.props.otpType}>
                <h1 id="modalTitle">
                    <FormattedMessage id="enterOtp.title" />
                </h1>
                <p aria-label={this.getMainTextAndAriaLabel(true)}>
                    <span aria-hidden="true">
                        {this.getMainTextAndAriaLabel()}
                    </span>
                </p>
                {this.props.isMockLogin ? (
                    ""
                ) : (
                    <p>
                        <FormattedMessage id="enterOtp.label.takeUp" />
                        {takeUp}
                    </p>
                )}

                <div>
                    {[0, 1, 2, 3, 4, 5].map((item, index) => {
                        return (
                            <input
                                tabIndex={0}
                                aria-label={
                                    this.props.intl.formatMessage({
                                        id: "enterOtp.label.inputAria"
                                    }) + `${index + 1}`
                                }
                                key={"digitCode" + index}
                                type={this.state.inputType}
                                name="digitCodeSingle"
                                data-otpindex={index}
                                value={this.state[index]}
                                onChange={this.handleChange}
                                onKeyDown={this.handleKeyDown}
                                className="code-enter"
                                autoComplete="off"
                                data-testid="enter-code-input"
                                onFocus={() => {
                                    if (!this.touched) {
                                        window.tealiumHub.ET008();
                                        this.touched = true;
                                    }
                                    this.setState({
                                        verificationCodelegal: true
                                    });
                                }}
                            />
                        );
                    })}
                </div>
                <div>
                    <div aria-live="assertive" aria-atomic="true">
                        {errorComp}
                    </div>
                </div>
                <p className="separator" />
                <div className="foot">
                    {this.props.isMockLogin ? (
                        ""
                    ) : (
                        <div>{countDownComponent}</div>
                    )}
                    {this.props.isLoading ? (
                        <div className="btn--loader--container contentmodal--footer-right ">
                            <Loader
                                className="button icon btn--loader"
                                size="small"
                            ></Loader>
                        </div>
                    ) : (
                        <Button
                            disabled={isDisable}
                            secondary
                            className={`login-otp-btn submit ${isLoading} ${otpFinished}`}
                            onClick={this.validateAndSubmitOTP}
                        >
                            <FormattedMessage id="enterOtp.label.submit" />
                        </Button>
                    )}
                </div>
            </ModalContent>
        );
    }
}

const ModalContent = styled.div`
    box-shadow: 0px 16px 24px 2px rgba(0, 0, 0, 0.14);
    background-color: ${color.white};
    color: ${color.text_color_dark};
    padding: 50px 50px 40px 50px;
    padding-bottom: ${p => (p.otpType === "EMAIL" ? "12px" : "40px")};
    order: 1;
    margin: 20px;
    margin-top: ${props => (props.showHeader ? "20px" : "105px")};
    margin-bottom: ${props => (props.showHeader ? "20px" : "104px")};
    input[name="digitCodeSingle"] {
        font-family: "UniversNextForHSBC", "Helvetica Neue", Helvetica, Arial,
            sans-serif;
        border-radius: 6px;
        border: 1px solid #767676;
        margin: 4px;
        padding-left: 8px;
        padding-right: 8px;
        width: 36px;
        height: 42px;
        font-size: 28px;
        box-sizing: border-box;
        color: #333333;
        background-color: white;
        &:focus {
            border: 1px solid red;
            outline: none;
        }
    }
    @media (max-width: 320px) {
        width: 280px;
        padding: 14px;

        .p-line--height {
            max-width: 130px;
        }
    }
    @media (min-width: 321px) and (max-width: 413px) {
        width: 345px;
        padding: 14px;

        .p-line--height {
            max-width: 200px;
        }
    }
    @media (min-width: 414px) and (max-width: 768px) {
        width: 374px;

        .p-line--height {
            max-width: 170px;
        }

        .note--emailOTP-resent {
            left: -35px;
            width: 344px;
        }
    }
    @media (min-width: 769px) {
        width: 524px;
        max-height: 600px;

        .note--emailOTP-resent {
            left: -35px;
            width: 500px;
        }
    }
    display: flex;
    flex-direction: column;

    input {
        text-align: center;
    }

    .separator {
        border-top: 1px solid #d7d8d6;
        margin-top: 31px;
        margin-bottom: 20px;
    }
    .code-enter {
        width: 80px;
        height: 40px;
        font-size: 14px;
        padding: 12px 12px;
        border: 1px solid #929292;
        text-security: disc;
        -webkit-text-security: disc;
        -moz-text-security: disc;
        &.error {
            border: 1px solid ${color.error_msg_stroke_outline};
        }
    }
    .btn--loader--container {
        position: absolute;
        display: inline-block;
        top: 0px;
        right: 0px;
        width: 102px;
        margin-bottom: 50px;
        .btn--loader {
            margin-top: 24px;
            width: 102px;
            padding-top: 10px;
            padding-bottom: 0px;
            height: 46px;
            cursor: default;
            background-color: #730014;
            .children {
                margin-top: 0px;
            }
        }
        .hsbc-ui.loader .spinner {
            border: 2px solid #fff;
            border-right-color: transparent;
        }
        .btn--loader:before {
            padding-top: 0px;
        }
    }
    .login-otp-btn {
        position: absolute;
        top: 0px;
        right: 0px;
        cursor: pointer;
        height: 46px;
        padding: 0 20px;
        font-size: 16px;
    }
    button {
        position: absolute;
        top: 0px;
        right: 0px;
        cursor: pointer;
        height: 46px;
        padding: 0 20px;
        font-size: 16px;
        font-weight: 100;
        line-height: 16px;
        border: none;
        font-family: "Univers Next for HSBC";
        &.request {
            background-color: ${color.white};
            color: ${color.text_color_dark};
            border: 1px solid #d7d8d6;
        }
        &.submit {
            background-color: ${color.hsbc_red};
            color: ${color.white};
            font-weight: 1000;
        }
        &.disabled {
            background-color: ${color.hsbc_warning_yellow};
        }
        &.otpNotFinished {
            background-color: ${color.grey};
        }
    }

    h1 {
        font-size: 27px;
        font-weight: 100;
        line-height: 36px;
        margin: 0px;
        margin-bottom: 12px;

        @media (min-width: 1024px) {
            font-size: 31px;
        }
    }

    h2 {
        margin-top: 30px;
        margin-bottom: 9px;
        font-size: 16px;
        font-weight: normal;
        line-height: 19px;
    }
    p {
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        &.statement--validation {
            @media (max-width: 768px) {
                width: auto;
            }
            @media (min-width: 769px) {
                width: 350px;
            }
            line-height: 28px;
            font-weight: 400;
            .icon {
                color: ${color.hsbc_warning_red};
                position: relative;
                padding-right: 1px;
            }
            .icon--container {
                float: left;
            }
            .error--content {
                @media (max-width: 768px) {
                    width: auto;
                }
                @media (min-width: 769px) {
                    float: right;
                    width: 320px;
                }

                font-size: 14px;
            }
        }
    }

    .foot {
        position: relative;
        display: flex;
        min-height: 46px;
    }

    /* .email-otp-entry {
        padding-top: 10px;
    } */
`;
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(EnterOTP));
