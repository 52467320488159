import TermsAndCondition from "./registrationConditions/T&C.js";
import PrivacyPolicy from "./registrationConditions/PP.js";
import lang from "./lang.json";

export default {
    lang,
    registrationTerms: [
        {
            label: "Terms and Conditions",
            html: TermsAndCondition
        },
        {
            label: "Privacy Policy",
            html: PrivacyPolicy
        }
    ]
};
