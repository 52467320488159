import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { Notification } from "hsbc-pb-sdk";

const WarningMsg = ({ warningMsg, type, plain, id, intl }) => {
    return (
        <WarningMsgContainer className="block--warning-msg">
            {type === "yellow" ? (
                <Notification
                    warning
                    inline
                    content={intl.formatMessage({ id: warningMsg })}
                />
            ) : (
                <Notification
                    error
                    inline
                    content={intl.formatMessage({ id: warningMsg })}
                />
            )}
        </WarningMsgContainer>
    );
};

WarningMsg.propTypes = {
    message: PropTypes.string,
    type: PropTypes.string,
    plain: PropTypes.bool
};

const WarningMsgContainer = styled.div`
    margin-top: 22px;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: -10px;
    position: relative;

    .hsbc-ui.notification {
        font-size: 14px;
        line-height: 20px;
        padding-left: 0;
        color: #000;
        span {
            margin-left: 10px;
        }
    }
`;

export default injectIntl(WarningMsg);
