import enGB from "./language/en-GB";
import enHK from "./language/en-HK";
import enUS from "./language/en-US";
import enCI from "./language/en-CI";
import frLU from "./language/fr-LU";
import zhSimHK from "./language/zh-sim-HK";
import zhTrdHK from "./language/zh-trd-HK";

export default {
    "en-GB": enGB,
    "en-HK": enHK,
    "en-US": enUS,
    "en-CI": enCI,
    "zh-sim-HK": zhSimHK,
    "zh-trd-HK": zhTrdHK,
    "fr-LU": frLU,
    "en-LU": frLU
};
