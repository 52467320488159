export const registrationActions = {
    validateDetailsIsLoading,
    resetDetailsError
};

function validateDetailsIsLoading(bool) {
    return {
        type: "VALIDATE_DETAILS_LOADING",
        isLoading: bool
    };
}

function resetDetailsError() {
    return {
        type: "RESET_DETAILS_ERROR"
    };
}
