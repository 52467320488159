export const initialState = {
    otpType: "SMS",
    target: null
};

export function currentOTPType(state = initialState, action) {
    switch (action.type) {
        case "CURRENT_OTP_TYPE_UPDATE":
            return {
                ...state,
                otpType: action.payload
            };
        case "CURRENT_TARGET_SELECTED":
            return {
                ...state,
                target: action.payload
            };
        default:
            return state;
    }
}
