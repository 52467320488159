import React, { Component } from "react";
import styled from "styled-components";
import { envConfig } from "env-config";
import { injectIntl } from "react-intl";
import { Button } from "hsbc-pb-sdk";
import _ from "lodash";
import { languageActions } from "actions/languageAction";
import { connect } from "react-redux";

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {
        showMobileLanguageContainer: isShowContianer =>
            dispatch(
                languageActions.showMobileLanguageContainer(isShowContianer)
            )
    };
};
class LanguageDropDown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [{ key: 1, value: "English", category: "English" }]
        };
    }
    showMobileLanguageContainer = () => {
        this.props.showMobileLanguageContainer("true");
    };
    render() {
        const languageList = envConfig.languageSlectList;
        let text;
        if (_.find(languageList, ["locale", this.props.selectedLang])) {
            text = _.find(languageList, ["locale", this.props.selectedLang])
                .text;
        } else {
            text = "English";
        }

        return (
            <LanguageDropDownContainer>
                <div
                    className="selectContainer"
                    onClick={this.showMobileLanguageContainer}
                    data-testid="select-containers"
                >
                    <Button
                        className="dropdown alignment"
                        data-testid="dropdown-text"
                    >
                        {text}
                    </Button>
                    <i className="icon angle down language-drop-down"></i>
                </div>
            </LanguageDropDownContainer>
        );
    }
}

const LanguageDropDownContainer = styled.div`
    .selectContainer {
        display: flex;
        margin-right: 10px;
        .language-drop-down {
            color: #333333;
            margin-left: 12px;
        }
    }
`;
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(LanguageDropDown));
