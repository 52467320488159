export const initialState = {
    validateUsername: {},
    validateSecurityQuestion: {},
    isLoading: false,
    error: {},
    securityQuestion: [
        {
            _authenticator: {
                _authenticatorMethodConfig: {
                    question: [""]
                }
            }
        }
    ],
    userName: "",
    otpStatus: null,
    seqQsStatus: null,
    isRenderLastAttemptMsg: false
};

export function resetPassword(state = initialState, action) {
    switch (action.type) {
        case "VALIDATE_USERNAME_LOADING":
            return {
                ...state,
                isLoading: action.isLoading,
                error: {}
            };
        case "RESET_PASSWORD_CREATE_PASSWORD_SUC":
            return {
                ...state,
                createPasswordSuc: true
            };
        case "RESET_PASSWORD_CREATE_PASSWORD_STATUS_CLEAR":
            return {
                ...state,
                createPasswordSuc: null,
                isLoading: false
            };
        case "RESET_PASSWORD_SECURITY_QUESTION_RECEIVED":
            return {
                ...state,
                securityQuestion: action.payload,
                isLoading: false
            };
        case "RESET_PASSWORD_USERNAME":
            return {
                ...state,
                userName: action.payload
            };
        default:
            return state;
    }
}

function processData(data) {
    return data;
}
