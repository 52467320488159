export const initialState = {
    validationError: false,
    selectedOption: undefined,
    hasError: false,
    target: null,
    resolveInput: null
};

export function contactOptions(state = initialState, action) {
    if (action.type === "EMAIL_OTP_LIST") {
        return {
            emailOtpList: action.list
        };
    } else {
        return state;
    }
}
