import React, { Component } from "react";
import { envConfig } from "env-config";
import styled from "styled-components";
import { Button } from "hsbc-pb-sdk";
import { FormattedMessage, injectIntl } from "react-intl";
import { color } from "util/color";

class ModalPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            pagestate: "",
            errorReason: ""
        };
    }

    onConfirmationClick = () => {
        const { url, wvUri } = envConfig;
        window.location.href = `https://${url + wvUri}`;
    };

    render() {
        const titleIconRight = (
            <i
                onClick={this.onConfirmationClick}
                className="icon icon--delete delete"
            />
        );
        const {
            title = "onlyEnglishTips.title",
            ariaTitle = "global.notext",
            titleIcon,
            confirmationBtnDisabled,
            customizedFooter,
            confirmationBtnTitle = "inputCredential.label.continue",
            confirmationBtnStyle,
            dialogWidth,
            disabledButton
        } = this.props;
        return (
            <SecurityModalContainer
                confirmationBtnStyle={confirmationBtnStyle}
                dialogWidth={dialogWidth}
                titleIcon={titleIcon}
            >
                <div className="contentmodal--body">
                    <div className="contentmodal--icon">{titleIcon || ""}</div>
                    <div style={{ width: "100%" }}>
                        <h1
                            aria-label={this.props.intl.formatMessage({
                                id: ariaTitle
                            })}
                            key="header"
                            className="contentmodal--header"
                        >
                            <FormattedMessage id={title} />
                        </h1>
                        <div key="content" className="contentmodal--content">
                            <FormattedMessage id="onlyEnglishTips.label.container" />
                        </div>
                    </div>
                    <div className="contentmodal--icon--right">
                        {titleIconRight || ""}
                    </div>
                </div>
                <div key="buttons" className="contentmodal--footer">
                    <div className="contentmodal--footer-left">
                        {customizedFooter}
                    </div>
                    <Button
                        className={`contentmodal--footer-right primary btn--confirm ${
                            disabledButton ? "disabledButton" : ""
                        }`}
                        secondary
                        onClick={this.onConfirmationClick}
                        hidden={confirmationBtnDisabled ? true : false}
                        disabled={disabledButton}
                    >
                        {disabledButton ? (
                            "Verifying..."
                        ) : (
                            <FormattedMessage id={confirmationBtnTitle} />
                        )}
                    </Button>
                </div>
            </SecurityModalContainer>
        );
    }
}

export default injectIntl(ModalPage);

const NoContactDetailsContainer = styled.div`
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 16px;
    font-weight: 300;
    line-height: 19px;
`;

const SecurityModalContainer = styled.div`
    box-shadow: 0px 16px 24px 2px rgba(0, 0, 0, 0.14);
    background-color: ${color.white};
    color: ${color.text_color_dark};
    margin: 20px;
    position: relative;
    z-index: 1;
    @media (max-width: 320px) {
        margin: 20px;
        padding: 14px;
        width: 280px;
        .divBody.bodyExpanded{
            .table_container {
                width: 100%;
                overflow: scroll;
            }
            max-width: 264px;
            
            overflow: hidden;
        }
    }
    @media (min-width: 321px) and (max-width: 413px) {
        margin: 20px;
        padding: 14px
        width: 335px;
        .divBody.bodyExpanded{
            .table_container {
                width: 100%;
                overflow: scroll;
            }
            max-width: 319px;
            overflow: hidden;
        }
    }
    @media (min-width: 414px) and (max-width: 768px) {
        margin: 20px;
        padding: 30px 50px 40px 50px;
        width: 374px;
        .divBody.bodyExpanded{
            .table_container {
                width: 100%;
                overflow: scroll;
            }
            max-width: 319px;
            overflow: hidden;
        }
    }
    @media (min-width: 769px) {
        padding: 30px 50px 40px 50px;
        margin-left: 0px;
        width: ${props => props.dialogWidth || "454px"};
        max-width:  ${props => props.dialogWidth || "454px"};
        .divBody.bodyExpanded{
            .table_container {
            }
        }
    }
    @media (min-width: 769px) and (max-width: 898px) {
        margin: 20px 0px 0px 0px;
    }
    @media (min-width: 898px) and (max-width: 907px) {
        margin-bottom: 0px;
    }
    .contentmodal--body {
        display: flex;
        justify-content: flex-start;
        text-align: center;
        position: relative;
        .icon {
            color: ${color.hsbc_warning_red};
        }
    }
    .contentmodal--icon {
        .icon {
            font-size: 42px;
            margin-right: 25px;
        }
        .warning {
            color: ${color.hsbc_warning_yellow};
        }
    }
    .contentmodal--icon--right {
        position: absolute;
        right: 0;
        top: 5px;
        .icon {
            font-size: 25px;
        }
        .delete {
            color: ${color.text_color_dark};
        }
    }
    .contentmodal--header {
        font-size: 20px;
        font-weight: 100;
        line-height: 36px;
        padding-bottom: 10px;
        margin: 0px;
        .title--wrapper {
            display: inline-block;
        }
    }
    .contentmodal--content {
        font-size: 16px;
        line-height: 19px;
        font-weight: normal;
        text-align: left;
        padding: 30px 0;
        border-top: 1px solid #d7d8d6;
        width: 100%;
    }
    .contentmodal--footer {
        padding-top: 20px;
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        .btn--confirm {
            ${props =>
                props.confirmationBtnStyle
                    ? props.confirmationBtnStyle
                    : `
                `};
            .disabledButton {
                background-color: ${color.hsbc_warning_yellow};
            }
        }
        .btn--confirm:disabled {
            background-color: ${color.hsbc_warning_yellow};
        }
        .btn--confirm:hidden {
            display: none;
        }
    }
`;
