import React from "react";
import FullScreenContainer from "components/common/FullScreenContainer";
import styled from "styled-components";

const UnsupportedBrowser = () => {
    const content = "unsupportedBrowser.content";
    const title = "unsupportedBrowser.title";
    return (
        <UnsupportedBrowserContainer>
            <FullScreenContainer content={content} title={title} />
        </UnsupportedBrowserContainer>
    );
};

const UnsupportedBrowserContainer = styled.div`
    width: 100%;
    @media (max-width: 1024px) and (min-width: 769px) {
        .wrapper {
            margin-top: 59px;
            .wrapper .fullscreenMsg-content p {
                width: 51%;
            }
        }
    }
    @media (max-width: 414px) {
        .wrapper {
            margin-top: 60px;
        }
    }
    @media (max-width: 360px) {
        .wrapper {
            margin-top: 40px;
        }
    }
    @media (max-width: 320px) {
        .wrapper {
            margin-top: 16px;
        }
    }
    @media (min-width: 1440px) {
        .wrapper .fullscreenMsg-content p {
            width: 47%;
        }
    }
`;

export default UnsupportedBrowser;
