export const securityQuestionListActions = {
    getSecurityQuestionListSuccess,
    securityQuestionBtn
};

function getSecurityQuestionListSuccess(data) {
    return {
        type: "SECURITY_QUESTION_LIST_SUCCESS",
        data
    };
}

function securityQuestionBtn(data) {
    return {
        type: "SECURITY_QUESTION_BTN",
        data
    };
}
