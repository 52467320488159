export const currentOTPTypeActions = {
    updateCurrentOTPType,
    updateSelectedTarget
};

function updateCurrentOTPType(otpType) {
    return {
        type: "CURRENT_OTP_TYPE_UPDATE",
        payload: otpType
    };
}

function updateSelectedTarget(target) {
    return {
        type: "CURRENT_TARGET_SELECTED",
        payload: target
    };
}
