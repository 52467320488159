import { tc2 } from "./T&C2";
export default `<html><head>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8">
<meta name="Generator" content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:DengXian;
	panose-1:2 1 6 0 3 1 1 1 1 1;}
@font-face
	{font-family:UniversNextforHSBC-Light;
	panose-1:0 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:UniversNextforHSBC-Regular;
	panose-1:0 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:UniversNextforHSBC-Thin;
	panose-1:2 11 3 3 3 2 2 2 2 3;}
@font-face
	{font-family:"Segoe UI";
	panose-1:2 11 5 2 4 2 4 2 2 3;}
@font-face
	{font-family:"\@等线";
	panose-1:2 1 6 0 3 1 1 1 1 1;}
 /* Style Definitions */
 p.MsoNormalrp, li.MsoNormalrp, div.MsoNormalrp
	{margin:0cm;
	margin-bottom:.0001pt;
	line-height:normal;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:windowtext;
	mso-believe-normal-left:yes;}
p.MsoCommentText, li.MsoCommentText, div.MsoCommentText
	{mso-style-link:"Comment Text Char";
	margin:0cm;
	margin-bottom:.0001pt;
	line-height:normal;
	font-size:10.0pt;
	font-family:"Times New Roman",serif;
	color:windowtext;}
p.MsoHeader, li.MsoHeader, div.MsoHeader
	{mso-style-link:"Header Char";
	margin:0cm;
	margin-bottom:.0001pt;
	line-height:normal;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:windowtext;}
p.MsoFooter, li.MsoFooter, div.MsoFooter
	{mso-style-link:"Footer Char";
	margin:0cm;
	margin-bottom:.0001pt;
	line-height:normal;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:windowtext;}
a:link, span.MsoHyperlink
	{font-family:"UniversNextforHSBC-Light",sans-serif;
	color:blue;
	text-decoration:underline;}
a:visited, span.MsoHyperlinkFollowed
	{font-family:"UniversNextforHSBC-Light",sans-serif;
	color:purple;
	text-decoration:underline;}
p
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:16.5pt;
	margin-left:0cm;
	line-height:16.5pt;
	font-size:12.0pt;
	font-family:"UniversNextforHSBC-Light",sans-serif;
	color:#252525;}
p.MsoCommentSubject, li.MsoCommentSubject, div.MsoCommentSubject
	{mso-style-link:"Comment Subject Char";
	margin:0cm;
	margin-bottom:.0001pt;
	line-height:normal;
	font-size:10.0pt;
	font-family:"Times New Roman",serif;
	color:windowtext;
	font-weight:bold;}
p.MsoAcetate, li.MsoAcetate, div.MsoAcetate
	{mso-style-link:"Balloon Text Char";
	margin:0cm;
	margin-bottom:.0001pt;
	line-height:normal;
	font-size:9.0pt;
	font-family:"Segoe UI",sans-serif;
	color:windowtext;}
p.msonormal0, li.msonormal0, div.msonormal0
	{mso-style-name:MsoNormalrp;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:16.5pt;
	margin-left:0cm;
	line-height:16.5pt;
	font-size:12.0pt;
	font-family:"UniversNextforHSBC-Light",sans-serif;
	color:#252525;}
p.Title1, li.Title1, div.Title1
	{mso-style-name:Title1;
	margin-right:0cm;
	margin-bottom:16.5pt;
	margin-left:0cm;
	line-height:32.25pt;
	font-size:25.0pt;
	font-family:"UniversNextforHSBC-Regular",sans-serif;
	color:#333333;}
p.subheader, li.subheader, div.subheader
	{mso-style-name:subheader;
	margin-right:0cm;
	margin-left:0cm;
	line-height:16.5pt;
	font-size:14.5pt;
	font-family:"UniversNextforHSBC-Thin",sans-serif;
	color:windowtext;}
p.bold, li.bold, div.bold
	{mso-style-name:bold;
	margin-right:0cm;
	margin-left:0cm;
	line-height:normal;
	font-size:12.0pt;
	font-family:"UniversNextforHSBC-Regular",sans-serif;
	color:windowtext;}
p.listonelevel, li.listonelevel, div.listonelevel
	{mso-style-name:listonelevel;
	margin-right:0cm;
	margin-left:0cm;
	line-height:16.5pt;
	font-size:12.0pt;
	font-family:"UniversNextforHSBC-Light",sans-serif;
	color:#252525;}
p.backtotop, li.backtotop, div.backtotop
	{mso-style-name:backtotop;
	margin-top:15.0pt;
	margin-right:0cm;
	margin-bottom:16.5pt;
	margin-left:0cm;
	line-height:normal;
	font-size:12.0pt;
	font-family:"UniversNextforHSBC-Light",sans-serif;
	color:windowtext;}
p.disclaimer, li.disclaimer, div.disclaimer
	{mso-style-name:disclaimer;
	margin-top:45.0pt;
	margin-right:-15.0pt;
	margin-bottom:0cm;
	margin-left:-15.0pt;
	margin-bottom:.0001pt;
	line-height:16.5pt;
	background:#0C1D28;
	font-size:12.0pt;
	font-family:"UniversNextforHSBC-Light",sans-serif;
	color:windowtext;}
p.label, li.label, div.label
	{mso-style-name:label;
	margin-right:0cm;
	margin-left:0cm;
	line-height:normal;
	font-size:12.0pt;
	font-family:"UniversNextforHSBC-Light",sans-serif;
	color:windowtext;}
p.icon, li.icon, div.icon
	{mso-style-name:icon;
	margin-right:0cm;
	margin-left:0cm;
	line-height:normal;
	font-size:12.0pt;
	font-family:"UniversNextforHSBC-Light",sans-serif;
	color:windowtext;}
p.copyright, li.copyright, div.copyright
	{mso-style-name:copyright;
	margin-right:0cm;
	margin-left:0cm;
	line-height:normal;
	font-size:12.0pt;
	font-family:"UniversNextforHSBC-Light",sans-serif;
	color:windowtext;}
p.note, li.note, div.note
	{mso-style-name:note;
	margin-right:0cm;
	margin-left:0cm;
	line-height:normal;
	font-size:12.0pt;
	font-family:"UniversNextforHSBC-Light",sans-serif;
	color:windowtext;}
p.margintop, li.margintop, div.margintop
	{mso-style-name:margintop;
	margin-top:16.5pt;
	margin-right:0cm;
	margin-left:0cm;
	line-height:normal;
	font-size:12.0pt;
	font-family:"UniversNextforHSBC-Light",sans-serif;
	color:windowtext;}
p.label1, li.label1, div.label1
	{mso-style-name:label1;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:16.5pt;
	margin-left:0cm;
	line-height:16.5pt;
	font-size:12.0pt;
	font-family:"UniversNextforHSBC-Regular",sans-serif;
	color:#252525;}
p.icon1, li.icon1, div.icon1
	{mso-style-name:icon1;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:16.5pt;
	margin-left:0cm;
	line-height:16.5pt;
	font-size:12.0pt;
	font-family:"UniversNextforHSBC-Light",sans-serif;
	color:#252525;}
p.copyright1, li.copyright1, div.copyright1
	{mso-style-name:copyright1;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:16.5pt;
	margin-left:0cm;
	text-align:right;
	line-height:9.75pt;
	font-size:9.0pt;
	font-family:"UniversNextforHSBC-Light",sans-serif;
	color:white;}
p.note1, li.note1, div.note1
	{mso-style-name:note1;
	margin-top:13.5pt;
	margin-right:9.0pt;
	margin-bottom:45.0pt;
	margin-left:9.0pt;
	line-height:12.0pt;
	font-size:9.0pt;
	font-family:"UniversNextforHSBC-Light",sans-serif;
	color:white;}
p.label2, li.label2, div.label2
	{mso-style-name:label2;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:16.5pt;
	margin-left:0cm;
	line-height:16.5pt;
	font-size:12.0pt;
	font-family:"UniversNextforHSBC-Regular",sans-serif;
	color:#252525;}
p.icon2, li.icon2, div.icon2
	{mso-style-name:icon2;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:16.5pt;
	margin-left:0cm;
	line-height:16.5pt;
	font-size:12.0pt;
	font-family:"UniversNextforHSBC-Light",sans-serif;
	color:#252525;}
p.copyright2, li.copyright2, div.copyright2
	{mso-style-name:copyright2;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:16.5pt;
	margin-left:0cm;
	text-align:right;
	line-height:9.75pt;
	font-size:9.0pt;
	font-family:"UniversNextforHSBC-Light",sans-serif;
	color:white;}
p.note2, li.note2, div.note2
	{mso-style-name:note2;
	margin-top:13.5pt;
	margin-right:9.0pt;
	margin-bottom:45.0pt;
	margin-left:9.0pt;
	line-height:12.0pt;
	font-size:9.0pt;
	font-family:"UniversNextforHSBC-Light",sans-serif;
	color:white;}
span.bold1
	{mso-style-name:bold1;font-weight: bold;	
	font-family:"UniversNextforHSBC-Regular",sans-serif;
	letter-spacing:.35pt;}
span.HeaderChar
	{mso-style-name:"Header Char";
	mso-style-link:Header;
	font-family:DengXian;}
span.FooterChar
	{mso-style-name:"Footer Char";
	mso-style-link:Footer;
	font-family:DengXian;}
span.BalloonTextChar
	{mso-style-name:"Balloon Text Char";
	mso-style-link:"Balloon Text";
	font-family:"Segoe UI",sans-serif;}
span.CommentTextChar
	{mso-style-name:"Comment Text Char";
	mso-style-link:"Comment Text";
	font-family:DengXian;}
span.CommentSubjectChar
	{mso-style-name:"Comment Subject Char";
	mso-style-link:"Comment Subject";
	font-family:DengXian;
	font-weight:bold;}
.MsoChpDefault
	{font-size:10.0pt;}
 /* Page Definitions */
 @page WordSection1
	{size:612.0pt 792.0pt;
	margin:72.0pt 72.0pt 72.0pt 72.0pt;}
div.WordSection1
	{page:WordSection1;}
-->
</style>
<!--[if mso 9]-->
<style>
p.MsoNormalrp
	{margin-left:65.0pt;word-break: break-word;}
</style>


</head>

<body lang="ZH-CN">

<div class="WordSection1">

<p class="MsoNormalrp" style="margin-left:0cm;text-indent:0cm"><span class="MsoSubtleEmphasis"><span lang="EN">ONLINE PRIVATE BANKING SERVICES TERMS AND
CONDITIONS AND CONSENT TO ELECTRONIC DOCUMENTS</span></span></p>

<p class="MsoNormalrp" style="margin-left:0cm;text-indent:0cm; font-weight: bold;"><em><span lang="EN">PLEASE
READ THIS INFORMATION CAREFULLY.</span></em></p>

<p class="MsoNormalrp" style="margin-left:0cm;text-indent:0cm"><span lang="EN">The
following Terms and Conditions and Consent To Electronic Documents, as amended
from time to time, together with any applicable account agreements
(collectively "Online Terms and Conditions"), govern the use of
Online Banking Services ("Online Services") for HSBC Private Banking,
a division of HSBC Bank USA, N.A. and HSBC Securities (USA) Inc., as
applicable.</span></p>

<p class="MsoNormalrp" style="margin-left:0cm;text-indent:0cm"><span lang="EN">&nbsp;</span></p>

<p class="MsoNormalrp" style="margin-left:0cm;text-indent:0cm"><span lang="EN">The
words "you"; "your" and "yours"; and
"account holder" refer to each and every person or organization who
holds the account. The words "we"; "us"; "our"
and "ours"; and "Private Banking" and "Private Banking's"
refer to HSBC Bank USA, N.A., HSBC Securities (USA) Inc., and any other
corporation or entity owned or controlled directly or indirectly by, or under
common control with HSBC Holdings plc, including without limitation any branch,
successor or assignor of any of the foregoing.</span></p>

<p class="MsoNormalrp" style="margin-left:21.6pt"><b><span lang="EN" style="font-family:&quot;Univers Next for HSBC Light&quot;,sans-serif">1.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span lang="EN">Online Services:</span></b><span lang="EN"> Online Services includes the
following:</span></p>

<p class="MsoNormalrp" style="margin-left:43.2pt"><span lang="EN" style="font-family:
&quot;Univers Next for HSBC Light&quot;,sans-serif">a.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang="EN">Balance Information:</span></b><span lang="EN">
Obtain account balances and Private Banking information as of the close of
business on the preceding Business Day. "Business Day" refers to a
day which is a business day at the relevant Private Banking office.</span></p>

<p class="MsoNormalrp" style="margin-left:43.2pt"><span lang="EN" style="font-family:
&quot;Univers Next for HSBC Light&quot;,sans-serif">b.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;
</span></span><b><span lang="EN">Transaction Information:</span></b><span lang="EN"> Review transaction information for the last eighteen (18) months.
Transaction information is available on the Business Day after execution. Download
and/or print transaction information by following the online instructions
accessible from the transaction screen.</span></p>

<p class="MsoNormalrp" style="margin-left:21.6pt"><b><span lang="EN" style="font-family:&quot;Univers Next for HSBC Light&quot;,sans-serif">2.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span lang="EN">Eligible Accounts:</span></b><span lang="EN"> Online Services are not
available for all accounts and availability may be changed from time to time at
our discretion. For further information on which accounts are eligible for
Online Services, please contact your Relationship Manager. All eligible
Accounts that are selected for Online Services will be linked and will appear
together without regard to ownership of those Accounts. For example, if an
Authorized Representative or Authorized User (as defined below) accesses Online
Services, that Person will be able to view and access at a single time the
Accounts of any Organization for which that Person is an Authorized Representative
or Authorized User; and any individual or other Account for which the Person is
a joint holder or an authorized signer under a power of attorney or other
authority.</span></p>

<p class="MsoNormalrp" style="margin-left:21.6pt"><b><span lang="EN" style="font-family:&quot;Univers Next for HSBC Light&quot;,sans-serif">3.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span lang="EN">Authorized Representatives:</span></b><span lang="EN"> An account
holder's designated Authorized Representative is authorized from time to time
to agree to the terms and conditions in this Online Terms and Conditions, as
amended from time to time. An Authorized Representative can access each
eligible account and request linkage of such accounts in any manner and
otherwise act and receive information and Services as available through Online
Services from time to time, whether now or at some time in the future, and we
may, but are not required to, act on such instructions. Any Authorized
Representative certifies individually that s/he is authorized and empowered to
so act.</span></p>

<p class="MsoNormalrp" style="margin-left:21.6pt"><b><span lang="EN" style="font-family:&quot;Univers Next for HSBC Light&quot;,sans-serif">4.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span lang="EN">Authorized User:</span></b><span lang="EN"> You may request that online
access, including access to information about online statement availability, be
granted to one or more “Authorized Users”. If permitted to “View Details,” all
Authorized Users will be able to view account transaction details, including
payee name and account number, which would otherwise be hidden.</span></p>

<p class="MsoNormalrp" style="margin-left:21.6pt"><b><span lang="EN" style="font-family:&quot;Univers Next for HSBC Light&quot;,sans-serif">5.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span lang="EN">You can change your Password at any time:</span></b><span lang="EN"> For
your protection, you should memorize your password, username and Login
Credentials; do not keep any notation of them on or with your computer. You
agree not to give or make available your Login Credentials to anyone you do not
authorize to use Online Services. Protect your Login Credentials to avoid any
unauthorized access to your Account(s). If any of your Accounts is a joint
account or has additional Authorized Representatives, each Account Holder may
register for separate Login Credentials.</span></p>

<p class="MsoNormalrp" style="margin-left:21.6pt;text-indent:0cm"><span lang="EN">If
you choose to activate the feature that allows you to use your biometric data
(for example, fingerprint or facial print) to log onto Online Services (for
supported devices only), you must not allow anyone else's biometric data to
access your mobile device. If you do allow anyone else's biometric data to be
stored in your mobile device, those persons will have access to your Online
Services and you will be responsible for any money withdrawn or transferred
from your accounts when such person uses their stored biometric data.</span></p>

<p class="MsoNormalrp" style="margin-left:21.6pt"><b><span lang="EN" style="font-family:&quot;Univers Next for HSBC Light&quot;,sans-serif">6.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span lang="EN">Removal of Account Access:</span></b><span lang="EN"> You may request
removal of online access to any Account by notifying us in writing.</span></p>

<p class="MsoNormalrp" style="margin-left:21.6pt"><b><span lang="EN" style="font-family:&quot;Univers Next for HSBC Light&quot;,sans-serif">7.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span lang="EN">Security:</span></b><span lang="EN"> There are risks associated with the
use of Services through the Internet, including but not limited to unauthorized
access, system outages, delays and disruption in telecommunications services
and the Internet. Additional information security risks may be posed by
services used to access the Internet, including but not limited to, public
cellular telephone carriers, and public and private wireless (Wi-Fi) systems.
Secure E-Mail and the other Services available under Online Services are
designed to provide maximum security for your information. They require a
username and password; they use high levels of security through 128-Bit
Encryption. However, the confidentiality of Secure E-Mail or other Internet
transmission is not absolute and cannot be guaranteed.</span></p>

<p class="MsoNormalrp" style="margin-left:21.6pt"><b><span lang="EN" style="font-family:&quot;Univers Next for HSBC Light&quot;,sans-serif">8.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span lang="EN">Hardware and Software Requirements:</span></b><span lang="EN"> You agree
that in order to use Online Services you have or will have access at all times,
at your cost, to a personal computer or mobile smartphone device (Android OS 7
or higher and Apple iOS 12 or higher) installed, as appropriate, capable of
supporting high-level browser encryption, Internet access, and a valid e-mail
address. The Account Holder(s) agree that in order to use Online Services they
will maintain the specified or later version of one or more of the following
browsers: Microsoft Internet Explorer 11; Google Chrome 67; Mozilla Firefox 60;
Safari 10; Microsoft Edge; and that they have access to a printer, or the
ability to download information, in order to keep copies for records. The
Account Holder(s) must also have access to Adobe Reader 9.0 (or a later
version) (http://get.adobe.com/reader) to utilize PDF files.</span></p>

<p class="MsoNormalrp" style="margin-left:21.6pt"><b><span lang="EN" style="font-family:&quot;Univers Next for HSBC Light&quot;,sans-serif">9.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span lang="EN">Your Responsibilities:</span></b><span lang="EN"> You confirm that you
have reviewed the security features for Online Services and determined that
these features, together with your own security measures, are adequate for your
Account(s). You agree that you are responsible for the performance and
protection of any browser used in connection with Online Services, including
the prompt adoption of all security patches and other security measures issued
or recommended from time to time by the suppliers of such browsers. All Account
Holders assume full responsibility individually and together for any and all
use, unauthorized use or misuse of Online Services by any Account Holder,
Authorized User or other person through their facilities or otherwise, and
agree that they will be bound by any action, transaction, message or
instruction effected through the Security Details, whether or not the action or
transaction, or the person carrying out the action or transaction, was properly
authorized or not. You agree that you will be bound by any affirmance, assent
or agreement transmitted to&nbsp; &nbsp;Private Banking. You agree that by
clicking on an "I agree", "I consent" or other similarly
worded "button" or entry field with a mouse, keystroke or other
computer device, the agreement or consent will be legally binding and
enforceable and the legal equivalent of a handwritten signature by or for the
Account Holder(s), Authorized Signer(s) and/or other representative(s). It is
your sole responsibility to advise Private Banking immediately of any changes
or updates to the designated e-mail address and/or Authorized Users. To update
your e-mail address, for questions about receiving disclosures, or for
technical or other assistance, contact your Relationship Manager/Administrator.</span></p>

<p class="MsoNormalrp" style="margin-left:21.6pt"><b><span lang="EN" style="font-family:&quot;Univers Next for HSBC Light&quot;,sans-serif">10.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span></b><b><span lang="EN">System
Responsiveness:</span></b><span lang="EN"> You understand that Online Services
system response times may vary due to market conditions, system performance,
access device location or other factors, and that access to Online Services may
be limited or unavailable during periods of peak demand, market volatility,
systems upgrades or maintenance, or for any other reason whatsoever. You
acknowledge that interruption or malfunction of communications, failure of
software or equipment, or malfunction of your or our computer system for any
reason may result in our not receiving instructions or you not receiving our
electronic acknowledgement, if applicable, and that&nbsp; &nbsp;Private Banking
shall have no liability in respect of any such interruption or malfunction. You
are responsible for contacting us outside Online Services if you have not
received our electronic acknowledgement, if applicable, for any transactions or
other action completed or attempted online.</span></p>

<p class="MsoNormalrp" style="margin-left:21.6pt"><b><span lang="EN" style="font-family:&quot;Univers Next for HSBC Light&quot;,sans-serif">11.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span></b><b><span lang="EN">Reserved.</span></b></p>

<p class="MsoNormalrp" style="margin-left:21.6pt"><b><span lang="EN" style="font-family:&quot;Univers Next for HSBC Light&quot;,sans-serif">12.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span></b><b><span lang="EN">Not
Official Documents:</span></b><span lang="EN"> Account information, including
pricing information, received through Online Services that is not delivered
pursuant to the E-Alert Service with paper statement suppression, is provided
for your convenience only. It may not be complete, updated, current or accurate.
You agree that your periodic statement provided on paper, or, if applicable,
under the terms of the E-Alert Service, will constitute the official record or
statement of the relevant Account(s).</span></p>

<p class="MsoNormalrp" style="margin-left:21.6pt"><b><span lang="EN" style="font-family:&quot;Univers Next for HSBC Light&quot;,sans-serif">13.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span></b><b><span lang="EN">Equipment
Failure:</span></b><span lang="EN"> Private Banking, its directors, officers,
employees, service providers or agents shall not be liable for any Claims
resulting in whole or in part from any act or failure to act caused by the
equipment or software belonging to you, them or any third party; any Internet
browser provider; any wireless phone provider; any Internet access provider;
any online service provider; or any Affiliate, agent or contractor of any of
the above.</span></p>

<p class="MsoNormalrp" style="margin-left:21.6pt"><b><span lang="EN" style="font-family:&quot;Univers Next for HSBC Light&quot;,sans-serif">14.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span></b><b><span lang="EN">Recording:</span></b><span lang="EN"> You 
authorize us to record and copy all forms of electronic communication including but not limited to e-mail communications.</span></p>

<p class="MsoNormalrp" style="margin-left:21.6pt"><b><span lang="EN" style="font-family:&quot;Univers Next for HSBC Light&quot;,sans-serif">15.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span></b><b><span lang="EN">Online
Access and Privacy:</span></b><span lang="EN"> You understand and agree that the
use of Online Services by you, any Account Holder, any Authorized
Representative or Authorized User, to access, download and/or print Account
information outside of the United States, may subject the information to the
laws of those non-U.S. jurisdictions, and that such laws may differ from U.S.
laws with respect to privacy and required disclosure of records and
information.</span></p>

<p class="MsoNormalrp" style="margin-left:21.6pt"><b><span lang="EN" style="font-family:&quot;Univers Next for HSBC Light&quot;,sans-serif">16.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span></b><b><span lang="EN">Sharing
Information with Affiliates and Service Providers:</span></b><span lang="EN"> You
understand and agree that the Online Services (including server locations and
website hosting), may be provided by an Affiliate of Private Banking or other
service provider, which may be located in the United States or in a non-U.S.
jurisdiction. You specifically consent to the sharing of information of any
kind about you with any Affiliate or service provider in connection with the
provision of Online Services.</span></p>

<p class="MsoNormalrp" style="margin-left:21.6pt"><b><span lang="EN" style="font-family:&quot;Univers Next for HSBC Light&quot;,sans-serif">17.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span></b><b><span lang="EN">Fees:</span></b><span lang="EN"> &nbsp;Private Banking 
does not charge fees for accessing our Online
Services. However, you may be charged a monthly inactivity fee as set forth on
the related Fee Schedule for not logging into Online Services at least once
within a twelve (12) month period. If electronic transaction fees later apply,
you will receive notice in advance of such changes to this Online Terms and
Conditions. You understand that accessing electronic statements and other
features through Online Services and receiving e-mail notices may result in
costs associated with e-mail, electronic access, downloading, online time and
subscription costs associated with Internet and telecommunications service
providers, paper and printing costs, and equipment or software upgrades if
necessary. You agree that you will pay all such costs and expenses in
connection with your use of the electronic statement Service or other Service
under Online Services.</span></p>

<p class="MsoNormalrp" style="margin-left:21.6pt"><b><span lang="EN" style="font-family:&quot;Univers Next for HSBC Light&quot;,sans-serif">18.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span></b><b><span lang="EN">Additional
Terms:</span></b><span lang="EN"> Use of Online Services may also be subject to
the terms or instructions appearing on the screen when using the service.</span></p>

<p class="MsoNormalrp" style="margin-left:21.6pt"><b><span lang="EN" style="font-family:&quot;Univers Next for HSBC Light&quot;,sans-serif">19.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span></b><b><span lang="EN">Indemnification:</span></b><span lang="EN"> Except 
as specifically provided otherwise in these Online Private Banking
Terms and Conditions (or where applicable law requires a different result which
is not modifiable by contract), none of the Indemnified Parties will be liable
for any Claims resulting in whole or in part from any act or failure to act of
their, your, or any third party's equipment or software, of any Internet
browser provider, of any Internet access provider, of any online service
provider, or of any Affiliate, agent or contractor of any of them.</span></p>

<p class="MsoNormalrp" style="margin-left:21.6pt"><b><span lang="EN" style="font-family:&quot;Univers Next for HSBC Light&quot;,sans-serif">20.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span></b><b><span lang="EN">Termination:</span></b><span lang="EN"> We 
may cancel your Online Services access without prior notice if
there has been no Online Services activity for a period of three (3)
consecutive months or for any other reason. We may also cancel all or part of
Online Services or any Service thereunder for any reason.</span></p>

<p class="MsoNormalrp" style="margin-left:21.6pt"><b><span lang="EN" style="font-family:&quot;Univers Next for HSBC Light&quot;,sans-serif">21.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span></b><b><span lang="EN">Electronic
Documents:</span></b></p>

<p class="MsoNormalrp" style="margin-left:43.2pt"><span lang="EN" style="font-family:
&quot;Univers Next for HSBC Light&quot;,sans-serif">a.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang="EN">Consent:</span></b><span lang="EN"> By switching
to paperless, you consent to receive electronic documents for all of those
accounts, products, and services that are provided to you through HSBC Private
Banking and their affiliates and that are accessible, either currently or in
the future, through Online Services (whether accessed through a personal
computer or mobile device, sometimes referred to as "Mobile Banking"
or other similar name), our websites, or other electronic means, including
e-mail. This includes, but is not limited to, the following account, product,
and service types: deposit accounts, credit and lending, credit cards, investment
accounts, custody, insurance, and other services.</span></p>

<p class="MsoNormalrp" style="margin-left:43.2pt"><span lang="EN" style="font-family:
&quot;Univers Next for HSBC Light&quot;,sans-serif">b.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang="EN">Your Legal Rights:</span></b><span lang="EN">
Certain laws and regulations require us to provide specific information to you
in writing, which means you have a right to receive that information on paper.
We may provide such information to you electronically if we first obtain your
consent to receive the information electronically. Your consent will also apply
to any other Authorized User that you have designated to us in your account
documentation, subject to applicable law. At times, we may still send you paper
documents, and electronic documents may not be available for certain accounts.
In clause (d) below, we explain ways to obtain selected disclosures or other
information on paper even after you have consented to electronic documents.</span></p>

<p class="MsoNormalrp" style="margin-left:43.2pt"><span lang="EN" style="font-family:
&quot;Univers Next for HSBC Light&quot;,sans-serif">c.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang="EN">Types of Electronic Documents You Will Receive:</span></b><span lang="EN"> You understand and agree that we may provide to you in electronic
format only, by posting the information on the website where you access your
accounts, products or services; through e-mail (if applicable and if you have
provided a valid e-mail address); or through other electronic means,
agreements, disclosures, notices, and other information and communications
regarding your accounts, services and products, the use of our websites or our
other electronic services, your relationship with us, and/or other programs,
products or services that are or may be in the future made available to you
(collectively, "Documents"). Such Documents may include, but are not
limited to:</span></p>

<p class="MsoNormalrp"><span lang="EN" style="font-family:&quot;Univers Next for HSBC Light&quot;,sans-serif">i.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN">These Online Terms and Conditions including any updates or amendments,
and any other service or user agreements for access to our websites or other
electronic services; all updates to these agreements, and all disclosures,
notices and other communications regarding transactions you make through our
websites or our other electronic services;</span></p>

<p class="MsoNormalrp"><span lang="EN" style="font-family:&quot;Univers Next for HSBC Light&quot;,sans-serif">ii.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN">The account opening application, HSBC Private Banking Terms and
Conditions for Accounts and applicable Supplements and Rules for Consumer
Deposit Accounts, in each case, as may be amended from time to time;</span></p>

<p class="MsoNormalrp"><span lang="EN" style="font-family:&quot;Univers Next for HSBC Light&quot;,sans-serif">iii.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp; </span></span><span lang="EN">Periodic, annual, monthly or other statements, disclosures and notices
relating to the maintenance or operation of an account, product or service
including, but not limited to account information, account activity, account
inactivity, payments made or due, or other statements, disclosures or notices
that may be required by applicable federal or state laws and regulations;</span></p>

<p class="MsoNormalrp"><span lang="EN" style="font-family:&quot;Univers Next for HSBC Light&quot;,sans-serif">iv.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp; </span></span><span lang="EN">Investment account disclosures, agreements, statements, trade
confirmations, tax reporting statements, shareholder notices, prospectuses,
service notices and performance reports regarding accounts, products and
services;</span></p>

<p class="MsoNormalrp"><span lang="EN" style="font-family:&quot;Univers Next for HSBC Light&quot;,sans-serif">v.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN">Any notice or disclosure regarding an account, product or service fee,
such as a late fee, an overdraft fee, an over limit fee, a fee for a draft,
check or electronic debit returned for any reason, such as insufficient funds
fee or a fee as a result of a stop payment order;</span></p>

<p class="MsoNormalrp"><span lang="EN" style="font-family:&quot;Univers Next for HSBC Light&quot;,sans-serif">vi.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp; </span></span><span lang="EN">Any notice of the addition of new terms and conditions or the deletion
or amendment of existing terms and conditions applicable to accounts, products
or services you obtain from us;</span></p>

<p class="MsoNormalrp"><span lang="EN" style="font-family:&quot;Univers Next for HSBC Light&quot;,sans-serif">vii.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp; </span></span><span lang="EN">Our
Privacy Notice and other privacy statements (by posting such notices on our
website at https://www.us.hsbc.com/online-privacy-statement/; and</span></p>

<p class="MsoNormalrp"><span lang="EN" style="font-family:&quot;Univers Next for HSBC Light&quot;,sans-serif">viii.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span><span lang="EN">Certain
tax statements or notices that we are legally required to provide to you, such
as the annual Internal Revenue Service (IRS) interest statements.</span></p>

<p class="MsoNormalrp" style="margin-left:43.2pt"><span lang="EN" style="font-family:
&quot;Univers Next for HSBC Light&quot;,sans-serif">d.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang="EN">Setting Your Electronic Documents Preferences:</span></b><span lang="EN"> When you provide your Consent to Electronic Documents, you will still
be able to set your preferences to receive certain categories of Documents in
either electronic and paper format or electronic format only. You may not be
able to set your Documents preferences for all products, accounts or services.
If you decide to receive some Documents in paper and some electronically, the
Documents that you receive electronically will be governed by this Consent to
Electronic Documents.</span></p>

<p class="MsoNormalrp" style="margin-left:43.2pt"><span lang="EN" style="font-family:
&quot;Univers Next for HSBC Light&quot;,sans-serif">e.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang="EN">Types of Documents You Will Receive in Paper:</span></b><span lang="EN"> This Consent does not apply to any Documents that we determine, in our
sole discretion, that we are required to deliver in paper form under applicable
law or that you should receive in paper rather than electronic form.</span></p>

<p class="MsoNormalrp" style="margin-left:43.2pt"><span lang="EN" style="font-family:
&quot;Univers Next for HSBC Light&quot;,sans-serif">f.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang="EN">Hardware and Software Requirements:</span></b><span lang="EN"> You agree that in order to use Online Services you have or will have
access at all times, at your cost, to a personal computer or mobile smartphone
device, as appropriate, capable of supporting high-level browser encryption,
Internet access, and a valid e-mail address. The Account Holder(s) agree that
in order to use Online Services they will maintain the specified or later
version of one or more of the following browsers: Internet Explorer 10.0 (or a
later version) for PC; Firefox 60 (or a later version) for PC; Safari 10 (or a
later version) for Mac; Firefox 60 (or a later version) for Mac; and that they
have access to a printer, or the ability to download information, in order to
keep copies for records. The Account Holder(s) must also have access to Adobe
Reader 9.0 (or a later version) (http://get.adobe.com/reader) to utilize PDF
files.</span></p>

<p class="MsoNormalrp" style="margin-left:43.2pt"><span lang="EN" style="font-family:
&quot;Univers Next for HSBC Light&quot;,sans-serif">g.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN">In certain circumstances, some Documents may be
provided by e-mail. You are responsible for providing us with a valid e-mail
address to accept delivery of Documents. At our option, we may also post the
emailed Documents within our websites.</span></p>

<p class="MsoNormalrp" style="margin-left:43.2pt"><span lang="EN" style="font-family:
&quot;Univers Next for HSBC Light&quot;,sans-serif">h.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN">To print or download Documents you must have a
printer connected to your device or sufficient hard-drive or other storage
space to store the Documents.</span></p>

<p class="MsoNormalrp" style="margin-left:43.2pt"><span lang="EN" style="font-family:
&quot;Univers Next for HSBC Light&quot;,sans-serif">i.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang="EN">How to Withdraw Your Consent to Electronic
Documents:</span></b><span lang="EN"> Subject to applicable law, you may withdraw
your Consent to Electronic Documents by contacting our Relationship Manager. If
you no longer wish to have access to Online Services, please contact your
Relationship Manager. You will not be charged a fee for withdrawal of your
consent. It may take up to 30 days for your withdrawal of consent to become
effective. Your consent shall remain in force until withdrawn in the manner
provided in this section. Remember that you can always set your Documents
preferences as described in Section 3 above without withdrawing your Consent to
Electronic Documents. Your withdrawal of consent will also apply to any other
Authorized User identified in the Online Services Application, subject to
applicable law.</span></p>

<p class="MsoNormalrp" style="margin-left:43.2pt"><span lang="EN" style="font-family:
&quot;Univers Next for HSBC Light&quot;,sans-serif">j.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang="EN">Consent Coverage; Notices That You Are Required
to Provide.</span></b><span lang="EN"> Applicable law or contracts sometimes
require you to give us "written" notices. You must still provide
these notices to us on paper. Your Consent to Electronic Documents does not impact
notices that are required to be provided by you, unless otherwise stated.</span></p>

<p class="MsoNormalrp" style="margin-left:43.2pt"><span lang="EN" style="font-family:
&quot;Univers Next for HSBC Light&quot;,sans-serif">k.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang="EN">Obtaining Copies of Electronic Documents:</span></b><span lang="EN"> You may print or make a copy of Documents by using the
"Print" menu feature from Acrobat Adobe application (or otherwise
using your printing functionality) or saving a copy. You may request a paper
copy of any electronic document that is available on Online Services provided
we receive your request within 12 months after the date the Communication was
first made available to you electronically. Our standard fee currently in
effect for delivery of paper copies of account documents will apply. For paper
document requests, please contact your Relationship Manager.</span></p>

<p class="MsoNormalrp" style="margin-left:43.2pt"><span lang="EN" style="font-family:
&quot;Univers Next for HSBC Light&quot;,sans-serif">l.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang="EN">Updating Your Contact Information:</span></b><span lang="EN"> It is your sole responsibility to advise us immediately of any changes
or updates to the designated e-mail address. In the event that your e-mail
address or other contact information is changed, you must notify us of such
changes immediately by contacting your Relationship Manager to update your e-mail
address or other contact information. If you fail to update or change an
incorrect or invalid e-mail address or other contact information, you
understand and agree that any Documents shall nevertheless be deemed to have
been provided to you if they were made available to you in electronic form on
our websites, e-mailed to the e-mail address we have for you in our records, or
delivered through other electronic means.</span></p>

<p class="MsoNormalrp" style="margin-left:43.2pt"><span lang="EN" style="font-family:
&quot;Univers Next for HSBC Light&quot;,sans-serif">m.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;
</span></span><b><span lang="EN">Retain Copies for Your Records:</span></b><span lang="EN"> We recommend that you print or download a copy of these Online Terms
and Conditions, the applicable service agreement and all other Documents to
retain for your permanent records. It is your sole responsibility to maintain
copies of Documents we send to you; we are not responsible for collecting
Documents in one place for you to access at a later date.</span></p>

<p class="MsoNormalrp" style="margin-left:43.2pt"><span lang="EN" style="font-family:
&quot;Univers Next for HSBC Light&quot;,sans-serif">n.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN">If we determine that a change to the Electronic
Document Service concerning any future system or hardware enhancement creates a
material risk that you may not be able to access or retain a subsequent
electronic record, we will notify you of such change and allow you to withdraw
consent for this electronic document service, whereupon we may terminate this
service until you choose to enroll again and deliver a new consent to
participate in this service by accepting our online consent form or otherwise
consenting.</span></p>

<p class="MsoNormalrp" style="margin-left:21.6pt"><b><span lang="EN" style="font-family:&quot;Univers Next for HSBC Light&quot;,sans-serif">22.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span></b><b><span lang="EN">Electronic
Documents:</span></b><span lang="EN"> When you sign up for Online Services, your
paper documents will be made available to you electronically at https://www.privatebanking.us.hsbc.com/login/#/logon.
You will continue to receive your paper documents under existing arrangements,
unless you elect to suppress paper documents if that service is available for
your account(s). You will not receive an email alert when your electronic
documents are ready to be viewed, except for certain mandatory documents as
described below in Section 24. Any correspondence that may be mailed to you
separately or that may be included in your statement package will not be
available electronically through Online Services. We reserve the right to continue
to send correspondence to you by email, mail or personal delivery, consistent
with these or other terms and conditions governing your accounts. It is your
sole responsibility to advise Private Banking immediately of any changes or
updates to the designated email address/or authorized person(s).</span></p>

<p class="MsoNormalrp" style="margin-left:21.6pt"><b><span lang="EN" style="font-family:&quot;Univers Next for HSBC Light&quot;,sans-serif">23.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span></b><b><span lang="EN">Email
Alert Service - Electronics Documents with Paper Document Suppression:</span></b><span lang="EN"> If this service is available for your account(s), you may elect to
turn off Paper Document Delivery once you are logged onto Online Services by
clicking on the paperless setting tab and turning off paperless. Your request
for Paper Suppression will not apply to certain mandatory documents as
described below in section 24. If you elect to turn off Paper Document
Delivery, you are requesting the Email Alert Service for electronic documents
for your designated accounts and you agree as follows:</span></p>

<p class="MsoNormalrp" style="margin-left:43.2pt"><span lang="EN" style="font-family:
&quot;Univers Next for HSBC Light&quot;,sans-serif">a.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN">Private Banking is authorized to send an Email
Alert notice to the email address that you have provided when the electronic
version of your document is available to view through Online Services. It is
your sole responsibility to inform us of any changes to the designated email
address. You will have access to the electronic documents for up to 18 months.
This service provides you with the flexibility to print and save documents that
are made available online. If you choose this Email Alert with Paper Document
Suppression service, the electronic document will be your official document.</span></p>

<p class="MsoNormalrp" style="margin-left:43.2pt"><span lang="EN" style="font-family:
&quot;Univers Next for HSBC Light&quot;,sans-serif">b.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN">The selected paper documents for the designated
accounts will no longer be mailed to you within a reasonable amount of time
after you enroll for the Email Alert Service, regardless of the frequency of
such documents. Until the Email Alert Service is activated, you may receive
both a paper and an electronic document.</span></p>

<p class="MsoNormalrp" style="margin-left:43.2pt"><span lang="EN" style="font-family:
&quot;Univers Next for HSBC Light&quot;,sans-serif">c.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN">The Email Alert will be the only notice sent to you
advising that your electronic document has been posted to Online Services. No
other notice of any kind will be given. If you don't receive an Email Alert
within 30 days of signing up for this service, please contact your Relationship
Manager to confirm your e-mail address on file is current. Any and all Email
Alerts sent by Private Banking to the designated email addresses will
constitute sufficient and effective delivery of the information contained in
your electronic document. <b>Even if the computer, the Email Alert, the Online
Services website, or the particular document is never accessed or reviewed, the
document shall be deemed to have been delivered personally, whether actually
viewed or not.</b> You agree to maintain access to Online Services in a manner
that gives you continuous ability to access, review and print your electronic
documents and to receive and access all notices sent to you at the designated
email address.</span></p>

<p class="MsoNormalrp" style="margin-left:43.2pt"><span lang="EN" style="font-family:
&quot;Univers Next for HSBC Light&quot;,sans-serif">d.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN">Email is not private or secure. The Email Alert
sent to you is an unencrypted, automatic notice. Although the notice is not
intended to contain personally identifiable confidential financial information,
it may contain in its design part or all of your name or other identifier,
which could be seen or intercepted by others if delivered to an address,
computer or electronic device not exclusively under your control.</span></p>

<p class="MsoNormalrp" style="margin-left:43.2pt"><span lang="EN" style="font-family:
&quot;Univers Next for HSBC Light&quot;,sans-serif">e.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN">You confirm that:</span></p>

<p class="MsoNormalrp"><span lang="EN" style="font-family:&quot;Univers Next for HSBC Light&quot;,sans-serif">i.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN">you have the available software and equipment required to view and
print your electronic documents;</span></p>

<p class="MsoNormalrp"><span lang="EN" style="font-family:&quot;Univers Next for HSBC Light&quot;,sans-serif">ii.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN">you have provided a valid email address to receive notifications that
your electronic document is available;</span></p>

<p class="MsoNormalrp"><span lang="EN" style="font-family:&quot;Univers Next for HSBC Light&quot;,sans-serif">iii.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp; </span></span><span lang="EN">you agree to receive electronically the documents which are made
available for various types of accounts from time to time, and you agree to be
bound by the terms of this agreement; and</span></p>

<p class="MsoNormalrp"><span lang="EN" style="font-family:&quot;Univers Next for HSBC Light&quot;,sans-serif">iv.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp; </span></span><span lang="EN">you confirm that you are able to access and retain the electronic
disclosures and terms and conditions, as amended from time to time, that are
made available through Online Services or provided to you electronically
through Secure Message.</span></p>

<p class="MsoNormalrp" style="margin-left:43.2pt"><span lang="EN" style="font-family:
&quot;Univers Next for HSBC Light&quot;,sans-serif">f.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN">You should print a paper copy of all account
disclosures, statements, account terms and all other electronic documents that
are important to you and retain a copy for your records.</span></p>

<p class="MsoNormalrp" style="margin-left:43.2pt"><span lang="EN" style="font-family:
&quot;Univers Next for HSBC Light&quot;,sans-serif">g.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN">You may request a paper copy of any electronic
document that is available on Online Services and our standard fee then in
effect for delivery of paper copies of account documents will apply. For paper
document requests, please contact your Relationship Manager. A request for a
paper copy does not cause a termination of the Email Alert Service. A paper
copy of electronic documents can be obtained until the electronic document is
no longer required to be made available or maintained under applicable law or
regulation.</span></p>

<p class="MsoNormalrp" style="margin-left:43.2pt"><span lang="EN" style="font-family:
&quot;Univers Next for HSBC Light&quot;,sans-serif">h.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN">If the method of electronic delivery of documents
requires additional software, upgrades, plug-ins or additional security
features in hardware or software system used by you, you may elect to terminate
the Email Alert Service. If you do not terminate the Email Alert Service, you
hereby agree to upgrade your hardware or software system to the required
standards. You acknowledge and agree that failure to obtain any necessary
system upgrades, as described above, may result in interruption of the Email
Alert Service and/or access to Online Services. Any potential losses associated
with such interruption are solely for your own account and risk.</span></p>

<p class="MsoNormalrp" style="margin-left:43.2pt"><span lang="EN" style="font-family:
&quot;Univers Next for HSBC Light&quot;,sans-serif">i.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN">You may revoke consent to the Email Alert Service,
by providing a written revocation of consent that will become effective upon Private
Banking’s receipt and reasonable opportunity to act thereon. Paper copies of
relevant documents will be sent to your permanent address of record within a
reasonable time thereafter. Your revocation does not affect the validity or
legal effect of any prior electronic document deemed delivered as set out
above.</span></p>

<p class="MsoNormalrp" style="margin-left:43.2pt"><span lang="EN" style="font-family:
&quot;Univers Next for HSBC Light&quot;,sans-serif">j.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN">DO NOT respond to the Email Alert notices by return
email, or use the reply function to request information, service, paper copies
or other items or to revoke consent. We will not act on requests made in that
manner.</span></p>

<p class="MsoNormalrp" style="margin-left:21.6pt"><b><span lang="EN" style="font-family:&quot;Univers Next for HSBC Light&quot;,sans-serif">24.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span></b><span lang="EN">Notwithstanding
any provision to the contrary, Private Banking reserves the right, in its sole
discretion, to send you paper copies and/or electronic copies of any documents
that Private Banking deems to be mandatory. For these mandatory documents, you
will automatically receive an Email Alert. You will not be able to turn off the
Email Alert Service for mandatory documents.</span></p>

<p class="MsoNormalrp" style="margin-left:21.6pt"><b><span lang="EN" style="font-family:&quot;Univers Next for HSBC Light&quot;,sans-serif">25.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span></b><b><span lang="EN">Insights
and Research Terms and Conditions.</span></b><span lang="EN"> The Insights and
Research (“I&amp;R”) terms and conditions in this Section 25 of the Online
Terms and Conditions herein (“I&amp;R Terms and Conditions”) govern the use of
and constitute your agreement with respect to the Publications, as defined
below. Capitalized terms not otherwise defined in this Section 25 shall have
the meaning set forth in the Online Terms and Conditions.</span></p>

<p class="MsoNormalrp" style="margin-left:43.2pt"><span lang="EN" style="font-family:
&quot;Univers Next for HSBC Light&quot;,sans-serif">a.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang="EN">Access to Publications</span></b></p>

<p class="MsoNormalrp"><span lang="EN" style="font-family:&quot;Univers Next for HSBC Light&quot;,sans-serif">i.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN">I&amp;R is a digital platform within Online Services that enables you
to access select (i) Global Private Bank macro commentary on financial markets
(“GPB Macro Reports”); (ii) HSBC Global Research reports (“HSBC Research”) by HSBC
Global Research (“HSBC GB&amp;M”), a division of HSBC Securities USA, Inc. (“HSI”)
and/or (iii) third party publications (“3rd Party Publications”) (collectively
referred to herein as “Publications”). The Publications may discuss financial
markets and investment products across several asset classes and may include
financial research material covering specific financial instruments and
issuers. </span></p>

<p class="MsoNormalrp"><span lang="EN" style="font-family:&quot;Univers Next for HSBC Light&quot;,sans-serif">ii.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN">You and your employees or other authorized persons will become the user
of the services provided hereunder and each of you will be a “User” or “Users”.
Each User, including you, agrees to be bound by the I&amp;R Terms and
Conditions, the Online Terms and Conditions, the account opening agreements,
the HSI terms and conditions, and such other terms and conditions, disclosures
and disclaimers that may be applicable to specific research accessed, including
any amendments to the respective terms and conditions.</span></p>

<p class="MsoNormalrp"><span lang="EN" style="font-family:&quot;Univers Next for HSBC Light&quot;,sans-serif">iii.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp; </span></span><span lang="EN">This service enables your access to Publications through I&amp;R for
your own information only. You agree that you will not distribute the
Publications to any third party or related party.</span></p>

<p class="MsoNormalrp"><span lang="EN" style="font-family:&quot;Univers Next for HSBC Light&quot;,sans-serif">iv.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp; </span></span><span lang="EN">Each User’s access to the Publications is currently free of any fees or
other charges, although we reserve the right to charge a fee in the future. You
would be notified prior to any such change. Your internet service provider and
your telephone company may charge fees.</span></p>

<p class="MsoNormalrp"><span lang="EN" style="font-family:&quot;Univers Next for HSBC Light&quot;,sans-serif">v.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN">While Private Banking will strive to provide the Users with
uninterrupted access to the Publications, it does not guarantee that such
access will be free of any interruption, and disclaims all liability in this
regard.</span></p>

<p class="MsoNormalrp"><span lang="EN" style="font-family:&quot;Univers Next for HSBC Light&quot;,sans-serif">vi.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp; </span></span><span lang="EN">Without prior notice, Private Banking may decide to suspend any User’s
access to the Publications for an indefinite period of time if, in its sole
discretion, it finds that any of the above terms and conditions have been
breached, or for any other reason which, in Private Banking’s opinion, may
require such suspension. In particular, and without limiting the generality of
the foregoing, the Private Banking may suspend any User’s access if such access
could contravene to any laws and regulations applicable to any User’s personal
situation or location. Private Banking may attempt to provide each User with a
notice prior to any suspension of any User’s access, but does not undertake any
obligation whatsoever to do so.</span></p>

<p class="MsoNormalrp" style="margin-left:43.2pt"><span lang="EN" style="font-family:
&quot;Univers Next for HSBC Light&quot;,sans-serif">b.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang="EN">Eligibility:</span></b><span lang="EN"> As a
Private Banking client, you are eligible to receive access to certain
Publications through I&amp;R. However, you acknowledge that you may only have
limited I&amp;R access and in some cases may only have access to (i) only GPB
Macro Reports (“Level 1 Access”); (ii) only GPB Macro Reports and HSBC Global
Research Macro Reports (“Level 2 Access”) or (iii) GPB Macro Reports, HSBC
Global Research Macro Reports and GPB Macro Reports, HSBC Global Research Macro
Reports, HSBC Global Research detailed asset class level reports for equities
and fixed income as well as HSBC Global Research and third party research
single line equity and fixed income reports at the company, security and issuer
level (“Level 3 Access”). You understand and acknowledge that Private Banking
will apply its own commercial criteria based on the overall relationship in
determining which of the Publications any particular Private Banking client may
be granted access to through I&amp;R and this access level may be revised at
any time at the Private Banking’s sole discretion.</span></p>

<p class="MsoNormalrp" style="margin-left:43.2pt"><span lang="EN" style="font-family:
&quot;Univers Next for HSBC Light&quot;,sans-serif">c.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang="EN">Distribution Channels of Publications</span></b></p>

<p class="MsoNormalrp"><span lang="EN" style="font-family:&quot;Univers Next for HSBC Light&quot;,sans-serif">i.<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN">The Private Banking will provide each User with access to the
Publications via I&amp;R or any other digital platform designed to that end.
You as Private Banking’s client, for and on your behalf and for and on behalf
of each User, hereby confirm that you have read, understood, and agreed to be
bound by the I&amp;R Terms and Conditions and the Online Terms and Conditions.</span></p>

` + tc2;
