import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { color } from "util/color";
import { injectIntl, FormattedMessage } from "react-intl";

class Dropdown extends Component {
    constructor(props) {
        super(props);
        this.state = { value: null };
        this.nodeRef = React.createRef();
        this.openListStause = "false";
    }
    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }
    }
    toggleSelect = () => {
        if (this.openListStause === "true") {
            this.props.toggleSelectCallBack(false);
            this.openListStause = "false";
        } else {
            this.props.toggleSelectCallBack(true);
            this.openListStause = "true";
        }

        this.setState(previousState => {
            return {
                ...previousState,
                isShow: !this.state.isShow
            };
        });
    };
    onKeyDown = (event, func) => {
        if (event.keyCode === 32 || event.keyCode === 13) {
            func(event);
            event.preventDefault();
        }
    };

    onOptionSelect = e => {
        this.props.onOptionSelect(e);
    };

    render() {
        let dropdownList = null;
        if (this.props.optionList) {
            let selectedId = null;
            if (this.props.selectedOption) {
                selectedId = this.props.selectedOption.id;
            }
            dropdownList = this.props.optionList.map(entry => {
                return (
                    <li
                        role="option"
                        tabIndex="0"
                        key={entry._id}
                        aria-selected={selectedId === entry._id}
                        value={entry._id}
                        onClick={this.onOptionSelect}
                        data-testid={entry && entry._id}
                    >
                        {entry.translated || entry._text}
                    </li>
                );
            });
        }
        return (
            <DropdownContainer>
                <div className="label--question">
                    <FormattedMessage id={this.props.label} />
                </div>
                <div className="dropdown--container" tabIndex="-1">
                    <div
                        tabIndex="0"
                        role="button"
                        data-testid="dropdown-toggle"
                        aria-expanded={this.openListStause}
                        className="dropdown--selected"
                        onClick={this.toggleSelect}
                        onKeyDown={e => {
                            this.onKeyDown(e, this.toggleSelect);
                        }}
                        ref={this.nodeRef}
                    >
                        <span>
                            {this.props.selectedOption
                                ? this.props.selectedOption.translated ||
                                  this.props.selectedOption._text
                                : this.props.intl.formatMessage({
                                      id: "Dropdown.Select"
                                  })}
                        </span>
                        <i
                            className={
                                this.state.isShow
                                    ? "icon chevron up"
                                    : "icon chevron down"
                            }
                        />
                    </div>
                    <ul
                        className={this.state.isShow ? "show" : ""}
                        onKeyDown={e => {
                            this.onKeyDown(e, this.onOptionSelect);
                        }}
                    >
                        {dropdownList}
                    </ul>
                </div>
            </DropdownContainer>
        );
    }
}

Dropdown.propTypes = {
    content: PropTypes.element,
    buttons: PropTypes.element,
    show: PropTypes.bool,
    onClose: PropTypes.func,
    label: PropTypes.string
};

const DropdownContainer = styled.div`
    .dropdown--container {
        position: relative;
        font-size: 14px;
        
        .dropdown--selected {
            display: flex;
            justify-content: space-between;
            
            border: 1px solid grey;
            margin: 0;
            padding: 12px 15px;
            
            span {
                width: 100%;
            }
            i {
                color: grey;
            }
        }
        .show {
            display: inline-block;
        }
        ul {
            list-style: none;
            position: absolute;
            display: none;
            z-index: 1;
            
            width: 100%;
            border: 1px solid grey;
            border-top: 0px;
            margin: 0;
            padding: 12px 15px;
            
            background-color: ${color.white}
            
            text-align: left;
            
            li {
                &:hover {
                    background-color: #eee;
                    &:active {
                        background-color: #aaa;
                        border-left: 5px solid ${color.hsbc_red};
                    }
                }
            }
        }
    }
`;

export default injectIntl(Dropdown);
