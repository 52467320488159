import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { color } from "util/color";
import { history } from "util/history";
import { FormattedMessage, injectIntl } from "react-intl";
import { envConfig } from "env-config";
import { changeIdleTimeOutValid } from "util/service";
import appSignBg from "assets/image/appsign_bg.png";
import loader from "assets/image/loader.svg";

const mapStateToProps = state => {
    return {};
};
const mapDispatchToProps = dispatch => {
    return {};
};

class CheckMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resolveInputFromTransmit: () => {
                return null;
            },
            rejectInputFromTransmit: null,
            showTryAgainScreen: false,
            confirmTryAgain: false,
            cofirmTryAnother: false,
            isAuthentication: false,
            initSeconds: 80,
            accsibilitySecondsCountdown: 80,
            timerToZero: false
        };
        if (props.storeThis) {
            props.storeThis("CheckMobile", this);
        }
    }

    componentDidMount() {
        changeIdleTimeOutValid(true);
        sessionStorage.setItem("counter", 1);
        window.tealiumHub.PT022();
        setTimeout(() => {
            this.countdownTimer();
        }, 1000);
        this.timer = setInterval(() => {
            this.confirmTrust();
        }, 1000);
        const descriptionEle = document.getElementById(
            "check-mobile-description"
        );
        if (descriptionEle) {
            descriptionEle.focus();
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    confirmTrust = () => {
        const input = window.com.ts.mobile.sdk.MobileApproveInputRequestPolling.createRequestPollingInput();
        const inputs = window.com.ts.mobile.sdk.TargetBasedAuthenticatorInput.createAuthenticatorInput(
            input
        );
        this.state.resolveInputFromTransmit(
            window.com.ts.mobile.sdk.InputOrControlResponse.createInputResponse(
                inputs
            )
        );
    };

    countdownTimer = () => {
        const timer = setInterval(() => {
            if (this.state.initSeconds > 0) {
                if (this.state.initSeconds % 10 === 0) {
                    this.setState({
                        accsibilitySecondsCountdown: this.state.initSeconds
                    });
                }
                this.setState({
                    initSeconds: this.state.initSeconds - 1
                });
            } else {
                clearInterval(timer);
                this.setState({
                    initSeconds: 80,
                    accsibilitySecondsCountdown: 0,
                    timerToZero: true
                });
                setTimeout(() => {
                    const timeoutEle = document.getElementById("error-content");

                    if (timeoutEle) {
                        timeoutEle.focus();
                    }
                }, 200);
            }
        }, 1000);
    };

    confirmTryAgain = () => {
        window.tealiumHub.ET053();
        const counter = Number(sessionStorage.getItem("counter")) + 1;
        sessionStorage.setItem("counter", counter);
        this.setState({
            confirmTryAgain: true,
            showTryAgainScreen: false,
            timerToZero: false,
            accsibilitySecondsCountdown: 80
        });

        setTimeout(() => {
            const descriptionEle = document.getElementById(
                "check-mobile-description"
            );

            if (descriptionEle) {
                descriptionEle.focus();
            }
        }, 100);
        this.timer = setInterval(() => {
            this.confirmTrust();
        }, 1000);
        setTimeout(() => {
            this.countdownTimer();
        }, 1000);
    };

    tryAnotherAuthonticator = () => {
        window.tealiumHub.ET054();
        this.setState({
            cofirmTryAnother: true
        });
    };

    render() {
        const currentUrlPathIsNotLogon =
            window.location.href.indexOf("logon") === -1;
        const showHeader =
            currentUrlPathIsNotLogon ||
            (envConfig.languageSlectList &&
                envConfig.languageSlectList.length > 1);
        const counter = parseInt(sessionStorage.getItem("counter"));
        const appsignNote =
            envConfig.realm === "HK"
                ? this.props.intl.formatMessage({
                      id: "appsign.note"
                  })
                : "";
        return (
            <CheckMobileContanier
                showHeader={showHeader}
                isMulLanguage={envConfig.locale}
            >
                {!this.state.timerToZero ? (
                    <div id="check-mobile-description" tabindex="0">
                        <img src={appSignBg} className="app-bg" alt="" />
                        <h1
                            id="modalTitle"
                            aria-label={this.props.intl.formatMessage({
                                id: "checkMobile.title"
                            })}
                        >
                            <FormattedMessage id="checkMobile.title" />
                        </h1>
                        <div
                            aria-label={
                                this.props.intl.formatMessage({
                                    id: "checkMobile.description"
                                }) + appsignNote
                            }
                            className="checkmobole-description"
                        >
                            <FormattedMessage id="checkMobile.description" />
                            {appsignNote}
                        </div>
                        <div className="foot">
                            <div className="contentmodal--footer-right ">
                                <div className="countdown-timer">
                                    <img src={loader} className="app-loader" />
                                    <span className="time">
                                        {" "}
                                        {this.state.initSeconds}
                                    </span>
                                    <div
                                        role="timer"
                                        aria-live="polite"
                                        aria-atomic="true"
                                        id="a11yTimer"
                                        className="sr-only"
                                    >
                                        <span id="a11ySecRemain">
                                            {
                                                this.state
                                                    .accsibilitySecondsCountdown
                                            }
                                        </span>
                                        <span> Seconds remaining</span>
                                    </div>
                                </div>
                                <span className="second">
                                    <FormattedMessage id="countDown.seconds" />
                                </span>
                            </div>
                        </div>
                    </div>
                ) : (
                    <ErrorContent showHeader={showHeader}>
                        <div
                            id="error-content"
                            className="error-content-box"
                            tabindex="0"
                        >
                            <h1
                                id="modalTitle"
                                aria-label={this.props.intl.formatMessage({
                                    id: "responseNoReceived.title"
                                })}
                                className="title"
                            >
                                <FormattedMessage
                                    id={"responseNoReceived.title"}
                                />
                            </h1>
                            <div
                                aria-label={
                                    this.props.intl.formatMessage({
                                        id: "responseNoReceived.description"
                                    }) + appsignNote
                                }
                                className="no-response"
                            >
                                <FormattedMessage
                                    id={
                                        counter < 3
                                            ? "responseNoReceived.description"
                                            : "responseNoReceived.description.tryAnother"
                                    }
                                />
                                {appsignNote}
                            </div>
                            <div className="foot">
                                {counter < 3 ? (
                                    <button
                                        className="submit"
                                        onClick={() => this.confirmTryAgain()}
                                    >
                                        <FormattedMessage id="responseNoReceived.button.tryAgain" />
                                    </button>
                                ) : (
                                    <div>
                                        <button
                                            className="submit"
                                            onClick={() =>
                                                this.tryAnotherAuthonticator()
                                            }
                                        >
                                            <FormattedMessage
                                                id={
                                                    "responseNoReceived.button.tryAnotherAuthenticator"
                                                }
                                            />
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </ErrorContent>
                )}
            </CheckMobileContanier>
        );
    }

    startSession = () => {
        console.log("startSession");
    };

    promiseFormInput = payload => {
        return new Promise((accept, reject) => {
            this.setPromiseHandlers(accept, reject);
        });
    };

    setPromiseHandlers = (acceptFN, rejectFN) => {
        this.acceptFN = acceptFN;
        this.rejectFN = rejectFN;
    };

    promiseInput = () => {
        return new Promise((resolve, reject) => {
            this.setState({
                resolveInputFromTransmit: resolve,
                rejectInputFromTransmit: reject
            });
        });
    };

    endSession = () => {
        console.log("endSession");
    };

    onContinue = payload => {
        console.log("onContinue");
    };

    promiseRecoveryForError = errorObject => {
        if (errorObject._errorCode !== 20) {
            const counter = parseInt(sessionStorage.getItem("counter"));
            if (counter < 3) {
                window.tealiumHub.PT023();
            } else {
                window.tealiumHub.PT024();
            }
            this.setState({
                showTryAgainScreen: true
            });
        }

        clearInterval(this.timer);
        const waitForConfirm = new Promise((resolve, reject) => {
            if (errorObject._errorCode === 9) {
                resolve(
                    window.com.ts.mobile.sdk.AuthenticationErrorRecovery.Fail
                );
                setTimeout(() => {
                    history.push({
                        pathname: "/commonLockedAccount/fullaccountLock",
                        state: { errorCode: "fullaccountLock" }
                    });
                }, 10);
            } else {
                this.timerP1 = setInterval(() => {
                    if (this.state.confirmTryAgain) {
                        this.setState({
                            confirmTryAgain: false
                        });
                        resolve(
                            window.com.ts.mobile.sdk.AuthenticationErrorRecovery
                                .RetryAuthenticator
                        );
                        clearInterval(this.timerP1);
                    } else if (this.state.cofirmTryAnother) {
                        resolve(
                            window.com.ts.mobile.sdk.AuthenticationErrorRecovery
                                .ChangeAuthenticator
                        );
                        this.setState({
                            cofirmTryAnother: false
                        });

                        clearInterval(this.timerP1);
                    }
                }, 1000);
            }
        });
        return new Promise((resolve, reject) => {
            resolve(waitForConfirm);
        });
    };

    selectAuthenticator = (options, actionContext, clientContext) => {
        this.props.dispatchAction({
            type: "RESET_PASSWORD_SECURITY_QUESTION_RECEIVED",
            payload: options
        });
        return Promise.resolve(
            window.com.ts.mobile.sdk.AuthenticatorSelectionResult.createSelectionRequest(
                options[0]._authenticator
            )
        );
    };

    onError = payload => {
        history.push({
            pathname: "/globalError/noConnection?step=010",
            state: { errorCode: "noConnection" }
        });
    };

    setAvailableTargets(a, b, c) {
        console.log("setAvailableTargets");
    }

    setCreatedApprovalInfo(a, b, c) {
        console.log("setCreatedApprovalInfo");
    }
}

const CheckMobileContanier = styled.div`

#check-mobile-description {
    position: relative;
    box-shadow: 0px 16px 24px 2px rgba(0, 0, 0, 0.14);
    background-color: ${color.white};
    color: ${color.text_color_dark};
    padding: 50px 50px 40px 50px;
    order: 1;
    margin: 20px;
    margin-top: ${props => (props.showHeader ? "20px" : "105px")}
    margin-bottom: ${props => (props.showHeader ? "20px" : "104px")}
}

.app-bg {
    width: 100%;
    margin-bottom: 10px;
}

.app-loader {
    animation: load 1.5s infinite linear;
}

.countdown-timer {
    position: relative;
    .sr-only {
        position: absolute;
        opacity: 0;
    }
}

.second {
    padding-top: ${props =>
        props.isMulLanguage.indexOf("zh") !== -1 ? "17%" : "11%"};
    margin-left: 10px;
    font-weight: 400;
    font-size: 0.75rem;
    color: #333;
}

.time {
    font-size: 0.9375rem;
    font-weight: 400;
    position: absolute;
    width: 100%;
    text-align: center;
    left: 0;
    height: 100%;
    display: inline-block;
    top: 0;
    padding-top: 25%;
    color: #333;
}

.declined-button {
    background: none;
    color: #000;
    border: 1px solid #000;
    @media (max-width: 767px) {
        background: none;
        color: #fff;
        border: 1px solid #fff;
    }
}

@media (max-width: 767px) {
    padding: 20px 20px 10px;
}
@media (min-width: 768px) {
    width: 524px;
}
display: flex;
flex-direction: column;

input {
    text-align: center;
}

.code-enter {
    width: 80px;
    height: 40px;
    font-size: 0.875rem;
    padding: 12px 12px;
    /* text-align:center;*/
    border: 1px solid #929292;
    &.error {
        border: 1px solid ${color.error_msg_stroke_outline};
    }
}
.contentmodal--footer-right {
    padding-top: 10px;
    display: flex;
    margin-top: -30px;
    margin-bottom: -20px;
    @media (max-width: 767px) {
        margin-top: -30px;
        margin-bottom: 0px;
    }
    .hsbc-ui.loader.large.loader .spinner {
        width: 40px;
        height: 40px;
    }
}

.hsbc-ui.loader .spinner {
    border: 3px solid #1d262c;
    border-right-color: transparent;
    border-radius: 50%;
    display: inline-block;
    animation: load 1.5s infinite linear;
}

h1 {
    font-size: 1.75rem;
    font-weight: 400;
    line-height: 36px;
    margin: 0px;
}

h2 {
    margin-top: 30px;
    margin-bottom: 9px;
    font-size: 1rem;
    font-weight: normal;
    line-height: 19px;
}
.checkmobole-description {
    font-size: 1rem;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 50px;
}

#check-mobile-description:focus {
    outline: 0px;
}

.foot {
    position: relative;
    display: flex;
    justify-content: space-between;
}
`;

const ErrorContent = styled.div`
    box-shadow: 0px 16px 24px 2px rgba(0, 0, 0, 0.14);
    background-color: ${color.white};
    color: ${color.text_color_dark};
    padding: 40px 40px 20px 40px;
    order: 1;
    margin: 20px;
    margin-top: ${props => (props.showHeader ? "20px" : "105px")}
    margin-bottom: ${props => (props.showHeader ? "20px" : "104px")}
    position: relative;

    #error-content:focus {
        outline: 0px;
    }

    @media (min-width: 768px) {
        width: 524px;
    }
    display: flex;
    flex-direction: column;

    input {
        text-align: center;
    }

    .code-enter {
        width: 80px;
        height: 40px;
        font-size: 0.875rem;
        padding: 12px 12px;
        /* text-align:center;*/
        border: 1px solid #929292;
        &.error {
            border: 1px solid ${color.error_msg_stroke_outline};
        }
    }
    .btn--loader--container {
        position: relative;
        display: inline-block;
        width: 120px;
        margin-bottom: 50px;
        .btn--loader {
            margin-top: 20px;
            padding-top: 0px;
            width: 120px;
            padding-top: 10px;
            padding-bottom: 0px;
            height: 46px;
            cursor: default;
            background-color: rgb(219, 0, 17);
            .children {
                margin-top: 0px;
            }
        }
        .hsbc-ui.loader .spinner  {
            border: 2px solid #fff;
            border-right-color: transparent;
        }
        .btn--loader:before {
            padding-top: 0px;
        }
    }
    button {
        cursor: pointer;
        height: 46px;
        padding: 0 20px;
        font-size: 1rem;
        font-weight: 100;
        line-height: 16px;
        border: none;
        align-self: flex-end;
        margin-top: 10px;
        &.submit {
            background-color: ${color.hsbc_red};
            color: ${color.white};
            margin-bottom: 20px;
        }
    }

    h1 {
        font-size: 1.75rem;
        font-weight: 400;
        line-height: 36px;
        margin: 0px;
        margin-bottom: 12px;
    }

    h2 {
        margin-top: 30px;
        margin-bottom: 9px;
        font-size: 1rem;
        font-weight: normal;
        line-height: 19px;
    }
    .no-response {
        font-size: 1rem;
        font-weight: 400;
        line-height: 24px;
    }

    .foot {
        position: relative;
        .declined-button {
            width: 100%;
        }
    }
`;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(CheckMobile));
