import React, { Component } from "react";
import { color } from "util/color";
import SecurityModal from "../common/SecurityModal";
import { history } from "util/history";
import styled from "styled-components";
import cellphone from "assets/image/ad_phone.png";
import android from "assets/image/ad_andriod.jpg";
import iPhone from "assets/image/ad_iphone.jpg";
import androidSim from "assets/image/Android_sim.jpg";
import androidTrd from "assets/image/Android_trd.jpg";
import iPhoneSim from "assets/image/iphone_sim.svg";
import iPhoneTrd from "assets/image/iphone_trd.svg";
import { connect } from "react-redux";
import { envConfig } from "env-config";
import { injectIntl } from "react-intl";
import { securityQuestionListActions } from "actions/createSecurityQuestionAction";

const mapStateToProps = state => {
    return {
        disableButton: state.securityQuestionList.securityQuestionBtn
    };
};

const mapDispatchToProps = dispatch => {
    return {
        securityQuestionBtn: payload =>
            dispatch(securityQuestionListActions.securityQuestionBtn(payload))
    };
};

class MockSuccessfulRegistrated extends Component {
    constructor(props) {
        super(props);
        window.tealiumHub.PT014();
        this.state = {
            imageAndroidSrc: android,
            imageIphoneSrc: iPhone
        };
    }

    goAndroidPic = () => {
        // window.location.href = envConfig.androidAppUrl
    };

    goIphonePic = () => {
        // window.location.href = envConfig.iosAppUrl
    };

    switchImage = selectedLang => {
        switch (selectedLang) {
            case "zh-trd-HK":
                this.setState({
                    imageAndroidSrc: androidTrd,
                    imageIphoneSrc: iPhoneTrd
                });
                break;
            case "zh-sim-HK":
                this.setState({
                    imageAndroidSrc: androidSim,
                    imageIphoneSrc: iPhoneSim
                });
                break;
            default:
                this.setState({
                    imageAndroidSrc: android,
                    imageIphoneSrc: iPhone
                });
        }
    };

    componentDidMount() {
        const selectedLang = localStorage.getItem("selectedLang");
        this.switchImage(selectedLang);
        this.props.securityQuestionBtn(false);
        sessionStorage.removeItem("otpErrorCounter");
    }

    render() {
        const selectedLang = localStorage.getItem("selectedLang");
        return (
            <RegistratedSuccessContainer selectedLang={selectedLang}>
                <SecurityModal
                    title="successRegistrated.title"
                    ariaTitle="successRegistrated.aria.title"
                    onConfirmationClick={() => {
                        window.tealiumHub.ET031("inputCredential.label.logon");
                        history.push("/logon");
                    }}
                    customizedClass="none-shadow"
                    confirmationBtnTitle="inputCredential.label.logon"
                >
                    <p className="content--style">
                        {this.props.intl.formatMessage({
                            id: "successRegistrated.label.subTitle"
                        })}
                    </p>
                </SecurityModal>
                {envConfig.showAppDownloadAd && (
                    <div className="mobile--div">
                        <div>
                            <h1
                                aria-label={this.props.intl.formatMessage({
                                    id: "successRegistrated.aria.pic.title"
                                })}
                            >
                                <span>
                                    {this.props.intl.formatMessage({
                                        id: "successRegistrated.pic.title"
                                    })}
                                </span>
                                <span>
                                    {this.props.intl.formatMessage({
                                        id: "successRegistrated.pic.title2"
                                    })}
                                </span>
                            </h1>
                        </div>
                        <div className="img--parent">
                            <div className="left--img">
                                <img
                                    src={cellphone}
                                    alt={this.props.intl.formatMessage({
                                        id: "successRegistrated.alt.cellPhone"
                                    })}
                                />
                            </div>
                            <div className="right--img">
                                <div
                                    className="right--img--up"
                                    onClick={this.goAndroidPic}
                                >
                                    <img
                                        src={this.state.imageAndroidSrc}
                                        alt={this.props.intl.formatMessage({
                                            id: "successRegistrated.alt.android"
                                        })}
                                    />
                                </div>
                                <div
                                    className="right--img--down"
                                    onClick={this.goIphonePic}
                                >
                                    <img
                                        src={this.state.imageIphoneSrc}
                                        alt={this.props.intl.formatMessage({
                                            id: "successRegistrated.alt.iPhone"
                                        })}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div></div>
            </RegistratedSuccessContainer>
        );
    }
}

const RegistratedSuccessContainer = styled.div`
        display:flex;
        position: relative;
        top:50%; 
        transform:translateY(-50%);
        @media (max-width: 907px) {
            flex-wrap: wrap;
        }
        @media (max-width: 449px) {
            // padding: 30px 20px;
            width: 90%;
            margin: auto;
        }
        justify-content: center;

        .none-shadow {
            box-shadow: none;
            @media (min-width: 414px) and (max-width: 768px) {
                padding: 30px 30px 17px 30px;
            }
        }
        
        .content--style{
            font-size: 16px;	
            font-weight: 300;	
            line-height: 24px;
            height: 24px;
            margin-top: 30px;
            margin-bottom: 60px;
            @media (max-width: 449px) {
                margin-top: 0px;
                margin-bottom: 40px;
            }
        }

        .responsive-padding {
            @media (min-width: 414px) {
                padding: 46.19px 40px 40px 40px;
            }
        }

        .contentmodal--header {
            @media (max-width: 449px) { 
                font-size: 20px;
                white-space: normal;
            }
        }

        .mobile--div{
            display: block; // No Mobile Pic
            padding: 46.19px 50px 0px 50px;
            margin-left: -22px;
            margin-top: 20px;
            margin-bottom: 20px;
            position: relative;
            
            overflow: hidden;

            @media (max-width: 320px) {
                margin: 20px;
                padding: 14px;
                width: 280px;
                margin-top: -21px;
                .divBody.bodyExpanded{
                    .table_container {
                        width: 270px;
                        overflow: scroll;
                    }
                    width: 264px
                    overflow: scroll;
                }
            }
            @media (min-width: 321px) and (max-width: 413px) {
                margin: 20px;
                padding: 14px;
                width: 335px;
                margin-top: -21px;
                .divBody.bodyExpanded{
                    .table_container {
                        width: 319px;
                        overflow: scroll;
                    }
                    width: 319px;
                    overflow: scroll;
                }
            }
            @media (min-width: 414px) and (max-width: 768px) {
                margin: 20px;
                padding: 30px 30px 17px 30px;
                width: 374px;
                margin-top: -21px;
                .divBody.bodyExpanded{
                    .table_container {
                        width: 319px;
                        overflow: scroll;
                    }
                    width: 319px;
                    overflow: scroll;
                }
            }

            @media (max-width: 769px) {
                box-shadow: 0px 16px 24px 2px rgba(0, 0, 0, 0.14);
            }

            @media (min-width: 769px) {
                padding: 50px 50px 0px 50px;
                margin-top: 20px;
                width: ${props => props.dialogWidth || "454px"};
                max-width:  ${props => props.dialogWidth || "454px"};
            }

            @media (min-width: 769px) and (max-width: 898px) {
                margin-top: 0px;
                margin-left: 0px;
            }

            @media (min-width: 898px) and (max-width: 907px) {
                margin-top: 0px;
            }
            
            background-color:${color.white};
            h1{
                margin:0px;
                margin-left: 6px;
                font-size: 28px;
                font-weight: 100;
                line-height: 36px;
                color: #333333;
                @media (max-width: 449px) { 
                    font-size: 20px;
                    margin-bottom: -70px;
                    white-space: normal;
                }
            }
            .img--parent{
                display:flex;
                .left--img{
                    width:190px;
                    height: 240px;
                    img{
                        width: 120%;
                        margin-top: 25px;
                        @media (max-width: 449px) {
                            width: 180%;
                            margin-top: 67px;
                            margin-left: -35%;
                        }
                        @media (min-width: 450px) and (max-width: 1019px) {
                            margin-top: 40px;
                        }
                    }
                }
                .right--img{
                    padding-bottom:0px;
                    padding-top: 120px;
                    margin-left: 40px;
                    @media (max-width: 350px) {
                        top: -15%;
                        height: 130%;
                        margin-right: 10px;
                        margin-left: 10px;
                    }
                    @media (max-width: 449px) {
                        margin-right: 20px;
                    }
                    .right--img--up{
                        margin-bottom: 5px;
                        img{
                            width:98px;
                            border-radius:  ${props =>
                                props.selectedLang &&
                                props.selectedLang.indexOf("zh") !== -1
                                    ? "5px"
                                    : "0px"};
                            height: ${props =>
                                props.selectedLang &&
                                props.selectedLang.indexOf("zh") !== -1
                                    ? "34px"
                                    : ""};
                        }
                    }
                    .right--img--down{
                        img{
                            width: ${props =>
                                props.selectedLang &&
                                props.selectedLang.indexOf("zh") !== -1
                                    ? "100px"
                                    : "99px"};
                        }
                    }
                    @media (min-width: 769px) and (max-width: 910px) {
                        padding-top: 110px;
                    }
                }                    
                }

            } 
        }     
`;
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(MockSuccessfulRegistrated));
