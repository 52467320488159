import React, { Component } from "react";
import styled from "styled-components";
import { color } from "util/color";
import { FormattedMessage, injectIntl } from "react-intl";
import { envConfig } from "env-config";
import SecurityModal from "components/common/SecurityModal";
import { Radio } from "hsbc-pb-sdk";
import { history } from "util/history";
import { connect } from "react-redux";

const mapStateToProps = state => {
    return {
        selectedLang: state.languageSession.selectedLang,
        lockedType: state.lockedType.type,
        emailOtpList: state.contactOptions.emailOtpList
    };
};

const ContactsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 10px;

    .contentmodal--content {
        .statement--p1 {
        }
    }
    .statement--title {
        color: ${color.text_color_dark};
        display: inline-block;
    }
    p {
        font-size: 14px;
        margin-top: 30px;
        margin-bottom: 18px;
    }
    .radio--button-container {
        label {
            margin-bottom: 20px;
        }
        .hsbc-ui.checkbox.radio {
            font-size: 13px;
            label::before,
            label::after {
                width: 25px;
                height: 25px;
            }
        }
    }
    .icon {
        padding-right: 7px;
    }
    .validation--error {
        .container {
            .checkmark {
                label:before {
                    border: 1px solid ${color.hsbc_warning_red};
                    background-color: #f9f2f3;
                }
            }
        }
    }

    .statement--validation {
        margin-top: 0px;
        overflow: auto;
        width: 100%;
        line-height: 28px;
        font-weight: 400;
        .icon {
            color: ${color.hsbc_warning_red};
            position: relative;
            padding-right: 1px;
        }
        .icon--container {
            float: left;
            width: 5%;
        }
        .error--content {
            float: right;
            width: 93%;
            font-size: 14px;
        }
    }
`;

const locale = envConfig.locale;

class OptionsList extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.prechooseTargetOrGoToNoContact();
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.targets && Array.isArray(this.props.targets)) {
            this.prechooseTargetOrGoToNoContact();
        }
    }

    prechooseTargetOrGoToNoContact = () => {
        if (Array.isArray(this.props.targets)) {
            if (this.props.targets.length) {
                this.props.prechooseEmailOption();
            } else {
                history.push("/noContactDetails");
            }
        }
    };

    render() {
        if (this.props.targets === null) {
            return null;
        }

        const contactRadioes = [];

        for (let i = 0; i < this.props.targets.length; i++) {
            const valueIdentifier = `${this.props.targets[i]._targetIdentifier};${this.props.targets[i]._channel}`;
            const label = this.props.emailOtpList;

            contactRadioes.push({
                label,
                value: valueIdentifier
            });
        }

        return (
            <ContactsContainer>
                <SecurityModal
                    title="EmailOTPContactOptions.title"
                    ariaTitle="EmailOTPContactOptions.ariaTitle"
                    onConfirmationClick={this.props.sendCodeToContact}
                    confirmationBtnTitle="EmailOTPContactOptions.label.sendCode"
                >
                    <div>
                        <p id="selectWarning" className="statement--title">
                            <FormattedMessage id="EmailOTPContactOptions.label.selectTip" />
                        </p>

                        <div className="radio--button-container">
                            <Radio.Group
                                name="radioGroup"
                                title=""
                                items={contactRadioes}
                                active={this.props.activeOption}
                                handleChange={this.props.handleChange}
                            />
                        </div>
                        <p>
                            <FormattedMessage id="EmailOTPContactOptions.label.footTip" />
                        </p>
                        {locale === "en-US" ? (
                            <p>
                                <FormattedMessage id="contactOptions.label.feeTip" />
                            </p>
                        ) : null}
                        {this.props.isLastAttempt
                            ? this.props.lastAttemptTip
                            : ""}
                    </div>
                </SecurityModal>
            </ContactsContainer>
        );
    }
}

export default connect(mapStateToProps)(injectIntl(OptionsList));
