import _ from "lodash";
import { locale } from "../store/configureStore";
import messages from "util/messages";
import { FormattedMessage } from "react-intl";
import React from "react";

const firstPartText = "validator.than.firstPart";
const middlePartText = "validator.longerThan.middlePart";
const afterPartText = "validator.than.afterPart";
const middlePartLessText = "validator.lessThan.middlePart";
export function eachCharacterCountToleranceCheck(val, tolerance) {
    const result = {};
    const valLength = val.length;
    for (let i = 0, length = valLength; i < length; i++) {
        result[val[i]] = result[val[i]] ? result[val[i]] + 1 : 1;
    }
    const resultKey = Object.keys(result);
    const aCharacterOutOfTolerance = resultKey.some(
        item => result[item] / valLength > tolerance
    );
    return !aCharacterOutOfTolerance;
}

export function isContainKeyboardSequence(val, step) {
    const string = "0123456789qwertyuiopasdfghjklzxcvbnm";
    const limit = val.length - step;
    let err = 0;
    const lowerCaseVal = val.toLowerCase();
    for (let i = 0; i <= limit; i++) {
        var substr = lowerCaseVal.substr(i, step);
        var n = string.indexOf(substr);
        var m = string
            .split("")
            .reverse()
            .join("")
            .indexOf(substr);
        if (n !== -1 || m !== -1) {
            err++;
        }
        if (err > 0) {
            return true;
        }
    }
    return false;
}

export function isSameCharacterRepeted1(val, times) {
    const valLength = val.length;
    let sameCounter = 0;
    for (let i = 0, length = valLength; i < length; i++) {
        if (sameCounter === times) {
            return true;
        }
        if (val[i] === val[i + 1] && sameCounter <= times) {
            sameCounter++;
        } else {
            sameCounter = 0;
        }
    }
    return false;
}

function unameNeddlessThan4Lu(localestr) {
    return _.get(messages[localestr].lang, "validator.Lessthan.4");
}
function qestionLessThan3Lu(localestr) {
    return _.get(messages[localestr].lang, "validator.question.Lessthan.3");
}
function defaultForLongThan(localestr, typeName, length, type) {
    let result = null;
    if (type === "question") {
        result = qestionLessThan3Lu(localestr);
        return result;
    }
    const firstPart = _.get(messages[localestr].lang, firstPartText);
    const middlePart = _.get(messages[localestr].lang, middlePartText);
    const afterPart = _.get(messages[localestr].lang, afterPartText);
    result =
        firstPart + typeName + middlePart + (length - 1) + "\n" + afterPart;
    return result;
}

export function longerThan(value, length, typeName, type) {
    let result;
    const localestr = locale();
    if (value.length < length) {
        switch (localestr) {
            case "zh-trd-HK":
            case "zh-sim-HK":
                result =
                    _.get(messages[localestr].lang, firstPartText) +
                    _.get(messages[localestr].lang, middlePartText) +
                    (length - 1) +
                    _.get(messages[localestr].lang, afterPartText) +
                    typeName;
                break;
            case "en-LU":
            case "fr-LU":
                result =
                    type === "username"
                        ? unameNeddlessThan4Lu(localestr)
                        : defaultForLongThan(localestr, typeName, length, type);
                break;
            default:
                result =
                    _.get(messages[localestr].lang, firstPartText) +
                    typeName +
                    _.get(messages[localestr].lang, middlePartText) +
                    (length - 1) +
                    _.get(messages[localestr].lang, afterPartText);
        }
    } else {
        result = false;
    }

    return result;
}
function unameMoreThan77Lu(localestr) {
    return _.get(messages[localestr].lang, "validator.Morethan.77");
}
function questionNeedUnder31(localestr) {
    return _.get(messages[localestr].lang, "validator.question.morethan.31");
}
function defaultForLessThan(localestr, typeName, length, type) {
    let result = null;
    if (type === "question") {
        return questionNeedUnder31(localestr);
    }
    const firstPart = _.get(messages[localestr].lang, firstPartText);
    const middlePart = _.get(messages[localestr].lang, middlePartLessText);
    const afterPart = _.get(messages[localestr].lang, afterPartText);
    result =
        firstPart + typeName + middlePart + (length + 1) + "\n" + afterPart;
    return result;
}
export function lessThan(value, length, typeName, type) {
    let result;
    const localestr = locale();
    if (value.length > length) {
        switch (localestr) {
            case "zh-trd-HK":
            case "zh-sim-HK":
                result =
                    _.get(messages[localestr].lang, firstPartText) +
                    _.get(messages[localestr].lang, middlePartLessText) +
                    (length + 1) +
                    _.get(messages[localestr].lang, afterPartText) +
                    typeName;
                break;
            case "en-LU":
            case "fr-LU":
                result =
                    type === "username"
                        ? unameMoreThan77Lu(localestr)
                        : defaultForLessThan(localestr, typeName, length, type);
                break;
            default:
                result =
                    _.get(messages[localestr].lang, firstPartText) +
                    typeName +
                    _.get(messages[localestr].lang, middlePartLessText) +
                    (length + 1) +
                    _.get(messages[localestr].lang, afterPartText);
        }
    } else {
        result = false;
    }
    return result;
}

export function isContainKeyOnlyLetterNumberSpecailChra(value) {
    let result;
    const isResetPassword = window.location.href.includes("resetPassword");
    const localestr = locale();
    if (/^[-.@'_a-zA-Z0-9]+$/.test(value)) {
        result = false;
    } else {
        result = _.get(
            messages[localestr].lang,
            isResetPassword
                ? "forgotPassword.warning.notRecognised"
                : "validator.usernameCommon.message"
        );
    }
    return result;
}

export function usernameCommonValidate(value, type) {
    const typeStr = _.get(messages[locale()].lang, "validator.than.username");
    return (
        longerThan(value, 5, typeStr, "username") ||
        lessThan(value, 76, typeStr, "username") ||
        isContainKeyOnlyLetterNumberSpecailChra(value)
    );
}

export function createPasswordValidate(password, opt = {}) {
    let errorMsg = "";
    if (password.length < 12) {
        errorMsg = (
            <FormattedMessage id="validator.createPassword.longerMessage" />
        );
    }
    return errorMsg;
}

export function securityQuestionCreateValidate(value, opt) {
    const { customizeSpecifiedWords = [], userName } = opt;
    if (value) {
        const typeStr = _.get(
            messages[locale()].lang,
            "validator.than.securityQuestion"
        );
        let errorMsg =
            longerThan(value, 3, typeStr, "question") ||
            lessThan(value, 30, typeStr, "question");
        if (errorMsg) {
            return errorMsg;
        }
        if (
            [...customizeSpecifiedWords, "PASSWORD"].indexOf(
                value.toUpperCase()
            ) > -1
        ) {
            errorMsg = (
                <FormattedMessage id="validator.commonMessage.specifiedWords.securityQuestion" />
            );
        } else if (isContainKeyboardSequence(value, 5)) {
            errorMsg = (
                <FormattedMessage id="validator.securityQuestion.sequentialCharacter" />
            );
        } else if (!eachCharacterCountToleranceCheck(value, 0.5)) {
            errorMsg = (
                <FormattedMessage id="validator.securityQuestion.sameCharacters" />
            );
        } else if (userName && userName.toUpperCase() === value.toUpperCase()) {
            errorMsg = (
                <FormattedMessage id="validator.commonMessage.sameUserName" />
            );
        } else if (isSameCharacterRepeted1(value, 2)) {
            errorMsg = (
                <FormattedMessage id="validator.securityQuestion.consecutiveCharacters" />
            );
        }
        return errorMsg;
    }
    return null;
}
