export const createUserNameActions = {
    createUserNameIsLoading
};

function createUserNameIsLoading(bool) {
    return {
        type: "CREATE_USERNAME_LOADING",
        isLoading: bool
    };
}
