export default `<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
    body {
        text-justify-trim:punctuation;
    }
    .bullets {
        
        line-height:110%;
        font-family:Wingdings;
        color:#DB0011;
    }

    .MsoNormal p, a{
        font-size: 10.0pt;
        text-autospace: none;
    }
</style>
<style>
.mybullet {
    content: '';
    height: .6em;
    width: .6em;
    background: #DB0011;
    display: inline-block;
    transform: rotate(45deg);
	margin-left: 5px;
	margin-right: 15px;
    vertical-align: middle;
    margin-top: 5px;
    float: left;
  }
.mybullet + span {
    overflow: hidden;
    display: block;
    word-wrap: break-word;
}
  </style>

</head>

<body lang=GB-ENG>

<div class=WordSection1>
<!--Start-->
<div class="WordSection1">

<p class="MsoNormal" style="line-height: 105%;"><span lang="EN-GB" style="
mso-fareast-language: EN-GB;">We know that you are concerned with how your personal and financial information
is dealt with. This privacy statement sets out our current policies and
demonstrates our commitment to your financial privacy.<o:p></o:p></span></p>

<p class="MsoNormal" style="line-height: 105%;"><span lang="EN-GB" style="mso-fareast-language: EN-GB;"><o:p>&nbsp;</o:p></span></p>

<p class="MsoNormal" style="line-height: 105%;"><span lang="EN-GB" style="mso-fareast-language: EN-GB;">By accessing this website (the 'Site') and any of its pages, you are
agreeing to the privacy statement set out below.<o:p></o:p></span></p>

<p class="MsoNormal" style="line-height: 105%;"><span lang="EN-GB" style="mso-fareast-language: EN-GB;"><o:p>&nbsp;</o:p></span></p>

<p class="MsoNormal" style="line-height: 105%;"><span lang="EN-GB" style="mso-fareast-language: EN-GB;">Before using this Site, you should read this privacy statement
carefully, as well as our </span><span lang="EN-GB"><a href="https://www.hsbc.lu/en-gb/terms-conditions"><span style="
color: windowtext; mso-fareast-language: EN-GB;">terms and conditions</span></a></span><span lang="EN-GB" style="
mso-fareast-language: EN-GB;"> and </span><span lang="EN-GB"><a href="https://www.hsbc.lu/en-gb/hyperlink-policy"><span style="
color: windowtext; mso-fareast-language: EN-GB;">hyperlink policy</span></a></span><span lang="EN-GB" style="
mso-fareast-language: EN-GB;">. You should also read our </span><span lang="EN-GB"><a href="https://www.hsbc.lu/en-gb/cookie-policy"><span style="

color: windowtext; mso-fareast-language: EN-GB;">cookie policy</span></a></span><span lang="EN-GB" style="mso-fareast-language: EN-GB;"> to find out more about how HSBC
and our trusted partners use cookies, which is relevant to your experience on
this Site.<o:p></o:p></span></p>

<p class="MsoNormal" style="line-height: 105%;"><span lang="EN-GB" style="mso-fareast-language: EN-GB;"><o:p>&nbsp;</o:p></span></p>

<p class="MsoNormal" style="line-height: 105%;"><span lang="EN-GB" style="mso-fareast-language: EN-GB;">The information, material and content provided in the pages of the Site
may be changed at any time without notice, and consequently this privacy
statement may change at any time in the future. You agree to review this
privacy statement regularly and your continued access to or use of the Site will
mean that you agree to any changes.<o:p></o:p></span></p>

<p class="MsoNormal" style="line-height: 105%;"><span lang="EN-GB" style="mso-fareast-language: EN-GB;"><o:p>&nbsp;</o:p></span></p>

<p class="MsoNormal" style="line-height: 105%;"><span lang="EN-GB" style="mso-fareast-language: EN-GB;">The HSBC Group provides financial products and services through its
affiliated companies and branches located worldwide. Privacy and personal data
protection principles vary from one country to another. When you access or link
to any other website, please read the privacy and data protection statements or
agreements issued by such other website to determine the policies that apply to
information or data maintained by that website.<o:p></o:p></span></p>

<p class="MsoNormal" style="line-height: 105%;"><span lang="EN-GB" style="mso-fareast-language: EN-GB;"><o:p>&nbsp;</o:p></span></p>

<p class="MsoNormal" style="line-height: 105%;"><span lang="EN-GB" style="mso-fareast-language: EN-GB;">Our business has been built on trust between us and our customers. To
preserve the confidentiality of all information you provide to us, we have
adopted the following privacy principles:<o:p></o:p></span></p>

<p class="MsoListParagraphCxSpFirst" style="
margin: 3pt 0cm 6pt 36pt; line-height: 110%; text-indent: -18pt; mso-list: l13 level1 lfo17; mso-add-space: auto;"><!--[if !supportLists]--><span lang="EN-GB" style="
color: red; font-family: Symbol; mso-fareast-font-family: Symbol; mso-fareast-language: EN-GB; mso-bidi-font-family: Symbol;"><span style="
mso-list: Ignore;">¨<span style='
font: 7pt/normal "Times New Roman"; font-size-adjust: none; font-stretch: normal;'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="EN-GB" style="
mso-fareast-language: EN-GB;">We will only collect information
that we believe to be relevant and required to understand your financial needs
and to conduct our business<o:p></o:p></span></p>

<p class="MsoListParagraphCxSpMiddle" style="
margin: 3pt 0cm 6pt 36pt; line-height: 110%; text-indent: -18pt; mso-list: l13 level1 lfo17; mso-add-space: auto;"><!--[if !supportLists]--><span lang="EN-GB" style="
color: red; font-family: Symbol; mso-fareast-font-family: Symbol; mso-fareast-language: EN-GB; mso-bidi-font-family: Symbol;"><span style="
mso-list: Ignore;">¨<span style='
font: 7pt/normal "Times New Roman"; font-size-adjust: none; font-stretch: normal;'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="EN-GB" style="
mso-fareast-language: EN-GB;">We use your information to
provide you with better customer services and products<o:p></o:p></span></p>

<p class="MsoListParagraphCxSpMiddle" style="
margin: 3pt 0cm 6pt 36pt; line-height: 110%; text-indent: -18pt; mso-list: l13 level1 lfo17; mso-add-space: auto;"><!--[if !supportLists]--><span lang="EN-GB" style="
color: red; font-family: Symbol; mso-fareast-font-family: Symbol; mso-fareast-language: EN-GB; mso-bidi-font-family: Symbol;"><span style="
mso-list: Ignore;">¨<span style='
font: 7pt/normal "Times New Roman"; font-size-adjust: none; font-stretch: normal;'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="EN-GB" style="
mso-fareast-language: EN-GB;">We may pass your information to
other HSBC Group companies or agents, as permitted by law<o:p></o:p></span></p>

<p class="MsoListParagraphCxSpMiddle" style="
margin: 3pt 0cm 6pt 36pt; line-height: 110%; text-indent: -18pt; mso-list: l13 level1 lfo17; mso-add-space: auto;"><!--[if !supportLists]--><span lang="EN-GB" style="
color: red; font-family: Symbol; mso-fareast-font-family: Symbol; mso-fareast-language: EN-GB; mso-bidi-font-family: Symbol;"><span style="
mso-list: Ignore;">¨<span style='
font: 7pt/normal "Times New Roman"; font-size-adjust: none; font-stretch: normal;'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="EN-GB" style="
mso-fareast-language: EN-GB;">We will not disclose your
information to any external organisation unless we have your consent or are
required by law or have previously informed you<o:p></o:p></span></p>

<p class="MsoListParagraphCxSpMiddle" style="
margin: 3pt 0cm 6pt 36pt; line-height: 110%; text-indent: -18pt; mso-list: l13 level1 lfo17; mso-add-space: auto;"><!--[if !supportLists]--><span lang="EN-GB" style="
color: red; font-family: Symbol; mso-fareast-font-family: Symbol; mso-fareast-language: EN-GB; mso-bidi-font-family: Symbol;"><span style="
mso-list: Ignore;">¨<span style='
font: 7pt/normal "Times New Roman"; font-size-adjust: none; font-stretch: normal;'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="EN-GB" style="
mso-fareast-language: EN-GB;">We may be required from time to
time to disclose your information to Governmental or judicial bodies or
agencies or our regulators, but we will only do so under proper authority<o:p></o:p></span></p>

<p class="MsoListParagraphCxSpMiddle" style="
margin: 3pt 0cm 6pt 36pt; line-height: 110%; text-indent: -18pt; mso-list: l13 level1 lfo17; mso-add-space: auto;"><!--[if !supportLists]--><span lang="EN-GB" style="
color: red; font-family: Symbol; mso-fareast-font-family: Symbol; mso-fareast-language: EN-GB; mso-bidi-font-family: Symbol;"><span style="
mso-list: Ignore;">¨<span style='
font: 7pt/normal "Times New Roman"; font-size-adjust: none; font-stretch: normal;'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="EN-GB" style="
mso-fareast-language: EN-GB;">We aim to keep your information
up-to-date<o:p></o:p></span></p>

<p class="MsoListParagraphCxSpMiddle" style="
margin: 3pt 0cm 6pt 36pt; line-height: 110%; text-indent: -18pt; mso-list: l13 level1 lfo17; mso-add-space: auto;"><!--[if !supportLists]--><span lang="EN-GB" style="
color: red; font-family: Symbol; mso-fareast-font-family: Symbol; mso-fareast-language: EN-GB; mso-bidi-font-family: Symbol;"><span style="
mso-list: Ignore;">¨<span style='
font: 7pt/normal "Times New Roman"; font-size-adjust: none; font-stretch: normal;'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="EN-GB" style="
mso-fareast-language: EN-GB;">We maintain strict security
systems designed to prevent unauthorised access to your information by anyone,
including our staff<o:p></o:p></span></p>

<p class="MsoListParagraphCxSpLast" style="
margin: 3pt 0cm 6pt 36pt; line-height: 110%; text-indent: -18pt; mso-list: l13 level1 lfo17; mso-add-space: auto;"><!--[if !supportLists]--><span lang="EN-GB" style="
color: red; font-family: Symbol; mso-fareast-font-family: Symbol; mso-fareast-language: EN-GB; mso-bidi-font-family: Symbol;"><span style="
mso-list: Ignore;">¨<span style='
font: 7pt/normal "Times New Roman"; font-size-adjust: none; font-stretch: normal;'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="EN-GB" style="
mso-fareast-language: EN-GB;">All HSBC Group companies, all our
staff and all third parties with permitted access to your information are
specifically required to observe our confidentiality obligations<o:p></o:p></span></p>

<p class="MsoNormal" style="line-height: 105%;"><span lang="EN-GB" style="mso-fareast-language: EN-GB;">By maintaining our commitment to these principles, we will ensure that
we respect the inherent trust that you place in us.<o:p></o:p></span></p>

<h1 style="text-indent: 0cm; margin-left: 0cm; mso-list: none;"><span lang="EN" style="mso-fareast-language: EN-GB; mso-ansi-language: EN;">Cookie Policy <o:p></o:p></span></h1>

<p class="MsoNormal" style="line-height: 14.4pt; margin-bottom: 7.15pt;"><span lang="EN" style='
color: rgb(2, 2, 2); font-family: "Times New Roman",serif; font-size: 13.5pt; mso-fareast-language: EN-GB; mso-ansi-language: EN;'>Understanding how and why we
use cookies<o:p></o:p></span></p>

<p class="MsoNormal" style="line-height: 105%;"><span lang="EN-GB" style="mso-fareast-language: EN-GB;">We use cookies on our website to create the most effective website
possible for our users. Among other things, the cookies we use allow us to
improve your experience when using our website and to ensure the website performs
as you expect it to.<o:p></o:p></span></p>

<p class="MsoNormal" style="line-height: 105%;"><span lang="EN-GB" style="mso-fareast-language: EN-GB;"><o:p>&nbsp;</o:p></span></p>

<p class="MsoNormal" style="line-height: 105%;"><span lang="EN-GB" style="mso-fareast-language: EN-GB;">This cookie policy explains what cookies are, how we use them on our
website and what benefits they bring.<o:p></o:p></span></p>

<p class="MsoNormal" style="line-height: 105%;"><span lang="EN-GB" style="mso-fareast-language: EN-GB;"><o:p>&nbsp;</o:p></span></p>

<p class="MsoNormal" style="line-height: 105%;"><span lang="EN-GB"><a href="https://www.hsbc.lu/en-gb/cookie-policy#faqs"><span style="
color: windowtext; mso-fareast-language: EN-GB;">Frequently asked questions about cookies</span></a></span><span lang="EN-GB" style="
mso-fareast-language: EN-GB;"><o:p></o:p></span></p>

<p class="MsoNormal" style="line-height: 105%;"><span lang="EN-GB" style="mso-fareast-language: EN-GB;"><o:p>&nbsp;</o:p></span></p>

<p class="MsoNormal" style="line-height: 14.4pt; margin-bottom: 7.15pt;"><span lang="EN" style='
color: rgb(2, 2, 2); font-family: "Times New Roman",serif; font-size: 13.5pt; mso-fareast-language: EN-GB; mso-ansi-language: EN;'>What are cookies?<o:p></o:p></span></p>

<p class="MsoNormal" style="background: white; margin-bottom: 11.25pt;"><span lang="EN-GB" style="mso-fareast-language: EN-GB;">Cookies are small text files that
are sent to and stored on your computer, smartphone or other device for
accessing the Internet, whenever you visit a website. Cookies are useful
because they allow a website to recognise a user's device.<o:p></o:p></span></p>

<p class="MsoNormal" style="background: white; margin-bottom: 11.25pt;"><span lang="EN-GB" style="mso-fareast-language: EN-GB;">HSBC Group websites use cookies
for a variety of reasons, including determining preferences, letting users
navigate between pages efficiently, verifying users and carrying out other
essential security checks. The cookie policy on each HSBC Group website will
explain how they use cookies.<o:p></o:p></span></p>

<p class="MsoNormal" style="background: white; margin-bottom: 11.25pt;"><span lang="EN-GB" style="mso-fareast-language: EN-GB;">Some of the functions that
cookies perform can also be achieved using similar technologies. This policy
refers to 'cookies' throughout; <span class="GramE">however</span> it also covers
these alternate mechanisms.<o:p></o:p></span></p>

<p class="MsoNormal" style="background: white; margin-bottom: 11.25pt;"><span lang="EN-GB" style="mso-fareast-language: EN-GB;">More information about cookies
can be found on </span><span lang="EN-GB"><a title="Learn more about cookies" href="http://www.allaboutcookies.org/"><span style="
color: windowtext; mso-fareast-language: EN-GB;">allaboutcookies.org</span></a></span><span lang="EN-GB" style="mso-fareast-language: EN-GB;">.<o:p></o:p></span></p>

<p class="MsoNormal" style="line-height: 14.4pt; margin-bottom: 7.15pt;"><span lang="EN" style='
color: rgb(2, 2, 2); font-family: "Times New Roman",serif; font-size: 13.5pt; mso-fareast-language: EN-GB; mso-ansi-language: EN;'>How cookies make our website
safer and easier to use<o:p></o:p></span></p>

<p class="MsoNormal" style="background: white; margin-bottom: 11.25pt;"><span lang="EN-GB" style="mso-fareast-language: EN-GB;">Cookies are used for a variety of
reasons, such as to improve website functionality and performance and to
safeguard your privacy when you're browsing our website.<o:p></o:p></span></p>

<p class="MsoNormal" style="background: white; margin-bottom: 11.25pt;"><span lang="EN-GB" style="mso-fareast-language: EN-GB;">We use 'analytics' cookies to
help us make our website better for those who visit it regularly. They help us
work out what users like and don't like and how we can improve things for you.<o:p></o:p></span></p>

<p class="MsoNormal" style="background: white; margin-bottom: 11.25pt;"><span lang="EN-GB" style="mso-fareast-language: EN-GB;">When we include links to other
websites, please bear in mind they will have their own privacy and cookie
policies that will govern the use of any information you submit. We recommend
you read their policies as HSBC is not responsible or liable for their privacy practices.<o:p></o:p></span></p>

<p class="MsoNormal" style="line-height: 14.4pt; margin-bottom: 7.15pt;"><span lang="EN" style='
color: rgb(2, 2, 2); font-family: "Times New Roman",serif; font-size: 13.5pt; mso-fareast-language: EN-GB; mso-ansi-language: EN;'>How we work with trusted third
parties<o:p></o:p></span></p>

<p class="MsoNormal" style="background: white; margin-bottom: 11.25pt;"><span lang="EN-GB" style="mso-fareast-language: EN-GB;">Third parties are trusted
suppliers to HSBC. They include partners who provide important functionality to
our website, and partners who help us improve our website, such as <span class="SpellE">Webtrends</span>, who provide statistics about which pages are
visited most frequently.<o:p></o:p></span></p>

<p class="MsoNormal" style="background: white; margin-bottom: 11.25pt;"><span lang="EN-GB" style="mso-fareast-language: EN-GB;">We will not share any personal
information gathered through the cookies on this website with third parties.<o:p></o:p></span></p>

<p class="MsoNormal" style="line-height: 14.4pt; margin-bottom: 7.15pt;"><a name="faqs"></a><span lang="EN" style='
color: rgb(2, 2, 2); font-family: "Times New Roman",serif; font-size: 13.5pt; mso-fareast-language: EN-GB; mso-ansi-language: EN;'>More about your
privacy<o:p></o:p></span></p>

<p class="MsoNormal" style="background: white; margin-bottom: 11.25pt;"><span lang="EN-GB" style="mso-fareast-language: EN-GB;">To find out more about how HSBC
protects your privacy when using our website, please read our </span><span lang="EN-GB"><a href="https://www.hsbc.lu/en-gb/terms-conditions"><span style="
color: windowtext; mso-fareast-language: EN-GB;">terms and conditions</span></a></span><span lang="EN-GB" style="
mso-fareast-language: EN-GB;"> and </span><span lang="EN-GB"><a href="https://www.hsbc.lu/en-gb/privacy-statement"><span style="
color: windowtext; mso-fareast-language: EN-GB;">privacy statement</span></a></span><span lang="EN-GB" style="
mso-fareast-language: EN-GB;">.<o:p></o:p></span></p>

<p class="MsoNormal" style="line-height: 107%; margin-bottom: 8pt;"><span lang="EN-US" style='
color: black; line-height: 107%; font-family: "Arial",sans-serif; font-size: 10pt; mso-fareast-language: EN-GB; mso-ansi-language: EN-US;'><o:p>&nbsp;</o:p></span></p>

<p class="MsoNormal"><span lang="EN-GB"><o:p>&nbsp;</o:p></span></p>

</div>


<!--End-->
</div>

</body>

</html>
`;
