import { tc2 } from "./T&C_LUX2";

export default `<html><head>
<meta http-equiv="Content-Type" content="text/html; charset=gb2312">
<meta name="Generator" content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:等线;
	panose-1:2 1 6 0 3 1 1 1 1 1;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:"Univers HSBCPB Light Con";}
@font-face
	{font-family:"Univers HSBCPB Light";}
@font-face
	{font-family:"Segoe UI";
	panose-1:2 11 5 2 4 2 4 2 2 3;}
@font-face
	{font-family:"Arial Narrow";
	panose-1:2 11 6 6 2 2 2 3 2 4;}
@font-face
	{font-family:"\@等线";
	panose-1:2 1 6 0 3 1 1 1 1 1;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin:0cm;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
h1
	{mso-style-link:"Heading 1 Char";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:6.5pt;
	margin-bottom:.0001pt;
	text-indent:-8.6pt;
	font-size:10.0pt;
	font-family:"Univers HSBCPB Light Con",sans-serif;
	font-weight:normal;}
p.MsoCommentText, li.MsoCommentText, div.MsoCommentText
	{mso-style-link:"Comment Text Char";
	margin:0cm;
	margin-bottom:.0001pt;
	font-size:10.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoHeader, li.MsoHeader, div.MsoHeader
	{mso-style-link:"Header Char";
	margin:0cm;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoFooter, li.MsoFooter, div.MsoFooter
	{mso-style-link:"Footer Char";
	margin:0cm;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoBodyText, li.MsoBodyText, div.MsoBodyText
	{mso-style-link:"Body Text Char";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:123.4pt;
	margin-bottom:.0001pt;
	font-size:8.0pt;
	font-family:"Univers HSBCPB Light",sans-serif;}
a:link, span.MsoHyperlink
	{color:#0563C1;
	text-decoration:underline;}
a:visited, span.MsoHyperlinkFollowed
	{color:#954F72;
	text-decoration:underline;}
p.MsoCommentSubject, li.MsoCommentSubject, div.MsoCommentSubject
	{mso-style-link:"Comment Subject Char";
	margin:0cm;
	margin-bottom:.0001pt;
	font-size:10.0pt;
	font-family:"Calibri",sans-serif;
	font-weight:bold;}
p.MsoAcetate, li.MsoAcetate, div.MsoAcetate
	{mso-style-link:"Balloon Text Char";
	margin:0cm;
	margin-bottom:.0001pt;
	font-size:9.0pt;
	font-family:"Segoe UI",sans-serif;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:36.0pt;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:36.0pt;
	margin-bottom:.0001pt;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:36.0pt;
	margin-bottom:.0001pt;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:36.0pt;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
span.Heading1Char
	{mso-style-name:"Heading 1 Char";
	mso-style-link:"Heading 1";
	font-family:"Univers HSBCPB Light Con",sans-serif;}
span.BodyTextChar
	{mso-style-name:"Body Text Char";
	mso-style-link:"Body Text";
	font-family:"Univers HSBCPB Light",sans-serif;}
span.CommentTextChar
	{mso-style-name:"Comment Text Char";
	mso-style-link:"Comment Text";}
span.BalloonTextChar
	{mso-style-name:"Balloon Text Char";
	mso-style-link:"Balloon Text";
	font-family:"Segoe UI",sans-serif;}
span.HeaderChar
	{mso-style-name:"Header Char";
	mso-style-link:Header;}
span.FooterChar
	{mso-style-name:"Footer Char";
	mso-style-link:Footer;}
span.CommentSubjectChar
	{mso-style-name:"Comment Subject Char";
	mso-style-link:"Comment Subject";
	font-weight:bold;}
.MsoChpDefault
	{font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
.MsoPapDefault
	{margin-bottom:8.0pt;
	line-height:107%;}
 /* Page Definitions */
 @page WordSection1
	{size:595.3pt 841.9pt;
	margin:72.0pt 72.0pt 72.0pt 72.0pt;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}
-->
</style>

</head>

<body lang="ZH-CN" link="#0563C1" vlink="#954F72">

<div class="WordSection1">

<p class="MsoNormal" align="center" style="margin-top:4.7pt;text-align:center"><b><span lang="FR" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">Conditions
générales des Services digitaux</span></b></p>

<p class="MsoNormal" style="text-justify:inter-ideograph"><span lang="FR" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">Les
conditions générales suivantes, et leurs modifications ultérieures, régiront
toutes les relations entre HSBC Private Bank (Luxembourg) S.A. (la «&nbsp;<b>Banque</b>&nbsp;»)
et le Client et/ou un ou plusieurs agents ou représentants (chacun, y compris
le Client, étant dénommé «&nbsp;<b>l’Utilisateur</b>&nbsp;», tel que défini à
l’article 2.3 ci-dessous) en relation avec les Services Digitaux (tels que
définis ci-dessous).</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">Les
présentes conditions générales doivent être lues attentivement par
l’Utilisateur avant de demander l’accès et/ou l’utilisation du site internet
et/ou de l’application des Services Digitaux de la banque privée, y compris les
fonctionnalités auxquelles l’Utilisateur peut accéder via le site internet
et/ou l’application. Ledit site internet (accessible via l’URL&nbsp;: </span><span lang="FR"><a href="https://www.privatebanking.hsbc.lu/login/"><span style="
font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">https://www.privatebanking.hsbc.lu/login/</span></a></span><span class="
MsoHyperlink"><span lang="FR" style="font-size:10.0pt;line-height:110%;
font-family:&quot;Arial&quot;,sans-serif;color:windowtext;text-decoration:none">),</span></span><span class="MsoHyperlink"><span lang="FR" style="font-size:10.0pt;line-height:110%;
font-family:&quot;Arial&quot;,sans-serif;text-decoration:none"> </span></span><span lang="FR">l’application</span><span lang="FR" style="font-size:10.0pt;line-height:
110%;font-family:&quot;Arial&quot;,sans-serif"> et les fonctionnalités sous-jacentes
auxquelles l’Utilisateur peut accéder via le site internet et/ou l’application
Services Digitaux de la banque privée sont désignés les «&nbsp;<b>Services Digitaux</b>&nbsp;»).</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">En
utilisant les Services Digitaux, l’Utilisateur accepte les présentes conditions
générales et en cas d’utilisation des Services Digitaux au nom de tout autre
titulaire de compte (y compris, sans s’y limiter, tout autre individu,
association, société ou personne morale), le Client confirme qu’il a tous
pouvoirs pour lier cette personne aux présentes conditions générales. Pour
pouvoir utiliser les Services Digitaux, l’Utilisateur doit accepter les
présentes conditions générales.</span><span lang="FR"> </span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">Les
présentes conditions générales s’appliqueront aussi bien à l’accès qu’à
l’utilisation des Services Digitaux, de même que&nbsp;:</span></p>

<p class="MsoListParagraphCxSpFirst" style="margin-top:3.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:36.0pt;text-justify:inter-ideograph;
text-indent:-18.0pt;line-height:110%"><span lang="FR" style="font-size:10.0pt;
line-height:110%">-<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="FR" style="font-size:10.0pt;line-height:110%;
font-family:&quot;Arial&quot;,sans-serif">les conditions générales de la Banque&nbsp;; </span></p>

<p class="MsoListParagraphCxSpMiddle" style="margin-top:3.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:36.0pt;text-justify:inter-ideograph;
text-indent:-18.0pt;line-height:110%"><span lang="FR" style="font-size:10.0pt;
line-height:110%">-<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="FR" style="font-size:10.0pt;line-height:110%;
font-family:&quot;Arial&quot;,sans-serif">la politique de confidentialité de la
Banque&nbsp;;</span></p>

<p class="MsoListParagraphCxSpLast" style="margin-top:3.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:36.0pt;text-justify:inter-ideograph;
text-indent:-18.0pt;line-height:110%"><span lang="FR" style="font-size:10.0pt;
line-height:110%">-<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="FR" style="font-size:10.0pt;line-height:110%;
font-family:&quot;Arial&quot;,sans-serif">la politique relative aux cookies&nbsp;;</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">(désignés
ensemble les «&nbsp;<b>Conditions Générales</b>&nbsp;»). En cas de
contradiction entre les termes des présentes conditions générales des Services Digitaux
et les termes des Conditions Générales, les présentes conditions générales des
Services Digitaux prévaudront.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Types
de services disponibles</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">Les
Services Digitaux comprendront les services suivants&nbsp;:</span></p>

<p class="MsoListParagraphCxSpFirst" style="margin-top:3.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:36.0pt;text-justify:inter-ideograph;
text-indent:-18.0pt;line-height:110%"><span lang="FR" style="font-size:10.0pt;
line-height:110%;font-family:Symbol;color:red">¨<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="FR" style="font-size:10.0pt;line-height:110%;
font-family:&quot;Arial&quot;,sans-serif">obtention des soldes des comptes et des
informations consolidées sur le compte à la fermeture des bureaux le jour
ouvrable précédant&nbsp;;</span></p>

<p class="MsoListParagraphCxSpMiddle" style="margin-top:3.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:36.0pt;text-justify:inter-ideograph;
text-indent:-18.0pt;line-height:110%"><span lang="FR" style="font-size:10.0pt;
line-height:110%;font-family:Symbol;color:red">¨<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="FR" style="font-size:10.0pt;line-height:110%;
font-family:&quot;Arial&quot;,sans-serif">revue des informations sur les transactions des
trois derniers mois&nbsp;;</span></p>

<p class="MsoListParagraphCxSpLast" style="margin-top:3.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:36.0pt;text-justify:inter-ideograph;
text-indent:-18.0pt;line-height:110%"><span lang="FR" style="font-size:10.0pt;
line-height:110%;font-family:Symbol;color:red">¨<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="FR" style="font-size:10.0pt;line-height:110%;
font-family:&quot;Arial&quot;,sans-serif">pour les clients HSBC Prism Advisory, accès à
la Plateforme Insights &amp; Research (veuillez vous référer au complément
ci-dessous applicable à Insights &amp; Research).</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">La
Banque se réserve le droit de modifier ces services à tout moment et sans
préavis.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">2.
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Accès aux Services Digitaux</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">2.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Accès
technique</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">Pour
accéder aux Services Digitaux, l’Utilisateur devra s’assurer d’une connexion à internet
auprès d’un Fournisseur d’Accès Internet (ci-après le «&nbsp;<b>FAI</b>&nbsp;»)
de son choix. Il appartient à l’Utilisateur de se procurer les équipements
appropriés, notamment informatiques, de télécommunication et de sécurité pour
accéder aux Services Digitaux, et d’entretenir ces équipements.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">Tous
les coûts et dépenses d’équipement, de communication, de télécommunication et
autres dépenses nécessaires à la connexion et à l’utilisation des Services Digitaux,
ainsi que toute autorisation pertinente requise sont à la charge de
l’Utilisateur.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">Dans
le cas où il s’avérerait nécessaire de télécharger un logiciel de communication
sur son ordinateur, ou une application sur son dispositif de connexion,
l’Utilisateur s’engage à se conformer aux recommandations et directives de la
Banque.</span></p>

<span lang="FR" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif"><br clear="all" style="page-break-before:always">
</span>

<p class="MsoNormal" style="margin-bottom:8.0pt;line-height:107%"><span lang="FR" style="
font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">2.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Accès
et identifiant</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">L’Utilisateur
demande à la Banque de lui fournir les informations d’identification
utilisateur nécessaires (ci-après «&nbsp;<b>l’Identifiant</b>&nbsp;») et les
instructions pour s’inscrire aux Services Digitaux. </span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">Sauf
accord contraire, la Banque ne peut procéder à la transmission de l’Identifiant
que par courrier ou e-mail. </span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">2.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Utilisateurs</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">Le
Client autorise expressément la Banque à délivrer aux Utilisateurs présents ou
futurs du Client dûment habilités, <span style="color:black">sur n’importe lequel
de </span>ses comptes, l’Identifiant permettant leur accès aux Services Digitaux
sur leur demande expresse et écrite. Le terme Utilisateur comprend tous les
signataires autorisés, titulaires d’une procuration, d’un pouvoir
d’administration ou d’un droit de regard. </span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">Le
Client reconnaît que si l’un de ses comptes est un compte joint ou comporte des
signataires autorisés supplémentaires, chaque titulaire de compte, ainsi que le
signataire autorisé de chaque titulaire de compte, doit avoir son propre Identifiant.
</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">Seules
les personnes dont les coordonnées ont été fournies et qui ont été approuvées
par écrit par la Banque en tant qu’Utilisateurs peuvent utiliser les Services Digitaux.
L’utilisation des Services digitaux par tout Utilisateur vaudra acceptation par
cet <span style="color:black">Utilisateur des Conditions </span>Générales. </span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">2.4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Accès
aux Services Digitaux </span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">Tout
accès aux Services Digitaux par un Utilisateur grâce à un mot de passe ou un
identifiant appartenant à cet Utilisateur sera réputé avoir été effectué par
cet Utilisateur au nom et pour le compte du Client.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">2.5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Refus
d’accès</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">L’Utilisateur
peut se voir refuser l’accès aux Services Digitaux s’il saisit un mot de passe
trois fois de manière incorrecte. </span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">2.6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Utilisation
abusive des Services Digitaux par une personne habilitée et révocation de
l’accès aux Services Digitaux</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">Tous
les titulaires de compte seront conjointement et solidairement responsables de
toute utilisation, utilisation abusive ou utilisation non autorisée des
Services Digitaux par tout titulaire de compte ou mandataire ou titulaire d’un
droit de regard, et le Client accepte d’être lié par tout message traité via
les Services Digitaux, que l’action ou la personne réalisant l’action ait été
ou non dûment autorisée.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">Il
est expressément convenu que la révocation du droit de signature d’un titulaire
de compte ou d’un mandataire ou la révocation d’un droit de regard ne rend pas
automatiquement invalide l’identifiant ou le mot de passe. Le décès ou la perte
de la capacité d’agir du titulaire du compte ou du titulaire d’une procuration
ou d’un droit de regard n’entraîne pas non plus automatiquement l’invalidation
des identifiants ou des mots de passe. </span></p>

<p class="MsoNormal" style="text-justify:inter-ideograph"><span lang="FR" style="
font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;color:#020202">3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Sécurité
et confidentialité de l’identifiant</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">3.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Sécurité</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">Il
existe des risques associés à l’utilisation des services fournis via internet,
y compris, mais sans s’y limiter, l’accès non autorisé, les pannes de système,
les retards et les interruptions des services de télécommunication et d’internet.
L’accès aux Services Digitaux vise à fournir un niveau de sécurité
commercialement raisonnable. Cependant, la confidentialité des communications
transmises par internet n’est pas absolue et ne peut être garantie. </span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">L’Utilisateur
utilisera les Services Digitaux conformément aux exigences techniques, aux
instructions de sécurité et à toutes autres instructions qui pourraient être
fournies par la Banque ultérieurement. Ces instructions seront transmises par
les moyens de communication convenus.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">L’Utilisateur
doit prendre toutes les mesures nécessaires pour s’assurer que les
caractéristiques techniques de son ordinateur personnel, de ses logiciels et de
sa connexion internet lui permettent d’accéder aux Services Digitaux de la
Banque de manière sécurisée.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">L’Utilisateur
est entièrement responsable du bon fonctionnement de ses propres équipements
informatiques, modem et téléphone ou accès internet. L’Utilisateur doit
s’assurer que ces appareils ne présentent pas de problèmes ou de virus
apparents et offrent une sécurité suffisante pour éviter qu’un tiers n’accède
aux données relatives aux Services Digitaux. L’Utilisateur s’efforcera de
maintenir cette sécurité. L’Utilisateur doit en outre s’assurer qu’il n’y a
aucun risque que des programmes ou virus hostiles envahissent et perturbent les
Services Digitaux. En particulier, l’Utilisateur veillera à ce que la sécurité
de son ordinateur personnel soit suffisante et mettra régulièrement à jour les
logiciels antivirus et anti-espion ainsi que son pare-feu personnel.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">L’Utilisateur
supportera tous les risques techniques tels que la perturbation du transport
d’énergie électrique, l’indisponibilité des lignes de communication, le mauvais
fonctionnement ou la surcharge des systèmes ou des réseaux.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">La
Banque ne saurait être tenue pour responsable des risques créés par l’accès à internet
et par la transmission de données depuis ou vers l’Utilisateur, notamment en
cas de conflit entre l’Utilisateur et le FAI portant sur des données
personnelles et/ou confidentielles de l’Utilisateur.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">L’utilisateur
est responsable des performances et de la protection de tout navigateur utilisé
dans le cadre des Services Digitaux, y compris l’adoption rapide de tous les
correctifs de sécurité et autres mesures de sécurité émis ou recommandés
ultérieurement par les fournisseurs de ces navigateurs.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">3.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Confidentialité
de l’identifiant</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">Les
détails de l’identifiant ne seront en aucun cas divulgués ou partagés avec un
tiers. Pour la protection de chaque Utilisateur, l’Utilisateur doit mémoriser
son mot de passe et ne doit conserver aucune trace de son mot de passe ou de
tout autre détail d’identifiant sur ou près de l’appareil utilisé pour accéder
aux Services Digitaux. </span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">L’Utilisateur
doit se déconnecter lorsqu’il cesse d’utiliser les Services Digitaux afin
d’empêcher tout accès non autorisé à ceux-ci. L’Utilisateur doit informer
immédiatement la Banque par écrit s’il estime qu’un tiers a pu voir ou avoir eu
accès aux données de l’Identifiant. L’Utilisateur s’engage à accepter tous les
risques d’accès non autorisé, non directement causés par une faute lourde de la
Banque. Si l’Utilisateur fournit des informations inexactes à la Banque ou omet
de mettre à jour les informations fournies à la Banque, la Banque a le droit de
suspendre ou de résilier son accès aux Services Digitaux.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">Il
est fortement recommandé à l’Utilisateur de modifier régulièrement son mot de
passe. Lorsque l’Utilisateur modifie ses mots de passe, il veille à ce que
ceux-ci ne soient pas constitués de combinaisons facilement identifiables
(telles que son identifiant, nom, prénom, date de naissance ou ceux d’un proche
(conjoint, enfant, etc.)) et plus généralement un mot ou une combinaison de
mots du dictionnaire, un mot épelé à l’envers, un mot suivi d’un chiffre ou
d’une année, un mot de passe utilisé à d’autres fins (y compris pour une
messagerie personnelle, etc.). L’Utilisateur devra notamment choisir d’utiliser
un mot de passe de longueur suffisante et composé, dans la mesure du possible,
d’une combinaison de lettres, de chiffres et de signes de ponctuation ou de
caractères spéciaux, ainsi que de lettres majuscules et minuscules. La Banque
peut, à sa discrétion, imposer une date d’expiration des mots de passe au-delà
de laquelle l’Utilisateur ne pourra plus accéder aux Services Digitaux sans une
modification préalable de son ou ses mot(s) de passe.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">L’Utilisateur
confirme qu’il comprend le fonctionnement des Services Digitaux tel que décrit
dans les présentes conditions générales et, le cas échéant, dans tout autre
document pertinent porté à la connaissance de l’Utilisateur dans ce cadre.
L’Utilisateur peut contacter son chargé de clientèle pour toute question
relative aux instructions et conditions d’utilisation des Services Digitaux. </span></p>

<p class="MsoNormal" style="margin-bottom:7.15pt;text-justify:
inter-ideograph;line-height:14.4pt"><span lang="FR" style="font-size:10.0pt;
font-family:&quot;Arial&quot;,sans-serif;color:#020202">4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Incident
et suspension du service</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">En
cas d’incident, tel que la perte ou le vol de l’Identifiant, la divulgation à
un tiers (même involontaire ou simplement suspecté) de l’un des identifiants,
le détournement ou toute autre utilisation non autorisée (réelle ou suspectée)
des Services Digitaux par un tiers (ci-après l’«&nbsp;<b>Incident</b>&nbsp;»),
l’Utilisateur en informera immédiatement la Banque par écrit (courrier ou
e-mail).</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">La
Banque se réserve le droit de bloquer les Services Digitaux&nbsp;:</span></p>

<p class="MsoListParagraphCxSpFirst" style="margin-top:3.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:36.0pt;text-justify:inter-ideograph;
text-indent:-18.0pt;line-height:110%"><span lang="FR" style="font-size:10.0pt;
line-height:110%;font-family:Symbol;color:red">¨<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="FR" style="font-size:10.0pt;line-height:110%;
font-family:&quot;Arial&quot;,sans-serif">lorsque la sécurité des Services Digitaux est
compromise, par exemple en raison d’un problème ou d’une défaillance technique
des Services Digitaux eux-mêmes ou en raison d’attaques de piratage&nbsp;;</span></p>

<p class="MsoListParagraphCxSpMiddle" style="margin-top:3.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:36.0pt;text-justify:inter-ideograph;
text-indent:-18.0pt;line-height:110%"><span lang="FR" style="font-size:10.0pt;
line-height:110%;font-family:Symbol;color:red">¨<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="FR" style="font-size:10.0pt;line-height:110%;
font-family:&quot;Arial&quot;,sans-serif">lorsque la Banque a des raisons de suspecter
(par exemple lorsqu’elle a identifié une activité suspecte) ou a reçu une
notification qu’un Incident s’est produit&nbsp;;</span></p>

<p class="MsoListParagraphCxSpLast" style="margin-top:3.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:36.0pt;text-justify:inter-ideograph;
text-indent:-18.0pt;line-height:110%"><span lang="FR" style="font-size:10.0pt;
line-height:110%;font-family:Symbol;color:red">¨<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="FR" style="font-size:10.0pt;line-height:110%;
font-family:&quot;Arial&quot;,sans-serif">lorsque la Banque est obligée par la loi de
procéder à un tel blocage.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">Si
l’un des cas susmentionnés se produit, la Banque en informera l’Utilisateur,
dans la mesure du possible, avant le blocage des Services Digitaux conformément
aux Conditions Générales de la Banque, à moins que la fourniture de telles
informations ne soit légalement interdite.</span></p>

<p class="MsoNormal" style="margin-bottom:7.15pt;text-justify:
inter-ideograph;line-height:14.4pt"><span lang="FR" style="font-size:10.0pt;
font-family:&quot;Arial&quot;,sans-serif;color:#020202">5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Déclarations
officielles</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">La
Banque veillera, dans la mesure du raisonnable, à ce que toute information
fournie par le biais des Services Digitaux reflète fidèlement les informations
contenues dans ses systèmes informatiques. Toute information fournie sur les
Services Digitaux n’est toutefois qu’indicative.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">L’Utilisateur
accepte donc expressément que seuls les relevés périodiques émis par la Banque
constitueront le registre ou le relevé officiel du ou des comptes concernés.</span></p>

<p class="MsoNormal" style="margin-bottom:7.15pt;text-justify:
inter-ideograph;line-height:14.4pt"><span lang="FR" style="font-size:10.0pt;
font-family:&quot;Arial&quot;,sans-serif;color:#020202">6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Disponibilité
du système et limites de responsabilité de la Banque</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">L’Utilisateur
reconnaît que les temps de réponse peuvent varier en fonction des conditions du
marché, des performances du système, de l’emplacement du dispositif d’accès ou
d’autres facteurs, et que l’accès aux Services Digitaux peut être limité ou
indisponible pendant les périodes de pic de demande ou d’activité, de
volatilité du marché, de mises à niveau du système, de maintenance ou pour
toute autre raison que ce soit.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">L’Utilisateur
reconnaît (i) qu’une interruption ou un dysfonctionnement des communications,
une défaillance du logiciel ou de l’équipement, ou un dysfonctionnement de son
système informatique ou de celui de la Banque pour quelque raison que ce soit,
peut faire que la Banque ne reçoive pas ses instructions ou que lui-même ne
reçoive pas les communications de la Banque, et (ii) que la Banque n’assumera
aucune responsabilité en cas d’interruption ou de dysfonctionnement. La Banque
n’est responsable dans ses relations avec l’Utilisateur qu’en cas de faute
lourde de sa part.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">La
Banque ne pourra être tenue responsable des dommages ou conséquences négatives
pouvant survenir en relation avec la connexion internet de l’Utilisateur, dont
l’Utilisateur supportera le risque exclusif.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">L’Utilisateur
s’engage à respecter les prescriptions légales et réglementaires en vigueur
dans le pays à partir duquel s’effectue l’accès aux Services Digitaux via internet
et renonce expressément et définitivement à engager la responsabilité de la
Banque au titre de tout préjudice qu’il pourrait subir à ce titre. Plus
particulièrement, la Banque ne saurait être tenue pour responsable et ne
saurait être mise en cause en cas d’accès d’un tiers aux comptes du Client ou
d’obtention d’informations concernant les relations du Client avec la Banque
par le biais des Services Digitaux.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">Sauf
dans la mesure où la Banque est expressément déclarée responsable en vertu des
présentes conditions, le Client accepte, individuellement et solidairement,
d’indemniser la Banque, ses administrateurs, dirigeants, cadres, employés,
prestataires de services et agents de tout préjudice résultant de toutes
actions, pertes, responsabilités, réclamations, demandes, dommages, jugements,
coûts et dépenses (y compris les frais juridiques) (ci-après la «&nbsp;<b>Réclamation</b>&nbsp;»)
lié directement ou indirectement aux Services Digitaux, aux services connexes,
et à tout acte ou manquement de l’un d’entre eux en relation avec ceux-ci.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">Sans
limiter de quelque manière que ce soit la généralité de la disposition
précédente, sauf disposition contraire expresse dans les présentes conditions
générales des Services Digitaux ou lorsque la loi l’exige, ni la Banque, ni ses
administrateurs, dirigeants, employés, fournisseurs de services ou agents ne
seront responsables de toute Réclamation résultant en tout ou en partie d’un
acte ou d’un défaut d’action de l’équipement ou du logiciel de la Banque, de
l’Utilisateur ou d’un tiers, de tout fournisseur de navigateur Internet, de
tout FAI ou d’une société affiliée, d’un agent ou d’un contractuel de l’un
d’eux.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">En
aucun cas, la Banque ou ses administrateurs, dirigeants, employés, fournisseurs
de services ou agents ne seront responsables de tout dommage direct ou
indirect, spécial ou consécutif, économique ou autre à quiconque découlant
directement ou indirectement des Services Digitaux, des services connexes ou de
tout acte ou défaut d’acte de quiconque en relation avec ceux-ci.</span></p>

<p class="MsoNormal" style="margin-bottom:7.15pt;text-justify:
inter-ideograph;line-height:14.4pt"><span lang="FR" style="font-size:10.0pt;
font-family:&quot;Arial&quot;,sans-serif;color:#020202">7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Surveillance
et enregistrement</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">L’Utilisateur
autorise expressément la Banque à enregistrer, stocker et archiver les
communications par e-mail. L’Utilisateur reconnaît et accepte en outre que la
Banque stocke et archive les connexions aux Services Digitaux effectuées à
l’aide de l’Identifiant ainsi que l’heure d’accès de l’Utilisateur aux Services
Digitaux.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">L’Utilisateur
accepte notamment que l’enregistrement des identifiants et des heures d’accès
conservé par la Banque constitue la preuve de l’existence, du contenu et de la
date et de l’heure exactes d’un tel accès ou connexion.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">L’Utilisateur
traitera les messages ou informations échangés ou reçus via les Services Digitaux
comme une correspondance privée.</span></p>

<p class="MsoNormal" style="margin-bottom:7.15pt;text-justify:
inter-ideograph;line-height:14.4pt"><span lang="FR" style="font-size:10.0pt;
font-family:&quot;Arial&quot;,sans-serif;color:#020202">8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Lieu
de communication</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">Tout
accès ou connexion aux Services Digitaux par l’Utilisateur et toutes les
communications de l’Utilisateur avec la Banque par le biais des Services Digitaux
sont réputés avoir été effectués directement dans les bureaux de la Banque à la
date et à l’heure indiquées sur les archives internet de la Banque.</span></p>

<p class="MsoNormal" style="margin-bottom:7.15pt;text-justify:
inter-ideograph;line-height:14.4pt"><span lang="FR" style="font-size:10.0pt;
font-family:&quot;Arial&quot;,sans-serif;color:#020202">9.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Protection
des droits de propriété intellectuelle</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">Le
copyright concernant les Services Digitaux appartient à la Banque, à ses
filiales au sein du groupe HSBC ou à ses prestataires. Toute représentation ou
reproduction de tout ou partie du site, marques, logos ou éléments figurant sur
le site, sous quelque forme que ce soit, est interdite, sauf autorisation
écrite préalable de la Banque. Les logiciels, programmes et applications
fournis par la Banque sont la propriété de la Banque ou de ses concédants de
licence.</span></p>

<p class="MsoNormal" style="margin-bottom:7.15pt;text-justify:
inter-ideograph;line-height:14.4pt"><span lang="FR" style="font-size:10.0pt;
font-family:&quot;Arial&quot;,sans-serif;color:#020202">10.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Modification
des présentes conditions générales</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">La
Banque peut modifier à tout moment les présentes conditions générales des
Services Digitaux pour tenir compte notamment de l’évolution de la législation
ou de la réglementation, des usages locaux, de la situation du marché ou de la
politique de la Banque.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">La
Banque se réserve le droit, à tout moment, d’informer le Client, par tout moyen,
des modifications apportées aux présentes conditions générales des Services Digitaux.
Ces modifications seront réputées approuvées par le Client si celui-ci ne
formule aucune objection par écrit à la Banque dans les 30 jours suivant
l’envoi de la notification.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">Si
le Client notifie son objection conformément au présent paragraphe, le Client
est en droit de résilier les présentes conditions générales des Services Digitaux.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">11.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Résiliation</span></p>

<p class="MsoNormal" style="margin-bottom:8.0pt;text-justify:
inter-ideograph;line-height:107%"><span lang="FR" style="font-size:10.0pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">La Banque peut résilier
l’accès de tout Utilisateur aux Services Digitaux sans préavis si elle
détermine à sa discrétion que l’une des dispositions des Conditions Générales a
été enfreinte ou si les Services Digitaux n’ont pas été utilisés pendant une
période de 3 (trois) mois consécutifs. Pour toute autre raison, la Banque
pourra résilier l’accès de tout Utilisateur aux Services Digitaux moyennant un
préavis de 30 jours. </span></p>

<p class="MsoNormal" style="margin-bottom:8.0pt;line-height:107%"><span lang="FR" style="
font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span></p>

<p class="MsoNormal" align="center" style="margin-top:3.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:0cm;text-align:center;line-height:110%"><b><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif;
color:black">Complément aux Services Digitaux&nbsp;: HSBC <i>Insights and
Research</i> (I&amp;R)</span></b></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Portée
du complément</span></p>

<p class="MsoNormal" style="margin-top:2.55pt;margin-right:7.2pt;margin-bottom:
0cm;margin-left:0cm;margin-bottom:.0001pt;text-justify:inter-ideograph;
line-height:115%;vertical-align:baseline"><span lang="FR" style="font-size:10.0pt;
line-height:115%;font-family:&quot;Arial&quot;,sans-serif">Les présentes conditions
complémentaires régissent l’accès et l’utilisation de HSBC Private Bank
Insights and Research (ci-après la «&nbsp;<b>Plateforme I&amp;R</b>&nbsp;»),
une plateforme digitale qui permet aux Utilisateurs d’accéder aux rapports et
publications économiques et financiers de HSBC et/ou de tiers (ci-après les
«&nbsp;<b>Publications</b> »), y compris, mais sans s’y limiter, (i) les
commentaires macroéconomiques de Global Private Bank sur les marchés
financiers, (ii) les rapports de HSBC Global Research (ci-après «&nbsp;<b>HSBC
Research</b>&nbsp;») publiés par Global Research, une division de HSBC Global
Markets (ci-après «&nbsp;<b>HSBC Global Research</b>&nbsp;») et/ou (iii) des
publications de tiers hors HSBC. Les Publications peuvent traiter des marchés
financiers et des produits d’investissement dans plusieurs catégories d’actifs
et peuvent inclure des documents de recherche financière couvrant des
instruments financiers et des émetteurs spécifiques.</span></p>

<p class="MsoNormal" style="margin-top:2.55pt;margin-right:7.2pt;margin-bottom:
0cm;margin-left:0cm;margin-bottom:.0001pt;text-justify:inter-ideograph;
line-height:115%;vertical-align:baseline"><span lang="FR" style="font-size:10.0pt;
line-height:115%;font-family:&quot;Arial&quot;,sans-serif">La Plateforme I&amp;R est mise
à disposition par la Banque (i) aux Clients ayant conclu un contrat Prism
Advisory avec la Banque et (ii) aux Utilisateurs.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">Les
types de Publications accessibles à chaque catégorie d’Utilisateur seront
déterminés par la Banque à sa discrétion. </span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Canaux
de distribution des Publications</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">2.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Plateforme
I&amp;R</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">La
Banque fournira à chaque Utilisateur un accès en ligne direct aux Publications par
la Plateforme I&amp;R via les canaux des Services Digitaux de la Banque.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">2.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Alertes
e-mail</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">En
plus de ce qui précède, la Banque offrira à chaque Utilisateur la possibilité
de recevoir par e-mail certaines alertes liées aux Publications, telles que
certaines de leurs mises à jour. Parmi les services rendus dans le cadre du
présent contrat, chaque Utilisateur peut choisir de recevoir des alertes par
e-mail générées par la Plateforme I&amp;R (ci-après les «&nbsp;<b>Alertes E-mail</b>&nbsp;»).
Chaque Utilisateur sera invité à divulguer et à communiquer son adresse e-mail
valide si et lors de la demande d’Alertes e-mail.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">Accéder
à la plateforme I&amp;R&nbsp;: Afin d’accéder à la Plateforme I&amp;R et aux
Publications, chaque Utilisateur se verra fournir par la Banque des
informations d’identification (nom d’utilisateur et mot de passe) pour accéder
aux canaux des Services Digitaux. </span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">Alertes
E-mail&nbsp;: Si un Utilisateur choisit de recevoir les Alertes E-mail, il lui
sera demandé de communiquer à la Banque son adresse e-mail. L’Utilisateur
s’engage à informer immédiatement la Banque de tout changement de son adresse
e-mail. </span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">Dans
ce contexte, chaque Utilisateur doit être conscient que les communications
envoyées et l’utilisation de l’adresse e-mail de chaque Utilisateur ne sont pas
sécurisées. Dès lors, la transmission de toute documentation, telle que les
Publications ou les Alertes E-mail, peut avoir pour effet de révéler à des
tiers l’existence de la relation du Client avec la Banque. Dans ce cadre, la
Banque ne peut garantir que la confidentialité de la relation du Client avec
celle-ci pourra être préservée et décline expressément toute responsabilité à
cet égard.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Suivi
de l’utilisation par la Banque</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">L’accès
et l’utilisation des Publications par chaque Utilisateur, y compris les Alertes
E-mail, peuvent être surveillés par la Banque et ses sous-traitants, et les
informations qui en résultent peuvent être collectées et utilisées par la
Banque pour des considérations commerciales internes (voir ci-dessous) ou pour
tout exigence réglementaire applicable. En utilisant la Plateforme I&amp;R,
chaque Utilisateur consent à la surveillance et à la collecte d’informations
concernant son accès et son utilisation des Publications.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">Les
considérations commerciales internes pour le contrôle de l’accès aux
Publications et de leur utilisation par la Banque comprennent, sans s’y
limiter&nbsp;: (i) vérifier si chaque Utilisateur accède aux Publications, y
compris les Alertes E-mail, et plus particulièrement celles liées aux positions
du portefeuille du Client et aux modèles de trading et (ii) s’assurer que la
Banque, dans toute la mesure du possible, fait les recommandations les plus
appropriées, le cas échéant, pour l’allocation/le trading de portefeuille à la
lumière des Publications, y compris les Alertes E-mail, auxquelles
l’Utilisateur a accès.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Langue
des publications</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">Les
Publications et Alertes E-mail sont fournies à chaque Utilisateur exclusivement
en langue anglaise. En utilisant la Plateforme I&amp;R, l’utilisateur confirme
qu’il possède un niveau de compétence suffisant en anglais pour comprendre
pleinement le contenu des Publications et des Alertes E-mail, ainsi que toute
clause de non-responsabilité ou avertissement de risque important qu’elles
peuvent contenir.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Connaissances,
expérience et documentation financières</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">Les
Publications auxquelles chaque Utilisateur accédera peuvent couvrir différentes
catégories de produits financiers, y compris, mais sans s’y limiter, le changement
climatique, l’allocation d’actifs, les matières premières, les convertibles, le
crédit de grande qualité, le crédit à haut rendement, la stratégie de crédit,
les devises, l’économie, l’indexation sur les actions, la stratégie actions,
les obligations, les titres structurés et adossés à des actifs.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">Les
Publications font référence à des données, concepts et ratios de marché,
économiques et financiers, ainsi qu’à d’autres informations ou concepts
techniques qui nécessitent que chaque Utilisateur possède un degré significatif
de connaissances, d’expérience et de compétences financières.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">En
utilisant la Plateforme I&amp;R, l’Utilisateur confirme (i) qu’il possède un
niveau suffisant de connaissances, d’expérience et de compétences financières
pour lire et comprendre les Publications et leurs limites, en particulier
celles mentionnées aux sections 9.1 et 9.2 ci-dessous, et (ii) qu’il exercera
un jugement indépendant dans l’évaluation des Publications.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">Si
un Utilisateur éprouve des difficultés à comprendre l’une des Publications ou
des Alertes E-mail, celui-ci doit contacter le chargé de clientèle ou le conseiller
en placement en charge du compte pour obtenir des éclaircissements supplémentaires.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">Le
Client confirme qu’il est capable de supporter le risque financier associé à la
négociation sur les marchés financiers concernés et les instruments financiers
couverts dans les Publications.</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Pas
de contact avec l’émetteur des Publications</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">Aucun
Utilisateur ne tentera de contacter directement l’émetteur de toute
Publication, y compris, mais sans s’y limiter, tout analyste de HSBC Global
Research ou tout autre tiers (y compris leurs représentants ou employés).</span></p>

<p class="MsoNormal" style="margin-top:3.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-justify:inter-ideograph;line-height:
110%"><span lang="FR" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif">7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
Accès restreint</span></p>

` + tc2;
