import React, { Component } from "react";
import FullScreenContainer from "components/common/FullScreenContainer";
import styled from "styled-components";
import { connect } from "react-redux";
import { languageActions } from "actions/languageAction";
import { injectIntl } from "react-intl";

const mapDispatchToProps = dispatch => {
    return {
        hideSelectOption: isHideLanguageSelect =>
            dispatch(languageActions.hideSelectOption(isHideLanguageSelect))
    };
};

class RestrictedAccess extends Component {
    constructor(props) {
        super(props);
        window.tealiumHub.PT020();
    }

    componentDidMount() {
        this.props.hideSelectOption(false);
    }

    render() {
        const content = "restrictedAccess.content";
        const title = "restrictedAccess.title";
        return (
            <RestrictedAccessContainer>
                <FullScreenContainer content={content} title={title} />
            </RestrictedAccessContainer>
        );
    }
}

const RestrictedAccessContainer = styled.div`
    width: 100%;
    @media (max-width: 414px) {
        .wrapper {
            margin-top: 60px;
        }
    }
    @media (max-width: 360px) {
        .wrapper {
            margin-top: 40px;
        }
    }
    @media (max-width: 320px) {
        .wrapper {
            margin-top: 16px;
        }
    }
`;

export default connect("", mapDispatchToProps)(injectIntl(RestrictedAccess));
