import { envConfig } from "env-config";

const defaultCfgLang =
    (envConfig &&
        envConfig.languageSlectList &&
        envConfig.languageSlectList[0].locale) ||
    "en-GB"; // en-HK etc.
const hasSelectedLang = localStorage.getItem("selectedLang"); // zh-sim-HK/zh-trd-HK/en-HK/undefined
const selectedLangCode = localStorage.getItem("selectedLangCode");
//Read from WV
const WVLangMapSelected = () => {
    if (!selectedLangCode) {
        return undefined;
    }
    switch (selectedLangCode) {
        case "zh-Hans":
            return "zh-sim-HK";
        case "en":
            return "en-HK";
        case "zh-Hant":
            return "zh-trd-HK";
        case "fr":
            return "fr-LU";
    }
    return undefined;
};
const selectedLang = WVLangMapSelected() || hasSelectedLang || defaultCfgLang;

function cacheLangCodeByLang(language) {
    let code = null;
    switch (language) {
        case "en-HK":
            code = "en";
            break;
        case "zh-sim-HK":
            code = "zh-Hans";
            break;
        case "zh-trd-HK":
            code = "zh-Hant";
            break;
        case "fr-LU":
        case "en-LU":
            code = "fr";
            break;
    }
    localStorage.setItem("selectedLangCode", code);
}

//Set to WV
if (hasSelectedLang && !selectedLangCode) {
    cacheLangCodeByLang(hasSelectedLang);
}

export const initialState = {
    selectedLang, // from cache > conf
    hideLanguageSelect: true,
    isShowContianer: "false",
    alreadyInitMLContainer:
        envConfig &&
        envConfig.languageSlectList &&
        envConfig.languageSlectList.length > 1
};

export function languageSession(state = initialState, action) {
    switch (action.type) {
        case "HIDE_LANGUAGE_SELECT_OPTION":
            return {
                ...state,
                hideLanguageSelect: action.isHideLanguageSelect
            };
        case "SHOW_LANGUAGE_CONTAINER":
            return {
                ...state,
                isShowContianer: action.isShowContianer
            };
        case "UPDATE_LANGUAGE_SESSION_SUCCESS":
            localStorage.setItem("selectedLang", action.data);
            cacheLangCodeByLang(action.data);
            return {
                ...state,
                selectedLang: action.data
            };
        default:
            return state;
    }
}
