import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { injectIntl } from "react-intl";
import { envConfig } from "env-config";
import whiteLogo from "assets/image/HSBC_Logo_White.svg";
import LanguageList from "components/common/LanguageList";
import LanguageDropDown from "components/common/LanguageDropDown";
import { languageActions } from "actions/languageAction";
import { color } from "../../util/color";

const mapStateToProps = state => {
    return {
        error: state.logon.error,
        selectedLang: state.languageSession.selectedLang,
        hideLanguageSelect: state.languageSession.hideLanguageSelect,
        languageSlectList: state.languageSession.languageSlectList,
        alreadyInitMLContainer: state.languageSession.alreadyInitMLContainer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        showMobileLanguageContainer: isShowContianer =>
            dispatch(
                languageActions.showMobileLanguageContainer(isShowContianer)
            ),
        updateLanguage: lang => dispatch(languageActions.updateLanguage(lang))
    };
};

class AppHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            counterEndTime: null,
            changeToMobile: false
        };
    }

    startCounter = () => {
        this.counterWarning.showDialog();
        this.setState({
            counterEndTime: new Date().getTime() + 60000
        });
        this.countdownTimerRef.startTimer();
    };

    onCountdownDone = () => {
        this.counterWarning.hideDialog();
    };

    componentDidMount = () => {
        this.changeToMobile();
        window.addEventListener("resize", this.changeToMobile);
    };
    changeToMobile = () => {
        if (window.innerWidth <= 768) {
            this.setState({
                changeToMobile: true
            });
        } else {
            this.setState({
                changeToMobile: false
            });
            this.props.showMobileLanguageContainer("false");
        }
    };
    render() {
        let showLanguageList = this.props.alreadyInitMLContainer;
        if (envConfig.realm === "LU" && !envConfig.MLforLUDisplayToggle) {
            showLanguageList = false;
            this.props.updateLanguage("en-GB");
        }

        const languageEle = this.state.changeToMobile ? (
            <LanguageDropDown
                selectedLang={this.props.selectedLang}
            ></LanguageDropDown>
        ) : (
            <LanguageList />
        );

        const isShowLanguage = !this.props.hideLanguageSelect && languageEle;

        return (
            <HeaderContainer
                currentUrlPathIsShowHeaderFooter={
                    this.props.currentUrlPathIsShowHeaderFooter
                }
                showLanguageList={showLanguageList}
            >
                <Title showLanguageList={showLanguageList}>
                    <Logo
                        showLanguageList={showLanguageList}
                        id="hsbcLogo"
                        alt="hsbc logo"
                    />
                    <p className="logo-title" data-testid="logo-text">
                        {this.props.intl.formatMessage({
                            id: "logo.title"
                        })}
                    </p>
                </Title>
                <div className="languageList">
                    {showLanguageList ? isShowLanguage : null}
                </div>
                <div className="opacityBox"></div>
            </HeaderContainer>
        );
    }
}

const HeaderContainer = styled.div.attrs({
    className: "container--header"
})`
    position: relative;
    width: 100%;
    height: 60px;
    background: #fff;
    color: #fff;
    display: flex;
    border-bottom: 1.5px solid #d7d8d6;
    justify-content: space-between;
    @media (max-width: 768px) {
        justify-content: center;
        padding-left: 0px;
    }
    .logo-title {
        color: ${color.black};
        font-weight: bold;
        font-size: 15px
        margin-left: 3px;
        @media (max-width: 768px) {
            display: none;
        }
    }
    #hsbcLogo {
        @media (max-width: 768px) {
            margin-left: 0px;
        }
    }
    .opacityBox {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #fff;
        opacity: 0.9;
    }
    .languageList {
        display: flex;
        position: absolute;
        top: 0;
        right: 2%;
        height: 100%;
        align-items: center;
        justify-content: center;
        z-index: 1;
    }
    .counterPopup {
        .contentmodal--content {
            padding-top: 30px;
            padding-bottom: 32px !important;

            .icon--circle-error-solid {
                font-size: 50px;
            }
        }
        .icon--delete {
            font-size: 36px !important;
        }
    }
`;

const Title = styled.div.attrs({
    className: "title"
})`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    z-index: 1;
    h1 {
        margin-left: -15px;
        margin-top: 25px;
        font-size: 11px;
        font-weight: 100;
    }
`;

const Logo = styled.img.attrs({
    className: "logo",
    src: whiteLogo,
    version: `${process.env.REACT_APP_BUILD_VERSION}-${process.env.REACT_APP_BUILD_REPO}`
})`
    width: 50px;
    margin-left: 30px;
    @media (max-width: 415px) {
        margin-left: 0;
    }
`;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(AppHeader));
