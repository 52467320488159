import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import { envConfig } from "env-config";
import { languageActions } from "actions/languageAction";

const mapStateToProps = state => {
    return {
        selectedLang: state.languageSession.selectedLang
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateLanguage: lang => dispatch(languageActions.updateLanguage(lang))
    };
};

class LanguageList extends Component {
    constructor(props) {
        super(props);
        this.state = { value: null };
    }

    selectLanguage = locale => {
        this.props.updateLanguage(locale);
    };

    render() {
        const languageList = envConfig.languageSlectList;
        return (
            <LanguageListContainer>
                <div
                    className="hsbc-ui attached tabular menu"
                    style={{ border: "none" }}
                >
                    {languageList.map((item, index) => {
                        const isSelected =
                            this.props.selectedLang === item.locale;
                        return (
                            <button
                                key={item.locale}
                                tabIndex={index + 1}
                                data-testid={"buttons" + index}
                                onClick={() => this.selectLanguage(item.locale)}
                                className={`${isSelected ? "active" : ""} item`}
                            >
                                {item.text}
                            </button>
                        );
                    })}
                </div>
            </LanguageListContainer>
        );
    }
}

const LanguageListContainer = styled.div`
    font-size: 13px;
    font-weight: bold;

    .hsbc-ui.tabular.menu .item {
        background: transparent;
    }
    button {
        padding: 0.625rem;
        min-width: 100px;
        background: #f3f3f3;
        border-bottom: none;
        color: #333333;
    }
    .middle {
        display: inline-block;
        margin: 0 10px;
        padding: 0 10px;
        border: 2px solid #455663;
        border-top: 0;
        border-bottom: 0;
    }
    .selected {
        text-decoration: underline;
    }
    button:not(:first-of-type):not(:last-of-type) {
        display: inline-block;
        margin: 0 10px;
        padding: 0 10px;
        //border: 2px solid #455663;
        border-top: 0;
        border-bottom: 0;
    }
`;

export default connect(mapStateToProps, mapDispatchToProps)(LanguageList);
