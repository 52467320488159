import TermsAndCondition from "./registrationConditions/T&C.js";
import PrivacyPolicy from "./registrationConditions/PP.js";
import PrivacyPolicyLUX from "./registrationConditions/PP_LUX.js";
import TermsAndConditionLUX from "./registrationConditions/T&C_LUX.js";
import TermsAndConditionCH from "./registrationConditions/T&C_CH.js";

import lang from "./lang.json";

const labelTitle = "Terms and Conditions";

export default {
    lang,
    registrationTerms: [
        {
            label: labelTitle,
            html: TermsAndCondition
        },
        {
            label: "Privacy Policy",
            html: PrivacyPolicy
        }
    ],
    registrationTermsLUX: [
        {
            label: labelTitle,
            html: TermsAndConditionLUX
        },
        {
            label: "Privacy Policy",
            html: PrivacyPolicyLUX
        }
    ],
    registrationTermsCH: [
        {
            label: labelTitle,
            html: TermsAndConditionCH
        }
    ]
};
