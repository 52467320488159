import { tc2 } from "./T&C2";
export default `
<html><head>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8" lang="EN-US">
<meta name="Generator" content="Microsoft Word 15 (filtered)">
<title>T&amp;C_en</title>
    <style>
		.level1{
		margin-left:1.5cm;
	    text-indent:-30pt;
		}
        .level1 .left{		
		    display: inline-block;
			width: 15px;
			text-align:right}
        .level1 .right{
		    margin-left:0.5cm;
			text-align:left}	
	
        .list{
		list-style: none;		 
		margin-left:0.4cm;
	    text-indent:-24pt;
		text-align:left;
		}

		.item .left{	
		    display: inline-block;	
			text-align:right;}
        .item .right{
		    margin-left:0.9cm;
			text-align:left}
			
        .list2{
		list-style: none;		 
		margin-left:1.5cm;
	    text-indent:-24pt;
		text-align:left;
		}
		
		.item2 .left{	
		    display: inline-block;			
			width: 15px;
			text-align:right}
        .item2 .right{	
		    margin-left:0.3cm;
			text-align:left}

        .list3{
		list-style: none;		 
		margin-left:0.4cm;
	    text-indent:-20pt;
		text-align:left;
		}

		.item3 .left{
		    width:15px;
			text-align:left;}
        .item3 .right{	
		    margin-left:0.2cm;	
			text-align:left}
        h2 {
            font-size: 16px;
            font-weight: 700;
        }
        
        h3 {
            font-size: 14px;
            font-weight: 700;
        }

    </style>
</head>
<body>  
      
      <br>
      <section>
        <p style="margin-left:0.8cm;">
          The terms of the services available on our Internet Banking platform are set out below. Please read them carefully before you proceed to the next page:
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">
                <b>A. </b>
              </span>
              <span class="right">
                <b>Terms and Conditions for Internet Banking Services</b>, which sets out terms and conditions for your access and use of the HSBC Global Private Banking internet banking platform;
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">
                <b>B. </b>
              </span>
              <span class="right">
                <b>Terms and Conditions for HSBC Private Banking Insights &amp; Research</b>, which sets out additional terms and conditions for HSBC Private Banking Insights &amp; Research which you may access and use through the HSBC Global Private Banking internet banking  platform;
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">
                <b>C. </b>
              </span>
              <span class="right">
                <b>Terms and Conditions for Push Notification Service</b>, which sets out additional terms and conditions for our provision of Push Notification Services to you in respect of products and services you can access or use through the HSBC Global Private Banking internet banking  platform; and
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">
                <b>D. </b>
              </span>
              <span class="right">
                <b>Terms and Conditions for eSignature Service</b>, which sets out additional terms and conditions application to any document executed using eSignature.
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">
                <b>E. </b>
              </span>
              <span class="right">
                <b>Terms and Conditions for the Suspicious Proxy Alert Model </b>
                which relates to alerts for potentially suspicious money transfers. 
              </span>
            </p>
          </li>
        </ol>
      </section>
      <section>
        <br>
        <div>
          <h2 align="center" style="font-size: 16px;font-weight: 700;">A.	Terms and Conditions for Internet Banking Services</h2>
        </div>
        <br>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">1</span>
          <span class="right">Definitions and Interpretation</span>
        </h3>
        <p class="level1">
          <span class="left">1.1</span>
          <span class="right">Unless otherwise defined here, expressions defined in our Standard Terms and Conditions (as found
            <a href="https://www.privatebanking.hsbc.com/content/dam/privatebanking/gpb/utilities/mobile/Standard%20Terms%20and%20Conditions%20-%20November%2022%20edition%20-%20English%20version.pdf">here</a>, the "<b>Standard Terms</b>") as may be amended from time to time shall have the same meaning when used in these Terms and Conditions for Internet Banking Services (the "<b>Internet Banking Terms</b>"). If there are any inconsistencies between these Internet Banking Terms and the Standard Terms, these Internet Banking Terms shall prevail.
          </span>
        </p>
        <p class="level1">
          <span class="left">1.2</span>
          <span class="right">
            In these Internet Banking Terms, the following capitalised terms shall have the following meanings unless the context requires otherwise:
          </span>
        </p>
        <ol class="list">
          <li class="item">
            <p>
              <span class="right">
                "<b>Authorized Internet User</b>" means such person(s) designated by (and may include) you with authority to use the Internet Banking Services in respect of your Account(s).
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                "<b>Information</b>" means financial, market or other information and data made available by us via any Internet Site, supplied by any Information Provider, including any such information contained in "Publications" as defined in Part B.
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                "<b>Information Provider</b>" means any person or entity, including us and any Group Office who supplies Information and any other person or entity who supplies any information to an Information Provider.
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                "<b>Internet Site</b>" means any Internet site or other platform established, operated and/or maintained by or on behalf of us for the provision of the Internet Banking Services and includes the Mobile Banking Application.
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                "<b>Internet Banking Services</b>" means the services described in Clause 2 (<i>Internet Banking Services</i>) below, as may be amended from time to time and includes the I&amp;R Services provided under Part B, Mobile Banking Application Services and eSignature provided under Part D.
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                "<b>Internet Banking Terms</b>" means these Terms and Conditions for Internet Banking Services.
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                "<b>Mobile Banking Application</b>" means the application made available by us and downloaded to your mobile device through which the Mobile Banking Application Services may be accessed.
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                "<b>Mobile Banking Application Services</b>" means services made available to you to enable you to access Internet Banking Services using the Mobile Banking Application, including the Push Notification Service as defined in Part C.
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                "<b>Password</b>" means the password used by the Authorized Internet User for the purposes of accessing the Internet Banking Services.
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                "<b>Reports</b>" means any reports compiled from Information in any form, medium or means, including any "Publications" as defined in Part B.
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                "<b>Security Code</b>" means any code provided by us and used for the purposes of accessing the Internet Banking Services.
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                "<b>Standard Terms</b>" has the meaning ascribed to it in Clause 1 (<i>Definitions and Interpretation</i>) of these Internet Banking Terms.
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                "<b>Us</b>", "<b>our</b>", "<b>we</b>" or words to that or a similar effect means the Bank.
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                "<b>User ID</b>" means the Authorized Internet User’s user identification code accepted by us for the purposes of accessing the Internet Banking Services.
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                "<b>You</b>", "<b>your</b>" or words to that or a similar effect means the Customer and, where the context permits, includes any person authorized by the Customer to give instructions or requests to us in connection with the use of the Internet Banking Services, including any Authorized Internet User.
              </span>
            </p>
          </li>
        </ol>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">2</span>
          <span class="right">Internet Banking Services</span>
        </h3>
        <p class="level1">
          <span class="left">2.1</span>
          <span class="right">We may make available through any Internet Site online services from time to time to enable you to give Communication to us for the purposes of operating the Customer Account(s), conducting banking, investment, financial and other transactions and dealings of various nature and obtaining services, products, Information, Reports, benefits and privileges from us as shall be made available from time to time.</span>
        </p>
        <p class="level1">
          <span class="left">2.2</span>
          <span class="right">
            We have the right to determine and vary from time to time the scope and type of the Internet Banking Services to be made available including, without limitation:
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">expanding, modifying or reducing the Internet Banking Services at any time;</span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">
                imposing and varying any restrictions on the use of the Internet Banking Services (or any part thereof) such as minimum and maximum daily limits with respect to the value of any transaction or dealing or type of transactions or dealings which you may conduct by using the Internet Banking Services; and
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">prescribing and changing the normal service hours during which the Internet Banking Services (or any part thereof) are available and any daily cut-off time for any type of Internet Banking Services, transactions or dealings. Any instruction of yours received after any applicable daily cut off time shall be deemed to be received on the next Business Day. We may specify Business Days and daily cut-off times by reference to the time of various markets operating in different time zones.
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">2.3</span>
          <span class="right">We may, in our discretion, suspend or terminate the Internet Banking Services generally or in relation to any Customer Account or group of Customer Accounts provided that in any such instance, we shall provide you with as much notice as possible if you are affected provided that in circumstances where it is reasonably necessary to protect our legitimate interests or where required by any Laws or in relation to Financial Crime Management Activity (including but not limited to fraud prevention) when suspension or termination may be made without prior notice. In the event your access to the Internet Banking Services is suspended, restricted or terminated, we may close any accounts, products or services that are only available through the Internet Banking Services, you will no longer receive digital statements, and you should delete our Mobile Banking Application from your device (if applicable).
          </span>
        </p>
        <p class="level1">
          <span class="left">2.4</span>
          <span class="right">We may require you to nominate or register specific Customer Account(s) for the purposes of the Internet Banking Services.
          </span>
        </p>
        <p class="level1">
          <span class="left">2.5</span>
          <span class="right">You may terminate the use of the Internet Banking Services and/or the authority of any Authorized Internet User at any time by giving to us prior written notice.
          </span>
        </p>
        <p class="level1">
          <span class="left">2.6</span>
          <span class="right">All provisions of these Internet Banking Terms which in order to give effect to their meaning need to survive the suspension or termination of the Internet Banking Services and/or your use of the Internet Banking Services shall remain in full force and effect after suspension or termination including, without limitation, Clauses 4 (<i>General Disclaimers</i>), 7 (<i>User ID and Password &amp; Security</i>), 9 (<i>Information and Information Providers</i>), 11 (<i>Liabilities of the Bank</i>) and 12 (<i>Liabilities of the Customer</i>). Notwithstanding such suspension or termination, you shall continue to be bound by these Internet Banking Terms to the extent that they relate to any of your obligations or liabilities which remain to be performed or discharged.
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">3</span>
          <span class="right">Governing Terms</span>
        </h3>
        <p class="level1">
          <span class="left"></span>
          <span class="right">Any transactions and dealings effected by using the Internet Banking Services are subject to these Internet Banking Terms, the Standard Terms and all other Account Documentation governing the relevant Customer Account(s), transactions, dealings, services, products, Information, Reports, benefits or privileges.
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">4</span>
          <span class="right">General Disclaimers</span>
        </h3>
        <p class="level1">
          <span class="left">4.1</span>
          <span class="right">All transactions that you may enter into through the Internet Banking Services (as made available from time to time) are conducted on an execution-only basis and based on your own judgment. We do not have any duty to assess or ensure suitability of the products or the transactions that you enter into through the Internet Banking Services. This clause 4.1 shall not apply where expressly excluded by the Bank in writing.
          </span>
        </p>
        <p class="level1">
          <span class="left">4.2</span>
          <span class="right">A solicitation or recommendation we may have made to or for you for which applicable Laws require a suitability assessment be made is valid only as at the time it was made, and we cannot ensure on-going suitability of such product. By executing a transaction for a product through use of Internet Banking Services, you understand and accept that if the circumstances relating to you, such a product or its issuer or market conditions change, the product may no longer be suitable for you and you trade using the Internet Banking Services at your own risk.
          </span>
        </p>
        <p class="level1">
          <span class="left">4.3</span>
          <span class="right">You are responsible for determining independently market prices and rates for trading purposes through your usual trading channels, verifying any Information and/or Report before relying or acting on it and seeking independent professional advice on legal, tax and other issues in connection with the use of the Internet Banking Services, the Information and the Reports and any transactions and dealings which may affect you under all applicable Laws.
          </span>
        </p>
        <p class="level1">
          <span class="left">4.4</span>
          <span class="right">Any exchange rate, interest rate, dealing rate and other prices and Information quoted by us on the Internet Site(s) or otherwise in response to an online inquiry is for reference only and is not binding. Any rate, price and Information offered by us for the purpose of the relevant transaction shall be binding on you upon you confirming acceptance, irrespective of any different rate, price or Information quoted by us.
          </span>
        </p>
        <p class="level1">
          <span class="left">4.5</span>
          <span class="right">You understand that there are risks associated with the use of services through the internet or through email, including but not limited to unauthorized access, system outages, delays and disruption in telecommunications services and the internet.  Response times for Internet Banking Services may vary due to market conditions, system performance, access device location or other factors, and that access to Information may be limited or unavailable during periods of peak demand, market volatility, system upgrades, maintenance, or for any other reason. You acknowledge that interruption or malfunction of communications, failure of software equipment, malfunction of the your or our computer system for any reason, or failure to keep your device browser or operating system or the Mobile Banking Application up to date may prevent or restrict your ability to access the Internet Banking Services, and that we will have no liability in respect of any such interruption or malfunction where the interruption or malfunction is beyond our reasonable control.
          </span>
        </p>
        <p class="level1">
          <span class="left">4.6</span>
          <span class="right">The Internet Banking Services are only available in selected jurisdictions. Information which is accessible using the Internet Banking Services is not intended for distribution to, or use by, any person in any jurisdiction where such distribution would be contrary to any law or regulation of that jurisdiction. We do not guarantee that the Internet Banking Services or the Information therein complies with the Laws of, or are appropriate for use in, other jurisdictions.
          </span>
        </p>
        <p class="level1">
          <span class="left">4.7</span>
          <span class="right">You acknowledge  that any products or services you may view on or through the Internet Banking Services may not be registered or authorized by any central bank, governmental or regulatory authority outside of any jurisdiction where we are licensed or authorized to provide such products or services or in your place of residence or location. Accordingly, you may not be protected by the securities laws, banking laws, insurance laws or other relevant Laws of any jurisdiction outside of where we are licensed or authorized to provide such products or services or your place of residence with respect to products or services you may view on or through Internet Banking Services.
          </span>
        </p>
        <p class="level1">
          <span class="left">4.8</span>
          <span class="right">If you access and/or download our Mobile Banking Application through a mobile application store, you acknowledge that:
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">we are responsible for the performance of our Mobile Banking Application and that you should contact us regarding any maintenance and support issues, content issues, malfunctions or other claims related to our Mobile Banking Application; and
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">when you use our Mobile Banking Application, such mobile application stores are considered as third party beneficiaries for the purposes of these Internet Banking Terms and they may  rely on these Internet Banking Terms.
              </span>
            </p>
          </li>
        </ol>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">5</span>
          <span class="right">Authorized Internet Users</span>
        </h3>
        <p class="level1">
          <span class="left">5.1</span>
          <span class="right">You shall:</span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">designate the persons authorized by you to use the Internet Banking Services in respect of the Customer Account(s) and the scope of the authority of each such person in such manner and form as we may prescribe from time to time;
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">advise us of any change in the mailing address and/or email address of the Authorized Internet User(s); and
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">ensure that all Authorized Internet Users comply with these Internet Banking Terms and the Standard Terms.
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">5.2</span>
          <span class="right">You and each Authorized Internet User shall provide such information as we may from time to time reasonably request for the provision of the Internet Banking Services.
          </span>
        </p>
        <p class="level1">
          <span class="left">5.3</span>
          <span class="right">You are responsible for the use of the Internet Banking Services, for taking appropriate measures to monitor and control the use of the Internet Banking Services and the appointment and change of the Authorized Internet Users, and to adopt proper safeguards against the Internet Banking Services being used by unauthorized persons or for unauthorized purposes, including but not limited to those measures set out in Clause 7 (<i>User ID and Password</i>) of these Internet Banking Terms.
          </span>
        </p>
        <p class="level1">
          <span class="left">5.4</span>
          <span class="right">Where you have appointed an Authorized Internet User to use any Internet Banking Service, such Authorized Internet User accepts and agrees to these Internet Banking Terms for and on your behalf, and you agree to be bound by such acceptance and/or agreement by the Authorized Internet User.
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">6</span>
          <span class="right">Use of the Internet Banking Services</span>
        </h3>
        <p class="level1">
          <span class="left">6.1</span>
          <span class="right">The Internet Banking Services are for the sole and exclusive use of Customers subscribing for the Internet Banking Services.</span>
        </p>
        <p class="level1">
          <span class="left">6.2</span>
          <span class="right">You shall neither do nor attempt to do any of the following:</span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">decompile, reverse engineer, translate, convert, adapt, alter, modify, enhance, add to, delete or in any way tamper with, or gain access to, any part of the Internet Banking Services or any Internet Site or any software comprised in them;
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">use or knowingly allow any other person to use the Internet Banking Services, the Information and/or the Reports for or in connection with any illegal purpose or activity;
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">remove or attempt to remove any titles or trademarks, copyrights or restricted rights notices in the Internet Banking Services;
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(D)</span>
              <span class="right">use any automated tools (i.e. bots) to access the Internet Banking Services or perform activities; and/or
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(E)</span>
              <span class="right">permit the use of any security testing tools in order to provide, scan, or attempt to penetrate or assess the security of the Internet Banking Services,
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left"></span>
          <span class="right">and shall notify us as soon as is practicable if you become aware of any such activity.</span>
        </p>
        <p class="level1">
          <span class="left">6.3</span>
          <span class="right">You must not upload or distribute files that contain viruses or other malicious code to the Internet Banking Services or do anything else to disrupt or attempt to disrupt, the systems and networks used for the provision of the Internet Banking Services. On becoming aware of an actual or suspected introduction of a virus or other malicious code to the Internet Banking Services you must notify us promptly and cooperate with us in mitigating the effects of such virus or malicious code.
          </span>
        </p>
        <p class="level1">
          <span class="left">6.4 </span>
          <span class="right">You acknowledge that there may be a time lag in transmission of Information or Communication via the Internet.
          </span>
        </p>
        <p class="level1">
          <span class="left">6.5</span>
          <span class="right">You acknowledge that:</span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">any information provided by you  in connection with your use of the Internet Banking Services may be disclosed to or made accessible within our business, any Group Office, and/or our authorized service providers who may be located in another jurisdiction; and
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">your use of the Internet Banking Services may be monitored by us, any Group Office and/or our authorized service providers and that any resulting information may be collected and used by us for our internal business considerations or for any applicable Law,
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left"></span>
          <span class="right">all in accordance with the terms of the Standard Terms and Conditions and you consent to such disclosure, monitoring and/or use.
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">7</span>
          <span class="right">User ID and Password &amp; Security</span>
        </h3>
        <p class="level1">
          <span class="left">7.1</span>
          <span class="right">Each Authorized Internet User shall comply with such procedures for designating his/her User ID and Password as we may prescribe from time to time.
          </span>
        </p>
        <p class="level1">
          <span class="left">7.2</span>
          <span class="right">Each Authorized Internet User may change the User ID and Password at any time but any change shall be effective only if accepted by us.
          </span>
        </p>
        <p class="level1">
          <span class="left">7.3</span>
          <span class="right">We may, in our sole discretion, require you to use a Security Code to access the Internet Banking Services.
          </span>
        </p>
        <p class="level1">
          <span class="left">7.4</span>
          <span class="right">Each Authorized Internet User shall act in good faith, exercise reasonable care and diligence and take all reasonable precautions in keeping safe and prevent fraudulent use of the User ID, the Password and the Security Code. At no time and under no circumstances shall any Authorized Internet User disclose the User ID, the Password and/or the Security Code to any other person.
          </span>
        </p>
        <p class="level1">
          <span class="left">7.5</span>
          <span class="right">You are fully responsible for any accidental or unauthorized disclosure of the User ID, the Password and/or the Security Code of any Authorized Internet User to any other person and shall bear the risks of the User ID, the Password, the Security Code or mobile device of any Authorized Internet User being used by unauthorized persons or for unauthorized purposes.
          </span>
        </p>
        <p class="level1">
          <span class="left">7.6</span>
          <span class="right">If you activate the feature that allows you to use your biometric credentials (such as fingerprint identity or face recognition) in the Mobile Banking Application and enables the use of such biometric credentials to log on to the Mobile Banking Application (or any other Internet Banking Services that we may support from time to time (for compatible devices only)), you must ensure that only your own biometric credentials are registered on the device. You acknowledge and agree that:
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">we have no control over the technology, availability, capability, verification method, security and integrity of the mobile device and cannot be held responsible for any unauthorized access to the Internet Banking Services using biometric credentials; and
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">you must not take any action to disable any function provided by, or agree to any settings of, your device that would otherwise compromise the security of the use of your biometric credentials for authentication purposes (e.g. disabling "attention-aware" for facial recognition).
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">7.7</span>
          <span class="right">Upon notice or suspicion of an Authorized Internet User’s User ID, the Password and/or the Security Code being disclosed to any unauthorized person or any unauthorized use of the Internet Banking Services being made, you or the relevant Authorized Internet User shall notify us in person as soon as practicable or by telephone at such telephone number(s) as we may from time to time prescribe. We may ask you or the relevant Authorized Internet User to confirm in writing any details given and until our actual receipt of such notification, you shall remain responsible for any and all use of the Internet Banking Services by unauthorized persons or for unauthorized purposes.
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">8</span>
          <span class="right">Customer’s instructions</span>
        </h3>
        <p class="level1">
          <span class="left">8.1</span>
          <span class="right">Each Authorized Internet User shall enter his/her User ID, Password, the Security Code and any other identifiers as shall be prescribed by us from time to time to logon at the Internet Site to use the Internet Banking Services and give Communication on your behalf to us on-line. We are authorized to act on the Communication of each Authorized Internet User.
          </span>
        </p>
        <p class="level1">
          <span class="left">8.2</span>
          <span class="right">Any Communication must be sent by the Authorized Internet Users to us at such website address and contact point as we may specify from time to time. Unless otherwise expressly agreed by us, Authorized Internet Users shall not send any Communication to our individual officers.
          </span>
        </p>
        <p class="level1">
          <span class="left">8.3</span>
          <span class="right">A Communication shall not be considered to be received by us unless it is given in such manner as we may prescribe from time to time and until we have actually received it.
          </span>
        </p>
        <p class="level1">
          <span class="left">8.4</span>
          <span class="right">Any Communication, once given, may not be rescinded or withdrawn without our consent. We shall be under no duty to verify the identity or authority of the person sending any Communication or the authenticity of any Communication apart from verifying the User ID, the Password, the Security Code and such other identifiers (if any) of the relevant Authorized Internet User.
          </span>
        </p>
        <p class="level1">
          <span class="left">8.5</span>
          <span class="right">We will only act on a Communication insofar as it is in our opinion practicable and reasonable to do so and in accordance with our regular business practices and procedures. We have a right to delay acting or refuse to act on a Communication if we are aware of or suspect a breach of security or other suspicious circumstances relating to the use of Internet Banking Services.
          </span>
        </p>
        <p class="level1">
          <span class="left">8.6</span>
          <span class="right">All transactions effected by us for you pursuant to a Communication shall be binding on you.
          </span>
        </p>
        <p class="level1">
          <span class="left">8.7</span>
          <span class="right">Advice or Confirmation that a Communication has been received by us and/or a transaction or dealing has been effected may be provided by us on-line if you have so elected and agreed. You agree to  check such advice or Confirmation to ensure its accuracy and completeness. You will enquire  with us if you do not receive an advice or Confirmation  or if you believe that any information in such advice or Confirmation is inaccurate or incomplete.
          </span>
        </p>
        <p class="level1">
          <span class="left">8.8</span>
          <span class="right">Information relating to any account, transaction or dealing made available on the Internet Site(s) are for reference only. Our records of such account, transaction or dealing shall in absence of fraud or manifest error be  evidence thereof.
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">9</span>
          <span class="right">Information Providers</span>
        </h3>
        <p class="level1">
          <span class="left">9.1</span>
          <span class="right">An Information Provider may impose from time to time terms and conditions in relation to the availability of any Information supplied by it. Access to such Information by you on or after the effective date, subject to prior notification, of such terms and conditions shall constitute your acceptance thereof.
          </span>
        </p>
        <p class="level1">
          <span class="left">9.2</span>
          <span class="right">The Information and the Reports are made available for reference only.</span>
        </p>
        <p class="level1">
          <span class="left">9.3</span>
          <span class="right">Except to the extent required by applicable Laws or provided for in any Relevant Document, neither we nor any Information Provider warrants, represents or guarantees the sequence, accuracy, truth, reliability, adequacy, timeliness or completeness of any of the Information or the Reports or whether it is fit for any purpose. You agree to independently assess and verify such matters in any Information or Report and, except to the extent required by applicable Laws or provided for in any Relevant Document, neither we nor any Information Provider assumes any liability (whether in tort or contract or otherwise) for any reliance on the Information or the Reports by you or any other person. However, this clause does not preclude any responsibility that we or the Information Provider may have to you or any other person in relation to conduct, statements or representations that we or the Information Provider may have under applicable Laws.
          </span>
        </p>
        <p class="level1">
          <span class="left">9.4</span>
          <span class="right">The Information will be made available as it is supplied by the Information Provider. We do not endorse or express any comment on any Information supplied by any other Information Provider nor assume any duty to check or verify any Information.
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">10</span>
          <span class="right">Confidential Information</span>
        </h3>
        <p class="level1">
          <span class="left">10.1</span>
          <span class="right">Confidential Information are trade secrets and confidential and proprietary property of us and the respective Information Providers.
          </span>
        </p>
        <p class="level1">
          <span class="left">10.2</span>
          <span class="right">Unless expressly permitted by these Internet Banking Terms or us, you shall neither do nor attempt to do any of the following:
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">sell, transfer, disclose, assign, convey, lease, sub-license, share, loan, distribute, transmit, broadcast, cablecast, put in circulation, duplicate or otherwise provide or disseminate any Confidential Information in any form or by any means to any other person or commercially exploit any Confidential Information;
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">download or reproduce any Confidential Information other than for your own personal reference and use; and/or
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">remove, obliterate, erase, relocate or modify in any way any proprietary marking on or appearing with the Confidential Information including, without limitation, any trademark or copyright notice.
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">10.3</span>
          <span class="right">The restrictions on disclosure shall not apply to any Confidential Information:
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">where its disclosure is compelled by law but only to the extent required by law and only after written notice of the requirement to disclose has been given by you to us; or
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">where we have expressly agreed in writing to its disclosure.</span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">10.4</span>
          <span class="right">All right, title and interest in and relating to the Confidential Information and any and all related copyright, patent, trademark, service mark, proprietary property, trade secrets and exclusive works are and shall remain the exclusive property of us and the respective Information Providers. No right, title or interest other than the right to access the Information and the Reports subject to these Internet Banking Terms is conveyed or transferred to you. You shall not make any representation or do any act which may be taken to indicate that you have any such right, title or interest.
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">11</span>
          <span class="right">Liabilities of the Bank</span>
        </h3>
        <p class="level1">
          <span class="left">11.1</span>
          <span class="right">We will take reasonably practicable steps to ensure that our systems in connection with the Internet Banking Services are installed with adequate security designs and to control and manage the risks in operating the systems, taking into account any Laws, circulars, codes of conduct and prevailing market practices which may be applicable to us from time to time.
          </span>
        </p>
        <p class="level1">
          <span class="left">11.2</span>
          <span class="right">None of us, any Group Office or any Information Provider warrants or represents that the Internet Banking Services, the Information and the Reports are free from viruses or other destructive features which may adversely affect your hardware, software or equipment.
          </span>
        </p>
        <p class="level1">
          <span class="left">11.3</span>
          <span class="right">Unless Clause 12.2 below applies or due to our fraud, negligence or wilful default, or the fraud, negligence or wilful default of any Group Office or our/their respective officers or employees, , neither we nor any Group Office assumes any liability or responsibility to you or any other person for the consequences arising from or in connection with:
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">the use of the Internet Banking Services and/or access to any Information as a result of such use by you or any other person whether or not authorized;
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">any interruption, interception, suspension, delay, loss, unavailability, mutilation or other failure in providing the Internet Banking Services (including, without limitation, any interruption, interception, suspension, delay or failure to generate the Security Code), in transmitting Information or Communication relating to the Internet Banking Services or in connecting with the Internet Site(s) caused by any acts, omissions or circumstances beyond our reasonable control including, without limitation, failure of any communication network, act or omission of any third party service providers, mechanical failure, power failure, malfunction, breakdown, or inadequacy of equipment, installation or facilities, or any Law, codes, directions, regulatory guidelines or government order (whether or not having the force of law); and
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">transmission and/or storage of any information and/or data relating to you and/or the Internet Banking Services through or in any system, equipment or instrument of any communication network provider.
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">11.4</span>
          <span class="right">Neither we, any Group Office or any Information Provider shall be liable to you or any other person for any incidental, indirect, special, consequential or exemplary damages including, without limitation, any loss of use, revenue, profits or savings. This clause does not apply in circumstances of our/their fraud, negligence or wilful default.
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">12</span>
          <span class="right">Liabilities of the Customer</span>
        </h3>
        <p class="level1">
          <span class="left">12.1</span>
          <span class="right">Unless Clause 12.2 below applies or unless there is fraud, negligence or wilful default by us , you shall be fully liable and responsible for all consequences arising from or in connection with the use of the Internet Banking Services and/or access to any Information or Report or any other information as a result of such use by you or any other person whether or not authorized.
          </span>
        </p>
        <p class="level1">
          <span class="left">12.2</span>
          <span class="right">Subject to your obligations under Clause 7 (<i>User ID and Password</i>) and if, in our reasonable opinion, there is no gross negligence or fraud on your part, you shall not be liable for loss or misplacement of funds caused by unauthorized transactions conducted through the use of the Internet Banking Services.
          </span>
        </p>
        <p class="level1">
          <span class="left">12.3</span>
          <span class="right">You shall indemnify us, any Group Office, any Information Provider and their respective officers and employees against all liabilities, claims, demands, Losses, damages, costs, charges and expenses of any kind (including, without limitation, legal costs on a full indemnity basis) which may be reasonably and properly incurred by any of them and all actions or proceedings which may be brought by or against any of them in connection with the provision of the Internet Banking Services, the Information and/or the Reports or the exercise or preservation of our powers and rights under these Internet Banking Terms and the Standard Terms, unless due to our fraud, negligence or wilful default, or the fraud, negligence or wilful default of any Group Office, any Information Provider or our/their respective officers or employees or unless Clause 12.2 above applies.
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">13</span>
          <span class="right">Amendments</span>
        </h3>
        <p class="level1">
          <span class="left"></span>
          <span class="right">We may amend and/or add to,  these Internet Banking Terms from time to time.  Any revision and/or addition to the Internet Banking Terms shall become effective subject to us giving prior reasonable notice which may be given to you by posting it on the Internet Site(s) or by any other means as we think fit, and shall be binding on you if you continue to maintain or use the Internet Banking Services on or after the effective date of variation.
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">14</span>
          <span class="right">Form of Notice and Communication</span>
        </h3>
        <p class="level1">
          <span class="left">14.1</span>
          <span class="right">We shall be entitled to prescribe, from time to time, the form of notice (whether written or any other form) and the mode of Communication with respect to each type of notice to be given pursuant to these Internet Banking Terms.
          </span>
        </p>
        <p class="level1">
          <span class="left">14.2</span>
          <span class="right">Communication sent by e-mail shall be deemed to have been received by you immediately after transmitting to the e-mail address last notified in writing by you to us.
          </span>
        </p>
      </section>
      <section>
        <br>
        <div>
          <h2 align="center" style="font-size: 16px;font-weight: 700;">B.	Terms and Conditions for HSBC Private Banking Insights &amp; Research</h2>
        </div>
        <br>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">1</span>
          <span class="right">Introduction to these Terms and Conditions</span>
        </h3>
        <p class="level1">
          <span class="left">1.1</span>
          <span class="right">Your use of the HSBC Private Banking Insights &amp; Research Services ("<b>I&amp;R Services</b>") made available by us is governed by:
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">these Terms and Conditions for HSBC Private Banking Insights &amp; Research (the "<b>I&amp;R Terms</b>");
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">the Terms and Conditions for Internet Banking Services (the "<b>Internet Banking Terms</b>"); and
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">our Standard Terms and Conditions as found
                <a href="https://www.privatebanking.hsbc.com/content/dam/privatebanking/gpb/utilities/mobile/Standard%20Terms%20and%20Conditions%20-%20November%2022%20edition%20-%20English%20version.pdf">here</a>,</span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left"></span>
          <span class="right">together the "<b>Terms</b>". To the extent of any conflict between these I&amp;R Terms and the Internet Banking Terms or the Standard Terms and Conditions, they shall take priority in the order listed above (highest to lowest).   We may amend the I&amp;R Terms from time to time upon giving reasonable notice to you.
          </span>
        </p>
        <p class="level1">
          <span class="left">1.2</span>
          <span class="right">Capitalised terms used but not otherwise defined in the I&amp;R Terms have the same meanings ascribed to them in the Internet Banking Terms and Standard Terms and Conditions.
          </span>
        </p>
        <p class="level1">
          <span class="left">1.3</span>
          <span class="right">For the avoidance of doubt, the terms "Internet Banking Services" and "Internet Site" as defined in the Internet Banking Terms will, respectively, include the I&amp;R Services and the Insights &amp; Research site ("<b>I&amp;R</b>").
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">2</span>
          <span class="right">Access and use of Publications</span>
        </h3>
        <p class="level1">
          <span class="left">2.1</span>
          <span class="right">We may provide you and/or your Authorized Internet User with access to I&amp;R (each a "<b>User</b>"), a digital platform which enables the User (among other things) to access certain:
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">Global Private Banking macro commentary on financial markets ("<b>GPB Macro Reports</b>");
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">HSBC Global Research reports ("<b>HSBC Research Reports</b>") by Global Research, a subdivision of our global markets division ("<b>HSBC Global Research</b>") and/or
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">non-HSBC third party publications ("<b>Third Party Publications</b>") 
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left"></span>
          <span class="right">
            (collectively referred to herein as "<b>Publications</b>"). 
          </span>
        </p>
        <p class="level1">
          <span class="left">2.2</span>
          <span class="right">The Publications may discuss financial markets and investment products across several asset classes and may include financial research material covering specific financial instruments and issuers. The types of Publications accessible to each category of User will be determined by us in our discretion.
          </span>
        </p>
        <p class="level1">
          <span class="left">2.3</span>
          <span class="right">Only persons whose details have been provided to and who have been approved by us as Users may use the I&amp;R Services. It is your responsibility to ensure that each User appointed by you has read and understood the Terms. 
          </span>
        </p>
        <p class="level1">
          <span class="left">2.4</span>
          <span class="right">We will provide each User with access to the Publications via I&amp;R which can be accessed using our Internet Banking Services. Users may also receive Publications via email from their relationship managers or investment counsellors, or by using the subscription alert function on I&amp;R.
          </span>
        </p>
        <p class="level1">
          <span class="left">2.5</span>
          <span class="right">The Publications are provided to each User exclusively in the English language. By nominating any User, you confirm that each User is sufficiently proficient in English to fully understand the contents of the Publications and any important disclaimer or risk warning which they may contain. 
          </span>
        </p>
        <p class="level1">
          <span class="left">2.6</span>
          <span class="right">No User will try to contact the issuer of any Publication directly, including but not limited to any HSBC Global Research analyst or third party, or any of their representatives or employees.
          </span>
        </p>
      </section>
` + tc2;
