export const initialState = {
    terms: {
        termText: ""
    },
    data: {},
    error: {},
    loggingIn: false,
    validationErrorUsername: true,
    validationErrorPassword: true,
    disableTermsBtn: false,
    gvMode: null,
    url: "",
    RBtoken: "",
    isMockLogin: false,
    pending: false
};

export function logon(state = initialState, action) {
    const { payload } = action;
    switch (action.type) {
        case "LOGON_TERMS_FETCHED_SUC":
            return {
                ...state,
                terms: payload
            };
        case "LOGON_SESSION_SUCCESS":
            return {
                ...state,
                error: {},
                logon: action.data
            };

        case "LOGON_SESSION_ERROR":
            return {
                ...state,
                error: action.error,
                logon: {}
            };
        case "LOGON_INIT":
            return {
                ...state,
                loggingIn: action.loggingIn
            };
        case "LOGON_VALIDATION_USERNAME":
            return {
                ...state,
                validationErrorUsername: action.validationErrorUsername
            };
        case "LOGON_VALIDATION_PASSWORD":
            return {
                ...state,
                validationErrorPassword: action.validationErrorPassword
            };
        case "DISABLE_TERMS_BUTTON":
            return {
                ...state,
                disableTermsBtn: action.disableTermsBtn
            };
        case "GV_LOGON":
            return {
                ...state,
                gvMode: payload
            };
        case "GV_Linking_Profile":
            return {
                ...state,
                url: action.url,
                RBtoken: action.RBtoken
            };
        case "ENCRYTED_PSW":
            return {
                ...state,
                encryptedpsw: action.encryptedpsw
            };
        case "MOCK_LOGIN":
            return {
                ...state,
                isMockLogin: action.isMockLogin
            };
        case "IS_LAST_ATTEMPT":
            return {
                ...state,
                isLastAttempt: action.isLastAttempt
            };
        case "IS_PENDING_RESPONSE":
            return {
                ...state,
                pending: action.pending
            };
        default:
            return state;
    }
}
