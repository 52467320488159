export const initialState = {
    data: {},
    refreshDigitCodeStatus: {},
    isLoading: false,
    error: {},
    otpSdkError: null
};

export function digitCode(state = initialState, action) {
    if (action.type === "SEND_CODE_LOADING") {
        return {
            ...state,
            error: {},
            isLoading: action.isLoading
        };
    } else {
        return state;
    }
}
