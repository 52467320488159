export const initialState = {
    validateDetails: {},
    isLoading: false,
    error: {}
};

export function registrationDetails(state = initialState, action) {
    switch (action.type) {
        case "VALIDATE_DETAILS_SUCCESS":
            return {
                ...state,
                validateDetails: action.validateDetails
            };
        case "VALIDATE_DETAILS_LOADING":
            return {
                ...state,
                isLoading: action.isLoading,
                error: {}
            };
        case "RESET_DETAILS_ERROR":
            return {
                ...state,
                error: {},
                validateDetails: {}
            };
        default:
            return state;
    }
}
