import { tc2 } from "./T&C2";

export default `
<html><head>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8">
<meta name="Generator" content="Microsoft Word 15 (filtered)">
<title>T&amp;C_tc</title>
    <style>
		.level1{
		margin-left:1.5cm;
	    text-indent:-30pt;
		}
        .level1 .left{		
		    display: inline-block;
			width: 1em;
			text-align:right}
        .level1 .right{
		    margin-left:0.58cm;
			text-align:left}	
	
        .list{
		list-style: none;		 
		margin-left:0.4cm;
	    text-indent:-24pt;
		text-align:left;
		}

		.item .left{	
		    display: inline-block;	
			text-align:right;}
        .item .right{
		    margin-left:0.9cm;
			text-align:left}
			
        .list2{
		list-style: none;		 
		margin-left:1.5cm;
	    text-indent:-24pt;
		text-align:left;
		}
		
		.item2 .left{	
		    display: inline-block;			
			width: 1em;
			text-align:right}
        .item2 .right{	
		    margin-left:0.38cm;
			text-align:left}

        .list3{
		list-style: none;		 
		margin-left:0.4cm;
	    text-indent:-20pt;
		text-align:left;
		}

		.item3 .left{
		    width:1em;
			text-align:left;}
        .item3 .right{	
		    margin-left:0.2cm;	
			text-align:left}
        h3 span:last-child {
            margin-left: 0.7em !important;
        }

        h2 {
            font-size: 16px;
            font-weight: 700;
        }
        
        h3 {
            font-size: 14px;
            font-weight: 700;
        }
    </style>
</head>
<body> 
      <br>
      <section>
        <p style="margin-left:0.8cm;">
          本行網上理財平台所提供服務的條款載列如下。在前往下一頁之前，請仔細閱讀有關條款：
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">
                <b>A.</b>
              </span>
              <span class="right">
                <b>網上理財服務的條款及細則</b>，當中載列閣下存取及使用滙豐環球私人銀行網上理財平台的條款及細則；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">
                <b>B.</b>
              </span>
              <span class="right">
                <b>滙豐私人銀行視野及研究的條款及細則</b>，當中載列閣下可透過滙豐環球私人銀行網上理財平台存取及使用的滙豐私人銀行視野及研究的額外條款及細則；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">
                <b>C.</b>
              </span>
              <span class="right">
                <b>推播通知服務的條款及細則</b>，當中載列本行向閣下提供並且與閣下可透過滙豐環球私人銀行網上理財平台存取或使用的產品及服務有關的推播通知服務的額外條款及細則；及
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">
                <b>D.</b>
              </span>
              <span class="right">
                <b>電子簽署服務的條款及細則</b>，當中載列任何使用電子簽署簽立的文件適用的額外條款及細則。
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">
                <b>E.</b>
              </span>
              <span class="right">
                <b>可疑委託警示模式的條款及細則</b>，此乃與潛在可疑轉賬交易的警示有關。
              </span>
            </p>
          </li>
        </ol>
      </section>
      <section>
        <br>
        <div>
          <h2 align="center">A. 網上理財服務的條款及細則</h2>
        </div>
        <br>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">1</span>
          <span class="right">釋義及詮釋</span>
        </h3>
        <p class="level1">
          <span class="left">1.1</span>
          <span class="right">
            除本文另有界定外，本行的標準章則（可經不時修訂）（按<a href="https://www.privatebanking.hsbc.com/content/dam/privatebanking/gpb/utilities/mobile/Standard%20Terms%20and%20Conditions%20-%20November%2022%20edition%20-%20Traditional%20Chinese%20version.pdf">此</a>查閱，<b>「標準章則」</b>）中界定的表述於本網上理財服務的條款及細則（<b>「網上理財條款」</b>）中使用時具有相同涵義。如本網上理財條款與標準章則之間有任何分歧，概以本網上理財條款為準。
          </span>
        </p>
        <p class="level1">
          <span class="left">1.2</span>
          <span class="right">
            於本網上理財條款中，除文義另有所指外，以下詞彙具有以下涵義：
          </span>
        </p>
        <ol class="list">
          <li class="item">
            <p>
              <span class="right">
                「<b>指定網上用戶</b>」指獲閣下授權使用閣下戶口之網上理財服務之指定人士（包括閣下）。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                「<b>資料</b>」指本行透過任何互聯網網站提供由任何資料供應商供應之金融、市場或其他資料及數據，包括B部中界定的「刊物」所包含的任何有關資料。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                「<b>資料供應商</b>」指供應資料之任何人士或實體，包括提供資料之本行及任何集團辦事處，以及供應任何資料予資料供應商之任何其他人士或實體。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                「<b>互聯網網站</b>」指由本行或任何其他人士代表本行建立、運作及╱或維持以提供網上理財服務之互聯網網站或其他平台，包括流動理財應用程式。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                「<b>網上理財服務</b>」指下文經不時修訂之第2條（<i>網上理財服務</i>）所載述之服務，包括根據B部提供的視野及研究服務、流動理財應用程式服務及根據D部提供的電子簽署。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                「<b>網上理財條款</b>」指網上理財服務的條款及細則。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                「<b>流動理財應用程式</b>」指本行提供的應用程式，下載至閣下的流動裝置即可藉其使用流動理財應用程式服務。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                「<b>流動理財應用程式服務</b>」指向閣下提供的服務，藉以讓閣下透過流動理財應用程式使用網上理財服務，包括C部中界定的推播通知服務。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                「<b>密碼</b>」指指定網上用戶就網上理財服務而使用之密碼。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                「<b>報告</b>」指以任何形式、媒介或途徑利用資料編製之任何報告，包括B部中界定的任何「刊物」。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                「<b>保安編碼</b>」指由本行提供並用作使用網上理財服務的任何編碼。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                「<b>標準章則</b>」具有本網上理財條款第1條（<i>釋義及詮釋</i>）所賦予的涵義。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                「<b>本行</b>」或具有該或類似涵義之詞語指本行。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                「<b>使用者名稱</b>」指本行接納之指定網上用戶使用者識別代碼，以便使用網上理財服務。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                「<b>閣下</b>」或具有該或類似涵義之詞語指客戶，如文義許可，包括經客戶授權就使用網上理財服務向本行發出指示或要求的任何人士，包括任何指定網上用戶。
              </span>
            </p>
          </li>
        </ol>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">2</span>
          <span class="right"> 網上理財服務</span>
        </h3>
        <p class="level1">
          <span class="left">2.1</span>
          <span class="right">
            本行可不時透過任何互聯網網站提供網上服務以便閣下向本行發出通訊，從而操作客戶戶口、進行銀行事務、投資、理財及任何性質之買賣及交易，以及獲取本行不時提供之服務、產品、資料、報告、利益及優惠。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.2</span>
          <span class="right">
            本行有權不時決定並修訂所提供之網上理財服務範圍及類別，包括但不限於：
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">隨時增加、修改或削減網上理財服務；</span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">
                制訂或更改使用網上理財服務（或該服務之任何部分）之限制，例如限制閣下使用網上理財服務每日進行任何買賣或交易之類別或任何買賣或交易之最高或最低交易額；及
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">
                指定及更改網上理財服務（或該服務之任何部分）之正常服務時間，以及任何類別之網上理財服務、買賣或交易之每日截數時間。本行於適用之每日截數時間後接獲閣下之任何指示，將被視為於下一營業日收到。本行可根據不同時區之市場運作時間，指定網上理財服務的營業日及每日截數時間。
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">2.3</span>
          <span class="right">本行可酌情全面或就任何客戶戶口或一組客戶戶口暫停或終止網上理財服務，但在任何有關情況下，如閣下受到影響，本行須盡可能通知閣下，但在有合理必要保障本行合法權益的情況下，或根據任何法律或為了金融罪案管理活動（包括但不限於為防止詐騙之目的），則可毋須事先通知而暫停或終止。如閣下就網上理財服務的使用遭暫停、限制或終止，本行可結束任何只能透過網上理財服務使用的戶口、產品或服務，閣下將不再收到電子結單，閣下亦應從閣下的裝置刪除本行的流動理財應用程式（如適用）。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.4</span>
          <span class="right">
            本行可要求閣下就使用網上理財服務而指定或登記專用客戶戶口。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.5</span>
          <span class="right">
            閣下可向本行發出事先書面通知而隨時終止使用網上理財服務及╱或終止對任何指定網上用戶之授權。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.6</span>
          <span class="right">
            為使其涵義得以施行，本網上理財條款中所有需要在網上理財服務暫停或終止及／或閣下暫停或終止使用網上理財服務後維持有效的條文（包括但不限於第4條（<i>一般免責聲明</i>）、第7條（<i>使用者名稱及密碼與安全</i>）、第9條（<i>資料及資料供應商</i>）、第11條（<i>本行之責任</i>）及第12條（<i>客戶之責任</i>））於暫停或終止後仍具有十足效力及作用。儘管有此等暫停或終止，只要該等條文與閣下仍未履行或解除之任何義務或法律責任有關，閣下仍須繼續受本網上理財條款約束。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">3</span>
          <span class="right">適用條款</span>
        </h3>
        <p class="level1">
          <span class="left"></span>
          <span class="right">
            使用網上理財服務進行任何買賣及交易，須受制於本網上理財條款、標準章則及所有其他管限有關客戶戶口、交易、買賣、服務、產品、資料、報告、利益或優惠之戶口文件。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">4</span>
          <span class="right">一般免責聲明</span>
        </h3>
        <p class="level1">
          <span class="left">4.1</span>
          <span class="right">
            所有閣下可透過網上理財服務進行的交易（不時提供者）純屬執行性質並根據閣下本身的判斷進行。本行並無責任評估或確保閣下透過網上理財服務買賣的產品或進行的交易是否合適。如本行以書面形式明示將本第4.1條摒除於適用範圍之外，則本第4.1條並不適用。
          </span>
        </p>
        <p class="level1">
          <span class="left">4.2</span>
          <span class="right">
            倘適用法律要求為其作出合適程度評估，則本行可能已向或為閣下作出之招攬或推薦僅於作出時有效，且本行無法確保有關產品是否持續合適。透過使用網上理財服務就產品執行交易，即表示閣下明白並接納，倘若與閣下、該產品或其發行人或市況相關之情況有變，則該產品可能不再適合閣下，而閣下使用網上理財服務進行交易之風險概由閣下承擔。
          </span>
        </p>
        <p class="level1">
          <span class="left">4.3</span>
          <span class="right">
            閣下有責任自行透過閣下慣常之買賣渠道，獨立決定買賣之市價及利率；並有責任在依賴任何資料及╱或報告或據此行事前，自行予以核證。閣下亦有責任就使用網上理財服務、資料及報告以及進行之任何買賣及交易在所有適用法律下對閣下可能產生之影響，尋求法律、稅務及其他方面之獨立專業意見。
          </span>
        </p>
        <p class="level1">
          <span class="left">4.4</span>
          <span class="right">
            本行透過互聯網網站或為回覆網上查詢而提供之任何匯率、利率、買賣報價及其他價格及資料乃僅供參考之用，而不具任何約束力。儘管本行曾提供不同之利率、報價或資料，閣下一經確認接納本行就有關交易而提供之任何利率、報價及資料，即對閣下有約束力。
          </span>
        </p>
        <p class="level1">
          <span class="left">4.5</span>
          <span class="right">
            閣下明白透過互聯網或電子郵件使用服務有相關風險，包括但不限於未經授權使用、系統故障、電訊服務及互聯網延誤及中斷。網上理財服務的反應時間可能會因市場狀況、系統性能、存取設備位置或其他因素而有所不同，而資料亦可能在需求高峰、市場波動、系統升級、維護期間或因任何其他原因而遭限制或無法存取。閣下確認，通訊中斷或故障、軟件設備故障、閣下或本行的電腦系統因任何原因發生故障，或未有更新閣下的裝置瀏覽器或作業系統或流動理財應用程式均可能會妨礙或限制閣下使用網上理財服務的能力，如中斷或故障超出本行的合理控制範圍，本行不會對任何有關中斷或故障承擔任何責任。
          </span>
        </p>
        <p class="level1">
          <span class="left">4.6</span>
          <span class="right">
            網上理財服務只在選定的司法管轄區提供。可使用網上理財服務存取的資料不擬在任何司法管轄區分發給有關分發將違反該司法管轄區的任何法例或規例的任何人士或供其使用。本行並不保證網上理財服務或其中的資料符合其他司法管轄區的法律或適合在其他司法管轄區使用。
          </span>
        </p>
        <p class="level1">
          <span class="left">4.7</span>
          <span class="right">
            閣下確認，閣下可在或透過網上理財服務檢視的任何產品或服務可能未經本行獲發牌或授權提供有關產品或服務的任何司法管轄區以外或閣下的居住或所在地的任何中央銀行、政府或監管機構註冊或授權。因此，就閣下可在或透過網上理財服務檢視的產品或服務而言，閣下可能不受本行獲發牌或授權提供有關產品或服務的任何司法管轄區以外或閣下的居住地的證券法、銀行法、保險法或其他相關法律保障。
          </span>
        </p>
        <p class="level1">
          <span class="left">4.8</span>
          <span class="right">
            如閣下透過流動應用程式商店存取及／或下載本行的流動理財應用程式，閣下確認：
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">
                本行對流動理財應用程式的效能負責，閣下應就與本行的流動理財應用程式相關的任何維護及支援事宜、內容事宜、故障或其他申索與本行聯絡；及
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">
                當閣下使用本行的流動理財應用程式時，就本網上理財條款而言，有關流動應用程式商店被視為第三方受益人，其可依賴本網上理財條款。
              </span>
            </p>
          </li>
        </ol>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">5</span>
          <span class="right">指定網上用戶</span>
        </h3>
        <p class="level1">
          <span class="left">5.1</span>
          <span class="right">閣下須：</span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">
                指定閣下授權之人士就客戶戶口使用網上理財服務，並按本行不時指定之方式及形式限制各有關人士所獲授權範圍；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">
                就指定網上用戶之郵寄地址及╱或電郵地址的任何更改知會本行；及
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">
                確保所有指定網上用戶遵守本網上理財條款及標準章則。
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">5.2</span>
          <span class="right">
            閣下及各指定網上用戶須提供本行為提供網上理財服務而不時合理要求的資料。
          </span>
        </p>
        <p class="level1">
          <span class="left">5.3</span>
          <span class="right">
            閣下須就使用網上理財服務負責，有責任採取適當措施以監控網上理財服務之使用情況，並負責指定和更換指定網上用戶，亦須採取恰當之保安措施，以免網上理財服務被未經授權人士使用或被用作未經授權用途，包括但不限於本網上理財條款第7條（<i>使用者名稱及密碼</i>）所載措施。
          </span>
        </p>
        <p class="level1">
          <span class="left">5.4</span>
          <span class="right">
            如閣下已委任指定網上用戶使用任何網上理財服務，該指定網上用戶為及代表閣下接受並同意本網上理財條款，而閣下亦同意受指定網上用戶的有關接受及／或同意約束。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">6</span>
          <span class="right">使用網上理財服務</span>
        </h3>
        <p class="level1">
          <span class="left">6.1</span>
          <span class="right">網上理財服務只供網上理財服務訂用客戶使用。</span>
        </p>
        <p class="level1">
          <span class="left">6.2</span>
          <span class="right">閣下不得進行或試圖進行以下任何活動：</span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">
                反編譯、反向工程、翻譯、轉換、改編、改變、修改、提昇、增添、刪除或以任何方式干擾或進入網上理財服務之任何部分或任何互聯網網站或當中組成之任何軟件；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">
                使用或在知情下容許任何其他人士使用網上理財服務、資料及╱或報告作任何非法目的或活動；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">
                移除或試圖移除網上理財服務中的任何標題或商標、版權或限制權利通知；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(D)</span>
              <span class="right">
                使用任何自動化工具（即機械人）存取網上理財服務或執行活動；及／或
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(E)</span>
              <span class="right">
                允許使用任何安全測試工具以提供、掃描或試圖滲透或評估網上理財服務的安全性，
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left"></span>
          <span class="right">閣下如察覺任何有關活動，須盡快通知本行。</span>
        </p>
        <p class="level1">
          <span class="left">6.3</span>
          <span class="right">
            閣下不得上傳或散佈包含病毒或其他惡意程式碼的檔案到網上理財服務，也不得作出任何其他行為破壞或試圖破壞用於提供網上理財服務的系統及網絡。一旦發現網上理財服務實際或懷疑遭病毒或其他惡意程式碼入侵，閣下必須立即通知本行並與本行合作以減低有關病毒或惡意程式碼的影響。
          </span>
        </p>
        <p class="level1">
          <span class="left">6.4 </span>
          <span class="right">
            閣下確認經由互聯網傳送之資料或通訊，可能會出現時差。
          </span>
        </p>
        <p class="level1">
          <span class="left">6.5</span>
          <span class="right"> 閣下確認：</span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">
                閣下就使用網上理財服務提供的任何資料可能會向本行的業務部門、任何集團辦事處及／或可能位於其他司法管轄區的授權服務供應商披露或供其存取；及
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">
                閣下使用網上理財服務可能會受到本行、任何集團辦事處及／或授權服務供應商監控，而本行可能出於內部業務考慮或任何適用法律而收集及使用由此產生的任何資料，
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left"></span>
          <span class="right">
            一切均按照標準章則的條款進行，閣下並且同意有關披露、監控及／或使用。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">7</span>
          <span class="right">使用者名稱及密碼與安全</span>
        </h3>
        <p class="level1">
          <span class="left">7.1</span>
          <span class="right">
            各指定網上用戶須按照本行不時訂明之程序指定其使用者名稱及密碼。
          </span>
        </p>
        <p class="level1">
          <span class="left">7.2</span>
          <span class="right">
            各指定網上用戶可隨時更改使用者名稱及密碼，惟任何更改須於本行接納後方為有效。
          </span>
        </p>
        <p class="level1">
          <span class="left">7.3</span>
          <span class="right">
            本行可行使絕對酌情權要求閣下使用保安編碼，以便使用網上理財服務。
          </span>
        </p>
        <p class="level1">
          <span class="left">7.4</span>
          <span class="right">
            各指定網上用戶須以真誠行事，在合理謹慎盡責的原則下採取一切合理措施，將使用者名稱、密碼及保安編碼保密以防盜用情況發生。無論任何時間或情況，指定網上用戶均不得向任何其他人士披露使用者名稱、密碼及╱或保安編碼。
          </span>
        </p>
        <p class="level1">
          <span class="left">7.5</span>
          <span class="right">
            倘若指定網上用戶之使用者名稱、密碼及╱或保安編碼不慎或在未經授權之情況下被洩露予其他人士知悉，閣下須負全責，並須承擔指定網上用戶之使用者名稱、密碼、保安編碼或流動裝置被未經授權人士使用或被用作未經授權用途之風險。
          </span>
        </p>
        <p class="level1">
          <span class="left">7.6</span>
          <span class="right">
            閣下如在流動理財應用程式上啟動生物識別憑據使用功能（譬如指紋認證或人臉識別），並允許使用有關生物識別憑據登入流動理財應用程式或本行可能不時支援之任何其他網上理財服務（僅為兼容裝置而設），便須確保只在裝置上登記閣下自身之生物識別憑據。閣下確認並同意：
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">
                本行無法控制流動裝置的技術、供應情況、性能、驗證方式、安全性及可靠性，因此概不就任何未經授權人士透過生物識別憑據登入網上理財服務之情況承擔責任；及
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">
                閣下不得採取任何行動以停用閣下裝置提供的任何功能或同意裝置的任何設定，從而危及使用生物識別憑據進行驗證的安全性（例如停用人臉識別的「螢幕注視感知功能」）。
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">7.7</span>
          <span class="right">
            閣下或有關指定網上用戶如發現或懷疑使用者名稱、密碼及╱或保安編碼被披露予未經授權人士，或網上理財服務被未經授權使用，須盡快親身通知本行，或根據本行不時指定之電話號碼致電通知本行。本行可要求閣下或有關指定網上用戶以書面形式確認所提供之資料。在本行實際收到該等通知前，閣下須就任何及所有因未經授權人士使用網上理財服務或網上理財服務被用作未經授權用途負責。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">8</span>
          <span class="right">客戶指示</span>
        </h3>
        <p class="level1">
          <span class="left">8.1</span>
          <span class="right">各指定網上用戶須先鍵入其使用者名稱、密碼、保安編碼及本行不時規定之任何其他識別字符，方可登入互聯網網站使用網上理財服務及代表閣下於網上向本行發出通訊。本行獲授權按各指定網上用戶之通訊行事。
          </span>
        </p>
        <p class="level1">
          <span class="left">8.2</span>
          <span class="right">
            指定網上用戶須於本行不時指定之網址及聯絡點向本行發出通訊。除非本行另行明確同意，否則指定網上用戶不得向本行之個別主管人員發出任何通訊。
          </span>
        </p>
        <p class="level1">
          <span class="left">8.3</span>
          <span class="right">
            通訊須按本行不時指定之方式發出及於本行實際收到後，始視為經由本行收妥。
          </span>
        </p>
        <p class="level1">
          <span class="left">8.4</span>
          <span class="right">
            任何通訊一經發出，如未得本行同意，概不得廢除或撤回。除核對有關指定網上用戶之使用者名稱、密碼、保安編碼及其他有關識別字符（如有）外，本行並無責任核證作出任何通訊之人士之身分或授權，或此等通訊之真確性。
          </span>
        </p>
        <p class="level1">
          <span class="left">8.5</span>
          <span class="right">
            本行只會執行本行認為合理可行之通訊，並將遵照正常業務慣例及程序行事。如本行得悉或懷疑存在與使用網上理財服務相關的安全漏洞或其他可疑情況，本行有權延遲或拒絕按通訊行事。
          </span>
        </p>
        <p class="level1">
          <span class="left">8.6</span>
          <span class="right">
            所有經由本行按照通訊代閣下辦理之交易均對閣下具有約束力。
          </span>
        </p>
        <p class="level1">
          <span class="left">8.7</span>
          <span class="right">
            若閣下選擇並同意，本行可在網上向閣下發出已收訖通訊及╱或已執行交易或買賣之通知或確認。閣下同意查核有關通知或確認以確保其準確及完整。倘閣下未有收到通知或確認，或閣下相信有關通知或確認中的任何資料為不準確或不完整，閣下將向本行查詢。
          </span>
        </p>
        <p class="level1">
          <span class="left">8.8</span>
          <span class="right">
            於互聯網網站提供有關任何戶口、交易或買賣之資料均僅供參考之用。本行對該等戶口、交易或買賣之紀錄，如無欺詐或明顯錯誤之情況，均為該等紀錄之證據。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">9</span>
          <span class="right">資料供應商</span>
        </h3>
        <p class="level1">
          <span class="left">9.1</span>
          <span class="right">
            資料供應商可就其提供之任何資料不時制訂有關之條款及細則，並將事先通知該等條款及細則之生效日期。閣下於該等條款及細則生效當日或之後使用該等資料，即表示閣下接納該等條款及細則。
          </span>
        </p>
        <p class="level1">
          <span class="left">9.2</span>
          <span class="right"> 資料及報告只供參考之用。</span>
        </p>
        <p class="level1">
          <span class="left">9.3</span>
          <span class="right">
            除在適用法律規定或任何有關文件訂明的範圍內，本行及任何資料供應商對任何資料或報告之次序、準確性、真確性、可靠性、充裕程度、時間性或完整性，或其是否適宜作任何用途，概不作出保證、聲明或擔保。閣下同意獨立評估及核實任何資料或報告中的有關事宜，除在適用法律規定或任何有關文件訂明的範圍內，本行及任何資料供應商不會就閣下或任何其他人士因依賴資料或報告而承擔任何法律責任（不論是侵權或合約或其他責任）。然而，本條款並不排除本行或資料供應商根據適用法律就行為、聲明或陳述對閣下或任何其他人士可能承擔的任何責任。
          </span>
        </p>
        <p class="level1">
          <span class="left">9.4</span>
          <span class="right">
            資料將按其由資料供應商所提供之形式提供。本行不會就任何其他資料供應商提供之資料作出批註或表示任何意見，亦無責任對有關資料進行檢查或核證。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">10</span>
          <span class="right">保密資料</span>
        </h3>
        <p class="level1">
          <span class="left">10.1</span>
          <span class="right">保密資料屬本行及有關資料供應商之商業秘密、機密及專有財產。
          </span>
        </p>
        <p class="level1">
          <span class="left">10.2</span>
          <span class="right">
            除本網上理財條款或本行明文許可外，閣下不得進行或試圖進行以下任何活動：
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">
                將任何保密資料向任何其他人士出售、轉移、披露、出讓、轉達、出租、分授許可權、分享、借出、分派、傳輸、廣播、電纜廣播、傳閱、複印，或以其他方式以任何形式或任何手段提供或散佈，或將任何保密資料作商業用途；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">
                下載或複製任何保密資料作閣下私人參考及使用以外之其他用途；及／或
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">
                將保密資料上顯示之任何所有權標記（包括但不限於任何商標或版權通告）以任何方式移除、塗掉、擦去、遷移或更改。
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">10.3</span>
          <span class="right">
            在下列情況下，對披露之限制並不適用於下列任何保密資料：
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">
                法律強制規定作出披露，惟只限於法律規定之範圍，及只在閣下已經向本行發出書面通知，說明要求披露之保密資料；或
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">本行已書面明確同意披露之保密資料。</span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">10.4</span>
          <span class="right">
            有關保密資料之一切權利、所有權及權益，以及任何及所有有關版權、專利權、商標、服務標記、專有財產、商業秘密及專用作品，均屬本行及╱或有關資料供應商之獨家財產。此等權利、所有權或權益（除根據本網上理財條款有權使用資料及報告外）均未有轉移或轉讓予閣下。閣下不得作出顯示閣下擁有任何此等權利、所有權或權益之任何聲明或作為。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">11</span>
          <span class="right">本行之責任</span>
        </h3>
        <p class="level1">
          <span class="left">11.1</span>
          <span class="right">
            本行將根據不時適用於本行之任何法律、通告、行為守則及現行市場慣例，採取合理可行之步驟，以確保與網上理財服務有關之系統已裝置足夠之保安設施，並於系統運作時，對有關風險予以監控。
          </span>
        </p>
        <p class="level1">
          <span class="left">11.2</span>
          <span class="right">
            本行、任何集團辦事處或任何資料供應商，概不保證或聲明網上理財服務、資料及報告不含有任何對閣下硬件、軟件或設備造成不利影響之病毒或其他破壞性程式。
          </span>
        </p>
        <p class="level1">
          <span class="left">11.3</span>
          <span class="right">
            除非引用下文第12.2條，或由於本行、任何集團辦事處或本行╱其各自之主管人員或僱員之欺詐、疏忽或故意失責所引致，本行或任何集團辦事處概不就以下事項所引致或與之有關之後果，而向閣下或任何其他人士承擔任何責任：
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">
                由於閣下或任何其他不論是否獲授權之人士使用網上理財服務及╱或取得任何資料；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">
                由於在提供網上理財服務、傳送與網上理財服務有關之資料或通訊或與互聯網網站連線時因任何本行之作為、不作為或本行所能合理控制範圍以外之情況，包括但不限於通訊網絡失靈、提供服務之第三者之作為或不作為、機械故障、電力故障、失靈、操作故障，或設備、裝置或設施不足，或因任何法律、守則、指令、監管指引或政府命令（不論是否具法律效力）而出現任何干擾、截取、中斷、延誤、損失、無法提供資料、毀壞或其他故障（包括但不限於任何干擾、截取、中斷、延誤或無法顯示保安編碼）；及
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">
                透過或在任何通訊網絡供應商之系統、設備或儀器傳送及╱或儲存任何與閣下及╱或網上理財服務有關之資料及╱或數據。
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">11.4</span>
          <span class="right">
            本行、任何集團辦事處或任何資料供應商，概毋須對閣下或任何其他人士就任何偶發性、間接、特殊、相應或懲罰性損害賠償負責，包括但不限於有關使用、收入、利潤或儲蓄方面之任何損失責任。本條款不適用於本行／彼等欺詐、疏忽或故意失責的情況。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">12</span>
          <span class="right">客戶之責任</span>
        </h3>
        <p class="level1">
          <span class="left">12.1</span>
          <span class="right">除非引用下文第12.2條或除非本行存在欺詐、疏忽或故意失責的行為，否則因閣下或任何其他人士（不論是否獲得授權）使用網上理財服務及／或因使用網上理財服務取得任何資料或報告或任何其他資料而引致或與之有關之後果，概由閣下全部承擔。
          </span>
        </p>
        <p class="level1">
          <span class="left">12.2</span>
          <span class="right">根據第7條（<i>使用者名稱及密碼</i>）下閣下責任之限制，及倘本行合理地認為閣下並無嚴重疏忽或欺詐，則閣下毋須就使用網上理財服務進行未經授權交易而產生之損失或資金錯置負責。
          </span>
        </p>
        <p class="level1">
          <span class="left">12.3</span>
          <span class="right">
            除非引用上文第12.2條，或由於本行、任何集團辦事處、任何資料供應商或本行╱其各自之主管人員或僱員之欺詐、疏忽或故意失責所引致，否則閣下須彌償本行、任何集團辦事處、任何資料供應商及其各自之主管人員及僱員因提供網上理財服務、資料及╱或報告或行使或保存本行在本網上理財條款及標準章則賦予之權力及權利或因提起或招致之任何法律行動或訴訟所合理及適當地招致之一切法律責任、索償、申索、損失、損害賠償、訟費、任何形式之費用及開支（包括但不限於按全數賠償基準支付之法律費用）。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">13</span>
          <span class="right">修訂</span>
        </h3>
        <p class="level1">
          <span class="left"></span>
          <span class="right">
            本行可不時對本網上理財條款進行修訂及／或增補。網上理財條款之任何修訂及╱或增補在本行透過於互聯網網站刊登或按照本行認為合適之其他方式向閣下發出事先合理通知後，即告生效。倘閣下在修訂生效當日或之後繼續維持或使用網上理財服務，則有關修訂將對閣下具有約束力。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">14</span>
          <span class="right">通知及通訊形式</span>
        </h3>
        <p class="level1">
          <span class="left">14.1</span>
          <span class="right">
            本行可不時根據本網上理財條款就各類通知訂明其通知形式（不論書面通知或其他形式）及通訊模式。
          </span>
        </p>
        <p class="level1">
          <span class="left">14.2</span>
          <span class="right">
            以電郵發出之通訊在傳送至閣下最近期以書面形式通知本行之電郵郵址後，即視作閣下經已收悉。
          </span>
        </p>
      </section>
      <section>
        <br>
        <div>
          <h2 align="center">B. 滙豐私人銀行視野及研究的條款及細則</h2>
        </div>
        <br>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">1</span>
          <span class="right">本條款及細則的簡介</span>
        </h3>
        <p class="level1">
          <span class="left">1.1</span>
          <span class="right">
            閣下使用由本行提供的滙豐私人銀行視野及研究服務（「<b>視野及研究服務</b>」）受以下條款規限：
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">
                本滙豐私人銀行視野及研究的條款及細則（「<b>視野及研究條款</b>」）；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">
                網上理財服務的條款及細則（「<b>網上理財條款</b>」）；及
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">本行的標準章則（按<a href="https://www.privatebanking.hsbc.com/content/dam/privatebanking/gpb/utilities/mobile/Standard%20Terms%20and%20Conditions%20-%20November%2022%20edition%20-%20Traditional%20Chinese%20version.pdf">此</a>查閱），
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left"></span>
          <span class="right">
            以上條款統稱為「<b>條款</b>」。如本視野及研究條款與網上理財條款或標準章則有任何衝突，則須按以上次序決定優先順序（從高到低）。本行可在向閣下發出合理通知後不時修訂視野及研究條款。
          </span>
        </p>
        <p class="level1">
          <span class="left">1.2</span>
          <span class="right">
            視野及研究條款中使用但未有另行界定的詞彙與網上理財條款及標準章則所賦予的涵義相同。
          </span>
        </p>
        <p class="level1">
          <span class="left">1.3</span>
          <span class="right">
            為免生疑問，網上理財條款中界定的「網上理財服務」及「互聯網網站」將分別包括視野及研究服務及視野及研究網站（「<b>視野及研究</b>」）。
          </span>
        </p>
      </section>     
` + tc2;
