import messages from "util/messages";
import _ from "lodash";
import { envConfig } from "env-config";

const locale = "en-GB";
const site_domain = envConfig.id;
const country = envConfig.tealiumCountry;
const region = envConfig.tealiumCountry;
const subregion = envConfig.tealiumCountry;
const language = envConfig.locale;

const buttonClick = "button click";
const textEntry = "text entry";
const registrationPath = "#/registration";
const resetPasswordPath = "#/resetPassword";
const pswRest = "password reset";
const hsbcPrivateBank = "HSBC Private Bank";
const wealthView = "wealth view";

const basePayload = {
    event_category: "content",
    event_action: buttonClick
};

const tealiumHub = {
    findText: function(id) {
        const text = _.get(messages[locale].lang, id);
        return text || id;
    },
    tealiumTrackView: function(tagAttribute) {
        try {
            const guid = localStorage.getItem("guid");
            tagAttribute.customer_id = guid ? guid : "";
            if (
                process.env.REACT_APP_TEALIUM_SWITCH === "ON" &&
                region !== "CH"
            ) {
                if (window.TMS) {
                    window.TMS.trackView(tagAttribute);
                } else {
                    setTimeout(() => {
                        this.tealiumTrackView(tagAttribute);
                    }, 50);
                }
            }
        } catch (err) {
            console.warn(
                "tealium view track fails with err: " + JSON.stringify(err)
            );
        }
    },
    tealiumTrackEvent: function(tagAttribute) {
        try {
            const guid = localStorage.getItem("guid");
            tagAttribute.customer_id = guid ? guid : "";
            if (
                process.env.REACT_APP_TEALIUM_SWITCH === "ON" &&
                region !== "CH"
            ) {
                if (window.TMS) {
                    window.TMS.trackEvent(tagAttribute);
                } else {
                    setTimeout(() => {
                        this.tealiumTrackEvent(tagAttribute);
                    }, 50);
                }
            }
        } catch (err) {
            console.warn(
                "tealium event track fails with err: " + JSON.stringify(err)
            );
        }
    },
    ET001: function(type) {
        this.tealiumTrackEvent({
            event_category: "content",
            event_action: textEntry,
            event_content: `log on: landing: ${type} entered`
        });
    },
    ET002: function(buttonLabel, journeyName) {
        this.tealiumTrackEvent({
            event_category: "content",
            event_action: buttonClick,
            event_content: `log on: landing: ${this.findText(buttonLabel)}`,
            funnel_name: `${journeyName}`,
            funnel_step: "1",
            funnel_step_name: `${journeyName} - start`
        });
    },
    ET003: function(buttonLabel) {
        this.tealiumTrackEvent({
            event_category: "content",
            event_action: buttonClick,
            event_content: `log on: no contact details alert: ${this.findText(
                buttonLabel
            )}`
        });
    },
    ET004: function(buttonLabel) {
        this.tealiumTrackEvent({
            event_category: "content",
            event_action: buttonClick,
            event_content: `log on: account locked reset password: ${this.findText(
                buttonLabel
            )}`
        });
    },
    ET005: function(buttonLabel) {
        this.tealiumTrackEvent({
            event_category: "content",
            event_action: buttonClick,
            event_content: `log on: account locked contact relationship manager: ${this.findText(
                buttonLabel
            )}`
        });
    },
    ET006: function() {
        let journeyName;
        const pathname = window.location.hash;
        switch (pathname) {
            case "#/logon":
                journeyName = "log on";
                break;
            case registrationPath:
                journeyName = "registration";
                break;
            case resetPasswordPath:
                journeyName = pswRest;
                break;
            default:
                return false;
        }
        this.tealiumTrackEvent({
            event_category: "content",
            event_action: "radio",
            event_content: `${journeyName}: send an activation code: option selected`
        });
    },
    ET007: function(buttonLabel) {
        let journeyName;
        const pathname = window.location.hash;
        switch (pathname) {
            case "#/logon":
                journeyName = "log on";
                break;
            case registrationPath:
                journeyName = "registration";
                break;
            case resetPasswordPath:
                journeyName = pswRest;
                break;
            default:
                return;
        }
        this.tealiumTrackEvent({
            event_category: "content",
            event_action: buttonClick,
            event_content: `${journeyName}: send an activation code: ${this.findText(
                buttonLabel
            )}`
        });
    },
    ET008: function() {
        let journeyName;
        const pathname = window.location.hash;
        switch (pathname) {
            case "#/logon":
                journeyName = "log on";
                break;
            case registrationPath:
                journeyName = "registration";
                break;
            case resetPasswordPath:
                journeyName = pswRest;
                break;
            default:
                return;
        }
        this.tealiumTrackEvent({
            event_category: "content",
            event_action: textEntry,
            event_content: `${journeyName}: enter code: code entered`
        });
    },
    ET009: function(buttonLabel) {
        let journeyName;
        const pathname = window.location.hash;
        switch (pathname) {
            case "#/logon":
                journeyName = "log on";
                break;
            case registrationPath:
                journeyName = "registration";
                break;
            case resetPasswordPath:
                journeyName = pswRest;
                break;
            default:
                return;
        }
        this.tealiumTrackEvent({
            event_category: "content",
            event_action: buttonClick,
            event_content: `${journeyName}: enter code: ${this.findText(
                buttonLabel
            )}`
        });
    },
    ET010: function(errorMessage) {
        let journeyName;
        const pathname = window.location.hash;
        switch (pathname) {
            case "#/logon":
                journeyName = "log on";
                break;
            case registrationPath:
                journeyName = "registration";
                break;
            case resetPasswordPath:
                journeyName = pswRest;
                break;
            default:
                return;
        }
        this.tealiumTrackEvent({
            event_category: "content",
            event_action: "error",
            event_content: `${journeyName}: enter code: ${this.findText(
                errorMessage
            )}`
        });
    },
    ET012: function(notificationMessage) {
        this.tealiumTrackEvent({
            event_category: "content",
            event_action: "notification",
            event_content: `log on: landing: ${this.findText(
                notificationMessage
            )}`
        });
    },
    ET014: function(buttonLabel) {
        this.tealiumTrackEvent({
            event_category: "content",
            event_action: buttonClick,
            event_content: `registration: terms and conditions: ${this.findText(
                buttonLabel
            )}`
        });
    },
    ET015: function(inputType) {
        this.tealiumTrackEvent({
            event_category: "content",
            event_action: textEntry,
            event_content: `registration: enter your details: ${inputType} entered`
        });
    },
    ET016: function(buttonLabel) {
        this.tealiumTrackEvent({
            event_category: "content",
            event_action: buttonClick,
            event_content: `registration: enter your details: ${this.findText(
                buttonLabel
            )}`
        });
    },
    ET023: function(type) {
        this.tealiumTrackEvent({
            event_category: "content",
            event_action: textEntry,
            event_content: `registration: create username and password: ${type} entered`
        });
    },
    ET024: function(buttonLabel) {
        this.tealiumTrackEvent({
            event_category: "content",
            event_action: buttonClick,
            event_content: `registration: create username and password: ${this.findText(
                buttonLabel
            )}`
        });
    },
    ET025: function(errorMessage) {
        this.tealiumTrackEvent({
            event_category: "content",
            event_action: "error",
            event_content: `registration: create username and password: ${this.findText(
                errorMessage
            )}`
        });
    },
    ET027: function(dropdownName, type) {
        this.tealiumTrackEvent({
            event_category: "content",
            event_action: "dropdown",
            event_content: `registration: security questions: ${this.findText(
                dropdownName
            )} ${type}`
        });
    },
    ET028: function(dropdownName) {
        this.tealiumTrackEvent({
            event_category: "content",
            event_action: "dropdown",
            event_content: `registration: security questions: ${this.findText(
                dropdownName
            )}: option selected`
        });
    },
    ET029: function(textBoxName) {
        this.tealiumTrackEvent({
            event_category: "content",
            event_action: textEntry,
            event_content: `registration: security questions: ${this.findText(
                textBoxName
            )}: text entered`
        });
    },
    ET030: function(buttonLabel) {
        this.tealiumTrackEvent({
            event_category: "content",
            event_action: buttonClick,
            event_content: `registration: security questions: ${this.findText(
                buttonLabel
            )}`
        });
    },
    ET031: function(buttonLabel) {
        this.tealiumTrackEvent({
            event_category: "content",
            event_action: buttonClick,
            event_content: `registration: complete: ${this.findText(
                buttonLabel
            )}`
        });
    },
    ET032: function() {
        this.tealiumTrackEvent({
            event_category: "content",
            event_action: textEntry,
            event_content: "password reset: enter username: username entered"
        });
    },
    ET033: function(buttonLabel) {
        this.tealiumTrackEvent({
            event_category: "content",
            event_action: buttonClick,
            event_content: `password reset: enter username: ${this.findText(
                buttonLabel
            )}`
        });
    },
    ET034: function(errorMessage) {
        this.tealiumTrackEvent({
            event_category: "content",
            event_action: "error",
            event_content: `password reset: enter username: ${this.findText(
                errorMessage
            )}`
        });
    },
    ET035: function() {
        this.tealiumTrackEvent({
            event_category: "content",
            event_action: textEntry,
            event_content: "password reset: security question: answer entered"
        });
    },
    ET036: function(buttonLabel) {
        this.tealiumTrackEvent({
            event_category: "content",
            event_action: buttonClick,
            event_content: `password reset: security question: ${this.findText(
                buttonLabel
            )}`
        });
    },
    ET037: function(errorMessage) {
        this.tealiumTrackEvent({
            event_category: "content",
            event_action: "error",
            event_content: `password reset: security question: ${this.findText(
                errorMessage
            )}`
        });
    },
    ET043: function(type) {
        this.tealiumTrackEvent({
            event_category: "content",
            event_action: textEntry,
            event_content: `password reset: create a new password: ${type} entered`
        });
    },
    ET044: function(buttonLabel) {
        this.tealiumTrackEvent({
            event_category: "content",
            event_action: buttonClick,
            event_content: `password reset: create a new password: ${this.findText(
                buttonLabel
            )}`,
            funnel_name: pswRest,
            funnel_step: "7",
            funnel_step_name: "password reset - completed"
        });
    },
    ET046: function(errorMessage) {
        this.tealiumTrackEvent({
            event_category: "content",
            event_action: "error",
            event_content: `page level: ${this.findText(errorMessage)}`
        });
    },

    ET053: function() {
        this.tealiumTrackEvent({
            event_category: "content",
            event_action: "button",
            event_content: `logon : appsign : authentication timed out: try again`,
            raw_datalayer: "5176v46"
        });
    },

    ET054: function() {
        this.tealiumTrackEvent({
            event_category: "content",
            event_action: "button",
            event_content: `logon : appsign : authentication timed out: try another method`,
            raw_datalayer: "5176v47"
        });
    },

    PT001: function() {
        this.tealiumTrackView({
            page_channel: "Web",
            site_domain: site_domain,
            site_region: region,
            site_subregion: subregion,
            site_country: country,
            site_entity: hsbcPrivateBank,
            site_brand: hsbcPrivateBank,
            page_business_line: "GPB",
            page_customer_group: "Private",
            page_url: "/gpb/wealth view/log on/landing",
            page_name: "gpb : wealth view : log on : landing",
            page_language: language,
            page_type: "landing",
            page_category: wealthView,
            page_subcategory: "log on"
        });
    },
    PT002: function() {
        this.tealiumTrackView({
            page_channel: "Web",
            site_domain: site_domain,
            site_region: region,
            site_subregion: subregion,
            site_country: country,
            site_entity: hsbcPrivateBank,
            site_brand: hsbcPrivateBank,
            page_business_line: "GPB",
            page_customer_group: "Private",
            page_url: "/gpb/wealth view/log on/no contact details alert",
            page_name: "gpb : wealth view : log on : no contact details alert",
            page_language: language,
            page_type: "verification",
            page_category: wealthView,
            page_subcategory: "log on"
        });
    },
    PT003: function() {
        this.tealiumTrackView({
            page_channel: "Web",
            site_domain: site_domain,
            site_region: region,
            site_subregion: subregion,
            site_country: country,
            site_entity: hsbcPrivateBank,
            site_brand: hsbcPrivateBank,
            page_business_line: "GPB",
            page_customer_group: "Private",
            page_url: "/gpb/wealth view/log on/account locked reset password",
            page_name:
                "gpb : wealth view : log on : account locked reset password",
            page_language: language,
            page_type: "verification",
            page_category: wealthView,
            page_subcategory: "log on"
        });
    },
    PT004: function() {
        this.tealiumTrackView({
            page_channel: "Web",
            site_domain: site_domain,
            site_region: region,
            site_subregion: subregion,
            site_country: country,
            site_entity: hsbcPrivateBank,
            site_brand: hsbcPrivateBank,
            page_business_line: "GPB",
            page_customer_group: "Private",
            page_url:
                "/gpb/wealth view/log on/account locked contact relationship manager",
            page_name:
                "gpb : wealth view : log on : account locked contact relationship manager",
            page_language: language,
            page_type: "verification",
            page_category: wealthView,
            page_subcategory: "log on"
        });
    },
    PT005: function() {
        this.tealiumTrackView({
            page_channel: "Web",
            site_domain: site_domain,
            site_region: region,
            site_subregion: subregion,
            site_country: country,
            site_entity: hsbcPrivateBank,
            site_brand: hsbcPrivateBank,
            page_business_line: "GPB",
            page_customer_group: "Private",
            page_url: "/gpb/wealth view/log on/send an activation code",
            page_name: "gpb : wealth view : log on : send an activation code",
            page_language: language,
            page_type: "verification",
            page_category: wealthView,
            page_subcategory: "log on",
            funnel_name: "log on",
            funnel_step: "2",
            funnel_step_name: "log on - send an activation code"
        });
    },
    PT006: function() {
        this.tealiumTrackView({
            page_channel: "Web",
            site_domain: site_domain,
            site_region: region,
            site_subregion: subregion,
            site_country: country,
            site_entity: hsbcPrivateBank,
            site_brand: hsbcPrivateBank,
            page_business_line: "GPB",
            page_customer_group: "Private",
            page_url: "/gpb/wealth view/log on/enter code",
            page_name: "gpb : wealth view : log on : enter code",
            page_language: language,
            page_type: "verification",
            page_category: wealthView,
            page_subcategory: "log on",
            funnel_name: "log on",
            funnel_step: "3",
            funnel_step_name: "log on - enter code"
        });
    },
    PT008: function() {
        this.tealiumTrackView({
            page_channel: "Web",
            site_domain: site_domain,
            site_region: region,
            site_subregion: subregion,
            site_country: country,
            site_entity: hsbcPrivateBank,
            site_brand: hsbcPrivateBank,
            page_business_line: "GPB",
            page_customer_group: "Private",
            page_url: "/gpb/wealth view/registration/terms and conditions",
            page_name:
                "gpb : wealth view : registration : terms and conditions",
            page_language: language,
            page_type: "verification",
            page_category: wealthView,
            page_subcategory: "registration",
            funnel_name: "registration",
            funnel_step: "2",
            funnel_step_name: "registration - terms and conditions"
        });
    },
    PT009: function() {
        this.tealiumTrackView({
            page_channel: "Web",
            site_domain: site_domain,
            site_region: region,
            site_subregion: subregion,
            site_country: country,
            site_entity: hsbcPrivateBank,
            site_brand: hsbcPrivateBank,
            page_business_line: "GPB",
            page_customer_group: "Private",
            page_url: "/gpb/wealth view/registration/enter your details",
            page_name: "gpb : wealth view : registration : enter your details",
            page_language: language,
            page_type: "verification",
            page_category: wealthView,
            page_subcategory: "registration",
            funnel_name: "registration",
            funnel_step: "3",
            funnel_step_name: "registration - enter your details"
        });
    },
    PT010: function() {
        this.tealiumTrackView({
            page_channel: "Web",
            site_domain: site_domain,
            site_region: region,
            site_subregion: subregion,
            site_country: country,
            site_entity: hsbcPrivateBank,
            site_brand: hsbcPrivateBank,
            page_business_line: "GPB",
            page_customer_group: "Private",
            page_url: "/gpb/wealth view/registration/send an activation code",
            page_name:
                "gpb : wealth view : registration : send an activation code",
            page_language: language,
            page_type: "verification",
            page_category: wealthView,
            page_subcategory: "registration",
            funnel_name: "registration",
            funnel_step: "4",
            funnel_step_name: "registration - send an activation code"
        });
    },
    PT011: function() {
        this.tealiumTrackView({
            page_channel: "Web",
            site_domain: site_domain,
            site_region: region,
            site_subregion: subregion,
            site_country: country,
            site_entity: hsbcPrivateBank,
            site_brand: hsbcPrivateBank,
            page_business_line: "GPB",
            page_customer_group: "Private",
            page_url: "/gpb/wealth view/registration/enter code",
            page_name: "gpb : wealth view : registration : enter code",
            page_language: language,
            page_type: "verification",
            page_category: wealthView,
            page_subcategory: "registration",
            funnel_name: "registration",
            funnel_step: "5",
            funnel_step_name: "registration - enter code"
        });
    },
    PT012: function() {
        this.tealiumTrackView({
            page_channel: "Web",
            site_domain: site_domain,
            site_region: region,
            site_subregion: subregion,
            site_country: country,
            site_entity: hsbcPrivateBank,
            site_brand: hsbcPrivateBank,
            page_business_line: "GPB",
            page_customer_group: "Private",
            page_url:
                "/gpb/wealth view/registration/create username and password",
            page_name:
                "gpb : wealth view : registration : create username and password",
            page_language: language,
            page_type: "verification",
            page_category: wealthView,
            page_subcategory: "registration",
            funnel_name: "registration",
            funnel_step: "6",
            funnel_step_name: "registration - create username and password"
        });
    },
    PT013: function() {
        this.tealiumTrackView({
            page_channel: "Web",
            site_domain: site_domain,
            site_region: region,
            site_subregion: subregion,
            site_country: country,
            site_entity: hsbcPrivateBank,
            site_brand: hsbcPrivateBank,
            page_business_line: "GPB",
            page_customer_group: "Private",
            page_url: "/gpb/wealth view/registration/security questions",
            page_name: "gpb : wealth view : registration : security questions",
            page_language: language,
            page_type: "verification",
            page_category: wealthView,
            page_subcategory: "registration",
            funnel_name: "registration",
            funnel_step: "7",
            funnel_step_name: "registration - security questions"
        });
    },
    PT014: function() {
        this.tealiumTrackView({
            page_channel: "Web",
            site_domain: site_domain,
            site_region: region,
            site_subregion: subregion,
            site_country: country,
            site_entity: hsbcPrivateBank,
            site_brand: hsbcPrivateBank,
            page_business_line: "GPB",
            page_customer_group: "Private",
            page_url: "/gpb/wealth view/registration/complete",
            page_name: "gpb : wealth view : registration : complete",
            page_language: language,
            page_type: "verification",
            page_category: wealthView,
            page_subcategory: "registration",
            funnel_name: "registration",
            funnel_step: "8",
            funnel_step_name: "registration - completed",
            event_action: "registration",
            event_category: "verification"
        });
    },
    PT015: function() {
        this.tealiumTrackView({
            page_channel: "Web",
            site_domain: site_domain,
            site_region: region,
            site_subregion: subregion,
            site_country: country,
            site_entity: hsbcPrivateBank,
            site_brand: hsbcPrivateBank,
            page_business_line: "GPB",
            page_customer_group: "Private",
            page_url: "/gpb/wealth view/password reset/enter username",
            page_name: "gpb : wealth view : password reset : enter username",
            page_language: language,
            page_type: "verification",
            page_category: wealthView,
            page_subcategory: pswRest,
            funnel_name: pswRest,
            funnel_step: "2",
            funnel_step_name: "password reset - enter username"
        });
    },
    PT016: function() {
        this.tealiumTrackView({
            page_channel: "Web",
            site_domain: site_domain,
            site_region: region,
            site_subregion: subregion,
            site_country: country,
            site_entity: hsbcPrivateBank,
            site_brand: hsbcPrivateBank,
            page_business_line: "GPB",
            page_customer_group: "Private",
            page_url: "/gpb/wealth view/password reset/security question",
            page_name: "gpb : wealth view : password reset : security question",
            page_language: language,
            page_type: "verification",
            page_category: wealthView,
            page_subcategory: pswRest,
            funnel_name: pswRest,
            funnel_step: "3",
            funnel_step_name: "password reset - security question"
        });
    },
    PT017: function() {
        this.tealiumTrackView({
            page_channel: "Web",
            site_domain: site_domain,
            site_region: region,
            site_subregion: subregion,
            site_country: country,
            site_entity: hsbcPrivateBank,
            site_brand: hsbcPrivateBank,
            page_business_line: "GPB",
            page_customer_group: "Private",
            page_url: "/gpb/wealth view/password reset/send an activation code",
            page_name:
                "gpb : wealth view : password reset : send an activation code",
            page_language: language,
            page_type: "verification",
            page_category: wealthView,
            page_subcategory: pswRest,
            funnel_name: pswRest,
            funnel_step: "4",
            funnel_step_name: "password reset - send an activation code"
        });
    },
    PT018: function() {
        this.tealiumTrackView({
            page_channel: "Web",
            site_domain: site_domain,
            site_region: region,
            site_subregion: subregion,
            site_country: country,
            site_entity: hsbcPrivateBank,
            site_brand: hsbcPrivateBank,
            page_business_line: "GPB",
            page_customer_group: "Private",
            page_url: "/gpb/wealth view/password reset/enter code",
            page_name: "gpb : wealth view : password reset : enter code",
            page_language: language,
            page_type: "verification",
            page_category: wealthView,
            page_subcategory: pswRest,
            funnel_name: pswRest,
            funnel_step: "5",
            funnel_step_name: "password reset - enter code"
        });
    },
    PT019: function() {
        this.tealiumTrackView({
            page_channel: "Web",
            site_domain: site_domain,
            site_region: region,
            site_subregion: subregion,
            site_country: country,
            site_entity: hsbcPrivateBank,
            site_brand: hsbcPrivateBank,
            page_business_line: "GPB",
            page_customer_group: "Private",
            page_url: "/gpb/wealth view/password reset/create a new password",
            page_name:
                "gpb : wealth view : password reset : create a new password",
            page_language: language,
            page_type: "verification",
            page_category: wealthView,
            page_subcategory: pswRest,
            funnel_name: pswRest,
            funnel_step: "6",
            funnel_step_name: "password reset - create a new password"
        });
    },
    PT020: function() {
        this.tealiumTrackView({
            page_channel: "Web",
            site_domain: site_domain,
            site_region: region,
            site_subregion: subregion,
            site_country: country,
            site_entity: hsbcPrivateBank,
            site_brand: hsbcPrivateBank,
            page_business_line: "GPB",
            page_customer_group: "Private",
            page_url: "/gpb/wealth view/log on/restricted",
            page_name: "gpb : wealth view : log on : restricted",
            page_language: language,
            page_type: "verification",
            page_category: wealthView,
            page_subcategory: "log on"
        });
    },
    PT022: function() {
        this.tealiumTrackView({
            page_channel: "Web",
            site_domain: site_domain,
            site_region: region,
            site_subregion: subregion,
            site_country: country,
            site_section: "ib",
            site_entity: hsbcPrivateBank,
            site_brand: hsbcPrivateBank,
            page_business_line: "GPB",
            page_customer_group: "general",
            page_url: "/gpb/wealth-view/logon/appsign/thanks/check-mobile",
            page_name:
                "gpb : wealth view : logon : appsign : thanks : check mobile",
            page_language: language,
            page_type: "verification",
            page_category: wealthView,
            page_subcategory: "logon",
            raw_datalayer: "5176v1",
            page_security_level: "30"
        });
    },
    PT023: function() {
        this.tealiumTrackView({
            page_channel: "Web",
            site_domain: site_domain,
            site_region: region,
            site_subregion: subregion,
            site_country: country,
            site_section: "ib",
            site_entity: hsbcPrivateBank,
            site_brand: hsbcPrivateBank,
            page_business_line: "GPB",
            page_customer_group: "general",
            page_url: "/gpb/wealth-view/logon/appsign/authentication-timed-out",
            page_name:
                "gpb : wealth view : logon : appsign : authentication timed out: try again",
            page_language: language,
            page_type: "verification",
            page_category: wealthView,
            page_subcategory: "logon",
            raw_datalayer: "5176v44",
            page_security_level: "30"
        });
    },
    PT024: function() {
        this.tealiumTrackView({
            page_channel: "Web",
            site_domain: site_domain,
            site_region: region,
            site_subregion: subregion,
            site_country: country,
            site_section: "ib",
            site_entity: hsbcPrivateBank,
            site_brand: hsbcPrivateBank,
            page_business_line: "GPB",
            page_customer_group: "general",
            page_url: "/gpb/wealth-view/logon/appsign/authentication-timed-out",
            page_name:
                "gpb : wealth view : logon : appsign : authentication timed out: try another method",
            page_language: language,
            page_type: "verification",
            page_category: wealthView,
            page_subcategory: "logon",
            raw_datalayer: "5176v45",
            page_security_level: "30"
        });
    }
};

window.tealiumHub = tealiumHub;
