import { combineReducers } from "redux";
import { digitCode } from "reducers/sendCodeReducer";
import { contactOptions } from "reducers/contactOptionsReducer";
import { resetPassword } from "reducers/resetPasswordReducer";
import { logon } from "reducers/logonReducer";
import { lockedAccount } from "reducers/lockedAccountReducer";
import { securityQuestionList } from "reducers/securityQuestionListReducer";
import { registrationDetails } from "reducers/registrationDetailsReducer";
import { createUsername } from "reducers/createUserNameReducer";
import { languageSession } from "reducers/languageReducer";
import { lockedType } from "./lockedTypeReducer";
import { currentOTPType } from "./currentOTPTypeReducer";

const AppReducers = combineReducers({
    resetPassword,
    logon,
    contactOptions,
    digitCode,
    lockedAccount,
    registrationDetails,
    createUsername,
    securityQuestionList,
    languageSession,
    lockedType,
    currentOTPType
});

const rootReducer = (state, action) => {
    if (action.type === "RESET_STATE") {
        state = undefined;
    }

    return AppReducers(state, action);
};

export default rootReducer;
