const initialState = {
    type: ""
};

export function lockedType(state = initialState, action) {
    switch (action.type) {
        case "RESET_LOCKED_TYPE_STATE":
            return {
                type: ""
            };
        case "UPDATE_LOCKED_TYPE":
            return {
                type: action.payload
            };
        default:
            return state;
    }
}
