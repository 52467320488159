import React from "react";
import FullScreenContainer from "components/common/FullScreenContainer";

const ErrorCode = props => {
    const content =
        props.match.params.errorCode === "NO_SESSION"
            ? "zuulError.content.nosession"
            : "zuulError.content.systemerror";
    const title =
        props.match.params.errorCode === "NO_SESSION"
            ? "NO SESSION"
            : "zuulError.title";
    return <FullScreenContainer content={content} title={title} />;
};

export default ErrorCode;
