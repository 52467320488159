import { tc2 } from "./T&C2";
import { tc3 } from "./T&C3";
export default `
<html><head>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8">
<meta name="Generator" content="Microsoft Word 15 (filtered)">
<title>Wealth View TC's v6</title>
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"MS Mincho";
	panose-1:2 2 6 9 4 2 5 8 3 4;}
@font-face
	{font-family:Batang;
	panose-1:2 3 6 0 0 1 1 1 1 1;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:"Helvetica Neue for HSBC Lt";
	panose-1:0 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"Wingdings 2";
	panose-1:5 2 1 2 1 5 7 7 7 7;}
@font-face
	{font-family:"Arial Bold";
	panose-1:0 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"Times New Roman Bold";
	panose-1:0 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:Tahoma;
	panose-1:2 11 6 4 3 5 4 4 2 4;}
@font-face
	{font-family:"Univers Next for HSBC Medium";
	panose-1:2 11 6 3 3 2 2 2 2 3;}
@font-face
	{font-family:"Univers Next Pro Light";
	panose-1:0 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"\@MS Mincho";
	panose-1:2 2 6 9 4 2 5 8 3 4;}
@font-face
	{font-family:"\@Batang";
	panose-1:2 3 6 0 0 1 1 1 1 1;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin:0cm;
	margin-bottom:.0001pt;
	font-size:12.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
h1
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:12.0pt;
	margin-left:18.0pt;
	text-indent:-18.0pt;
	page-break-after:avoid;
	font-size:12.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:normal;}
h2
	{margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:12.0pt;
	margin-left:1.0cm;
	text-indent:-1.0cm;
	page-break-after:avoid;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:normal;}
h3
	{margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:12.0pt;
	margin-left:36.0pt;
	text-indent:-36.0pt;
	page-break-after:avoid;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:normal;}
h4
	{margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:12.0pt;
	margin-left:45.0pt;
	text-indent:-45.0pt;
	page-break-after:avoid;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:normal;}
h5
	{margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:49.5pt;
	text-indent:-49.5pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:normal;}
h6
	{margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:54.0pt;
	text-indent:-54.0pt;
	font-size:8.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:normal;}
p.MsoHeading7, li.MsoHeading7, div.MsoHeading7
	{margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:58.5pt;
	text-indent:-58.5pt;
	font-size:8.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoHeading8, li.MsoHeading8, div.MsoHeading8
	{margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:63.0pt;
	text-indent:-63.0pt;
	font-size:8.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoHeading9, li.MsoHeading9, div.MsoHeading9
	{margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:67.5pt;
	text-indent:-67.5pt;
	font-size:8.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoToc1, li.MsoToc1, div.MsoToc1
	{margin-top:6.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:0cm;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.MsoToc2, li.MsoToc2, div.MsoToc2
	{margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:0cm;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoToc2CxSpFirst, li.MsoToc2CxSpFirst, div.MsoToc2CxSpFirst
	{margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:0cm;
	margin-bottom:.0001pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoToc2CxSpMiddle, li.MsoToc2CxSpMiddle, div.MsoToc2CxSpMiddle
	{margin:0cm;
	margin-bottom:.0001pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoToc2CxSpLast, li.MsoToc2CxSpLast, div.MsoToc2CxSpLast
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:0cm;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoToc3, li.MsoToc3, div.MsoToc3
	{margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:14.4pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoToc4, li.MsoToc4, div.MsoToc4
	{margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:14.4pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoToc5, li.MsoToc5, div.MsoToc5
	{margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:14.4pt;
	border:none;
	padding:0cm;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoToc6, li.MsoToc6, div.MsoToc6
	{margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:14.4pt;
	text-indent:18.0pt;
	border:none;
	padding:0cm;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoNormalIndent, li.MsoNormalIndent, div.MsoNormalIndent
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:36.0pt;
	margin-bottom:.0001pt;
	font-size:12.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoFootnoteText, li.MsoFootnoteText, div.MsoFootnoteText
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:10.8pt;
	margin-bottom:.0001pt;
	text-indent:-10.8pt;
	font-size:7.0pt;
	font-family:"Arial",sans-serif;}
p.MsoCommentText, li.MsoCommentText, div.MsoCommentText
	{mso-style-link:"Comment Text Char";
	margin:0cm;
	margin-bottom:.0001pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoHeader, li.MsoHeader, div.MsoHeader
	{mso-style-link:"Header Char";
	margin:0cm;
	margin-bottom:.0001pt;
	font-size:12.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoFooter, li.MsoFooter, div.MsoFooter
	{mso-style-link:"Footer Char";
	margin:0cm;
	margin-bottom:.0001pt;
	font-size:12.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoTof, li.MsoTof, div.MsoTof
	{margin:0cm;
	margin-bottom:.0001pt;
	font-size:12.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
span.MsoFootnoteReference
	{vertical-align:super;}
span.MsoPageNumber
	{font-family:"Arial",sans-serif;}
span.MsoEndnoteReference
	{vertical-align:super;}
p.MsoEndnoteText, li.MsoEndnoteText, div.MsoEndnoteText
	{mso-style-link:"Endnote Text Char";
	margin:0cm;
	margin-bottom:.0001pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoBodyText, li.MsoBodyText, div.MsoBodyText
	{mso-style-link:"Body Text Char";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:0cm;
	font-size:12.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoBodyText2, li.MsoBodyText2, div.MsoBodyText2
	{mso-style-link:"Body Text 2 Char";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:0cm;
	line-height:200%;
	font-size:12.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoBodyText3, li.MsoBodyText3, div.MsoBodyText3
	{mso-style-link:"Body Text 3 Char";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:0cm;
	font-size:8.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoBlockText, li.MsoBlockText, div.MsoBlockText
	{margin-top:0cm;
	margin-right:57.6pt;
	margin-bottom:0cm;
	margin-left:57.6pt;
	margin-bottom:.0001pt;
	border:none;
	padding:0cm;
	font-size:12.0pt;
	font-family:"Arial",sans-serif;
	color:#DB0011;
	font-style:italic;}
a:link, span.MsoHyperlink
	{color:blue;
	text-decoration:underline;}
a:visited, span.MsoHyperlinkFollowed
	{color:blue;
	text-decoration:underline;}
p
	{margin-right:0cm;
	margin-left:0cm;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
p.MsoCommentSubject, li.MsoCommentSubject, div.MsoCommentSubject
	{mso-style-link:"Comment Subject Char";
	margin:0cm;
	margin-bottom:.0001pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.MsoAcetate, li.MsoAcetate, div.MsoAcetate
	{mso-style-link:"Balloon Text Char";
	margin:0cm;
	margin-bottom:.0001pt;
	font-size:8.0pt;
	font-family:"Tahoma",sans-serif;
	color:black;}
p.MsoRMPane, li.MsoRMPane, div.MsoRMPane
	{margin:0cm;
	margin-bottom:.0001pt;
	font-size:12.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoTocHeading, li.MsoTocHeading, div.MsoTocHeading
	{margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:0cm;
	margin-bottom:.0001pt;
	line-height:107%;
	page-break-after:avoid;
	font-size:16.0pt;
	font-family:"Arial",sans-serif;
	color:#A4000C;}
p.TNCBulletOne, li.TNCBulletOne, div.TNCBulletOne
	{mso-style-name:TNC_BulletOne;
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:18.0pt;
	text-indent:-18.0pt;
	line-height:110%;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.Footnote, li.Footnote, div.Footnote
	{mso-style-name:Footnote;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:14.4pt;
	margin-bottom:.0001pt;
	text-indent:-14.4pt;
	font-size:7.0pt;
	font-family:"Arial",sans-serif;}
p.TNCCoverpagetitle, li.TNCCoverpagetitle, div.TNCCoverpagetitle
	{mso-style-name:"TNC_Cover page title";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:4.0pt;
	margin-left:0cm;
	line-height:36.0pt;
	font-size:26.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.TNCCoverpagesubtitle, li.TNCCoverpagesubtitle, div.TNCCoverpagesubtitle
	{mso-style-name:"TNC_Cover page subtitle";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:24.0pt;
	margin-left:0cm;
	font-size:26.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.TNCCoverpagedetails, li.TNCCoverpagedetails, div.TNCCoverpagedetails
	{mso-style-name:"TNC_Cover page details";
	margin-top:24.0pt;
	margin-right:0cm;
	margin-bottom:12.0pt;
	margin-left:0cm;
	font-size:12.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.Pageheader, li.Pageheader, div.Pageheader
	{mso-style-name:"Page header";
	margin-top:44.0pt;
	margin-right:0cm;
	margin-bottom:175.0pt;
	margin-left:0cm;
	line-height:55.0pt;
	font-size:54.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.2ndlevelbullettext, li.2ndlevelbullettext, div.2ndlevelbullettext
	{mso-style-name:"2nd level bullet text";
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:36.0pt;
	text-indent:-18.0pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.TNCBodyText1, li.TNCBodyText1, div.TNCBodyText1
	{mso-style-name:"TNC_Body Text1";
	mso-style-link:"TNC_Body Text1 Char";
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:0cm;
	line-height:110%;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.Bullettext, li.Bullettext, div.Bullettext
	{mso-style-name:"Bullet text";
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:18.0pt;
	text-indent:-18.0pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.Paragraphhead, li.Paragraphhead, div.Paragraphhead
	{mso-style-name:"Paragraph head";
	margin-top:108.0pt;
	margin-right:0cm;
	margin-bottom:84.0pt;
	margin-left:0cm;
	line-height:19.0pt;
	page-break-after:avoid;
	font-size:18.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
span.TNCBodyText1Char
	{mso-style-name:"TNC_Body Text1 Char";
	mso-style-link:"TNC_Body Text1";
	font-family:"Arial",sans-serif;
	color:black;}
p.Tabletextbold, li.Tabletextbold, div.Tabletextbold
	{mso-style-name:Tabletextbold;
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:0cm;
	line-height:110%;
	font-size:9.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.Tabletext, li.Tabletext, div.Tabletext
	{mso-style-name:Tabletext;
	mso-style-link:"Tabletext Char";
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:0cm;
	line-height:110%;
	page-break-after:avoid;
	font-size:9.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.Quotetext, li.Quotetext, div.Quotetext
	{mso-style-name:Quotetext;
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:0cm;
	line-height:110%;
	font-size:9.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-style:italic;}
p.TableBulletOne, li.TableBulletOne, div.TableBulletOne
	{mso-style-name:TableBulletOne;
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:18.0pt;
	text-indent:-18.0pt;
	line-height:110%;
	font-size:9.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.footnote0, li.footnote0, div.footnote0
	{mso-style-name:footnote;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:2.0pt;
	margin-left:0cm;
	line-height:7.05pt;
	text-autospace:none;
	font-size:7.0pt;
	font-family:"Arial",sans-serif;
	color:#626469;}
p.GraphTablecontent, li.GraphTablecontent, div.GraphTablecontent
	{mso-style-name:"Graph\/Table content";
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:0cm;
	font-size:7.0pt;
	font-family:"Arial",sans-serif;
	color:#626469;}
p.Tabletext2, li.Tabletext2, div.Tabletext2
	{mso-style-name:"Table text 2";
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:0cm;
	text-align:right;
	line-height:110%;
	page-break-after:avoid;
	font-size:9.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.Tbaletextbold2, li.Tbaletextbold2, div.Tbaletextbold2
	{mso-style-name:"Tbale text bold 2";
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:0cm;
	text-align:right;
	line-height:110%;
	font-size:9.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.BulletTwo, li.BulletTwo, div.BulletTwo
	{mso-style-name:BulletTwo;
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:28.8pt;
	text-indent:-14.4pt;
	line-height:110%;
	font-size:9.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.BulletThree, li.BulletThree, div.BulletThree
	{mso-style-name:BulletThree;
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:43.2pt;
	text-indent:-14.4pt;
	line-height:10.0pt;
	font-size:8.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.Source, li.Source, div.Source
	{mso-style-name:Source;
	margin-top:2.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:0cm;
	margin-bottom:.0001pt;
	text-autospace:none;
	font-size:7.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.Tableheadredborder, li.Tableheadredborder, div.Tableheadredborder
	{mso-style-name:Tableheadredborder;
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:0cm;
	line-height:12.0pt;
	page-break-after:avoid;
	border:none;
	padding:0cm;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	font-weight:bold;}
p.Tabletitle, li.Tabletitle, div.Tabletitle
	{mso-style-name:Tabletitle;
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:0cm;
	line-height:12.0pt;
	page-break-after:avoid;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	font-weight:bold;}
p.TableBulletTwo, li.TableBulletTwo, div.TableBulletTwo
	{mso-style-name:TableBulletTwo;
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:28.8pt;
	text-indent:-14.4pt;
	line-height:110%;
	font-size:9.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.TableBulletThree, li.TableBulletThree, div.TableBulletThree
	{mso-style-name:TableBulletThree;
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:43.2pt;
	text-indent:-14.4pt;
	line-height:10.0pt;
	font-size:9.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.TabletextboldWhite, li.TabletextboldWhite, div.TabletextboldWhite
	{mso-style-name:TabletextboldWhite;
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:0cm;
	line-height:110%;
	page-break-after:avoid;
	font-size:9.0pt;
	font-family:"Arial",sans-serif;
	color:white;
	font-weight:bold;}
p.Tabletextobject, li.Tabletextobject, div.Tabletextobject
	{mso-style-name:Tabletextobject;
	margin:0cm;
	margin-bottom:.0001pt;
	page-break-after:avoid;
	font-size:9.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.Tombstonetext, li.Tombstonetext, div.Tombstonetext
	{mso-style-name:Tombstonetext;
	margin-top:2.0pt;
	margin-right:0cm;
	margin-bottom:2.0pt;
	margin-left:0cm;
	page-break-after:avoid;
	font-size:8.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.PageheaderContents, li.PageheaderContents, div.PageheaderContents
	{mso-style-name:"Page headerContents";
	margin-top:44.0pt;
	margin-right:0cm;
	margin-bottom:175.0pt;
	margin-left:0cm;
	line-height:55.0pt;
	font-size:54.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.Pageheadercontd, li.Pageheadercontd, div.Pageheadercontd
	{mso-style-name:"Page header\(contd\)";
	margin-top:44.0pt;
	margin-right:0cm;
	margin-bottom:255.0pt;
	margin-left:0cm;
	line-height:28.0pt;
	font-size:28.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
span.BalloonTextChar
	{mso-style-name:"Balloon Text Char";
	mso-style-link:"Balloon Text";
	font-family:"Tahoma",sans-serif;
	color:black;}
p.StyleBodyText18ptLeft02cmRight02cmBefore2, li.StyleBodyText18ptLeft02cmRight02cmBefore2, div.StyleBodyText18ptLeft02cmRight02cmBefore2
	{mso-style-name:"Style Body Text1 + 8 pt Left\:  0\.2 cm Right\:  0\.2 cm Before\:  2 \.\.\.";
	margin-top:2.0pt;
	margin-right:5.65pt;
	margin-bottom:2.0pt;
	margin-left:5.65pt;
	font-size:8.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
span.EndnoteTextChar
	{mso-style-name:"Endnote Text Char";
	mso-style-link:"Endnote Text";
	font-family:"Arial",sans-serif;
	color:black;}
p.SpacerStyle, li.SpacerStyle, div.SpacerStyle
	{mso-style-name:"Spacer Style";
	margin:0cm;
	margin-bottom:.0001pt;
	font-size:9.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.Tombstonetext6pt, li.Tombstonetext6pt, div.Tombstonetext6pt
	{mso-style-name:"Tombstonetext6 pt";
	margin-top:2.0pt;
	margin-right:0cm;
	margin-bottom:2.0pt;
	margin-left:0cm;
	page-break-after:avoid;
	font-size:6.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.TabletextBanding, li.TabletextBanding, div.TabletextBanding
	{mso-style-name:"Tabletext Banding";
	mso-style-link:"Tabletext Banding Char";
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:0cm;
	line-height:110%;
	page-break-after:avoid;
	font-size:9.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
span.TabletextBandingChar
	{mso-style-name:"Tabletext Banding Char";
	mso-style-link:"Tabletext Banding";
	font-family:"Arial",sans-serif;
	color:black;}
span.TabletextChar
	{mso-style-name:"Tabletext Char";
	mso-style-link:Tabletext;
	font-family:"Arial",sans-serif;
	color:black;}
p.BulletFour, li.BulletFour, div.BulletFour
	{mso-style-name:BulletFour;
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:57.6pt;
	text-indent:-14.4pt;
	line-height:10.0pt;
	font-size:7.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.Sectionpagetitle, li.Sectionpagetitle, div.Sectionpagetitle
	{mso-style-name:"Section page title";
	margin-top:450.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:10.35pt;
	margin-bottom:.0001pt;
	font-size:30.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.Sectionpagesubtitle, li.Sectionpagesubtitle, div.Sectionpagesubtitle
	{mso-style-name:"Section page subtitle";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:24.0pt;
	margin-left:0cm;
	font-size:26.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
span.BodyTextChar
	{mso-style-name:"Body Text Char";
	mso-style-link:"Body Text";
	font-family:"Arial",sans-serif;
	color:black;}
p.NumberOne, li.NumberOne, div.NumberOne
	{mso-style-name:NumberOne;
	mso-style-link:"NumberOne Char";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:18.0pt;
	text-indent:-18.0pt;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
span.NumberOneChar
	{mso-style-name:"NumberOne Char";
	mso-style-link:NumberOne;
	font-family:"Arial",sans-serif;
	color:black;}
p.NumberTwo, li.NumberTwo, div.NumberTwo
	{mso-style-name:NumberTwo;
	mso-style-link:"NumberTwo Char";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:36.0pt;
	text-indent:-18.0pt;
	line-height:115%;
	font-size:8.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
span.NumberTwoChar
	{mso-style-name:"NumberTwo Char";
	mso-style-link:NumberTwo;
	font-family:"Arial",sans-serif;
	color:black;}
p.NumberThree, li.NumberThree, div.NumberThree
	{mso-style-name:NumberThree;
	mso-style-link:"NumberThree Char";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:61.2pt;
	text-indent:-25.2pt;
	line-height:115%;
	font-size:7.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
span.NumberThreeChar
	{mso-style-name:"NumberThree Char";
	mso-style-link:NumberThree;
	font-family:"Arial",sans-serif;
	color:black;}
p.NumberFive, li.NumberFive, div.NumberFive
	{mso-style-name:NumberFive;
	mso-style-link:"NumberFive Char";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:118.8pt;
	text-indent:-32.4pt;
	line-height:115%;
	font-size:6.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
span.NumberFiveChar
	{mso-style-name:"NumberFive Char";
	mso-style-link:NumberFive;
	font-family:"Arial",sans-serif;
	color:black;}
p.BulletFive, li.BulletFive, div.BulletFive
	{mso-style-name:BulletFive;
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:72.0pt;
	text-indent:-14.4pt;
	line-height:10.0pt;
	font-size:6.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.Sectionpagetitle2, li.Sectionpagetitle2, div.Sectionpagetitle2
	{mso-style-name:"Section page title 2";
	margin-top:280.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:10.8pt;
	margin-bottom:.0001pt;
	font-size:30.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.Sectiontitle, li.Sectiontitle, div.Sectiontitle
	{mso-style-name:"Section title";
	margin-top:20.0pt;
	margin-right:0cm;
	margin-bottom:4.0pt;
	margin-left:0cm;
	line-height:36.0pt;
	font-size:26.0pt;
	font-family:"Arial",sans-serif;
	color:white;
	font-weight:bold;}
p.StyleCoverpagedetailsBold, li.StyleCoverpagedetailsBold, div.StyleCoverpagedetailsBold
	{mso-style-name:"Style Cover page details + Bold";
	margin-top:24.0pt;
	margin-right:0cm;
	margin-bottom:12.0pt;
	margin-left:0cm;
	font-size:9.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.StyleStyleCoverpagedetailsBoldBold, li.StyleStyleCoverpagedetailsBoldBold, div.StyleStyleCoverpagedetailsBoldBold
	{mso-style-name:"Style Style Cover page details + Bold + Bold";
	margin-top:24.0pt;
	margin-right:0cm;
	margin-bottom:12.0pt;
	margin-left:0cm;` +
    tc2 +
    tc3;
