export const initialState = {
    data: {},
    isLoading: false,
    error: {}
};

export function createUsername(state = initialState, action) {
    if (action.type === "CREATE_USERNAME_LOADING") {
        return {
            ...state,
            isLoading: action.isLoading,
            error: {}
        };
    } else {
        return state;
    }
}
