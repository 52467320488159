import React, { Component } from "react";
import styled from "styled-components";
import { injectIntl } from "react-intl";
import backgroundKaleidoTl from "assets/image/dsp_background_tl.png";
import backgroundKaleidoTp from "assets/image/dsp_background_tp.png";
import backgroundKaleidoDt from "assets/image/dsp_background_dt.png";
import backgroundKaleidoMb from "assets/image/dsp_background_mb.png";

class AppBackgroundKaleido extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <BackgroundKaleido>
                <div className="kaleido"></div>
            </BackgroundKaleido>
        );
    }
}

const BackgroundKaleido = styled.div`
    // height: 100%;
    .kaleido {
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        text-align: right;

        @media (min-width: 1440px) {
            width: 50%;
            background: url(${backgroundKaleidoDt});
            background-size: 100%;
            background-repeat: no-repeat;
        }

        @media (min-width: 1020px) and (max-width: 1439px) {
            width: 50%;
            background: url(${backgroundKaleidoTl});
            background-size: 100%;
            background-repeat: no-repeat;
        }

        @media (min-width: 450px) and (max-width: 1019px) {
            width: 50%;
            background: url(${backgroundKaleidoTp});
            background-size: 100%;
            background-repeat: no-repeat;
        }

        @media (max-width: 449px) {
            width: 100%;
            background: url(${backgroundKaleidoMb});
            background-size: 100%;
            background-repeat: no-repeat;
        }
    }
`;

export default injectIntl(AppBackgroundKaleido);
