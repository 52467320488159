import React, { Component } from "react";
import styled from "styled-components";
import { color } from "util/color";
import SecurityModal from "components/common/SecurityModal";
import SecurityTextInput from "components/common/SecurityTextInput";
import {
    passwordSavedStatusClear,
    resetPasswordActions
} from "actions/resetPasswordAction";
import { createPasswordValidate } from "util/validator";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import WarningMsg from "components/common/WarningMsg";
import { encryptPassword } from "util/service";

const mapStateToProps = state => {
    return {
        createPasswordSuc: state.resetPassword.createPasswordSuc,
        userName: state.resetPassword.userName,
        disabledButton: state.resetPassword.isLoading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        clearSavedStatus: () => dispatch(passwordSavedStatusClear()),
        isLoading: payload =>
            dispatch(resetPasswordActions.validateUsernameIsLoading(payload))
    };
};

export class StepCreatePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstPassword: "",
            secondPassword: "",
            secondPasswordlegal: true,
            firstPasswordilegalMsg: "",
            password1verified: false,
            password2verified: false
        };
        this.firstPasswordComp = null;
        this.secondPasswordComp = null;
        if (props.storeThis) {
            props.storeThis("StepCreatePassword", this);
        }
        window.tealiumHub.PT019();
    }

    handleChange = event => {
        const { target } = event;
        const { value, name } = target;
        this.setState((previousState, currentProps) => {
            let newFirstPasswordilegalMsg =
                previousState.firstPasswordilegalMsg;
            let newSecondPasswordlegal = previousState.secondPasswordlegal;
            if (name === "firstPassword") {
                newFirstPasswordilegalMsg = "";
            } else if (name === "secondPassword") {
                newSecondPasswordlegal = true;
            }
            return {
                ...previousState,
                [name]: value,
                password1verified: false,
                password2verified: false,
                secondPasswordlegal: newSecondPasswordlegal,
                firstPasswordilegalMsg: newFirstPasswordilegalMsg
            };
        });
    };
    submit = keyCode => {
        window.tealiumHub.ET044("stepCreatePassword.label.submit");
        if (keyCode === 13) {
            this.validateFirstInput();
            this.validateSecondInput();
        }
        const {
            password1verified,
            password2verified,
            firstPasswordilegalMsg,
            secondPasswordlegal
        } = this.state;
        if (password1verified && password2verified) {
            if (!firstPasswordilegalMsg && secondPasswordlegal) {
                this.save();
            }
        } else {
            this.validateFirstInput();
            this.validateSecondInput();
        }
    };

    save = () => {
        const { firstPassword } = this.state;
        const encrypted =
            this.e2eState === "enabled"
                ? encryptPassword(firstPassword, this.e2ePublicKey)
                : firstPassword;
        const formInput = window.com.ts.mobile.sdk.FormInput.createFormInputSubmissionRequest(
            { password: encrypted }
        );
        this.props.isLoading(true);
        this.acceptFN(formInput);
    };

    validateFirstInput = () => {
        const { firstPassword } = this.state;
        const firstPasswordilegalMsg = createPasswordValidate(firstPassword, {
            userName: this.props.userName
        });
        this.setState(previousState => {
            return {
                ...previousState,
                firstPasswordilegalMsg,
                password1verified: true
            };
        });
    };

    validateSecondInput = () => {
        this.setState((previousState, currentProps) => {
            const isSame =
                previousState.secondPassword === previousState.firstPassword;
            const newfirstPassword = isSame ? previousState.firstPassword : "";
            const doubleValiadtePsw = createPasswordValidate(
                previousState.secondPassword,
                {
                    userName: this.props.userName
                }
            );
            if (!isSame) {
                this.firstPasswordComp.clearValue();
                this.secondPasswordComp.clearValue();
                this.firstPasswordComp.getSelfFocused();
                return {
                    ...previousState,
                    secondPasswordlegal: isSame,
                    firstPassword: newfirstPassword,
                    secondPassword: newfirstPassword,
                    password2verified: true
                };
            } else if (!doubleValiadtePsw) {
                return this.save();
            }
            return null;
        });
    };

    render() {
        const firstErrorComp = this.state.firstPasswordilegalMsg ? (
            <WarningMsg
                id="firstPassword_error"
                warningMsg={this.state.firstPasswordilegalMsg}
            />
        ) : null;

        const secondErrorComp = this.state.secondPasswordlegal ? null : (
            <WarningMsg
                id="secondPassword_error"
                warningMsg="stepCreatePassword.warning.secondErrorComp"
            />
        );

        return (
            <SecurityModal
                title="stepCreatePassword.title"
                onConfirmationClick={keyCode => this.submit(keyCode)}
                confirmationBtnTitle="stepCreatePassword.label.submit"
                disabledButton={this.props.disabledButton || false}
            >
                <CreatePasswordContainer>
                    <p className="content-main">
                        <FormattedMessage id="stepCreatePassword.label.charactersControl" />
                    </p>
                    <p className="content-main additional-content">
                        <label
                            className="label-style"
                            htmlFor="firstPassword"
                            aria-label={this.props.intl.formatMessage({
                                id:
                                    "createUsernamePassword.aria.label.firstPassword.additional"
                            })}
                        >
                            <span aria-hidden="true">
                                {this.props.intl.formatMessage({
                                    id:
                                        "createUsernamePassword.aria.label.firstPassword.additional"
                                })}
                            </span>
                        </label>
                        <p>
                            <label>
                                <ul className="">
                                    <li
                                        aria-label={this.props.intl.formatMessage(
                                            {
                                                id:
                                                    "createUsernamePassword.aria.label.firstPassword.additional.content1"
                                            }
                                        )}
                                    >
                                        {this.props.intl.formatMessage({
                                            id:
                                                "createUsernamePassword.aria.label.firstPassword.additional.content1"
                                        })}
                                    </li>
                                    <li
                                        aria-label={this.props.intl.formatMessage(
                                            {
                                                id:
                                                    "createUsernamePassword.aria.label.firstPassword.additional.content2"
                                            }
                                        )}
                                    >
                                        {this.props.intl.formatMessage({
                                            id:
                                                "createUsernamePassword.aria.label.firstPassword.additional.content2"
                                        })}
                                    </li>
                                    <li
                                        aria-label={this.props.intl.formatMessage(
                                            {
                                                id:
                                                    "createUsernamePassword.aria.label.firstPassword.additional.content3"
                                            }
                                        )}
                                    >
                                        {this.props.intl.formatMessage({
                                            id:
                                                "createUsernamePassword.aria.label.firstPassword.additional.content3"
                                        })}
                                    </li>
                                    <li
                                        aria-label={this.props.intl.formatMessage(
                                            {
                                                id:
                                                    "createUsernamePassword.aria.label.firstPassword.additional.content4"
                                            }
                                        )}
                                    >
                                        {this.props.intl.formatMessage({
                                            id:
                                                "createUsernamePassword.aria.label.firstPassword.additional.content4"
                                        })}
                                    </li>
                                    <li
                                        aria-label={this.props.intl.formatMessage(
                                            {
                                                id:
                                                    "createUsernamePassword.aria.label.firstPassword.additional.content5"
                                            }
                                        )}
                                    >
                                        {this.props.intl.formatMessage({
                                            id:
                                                "createUsernamePassword.aria.label.firstPassword.additional.content5"
                                        })}
                                    </li>
                                </ul>
                            </label>
                        </p>
                    </p>

                    <SecurityTextInput
                        type="password"
                        onChange={this.handleChange}
                        name="firstPassword"
                        id="firstPassword"
                        ariaDescribedby="firstPassword_error"
                        label="stepCreatePassword.label.EnterPassword"
                        title="stepCreatePassword.label.EnterPassword"
                        hideLabel={true}
                        isPswInput={true}
                        errorComp={firstErrorComp}
                        onRef={comp => (this.firstPasswordComp = comp)}
                        onFocus={touched => {
                            if (!touched) {
                                window.tealiumHub.ET043("password");
                            }
                        }}
                    />

                    <SecurityTextInput
                        type="password"
                        onChange={this.handleChange}
                        name="secondPassword"
                        id="secondPassword"
                        data-testid="secondPassword"
                        isPswInput={true}
                        ariaDescribedby="secondPassword_error"
                        label="stepCreatePassword.label.Re-enterPassword"
                        title="stepCreatePassword.label.Re-enterPassword"
                        hideLabel={false}
                        errorComp={secondErrorComp}
                        onRef={comp => (this.secondPasswordComp = comp)}
                        onFocus={touched => {
                            if (!touched) {
                                window.tealiumHub.ET043("reenter password");
                            }
                        }}
                    />
                </CreatePasswordContainer>
            </SecurityModal>
        );
    }

    startSession = payload => {
        console.log(
            "Reset Password - Create New Password:: Start Session",
            payload
        );
        if (payload.jsonData) {
            this.e2ePublicKey = payload.jsonData.e2ePublicKey;
            this.e2eState = payload.jsonData.E2EE_State;
            if (this.e2eState === "enabled" && !this.e2ePublicKey) {
                window.globalHistory.push({
                    pathname: "/globalError/noConnection?step=030",
                    state: { errorCode: "noConnection" }
                });
            }
        } else {
            window.globalHistory.push({
                pathname: "/globalError/noConnection?step=031",
                state: { errorCode: "noConnection" }
            });
        }
        this.props.isLoading(false);
    };

    endSession() {
        console.log("endSession");
    }

    onContinue = payload => {
        this.props.isLoading(false);
        if (payload.status === "INCORRECT_PASSWORD_FORMAT") {
            this.errorShowType = "yellow";
            this.setState(previousState => ({
                ...previousState,
                firstPasswordilegalMsg: this.props.intl.formatMessage({
                    id: "validator.commonMessage.incorrectPasswordFormat"
                })
            }));
        }
    };

    onError = payload => {
        console.log("onError", this.constructor.name, payload);
        window.globalHistory.push({
            pathname: "/globalError/noConnection?step=032",
            state: { errorCode: "noConnection" }
        });
    };

    promiseFormInput = payload => {
        // this function will be called by transmit sdk,
        // we will create a new promise, once user input has done,
        // resolve this promise with the data user input, then the form data will be captured by transmit.
        return new Promise((accept, reject) => {
            this.setPromiseHandlers(accept, reject);
        });
    };

    promiseInput = payload => {
        return new Promise((accept, reject) => {
            this.setPromiseHandlers(accept, reject);
        });
    };

    promiseRecoveryForError = () => {
        return Promise.resolve(
            window.com.ts.mobile.sdk.AuthenticationErrorRecovery.Fail
        );
    };

    setPromiseHandlers = (acceptFN, rejectFN) => {
        this.acceptFN = acceptFN;
        this.rejectFN = rejectFN;
    };
}

const CreatePasswordContainer = styled.div`
    padding-bottom: 30px;
    font-size: 16px;
    p {
        //margin: 0;
    }
    .hsbc-ui.notification .content p {
        font-size: 14px;
    }
    .content-main {
        font-weight: 300;
        line-height: 24px;
        padding-top: 30px;
        padding-bottom: 10px;
    }
    .additional-content {
        margin-top: -50px;
    }
    .content-repassword {
        font-weight: normal;
        padding-top: 15px;
    }
    input {
        height: 40px;
        width: 230px;
        background-color: ${color.white};
        margin: 10px 0 15px 0;
    }
    .error-comp {
        height: 40px;
        font-size: 14px;
        line-height: 20px;
        display: inline-block;
    }
    .icon--triangle-error {
        font-size: 19px;
        vertical-align: top;
        margin-right: 10px;
    }
    .error-text,
    icon--triangle-error {
        display: inline-block;
        margin-bottom: 20px;
    }
`;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(StepCreatePassword));
