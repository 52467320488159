import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { history } from "util/history";
import _ from "lodash";
import aes from "crypto-js/aes";

import { color } from "util/color";
import SecurityModal from "components/common/SecurityModal";
import SecurityTextInput from "components/common/SecurityTextInput";
import NoticeMsg from "components/common/NoticeMsg";
import { logonActions } from "actions/logonAction";
import { passwordSavedStatusClear } from "actions/resetPasswordAction";
import { updateLockedAccountInfo } from "actions/lockedAccountAction";
import { registrationActions } from "actions/registrationDetailAction";
import { FormattedMessage, injectIntl } from "react-intl";
import Loading from "components/common/Loading";
import { envConfig } from "env-config";
import { encryptPassword, changeIdleTimeOutValid } from "util/service";

const secrKey = "dsp-umd";

const mapStateToProps = state => {
    return {
        error: state.logon.error,
        selectedLang: state.languageSession.selectedLang,
        registrationError: state.registrationDetails.validateDetails,
        disableLogin: state.logon.loggingIn,
        logonValidationErrorUsername: state.logon.validationErrorUsername,
        logonValidationErrorPassword: state.logon.validationErrorPassword,
        alreadyInitMLContainer: state.languageSession.alreadyInitMLContainer,
        encryptedPswDone: state.logon.encryptedpsw,
        isMockLogin: state.logon.isMockLogin,
        isLastAttempt: state.logon.isLastAttempt,
        isPendingResponse: state.logon.pending
    };
};

const mapDispatchToProps = dispatch => {
    return {
        logon: (username, successCallback, failCallback) =>
            dispatch(
                logonActions.logon(
                    username.toLowerCase(),
                    successCallback,
                    failCallback
                )
            ),
        encryptedPsw: encryptedPsw =>
            dispatch(logonActions.encryptedPsw(encryptedPsw)),
        logonInit: loggingIn => {
            dispatch(logonActions.logonInit(loggingIn));
        },
        updateLockedAccountInfo: error =>
            dispatch(updateLockedAccountInfo(error)),
        clearSavedStatus: () => dispatch(passwordSavedStatusClear()),
        switchToGVLogin: payload =>
            dispatch(logonActions.switchToGVLogin(payload)),
        resetDetailsError: () =>
            dispatch(registrationActions.resetDetailsError()),
        isLastAttemptTime: isLastAttempt => {
            dispatch(logonActions.isLastAttempt(isLastAttempt));
        },
        isMockLoginJourney: isMockLogin => {
            dispatch(logonActions.isMockLogin(isMockLogin));
        }
    };
};

class InputCredential extends Component {
    constructor(props) {
        super(props);
        this.state = {
            usn: "",
            psw: "",
            usernameWarningMsg: false,
            pswWarningMsg: false,
            enableAcceptButton: true,
            count: 0,
            wrongCredentialFromBackend: false,
            subStep: 0,
            pswType: "text",
            escapeOptions: null,
            hideButton: false
        };
        this.countDown = null;
        this.__usernameComp = "";
        this.__passwordComp = "";
        if (props.storeThis) {
            props.storeThis("InputCredential", this);
        }
    }

    componentWillUnmount() {
        this.props.clearSavedStatus();
        document.removeEventListener("keydown", this.onkeydown);
    }
    componentWillMount() {
        if (window.transmitSDK) {
            window.transmitSDK.cancelCurrentRunningControlFlow();
        }
    }
    componentDidMount() {
        window.tealiumHub.PT001();
        document.addEventListener("keydown", this.onkeydown);
        const urlParam = history.location.search.substring(1).toLowerCase();
        if (urlParam === "forcedlogout") {
            window.tealiumHub.ET012("inputCredential.warning.forcedLogout");
        }
        if (urlParam === "logout") {
            window.tealiumHub.ET012("inputCredential.warning.optLogout");
        }
        if (urlParam === "globalview") {
            this.props.switchToGVLogin(true);
        }
        this.props.hideSelectOption(false);
        this.props.logonInit(false);
        this.props.isLastAttemptTime(false);
        this.props.isMockLoginJourney(false);

        if (this.__usernameComp) {
            this.__usernameComp.clearValue();
        }
        if (this.__passwordComp) {
            this.__passwordComp.clearValue();
        }

        const verifyAttempts = parseInt(
            sessionStorage.getItem("verifyAttempts")
        );
        if (verifyAttempts === 3) {
            this.startTimer(60);
            this.setState({
                enableAcceptButton: false
            });
        }
    }

    handleChange = e => {
        const { name, value } = e.target;
        this.setState((nextState, props) => ({
            [name === "password" ? "psw" : name]: value
        }));
    };

    changeInputType = () => {
        if (this.state.pswType === "text") {
            this.setState({
                pswType: "password"
            });
        }
    };

    switchError = (reasonMsg, failReason, response) => {
        switch (reasonMsg) {
            case "LOCKED_QUESTION":
            case "LOCKED_PASSWORD":
            case "LOCKED_GLOBAL":
            case "LOCKED_OTP": {
                history.push({
                    pathname: "/commonLockedAccount/globalGenericAccountLock",
                    state: { errorCode: "globalGenericAccountLock" }
                });
                break;
            }
            default: {
                const statusCode = sessionStorage.getItem("statuscode");
                const failReasons = _.get(failReason, "data.reason", "");
                if (response._errorCode === 7) {
                    return;
                } else if (
                    response._errorCode === 21 ||
                    response._errorCode === 20
                ) {
                    return;
                } else {
                    this.props.updateLockedAccountInfo("COMMON_LOCKED_ACCOUNT");
                    history.push({
                        pathname: `/globalError/noConnection?step=012&statusCode=${statusCode}${
                            failReasons
                                ? "&failReason=" +
                                  failReasons.replace(/\s/g, "")
                                : ""
                        }`,
                        state: { errorCode: "noConnection" }
                    });
                }
            }
        }
    };

    failCallback = response => {
        this.props.hideSelectOption(true);
        let reasonMsg = "";
        const errorcode = _.get(response, "_data.assertion_error_code", "");

        const failReason = _.get(response, "_data.failure_data.reason", "");

        const lockedType = _.get(failReason, "data", "");

        if (errorcode === 5) {
            const formInput = window.com.ts.mobile.sdk.FormInput.createEscapeRequest(
                this.state.escapeOptions,
                {}
            );
            this.acceptFN(formInput);
            return;
        }
        if (failReason) {
            if (lockedType["LOCK-CHECK"]) {
                reasonMsg = lockedType["LOCK-CHECK"];
            }
            if (lockedType["reason"] === "USER_NOT_RECOGNISED") {
                this.setState({
                    subStep: 0,
                    wrongCredentialFromBackend: true
                });
                this.setVerifyAttempts();
                if (this.__usernameComp) {
                    this.__usernameComp.clearValue();
                }
                return;
            }
            if (lockedType["reason"] === "INVALID_CREDENTIALS") {
                history.push({
                    pathname: "/commonLockedAccount/genericWrongCredential",
                    state: { errorCode: "genericWrongCredential" }
                });
                return;
            }

            if (lockedType["reason"] === "ACCOUNT_LOCKED") {
                history.push({
                    pathname: "/commonLockedAccount/globalGenericAccountLock",
                    state: { errorCode: "globalGenericAccountLock" }
                });
                return;
            }
        } else if (response._message) {
            reasonMsg = response._message;
        }
        this.switchError(reasonMsg, failReason, response);
    };

    checkApiStatusAndAction = () => {
        this.props.logonInit(true);
        let countTime = 0.1;
        const timer = setInterval(() => {
            countTime += 0.1;
            if (!this.props.isPendingResponse) {
                this.props.logon(
                    this.state.usn,
                    this.successCallback,
                    this.failCallback
                );
                clearInterval(timer);
            }
            if (countTime > 15 || this.props.fakeTest) {
                clearInterval(timer);
                history.push({
                    pathname: "/globalError/noConnection?step=033",
                    state: { errorCode: "noConnection" }
                });
            }
        }, 100);
    };

    logon = () => {
        window.tealiumHub.ET002("inputCredential.label.logon", "log on");
        if (this.state.count > 0) {
            return;
        }
        changeIdleTimeOutValid(true);
        if (
            _.get(this.props.registrationError, "errorType", "") ===
            "registrated"
        ) {
            this.props.resetDetailsError();
        }

        if (!this.state.usn) {
            this.setState({
                subStep: 0,
                wrongCredentialFromBackend: true
            });
            this.setVerifyAttempts();
            return;
        }

        if (this.state.subStep === 0 && this.state.usn) {
            const encrypted = aes.encrypt(this.state.usn, secrKey).toString();
            sessionStorage.setItem("nickKey", encrypted);
            this.setState({
                pswType: "text"
            });
            this.checkApiStatusAndAction();
        } else if (this.state.psw) {
            if (this.e2eState) {
                this.props.logonInit(true);
                const encrypted =
                    this.e2eState === "enabled"
                        ? encryptPassword(this.state.psw, this.e2ePublicKey)
                        : this.state.psw;
                this.acceptFN(
                    window.com.ts.mobile.sdk.FormInput.createFormInputSubmissionRequest(
                        {
                            dummy: "ok",
                            version: envConfig.tcVersion
                        }
                    )
                );
                this.props.encryptedPsw(encrypted);
            }
        } else {
            this.props.logonInit(false);
        }
    };

    onkeydown = e => {
        const activeElement = document.activeElement
            ? document.activeElement.nodeName
            : "";
        if (e.keyCode === 13) {
            if (activeElement === "A") {
                this.resetPassword();
            } else if (activeElement === "DIV") {
                this.register();
            }
        }
    };

    register = () => {
        const otpErrorCounter = parseInt(
            sessionStorage.getItem("otpErrorCounter") || 0
        );
        if (otpErrorCounter > 2) {
            sessionStorage.setItem("otpErrorCounter", 2);
            this.startTimer(60);
            return;
        }
        window.tealiumHub.ET002(
            "inputCredential.label.register",
            "registration"
        );
        if (
            _.get(this.props.registrationError, "errorType", "") ===
            "registrated"
        ) {
            this.props.resetDetailsError();
        }
        this.props.alreadyInitMLContainer && this.props.hideSelectOption(true);
        history.push("registration");
    };

    resetPassword = () => {
        window.tealiumHub.ET002(
            "inputCredential.label.resetPassword",
            "password reset"
        );
        if (
            _.get(this.props.registrationError, "errorType", "") ===
            "registrated"
        ) {
            this.props.resetDetailsError();
        }
        window.transmitSDK.cancelCurrentRunningControlFlow();
        history.push("/resetPassword");
    };

    promiseInput = () => {
        return Promise.resolve(
            window.com.ts.mobile.sdk.InputOrControlResponse.createInputResponse(
                window.com.ts.mobile.sdk.PasswordInput.create(
                    this.props.encryptedPswDone
                )
            )
        );
    };

    startSession = (payload, actionContext, clientContext) => {
        if (payload.jsonData) {
            this.e2ePublicKey = payload.jsonData.e2ePublicKey;
            this.e2eState = payload.jsonData.E2EE_State;
            if (this.e2eState === "enabled" && !this.e2ePublicKey) {
                history.push({
                    pathname: "/globalError/noConnection?step=013",
                    state: { errorCode: "noConnection" }
                });
            }
        }
        if (clientContext && clientContext._escapeOptions) {
            const escapeOptions = clientContext.getEscapeOptions()[0];
            this.setState({
                escapeOptions
            });
        }
    };

    promiseFormInput = payload => {
        const isRegistration = sessionStorage.getItem("isRegistration");
        if (!isRegistration) {
            this.setState({
                subStep: 1
            });
        } else {
            sessionStorage.removeItem("isRegistration");
        }

        this.props.logonInit(false);
        return new Promise((accept, reject) => {
            this.setPromiseHandlers(accept, reject);
        });
    };

    endSession = () => {
        console.log("endSession");
    };

    setPromiseHandlers(acceptFN, rejectFN) {
        this.acceptFN = acceptFN;
        this.rejectFN = rejectFN;
    }

    setVerifyAttempts = () => {
        const verifyAttempts =
            parseInt(sessionStorage.getItem("verifyAttempts") || 0) + 1;
        sessionStorage.setItem("verifyAttempts", verifyAttempts);
        if (this.state.enableAcceptButton && verifyAttempts === 3) {
            this.startTimer(60);
        }
    };

    startTimer = reDuration => {
        let startCount = reDuration ? reDuration : this.state.count;
        if (startCount > 0) {
            const otpErrorCounter = parseInt(
                sessionStorage.getItem("otpErrorCounter")
            );
            const enableAcceptButton = otpErrorCounter ? true : false;
            this.setState(previousState => {
                return {
                    ...previousState,
                    count: startCount,
                    enableAcceptButton,
                    hideButton: otpErrorCounter > 0 ? false : true
                };
            });
        }

        this.countDown = setInterval(() => {
            startCount -= 1;
            if (startCount > 0) {
                this.setState({ count: startCount });
            } else {
                this.setState(previousState => {
                    return {
                        ...previousState,
                        count: startCount,
                        registrationWarningMsg: "",
                        enableAcceptButton: true,
                        wrongCredentialFromBackend: false,
                        hideButton: false
                    };
                });
                clearInterval(this.countDown);
                sessionStorage.setItem("verifyAttempts", 0);
            }
        }, 1000);
    };

    componentWillUpdate(nextProps, nextState) {
        if (nextState.subStep !== this.state.subStep) {
            if (nextState.subStep === 0) {
                this.props.hideSelectOption(false);
            } else {
                this.props.hideSelectOption(true);
            }
        }
    }

    getTip = () => {
        let urlParam = window.location.href.split("?")[1];
        if (urlParam) {
            urlParam = urlParam.toLowerCase();
        }

        let msgText = null;
        let colors = "yellow";

        switch (urlParam) {
            case "resetpasswordsuc":
                window.tealiumHub.ET012(
                    "inputCredential.warning.passwordReset"
                );
                msgText = "inputCredential.warning.passwordReset";
                break;
            case "forcedlogout":
                msgText = "inputCredential.warning.forcedLogout";
                break;
            case "logout":
                colors = "";
                msgText = "inputCredential.warning.optLogout";
                break;
            case "invalidsession":
                msgText = "inputCredential.warning.invalidSession";
                break;
        }

        return msgText ? <NoticeMsg color={colors} msgText={msgText} /> : null;
    };

    caseLink = otpErrorCounter => {
        return otpErrorCounter > 0 ? (
            <span></span>
        ) : (
            <div className="button--register" onClick={this.register}>
                <label>
                    {this.props.intl.formatMessage({
                        id: "inputCredential.label.register"
                    })}
                </label>
                <i className="icon angle right" />
            </div>
        );
    };

    caseCustomizedFooter = (count, otpErrorCounter) => {
        return count ? (
            <div>
                <div className="link--register" tabIndex={0}>
                    {this.caseLink(otpErrorCounter)}
                </div>
                <p className="count--down">
                    <FormattedMessage id="inputCredential.label.maxAttemp.firstPart" />
                    <span className="count-down-timer">{count}</span>
                    <span className="count-down-timer">
                        <FormattedMessage
                            className="count-down-timer"
                            id="inputCredential.label.maxAttemp.secondPart"
                        />
                    </span>
                </p>
            </div>
        ) : (
            <div
                onClick={this.register}
                className="link--register"
                tabIndex={0}
            >
                <a
                    aria-label={this.props.intl.formatMessage({
                        id: "inputCredential.label.register.label"
                    })}
                >
                    <FormattedMessage id="inputCredential.label.register" />
                </a>
                <i className="icon angle right" />
            </div>
        );
    };

    caseErrorComp = verifyAttempts => {
        const msgText =
            verifyAttempts > 2
                ? "inputCredential.warning.incorrectCredential.exceed"
                : "inputCredential.warning.incorrectCredential";
        const submitErrorComp = this.state.wrongCredentialFromBackend ? (
            <NoticeMsg color="yellow" msgText={msgText} />
        ) : null;

        const { usernameWarningMsg, pswWarningMsg } = this.state;
        const usernameErrorComp = usernameWarningMsg ? (
            <NoticeMsg color="yellow" msgText={this.state.usernameWarningMsg} />
        ) : null;
        const pswErrorComp = pswWarningMsg ? (
            <NoticeMsg color="yellow" msgText={this.state.pswWarningMsg} />
        ) : null;

        return usernameErrorComp || pswErrorComp || submitErrorComp;
    };

    caseComps = (subStep, verifyAttempts, registeredErrorComp) => {
        if (subStep === 0 && !this.props.testSubStep) {
            return (
                <div>
                    {this.caseErrorComp(verifyAttempts) ||
                        this.getTip() ||
                        registeredErrorComp}
                    <SecurityTextInput
                        type="text"
                        name="usn"
                        key="username"
                        label="inputCredential.label.username"
                        hideLabel={false}
                        id="username"
                        ariaDescribedby="username_error"
                        title="inputCredential.label.username"
                        onChange={this.handleChange}
                        hasError={false}
                        onRef={comp => (this.__usernameComp = comp)}
                        onUnload={() => (this.__usernameComp = null)}
                        onFocus={touched => {
                            if (!touched) {
                                window.tealiumHub.ET001("username");
                            }
                        }}
                    />
                </div>
            );
        } else if (subStep === 1 || this.props.testSubStep) {
            return (
                <div>
                    <SecurityTextInput
                        type={this.state.pswType}
                        name="password"
                        key="password"
                        label="inputCredential.label.password"
                        hideLabel={false}
                        id="password"
                        isPswInput={true}
                        ariaDescribedby="password_error"
                        title="inputCredential.label.password"
                        onChange={this.handleChange}
                        hasError={false}
                        onRef={comp => (this.__passwordComp = comp)}
                        onUnload={() => (this.__passwordComp = null)}
                        onFocus={touched => {
                            if (!touched) {
                                window.tealiumHub.ET001("password");
                            }
                            this.changeInputType();
                        }}
                    />
                    {this.props.isLastAttempt ? (
                        <p className="statement--validation">
                            <span className="icon--container">
                                <i className="icon exclamation triangle" />
                            </span>
                            <span className="error--content">
                                <FormattedMessage id="last.attempt.warning" />
                            </span>
                        </p>
                    ) : (
                        ""
                    )}
                </div>
            );
        }
        return <span></span>;
    };

    render() {
        const currentUrlPathIsNotLogon =
            window.location.href.indexOf("logon") === -1;
        const showHeader =
            currentUrlPathIsNotLogon ||
            (envConfig.languageSlectList &&
                envConfig.languageSlectList.length > 1);
        if (this.state.subStep === -1) {
            return <Loading dialogHeight="354px" currentStep="loginPage" />;
        }
        let customizedFooter = null;

        const selectedCnLang =
            this.props.selectedLang &&
            this.props.selectedLang.indexOf("zh") !== -1;

        const otpErrorCounter = parseInt(
            sessionStorage.getItem("otpErrorCounter")
        );

        if (this.state.subStep === 0) {
            customizedFooter = this.caseCustomizedFooter(
                this.state.count,
                otpErrorCounter
            );
        }

        let registeredErrorComp;
        if (
            _.get(this.props.registrationError, "errorType", "") ===
            "registrated"
        ) {
            window.tealiumHub.ET012("inputCredential.label.alreadyRegistered");
            registeredErrorComp = (
                <NoticeMsg
                    msgText={_.get(
                        this.props.registrationError,
                        "statusText",
                        ""
                    )}
                />
            );
        } else {
            registeredErrorComp = null;
        }

        const verifyAttempts = parseInt(
            sessionStorage.getItem("verifyAttempts")
        );

        return (
            <InputCredentialContainer
                showHeader={showHeader}
                selectedCnLang={selectedCnLang}
                enableAcceptButton={this.state.enableAcceptButton}
                otpErrorCounter={otpErrorCounter}
                verifyAttempts={verifyAttempts}
            >
                <SecurityModal
                    title="inputCredential.title"
                    ariaTitle="inputCredential.ariaTitle"
                    confirmationBtnTitle="inputCredential.label.continue"
                    onConfirmationClick={this.logon}
                    customizedFooter={customizedFooter}
                    confirmationBtnDisabled={!this.state.enableAcceptButton}
                    disabledButton={this.props.disableLogin || false}
                    hideButton={this.state.hideButton}
                    currentStep="loginPage"
                    customizedClass="loginCredentialInput"
                >
                    {this.caseComps(
                        this.state.subStep,
                        verifyAttempts,
                        registeredErrorComp
                    )}
                    <p
                        className="link--forgot-password"
                        aria-label={this.props.intl.formatMessage({
                            id: "inputCredential.label.resetPasswordTitle"
                        })}
                    >
                        <a
                            onClick={this.resetPassword}
                            tabIndex={0}
                            aria-label={this.props.intl.formatMessage({
                                id: "inputCredential.label.resetPassword.label"
                            })}
                            data-testid="resetPassword"
                        >
                            <FormattedMessage id="inputCredential.label.resetPassword" />
                        </a>
                    </p>
                </SecurityModal>
            </InputCredentialContainer>
        );
    }

    onError = payload => {
        history.push({
            pathname: "/globalError/noConnection?step=014",
            state: { errorCode: "noConnection" }
        });
    };

    promiseRecoveryForError = () => {
        return Promise.resolve(
            window.com.ts.mobile.sdk.AuthenticationErrorRecovery
                .SelectAuthenticator
        );
    };
}

const InputCredentialContainer = styled.div`
    .loginCredentialInput {
        box-shadow: none;
        @media (max-width: 769px) {
            padding-top: 150px;
        }
        @media (max-width: 415px) {
            padding-top: 230px;
        }
        @media (max-width: 376px) {
            padding-top: 190px;
        }
    }
    .responsive-padding {
        @media (min-width: 769px) {
            padding: 50px 10px 40px 10px;
        }
    }
    input {
        height: 40px;
        color: ${color.text_color_dark};
    }
    span {
        color: #000;
    }

    .hsbc-ui.button.secondary span {
        color: #fff;
    }

    @media (min-width: 1366px) {
        .hsbc-ui.input {
            max-width: 100%;
        }
    }

    p {
        margin-top: 30px;
        margin-bottom: 9px;
        font-size: 16px;
        font-weight: normal;
        line-height: 19px;
    }
    p:first-child {
        margin-top: 45px;
    }

    .link--forgot-password {
        font-size: 14px;
        font-weight: normal;
        margin-top: 15px;
        margin-bottom: 33px;
        text-align: right;
        a {
            cursor: pointer;
        }
    }

    .link--register {
        cursor: pointer;
        margin-top: 10px;
        span {
            color: #000;
        }
        .icon {
            color: ${color.hsbc_warning_red};
            font-size: 20px;
            margin-left: 6px;
        }
        .button--register {
            display: inline-block;
        }
    }
    .block--notice-msg {
        span {
            color: #333333;
        }
        margin-bottom: -20px;
        padding-top: 30px;
        @media (max-width: 768px) {
            margin-top: 30px;
        }
        @media (min-width: 769px) {
            margin-top: 20px;
        }
        margin-bottom: 10px;
    }
    .contentmodal--footer {
        width: 100%;
    }
    .contentmodal--footer-left {
        width: 100%;
        .link--register {
            // width: 100%;
            float: left;
        }
        .count--down {
            @media (max-width: 780px) {
                font-size: 14px;
            }
            float: ${props => (props.otpErrorCounter > 1 ? "left" : "right")};
            margin-top: 15px;
            .count-down-timer {
                font-weight: bold;
            }
        }
    }
    .LogoMarkContainer {
        @media (max-width: 414px) {
            margin-top: ${props => (props.selectedCnLang ? "84px" : "54px")};
        }
        margin-top: -20px;
    }
    .contentmodal--header {
        font-size: 36px;
        @media (min-width: 1440px) {
            font-size: 38px;
        }

        @media (min-width: 1024px) and (max-width: 1439px) {
            font-size: 36px;
        }
        margin-top: -15px;
        margin-bottom: ${props => (props.showHeader ? "-30px" : "0")};
        @media (max-width: 375px) {
            font-size: 24px;
        }
        @media (min-width: 321px) and (max-width: 768px) {
            font-size: 30px;
        }

        @media (max-width: 320px) {
            font-size: 26px;
        }
    }
    .contentmodal--header:first-child {
        padding-bottom: 10px;
    }
    .contentmodal--footer-right.btn--confirm {
        width: 120px;
    }

    .count--down {
        width: ${props => (props.otpErrorCounter >= 2 ? "80%" : "65%")};
        text-align: ${props => (props.otpErrorCounter >= 2 ? "left" : "right")};
    }
    .statement--validation {
        margin-top: 0px;
        overflow: auto;
        width: 100%;
        line-height: 28px;
        font-weight: 400;
        .icon {
            color: ${color.hsbc_warning_red};
            position: relative;
            padding-right: 1px;
        }
        .icon--container {
            float: left;
            width: 5%;
        }
        .error--content {
            float: right;
            width: 93%;
            font-size: 14px;
            word-break: break-word;
        }
    }
`;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(InputCredential));
