export const lockedTypeActions = {
    resetState,
    updateLockedType
};

function resetState() {
    return {
        type: "RESET_LOCKED_TYPE_STATE"
    };
}

function updateLockedType(lockeTypeName) {
    return {
        type: "UPDATE_LOCKED_TYPE",
        payload: lockeTypeName
    };
}
