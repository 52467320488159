import React, { Component } from "react";
import styled from "styled-components";
import { FormattedMessage, injectIntl } from "react-intl";
import { history } from "util/history";

class FullScreenMsg extends Component {
    onConfirmationClick = () => {
        history.push("/logon");
    };

    componentDidMount() {
        document.addEventListener("keydown", this.onkeydown);
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.onkeydown);
    }
    onkeydown = event => {
        if (event.keyCode === 32 || event.keyCode === 13) {
            history.push("/logon");
        }
    };

    render() {
        return (
            <ModalContainer>
                <div className="wrapper" data-testid="full-screen">
                    <div className="fullscreenMsg-bigIcon">
                        <i className="icon exclamation triangle error-icon" />
                    </div>
                    <div className="fullscreenMsg-title">
                        <h1>
                            {this.props.intl.formatMessage({
                                id: this.props.title
                            })}
                        </h1>
                    </div>
                    <div className="fullscreenMsg-content">
                        <p>
                            {this.props.intl.formatMessage({
                                id: this.props.content
                            })}
                        </p>
                    </div>
                    <div className="fullscreenMsg-button">
                        <button
                            className="fullscreenMsg-button-ele"
                            onClick={this.onConfirmationClick}
                            onKeyDown={e => this.onkeydown(e)}
                            data-testid="fullscreenMsg-button-testid"
                        >
                            <FormattedMessage id={"restrictedAccess.button"} />
                        </button>
                    </div>
                </div>
            </ModalContainer>
        );
    }
}

const ModalContainer = styled.div`
    width: 100%;
    //background-color: #253038;
    background: #ffffff;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: calc(100vh - 110px);
    position: relative;
    .wrapper {
        position: absolute;
        top: 92px;
        @media (min-width: 768px) {
            top: 24%;
        }
        @media (max-width: 320px) {
            top: 27px;
        }

        button {
            background-color: rgb(219, 0, 17);
            color: #fff;
            border: 0;
            height: 46px;
            light-height: 46px;
            font-size: 16px;
            width: 25%;
            @media (max-width: 768px) {
                width: 50%;
            }
        }

        .fullscreenMsg-bigIcon {
            text-align: center;
            margin-bottom: 24px;
            .icon--triangle-error {
                font-size: 40px;
            }
            .error-icon {
                font-size: 58px;
                color: #a8000b;
            }
            @media (min-width: 415px) and (max-width: 1024px) {
                .error-icon {
                    font-size: 40px;
                }
            }
            @media (max-width: 414px) {
                .error-icon {
                    font-size: 36px;
                }
            }
        }
        .fullscreenMsg-title {
            h1 {
                margin-bottom: 20px;
                height: 36px;
                width: 307px;
                color: #545454;
                font-family: "Univers Next for HSBC";
                font-size: 28px;
                font-weight: 100;
                line-height: 36px;
                text-align: center;
                width: 100%;
            }
        }
        .fullscreenMsg-content {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            p {
                color: #545454;
                font-family: "Univers Next for HSBC";
                font-size: 16px;
                font-weight: 300;
                line-height: 24px;
                text-align: center;
                width: 65%;
            }
            @media (min-width: 1024px) and (max-width: 1440px) {
                p {
                    width: 58%;
                }
            }
            @media (max-width: 414px) {
                p {
                    width: 100%;
                    padding: 0 29px;
                }
            }
        }
        .fullscreenMsg-button {
            text-align: center;
        }
    }
`;
export default injectIntl(FullScreenMsg);
