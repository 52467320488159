import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import configureStore from "store/configureStore";

import "assets/theme.css";
import { envConfig } from "env-config";

import Intlization from "./Intl";
import App from "App";
import { history } from "util/history";
// both new & old ui share the same tealium file
import "util/tealium";

const initialState = {};
const store = configureStore(initialState);
const tealiumConifg = envConfig.tealiumEnv ? envConfig.tealiumEnv : "prod";

if (envConfig.enabledLoadUtagJs) {
    window.utag_cfg_ovrd = { noview: true };
    (function() {
        let a;
        a = process.env.REACT_APP_TEALIUM_PROFILE + tealiumConifg + "/utag.js";
        const b = document;
        const c = "script";
        const d = b.createElement(c);
        d.src = a;
        d.type = "text/java" + c;
        d.async = true;
        a = b.getElementsByTagName(c)[0];
        a.parentNode.insertBefore(d, a);
    })();
    (function() {
        let a;
        a = "https://cdn.appdynamics.com/adrum/adrum-latest.js";
        const b = document;
        const c = "script";
        const d = b.createElement(c);
        d.src = a;
        d.type = "text/java" + c;
        d.async = true;
        a = b.getElementsByTagName(c)[0];
        a.parentNode.insertBefore(d, a);
    })();
    (function() {
        let a;
        a = "https://" + envConfig.url + "/login/appd-eum.js";
        const b = document;
        const c = "script";
        const d = b.createElement(c);
        d.src = a;
        d.type = "text/java" + c;
        d.async = true;
        a = b.getElementsByTagName(c)[0];
        a.parentNode.insertBefore(d, a);
    })();
}

ReactDOM.render(
    <Provider store={store}>
        <Intlization>
            <App history={history} />
        </Intlization>
    </Provider>,
    document.getElementById("root")
);
