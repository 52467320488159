import { pp2 } from "./PP2";
export default `<html><head>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8">
<meta name="Generator" content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:等线;
	panose-1:2 1 6 0 3 1 1 1 1 1;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:"\@等线";
	panose-1:2 1 6 0 3 1 1 1 1 1;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoHeader, li.MsoHeader, div.MsoHeader
	{mso-style-link:"Header Char";
	margin:0cm;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoFooter, li.MsoFooter, div.MsoFooter
	{mso-style-link:"Footer Char";
	margin:0cm;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
span.HeaderChar
	{mso-style-name:"Header Char";
	mso-style-link:Header;}
span.FooterChar
	{mso-style-name:"Footer Char";
	mso-style-link:Footer;}
.MsoChpDefault
	{font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
.MsoPapDefault
	{margin-bottom:8.0pt;
	line-height:107%;}
 /* Page Definitions */
 @page WordSection1
	{size:595.3pt 841.9pt;
	margin:72.0pt 72.0pt 72.0pt 72.0pt;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}
-->
</style>

</head>

<body lang="ZH-CN">

<div class="WordSection1">

<p class="MsoNormal" style="margin-top:.4pt;line-height:22.45pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:19.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:.45pt">Before we begin</span></p>

<p class="MsoNormal" style="margin-top:7.3pt;margin-right:7.2pt;margin-bottom:
8.0pt;margin-left:0cm;line-height:12.0pt;punctuation-wrap:simple;vertical-align:
baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;
letter-spacing:-.1pt">This policy (Privacy Policy) applies to information held
by members of the HSBC Group as data controllers, as described below. It
explains what information we collect about you, how we’ll use that information,
who we’ll share it with, the circumstances when we’ll share it and what steps
we’ll take to make sure it stays private and secure. It continues to apply even
if your agreement for banking or other products and services with us ends. It
should also be read alongside your relevant product terms and conditions, as
these include sections relating to the use and disclosure of information.</span></p>

<p class="MsoNormal" style="margin-top:9.8pt;line-height:12.0pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:.05pt">This Privacy Policy covers
any private bank products or services you have with us in the UK, including
savings, loans, credit cards, mortgages, investments and insurance. Sometimes
we may need to provide you with separate or further information about specific
products and services. This information will also apply. If you also have a
relationship with other parts of the HSBC Group (e.g. HSBC personal or business
banking, first direct, M&amp;S Bank or HSBC in any other countries outside the
UK), they’ll provide you with information separately where required.</span></p>

<p class="MsoNormal" style="margin-top:9.85pt;line-height:11.95pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif">Some of the links on our websites lead to other
HSBC or non HSBC websites with their own privacy policies, which may be
different to this policy. You’ll need to make sure you’re happy with their
privacy policies when using those other sites.</span></p>

<p class="MsoNormal" style="margin-top:9.85pt;line-height:11.95pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:-.05pt">Wherever we’ve said ‘you’
or ‘your’, this means you, whether you are an individual or an entity, and any
person connected with you including, for example any guarantor, a director,
officer or employee of a company, partners or members of a partnership, any
substantial owner, controlling person, or beneficial owner, trustee, settlor or
protector of a trust, account holder of a designated account, recipient of a
designated payment, your attorney or representative (e.g. authorised
signatories), agent or nominee, any other persons or entities with whom you
have a relationship that’s relevant to your relationship with us.</span></p>

<p class="MsoNormal" style="margin-top:9.85pt;line-height:12.0pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:-.1pt">Parts of this Policy
relate only to individuals, and we have highlighted this where relevant. To be
clear this includes individuals acting in a business or other non-personal
capacity (e.g. a sole trader, partner in a general partnership, trustee in a
Trust).</span></p>

<p class="MsoNormal" style="margin-top:9.8pt;margin-right:3.6pt;margin-bottom:
8.0pt;margin-left:0cm;line-height:12.0pt;punctuation-wrap:simple;vertical-align:
baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">Where
you provide information to us about a connected person it is your
responsibility to draw their attention to this Policy and make sure they agree
to us using their information as described in it. More details about this can
be found in our relevant banking or investment services terms and conditions.</span></p>

<p class="MsoNormal" style="margin-top:10.1pt;line-height:12.0pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:-.2pt">When we say ‘we’, we mean
HSBC Group companies which act as a data controller in respect of your data.
Unless otherwise stated below, the data controller for the purposes of this
policy is HSBC UK Bank plc.</span></p>

<p class="MsoNormal" style="margin-top:9.85pt;margin-right:18.0pt;margin-bottom:
8.0pt;margin-left:0cm;line-height:11.9pt;punctuation-wrap:simple;vertical-align:
baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">HSBC
Bank plc will also act as a data controller where it is providing products or
services to you.</span></p>

<p class="MsoNormal" style="line-height:11.95pt;punctuation-wrap:simple;
vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:
&quot;Arial&quot;,sans-serif">The address for the HSBC Group companies set out in this policy
is 8 Canada Square, London E14 5HO. If you’d like to get in touch with us, you
can also find contact details below.</span></p>

<p class="MsoNormal" style="margin-top:12.2pt;margin-right:39.6pt;margin-bottom:
8.0pt;margin-left:0cm;line-height:19.9pt;punctuation-wrap:simple;vertical-align:
baseline"><span lang="EN-GB" style="font-size:17.0pt;font-family:&quot;Arial&quot;,sans-serif">What
information we collect</span></p>

<p class="MsoNormal" style="margin-top:8.0pt;line-height:12.0pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:-.05pt">We’ll only collect your
information in line with relevant regulations and law. We may collect it from a
range of sources and it may relate to any of our products or services you apply
for, currently hold or have held in the past. We may also collect information
about you when you interact with us, e.g. visit our websites or mobile
channels, call us, or ask about any of our products and services.</span></p>

<p class="MsoNormal" style="margin-top:9.85pt;margin-right:7.2pt;margin-bottom:
8.0pt;margin-left:0cm;line-height:12.0pt;punctuation-wrap:simple;vertical-align:
baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;
letter-spacing:-.05pt">Some of it will come directly from you, e.g. when you
provide ID to open an account. It can also come from your financial advisor,
broker or mortgage intermediary, other HSBC companies, or other sources you’ve
asked us to obtain information from. We might also get some of it from publicly
available sources. The information we collect may include:</span></p>

<p class="MsoNormal" style="margin-top:9.85pt;margin-right:7.2pt;margin-bottom:
8.0pt;margin-left:0cm;line-height:12.0pt;punctuation-wrap:simple;vertical-align:
baseline"><b><span lang="EN-GB" style="font-size:9.5pt;font-family:&quot;Arial&quot;,sans-serif">Information
that you provide to us or provided to us on your behalf, e.g.:</span></b></p>

<p class="MsoNormal" style="margin-top:5.75pt;margin-right:7.2pt;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.05pt">personal details, e.g. name, previous names, gender, date and place of
birth;</span></p>

<p class="MsoNormal" style="margin-top:1.4pt;margin-right:7.2pt;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">contact
details, e.g. address, email address, landline and mobile numbers;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
11.95pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">information
concerning your identity e.g. photo ID, passport information, National
Insurance number, National ID card and nationality;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
11.95pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">market
research, e.g. information and opinions expressed when participating in market
research;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:25.2pt;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.2pt">user login and subscription data, e.g. login credentials for phone and
online banking and mobile banking apps;</span></p>

<p class="MsoNormal" style="margin-top:1.4pt;margin-right:0cm;margin-bottom:0cm;
margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:12.0pt;
punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.05pt">other information about you that you give us or which is given to us on
your behalf by filling in forms or by communicating with us whether
face-to-face, by phone, email, online, or otherwise</span></p>

<p class="MsoNormal" style="margin-top:9.9pt;margin-right:25.2pt;margin-bottom:
8.0pt;margin-left:0cm;line-height:12.0pt;punctuation-wrap:simple;vertical-align:
baseline"><b><span lang="EN-GB" style="font-size:9.5pt;font-family:&quot;Arial&quot;,sans-serif;
letter-spacing:-.15pt">Information we collect or generate about you, e.g.:</span></b></p>

<p class="MsoNormal" style="margin-top:5.7pt;margin-right:0cm;margin-bottom:0cm;
margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:12.0pt;
punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">your
financial information and information about your relationship with us, including
the products and services you hold, the channels you use and your ways of
interacting with us, your ability to get and manage your credit, your payment
history, transactions records, market trades, payments into your account
including salary details and information concerning complaints and disputes;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:0cm;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">information
we use to identify and authenticate you, e.g. your signature and your biometric
information, such as your voice for voice ID, additional information that we
receive from external sources that</span></p>

<p class="MsoNormal" style="margin-top:1.2pt;margin-right:0cm;margin-bottom:0cm;
margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:12.0pt;
punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">we need for
compliance purposes;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:0cm;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">geographic
information, e.g. about which branches or ATMs you use;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:0cm;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">information
included in customer documentation, e.g. a record of advice that we may have
given you;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:0cm;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">marketing and
sales information,<span style="letter-spacing:-.05pt">e.g. details of the
services you receive</span> <span style="letter-spacing:-.05pt">and your
preferences;</span></span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:0cm;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">cookies and
similar technologies we use to recognise you, remember your preferences and
tailor the content we provide to you – our cookie policy contains more details
about how we use cookies and can be found at </span><span lang="EN-GB"><a href="http://www.hsbcprivatebank.com/en/utilities/cookie-policy"><span style="
font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;color:windowtext;
text-decoration:none">hsbcprivatebank.com/en/utilities/ cookie-policy</span></a></span><span lang="EN-GB" style="
font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:0cm;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">risk rating
information, e.g. credit risk rating, transactional behaviour and underwriting
information;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:0cm;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">investigations
data, e.g. due diligence checks, sanctions and anti-money laundering checks,
external intelligence reports, content and metadata related to relevant
exchanges of information between and among individuals, organisations,
including emails, voicemail, live chat;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:0cm;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">records of
correspondence and other communications between us, including email, live chat,
instant messages and social media communications;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:0cm;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">information
that we need to support our regulatory obligations, e.g. information about
transaction details, detection of any suspicious and unusual activity and
information about parties connected to you or these activities</span></p>

<p class="MsoNormal" style="margin-top:9.85pt;margin-right:0cm;margin-bottom:
8.0pt;margin-left:3.6pt;line-height:12.0pt;punctuation-wrap:simple;vertical-align:
baseline"><b><span lang="EN-GB" style="font-size:9.5pt;font-family:&quot;Arial&quot;,sans-serif">Information
we collect from other sources, e.g.:</span></b></p>

<p class="MsoNormal" style="margin-top:5.75pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">information
you’ve asked us to collect for you, e.g. information about your accounts or
holdings with other companies including transaction information;</span></p>

<p class="MsoNormal" style="margin-top:1.2pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.05pt">information from third party providers, e.g. information that helps us
to combat fraud or that relates to your social interactions (including your </span><span lang="EN-GB" style="
font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">communications
via social media, between individuals, organisations, prospects and other
stakeholders acquired from companies that collect combined information)</span></p>

<p class="MsoNormal" style="margin-top:12.05pt;margin-right:0cm;margin-bottom:
8.0pt;margin-left:3.6pt;line-height:22.15pt;punctuation-wrap:simple;vertical-align:
baseline"><span lang="EN-GB" style="font-size:19.0pt;font-family:&quot;Arial&quot;,sans-serif;
letter-spacing:.25pt">How we’ll use </span><span lang="EN-GB" style="font-size:
19.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:.2pt">your information</span></p>

<p class="MsoNormal" style="margin-top:7.45pt;margin-right:7.2pt;margin-bottom:
8.0pt;margin-left:3.6pt;line-height:12.0pt;punctuation-wrap:simple;vertical-align:
baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">We’ll
only use your information where we have your consent or we have another lawful
reason for using it. These reasons include where we:</span></p>

<p class="MsoNormal" style="margin-top:10.05pt;margin-right:0cm;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">need to
pursue our legitimate interests;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:3.6pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.1pt">need to process the information to carry out an agreement we have with
you;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:0cm;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">need to
process the information to comply with a legal obligation;</span></p>

<p class="MsoNormal" style="margin-top:1.2pt;margin-right:18.0pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.1pt">believe the use of your information as described is in the public
interest, e.g. for the purpose of preventing or detecting crime</span></p>

<p class="MsoNormal" style="margin-top:10.05pt;margin-right:28.8pt;margin-bottom:
8.0pt;margin-left:3.6pt;line-height:12.0pt;punctuation-wrap:simple;vertical-align:
baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;
letter-spacing:-.15pt">The reasons we use your information include to:</span></p>

<p class="MsoNormal" style="margin-top:9.85pt;margin-right:0cm;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.05pt">deliver our products and services;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:7.2pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">carry out
your instructions, e.g. to fulfil a payment request;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:0cm;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">manage our
relationship with you, including (unless you tell us otherwise) telling you
about products and services we think may be relevant for you;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:7.2pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">understand how you use your accounts and services;</span></p>

<p class="MsoNormal" style="margin-top:1.4pt;margin-right:0cm;margin-bottom:0cm;
margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:12.0pt;
punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">banking
operations support;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:7.2pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.05pt">prevent or detect crime including fraud and financial crime, e.g.
financing for terrorism and human trafficking;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:7.2pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">security and
business continuity;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:7.2pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">risk
management;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:7.2pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">online
banking, mobile apps and other online product platforms;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:7.2pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">product and
service improvement;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:7.2pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">data
analytics to better understand your circumstances and preferences so we can
make sure we can provide you with the best advice and offer you a tailored
service;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:7.2pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">protect our
legal rights and comply with our legal obligations;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:7.2pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">correspond
with solicitors, surveyors, valuers, other lenders, conveyancers and third
party intermediaries;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:7.2pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">system or
product development and planning, insurance, audit and administrative purposes</span></p>

<p class="MsoNormal" style="margin-top:10.05pt;margin-right:28.8pt;margin-bottom:
8.0pt;margin-left:0cm;line-height:12.0pt;punctuation-wrap:simple;vertical-align:
baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">Further
details of how we’ll use your information can be found in the Appendix below.</span></p>

<p class="MsoNormal" style="margin-top:11.7pt;line-height:10.25pt;punctuation-wrap:
simple;vertical-align:baseline"><b><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:.15pt">How we make decisions
about you</span></b></p>

<p class="MsoNormal" style="margin-top:5.4pt;line-height:12.0pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:-.1pt">We may use automated systems
to help us make decisions, e.g. for example about particular products and
services you may wish to access and to carry out fraud and money laundering
checks. We may use technology that helps us identify the level of risk involved
in customer or account activity, e.g. for credit, fraud or financial crime
reasons, or to identify if someone else is using your card without your
permission.</span></p>

<p class="MsoNormal" style="margin-top:10.1pt;margin-right:3.6pt;margin-bottom:
8.0pt;margin-left:0cm;line-height:11.85pt;punctuation-wrap:simple;vertical-align:
baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">Individuals
may have a right to certain information about how we make these decisions. More
details can be found in the ‘Rights of individuals’ section.</span></p>

<p class="MsoNormal" style="margin-top:10.1pt;margin-right:3.6pt;margin-bottom:
8.0pt;margin-left:0cm;line-height:11.85pt;punctuation-wrap:simple;vertical-align:
baseline"><b><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">Tracking
or recording what you say or do</span></b></p>

<p class="MsoNormal" style="margin-top:5.65pt;line-height:12.0pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:-.05pt">To help keep you and your
money safe, we may record details of your interactions with us. We may record
and keep track of conversations you have with us including phone calls, face-to-face
meetings, letters, emails, live chats, video chats and any other kinds of
communication. We may use these recordings to check your instructions to us,
assess, analyse and improve our service, train our people, manage risk or to
prevent and detect fraud and other crimes. We may also capture additional
information about these interactions, e.g. telephone numbers that you call us
from and information about the devices or software that you use. We use closed
circuit television (CCTV) in and around our sites and these may collect photos
or videos of you, or record your voice.</span></p>

<p class="MsoNormal" style="margin-top:9.95pt;margin-right:10.8pt;margin-bottom:
8.0pt;margin-left:0cm;line-height:12.0pt;punctuation-wrap:simple;vertical-align:
baseline"><b><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">Compliance
with laws and regulatory compliance obligations</span></b></p>

<p class="MsoNormal" style="margin-top:5.65pt;line-height:11.95pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:.05pt">We’ll use your information
to meet our compliance obligations, to comply with other laws and regulations
and to share with regulators and other authorities that HSBC Group companies
are subject to. This may include using it to help detect or prevent crime
(including terrorism financing, money laundering and other financial crimes).
We’ll only do this on the basis that it’s needed to comply with a legal
obligation or it’s in our legitimate interests and that of others.</span></p>

<p class="MsoNormal" style="margin-top:.85pt;line-height:10.25pt;punctuation-wrap:
simple;vertical-align:baseline"><b><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:.1pt">Marketing and market
research</span></b></p>

<p class="MsoNormal" style="margin-top:5.7pt;line-height:12.0pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif">We may use your information to provide you with
details about HSBC products and services, and also products and services from our
partners and other relevant third parties. We may send you marketing messages
by post, email, telephone, text or secure messages. You can change your mind on
how you receive marketing messages or choose to stop receiving them at any
time. To make that change, please contact us in the usual way.</span></p>

<p class="MsoNormal" style="margin-top:9.85pt;line-height:12.0pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif">If you ask us not to send you marketing, it may
take us a short period of time to update our systems and records to reflect
your request, during which time you may continue to receive marketing messages.
Even if you tell us not to send you marketing messages, we’ll continue to use
your contact details to provide you with important information, such as changes
to your terms and conditions or if we need to tell you something to comply with
our regulatory obligations.</span></p>

<p class="MsoNormal" style="margin-top:9.85pt;line-height:11.95pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:.1pt">We may use your information
for market research and to identify trends. Market research agencies acting on
our behalf may get in touch with you by post, telephone, email or other methods
of communication to invite you to take part in research. We won’t invite you to
take part in research using a communication method if you’ve asked us not to
get in touch that way. Any responses that you provide whilst participating in
market research will be reported back to us anonymously unless you give us
permission for your details to be shared.</span></p>

<p class="MsoNormal" style="margin-top:12.05pt;margin-right:0cm;margin-bottom:
8.0pt;margin-left:3.6pt;line-height:22.15pt;punctuation-wrap:simple;vertical-align:
baseline"><span lang="EN-GB" style="font-size:19.0pt;font-family:&quot;Arial&quot;,sans-serif;
letter-spacing:.25pt">Who we might share your information with</span></p>

<p class="MsoNormal" style="margin-top:7.5pt;margin-right:0cm;margin-bottom:8.0pt;
margin-left:3.6pt;line-height:12.0pt;punctuation-wrap:simple;vertical-align:
baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">We
may share your information with others where lawful to do so including where we
or they:</span></p>

<p class="MsoNormal" style="margin-top:10.1pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="

font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.2pt">need to in order to provide you with products or services you’ve
requested, e.g. fulfilling a payment request;</span></p>

<p class="MsoNormal" style="margin-top:1.4pt;margin-right:21.6pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="

font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">have a public
or legal duty to do so, e.g. to assist with detecting and preventing fraud, tax
evasion and financial crime;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="

font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">need to in
connection with regulatory reporting, litigation or asserting or defending
legal rights and interests;</span></p>

<p class="MsoNormal" style="margin-top:1.2pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="fon


t:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="

font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.2pt">have a legitimate business reason for doing so, e.g. to manage risk,
verify your identity, enable another company to provide you with services
you’ve requested, or assess your suitability for products and services;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="

font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">have asked
you for your permission to share it, and you’ve agreed</span></p>

<p class="MsoNormal" style="margin-top:10.1pt;margin-right:10.8pt;margin-bottom:
8.0pt;margin-left:3.6pt;line-height:12.0pt;punctuation-wrap:simple;vertical-align:
baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">We
may share your information for these purposes with others including:</span></p>

<p class="MsoNormal" style="margin-top:9.8pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="

font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.1pt">other HSBC Group companies and any sub-contractors, agents or service
providers who work for us or provide services to us or other HSBC Group
companies (including their employees, sub-contractors, service providers,
directors and officers);</span></p>

<p class="MsoNormal" style="margin-top:9.8pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="

font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">any joint account holders, trustees, beneficiaries or executors;</span></p>

<p class="MsoNormal" style="margin-top:9.8pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="

font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">people who give guarantees or other security for any amounts you owe
us;</span></p>

<p class="MsoNormal" style="margin-top:9.8pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="

font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">people you make payments to and receive payments from;</span></p>

<p class="MsoNormal" style="margin-top:9.8pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="

font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">your beneficiaries, intermediaries, correspondent and agent banks,</span></p>

<p class="MsoNormal" style="margin-top:9.8pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="

font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">clearing houses, clearing or settlement systems, market counterparties
and any companies you hold securities in through us, e.g. stocks, bonds or
options;</span></p>

<p class="MsoNormal" style="margin-top:9.8pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="

font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">other financial institutions, lenders and holders of security over any
property you charge to us, tax authorities, trade associations, credit
reference agencies, payment service providers and debt recovery agents;</span></p>

<p class="MsoNormal" style="margin-top:9.8pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="


font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">any fund managers who provide asset management services to you and any
brokers who introduce you to us or deal with us for you;</span></p>

<p class="MsoNormal" style="margin-top:9.8pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="


font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">any entity that has an interest in the products or services that we
provide to you, including if they take on the risk related to them;</span></p>

<p class="MsoNormal" style="margin-top:9.8pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="


font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">any people or companies where</span></p>

<p class="MsoNormal" style="margin-top:9.8pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="


font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">required in connection with potential or actual corporate
restructuring, merger, acquisition or takeover,</span></p>

<p class="MsoNormal" style="margin-top:9.8pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="


font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">including any transfer or potential transfer of any of our rights or
duties under our agreement with you;</span></p>

<p class="MsoNormal" style="margin-top:9.8pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="


font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">law enforcement, government, courts, dispute resolution bodies, our
regulators, auditors and any party appointed or requested by our regulators to
carry out investigations or audits of our activities;</span></p>

<p class="MsoNormal" style="margin-top:9.8pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="


font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">other parties involved in any disputes, including disputed
transactions;</span></p>

<p class="MsoNormal" style="margin-top:9.8pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="


font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">fraud prevention agencies who’ll also use it to detect and prevent
fraud and other financial crime and to verify your identity;</span></p>

<p class="MsoNormal" style="margin-top:9.8pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="


font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">anyone who provides instructions or operates any of your accounts on
your behalf, e.g. Power of Attorney, solicitors, intermediaries, etc;</span></p>

<p class="MsoNormal" style="margin-top:9.8pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="


font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">anybody else that we’ve been instructed to share your information with
by either you, a joint account holder or anybody else who provides instructions
or operates any of your accounts on your behalf;</span></p>

<p class="MsoNormal" style="margin-top:9.8pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="


font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">our card processing supplier(s) to carry out credit, fraud and risk
checks, process your payments, issue and manage your card;</span></p>

<p class="MsoNormal" style="margin-top:9.8pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="


font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">insurers so they can provide you with a quote or cover. For further details,
please refer to your policy documentation. You can also refer to </span><span lang="EN-GB"><a href="http://www.hsbc.co.uk"><span style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif;color:windowtext;letter-spacing:-.15pt;
text-decoration:none">hsbc.co.uk</span></a></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:-.15pt">
for more information</span></p>

<p class="MsoNormal" style="margin-top:9.95pt;line-height:12.0pt;punctuation-wrap:
simple;vertical-align:baseline"><b><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif">Sharing aggregated or anonymised information</span></b></p>

<p class="MsoNormal" style="margin-top:5.65pt;margin-right:3.6pt;margin-bottom:
8.0pt;margin-left:0cm;line-height:12.0pt;punctuation-wrap:simple;vertical-align:
baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;
letter-spacing:-.1pt">We may share aggregated or anonymised information within
and outside of the HSBC Group with partners such as research groups,
universities or advertisers. You won’t be able to be identified from this
information, e.g. we may share information about general spending trends in the
UK to assist in research.</span></p>

<p class="MsoNormal" style="margin-top:12.05pt;line-height:22.2pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:19.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:-.4pt">How long we’ll keep </span><span lang="EN-GB" style="


font-size:19.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">information</span></p>

<p class="MsoNormal" style="margin-top:7.4pt;line-height:12.0pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:-.1pt">We keep information in
line with our data retention policy. For example, we’ll normally keep your core
banking data for a period of seven years from the end of our relationship with
you. This enables us to comply with legal and regulatory requirements or use</span></p>

<p class="MsoNormal" style="margin-right:7.2pt;line-height:12.0pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif">it where we need to for our legitimate purposes
such as managing your account and dealing with any disputes or concerns that
may arise.</span></p>

<p class="MsoNormal" style="margin-top:9.85pt;line-height:12.0pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:-.05pt">We may need to retain
information for a longer period where we need the information to comply with
regulatory or legal requirements or where we may need it for our legitimate
purposes, e.g. to help us respond to queries or complaints, fighting fraud and
financial crime, responding to requests from regulators, etc.</span></p>

<p class="MsoNormal" style="margin-top:10.05pt;margin-right:7.2pt;margin-bottom:
8.0pt;margin-left:0cm;line-height:12.0pt;punctuation-wrap:simple;vertical-align:
baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">If
we don’t need to retain information for this period of time, we may destroy,
delete or anonymise it more promptly</span></p>

<p class="MsoNormal" style="margin-top:12.55pt;line-height:21.95pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:19.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:.25pt">Transferring </span><span lang="EN-GB" style="


font-size:19.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
.6pt">information</span><span lang="EN-GB" style="font-size:19.0pt;font-family:
&quot;Arial&quot;,sans-serif;letter-spacing:.25pt"> </span><span lang="EN-GB" style="


font-size:19.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:.15pt">overseas</span></p>

<p class="MsoNormal" style="margin-top:7.55pt;margin-right:3.6pt;margin-bottom:
8.0pt;margin-left:0cm;line-height:12.0pt;punctuation-wrap:simple;vertical-align:
baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;
letter-spacing:-.05pt">Your information may be transferred to and stored in
locations outside the European Economic Area (EEA) or the United Kingdom (UK),
including countries that may not have the same level of protection for personal
information. When we do this, we’ll ensure it has an appropriate level of
protection and that the transfer is lawful. We may need to transfer your
information in this way to carry out our contract with you, to fulfil a legal
obligation, to protect the public interest and/or for our legitimate interests.
In some countries the law might compel us to share certain information, e.g.
with tax authorities. Even in these cases, we’ll only share your information
with people who have the right to see it. </span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">You can obtain more
details of the protection given to your information when it’s transferred
outside the EEA or UK by contacting us using the details in the ‘More details
about your information’ section below.</span></p>

<p class="MsoNormal" style="margin-top:11.8pt;line-height:10.4pt;punctuation-wrap:
simple;vertical-align:baseline"><b><span lang="EN-GB" style="font-size:9.5pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:-.15pt">Rights of individuals</span></b></p>

<p class="MsoNormal" style="margin-top:5.4pt;margin-right:21.6pt;margin-bottom:
8.0pt;margin-left:0cm;line-height:12.0pt;punctuation-wrap:simple;vertical-align:
baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;
letter-spacing:-.05pt">Individuals have a number of rights in relation to the information
that we hold about them. These rights include:</span></p>

<p class="MsoNormal" style="margin-top:10.05pt;margin-right:7.2pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-14.4pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">the
right to access information we hold about them and to obtain information about
how we process it;</span></p>

<p class="MsoNormal" style="margin-top:1.2pt;margin-right:0cm;margin-bottom:0cm;
margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-14.4pt;line-height:12.0pt;
punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.1pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="
font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.1pt">in some circumstances, the right to withdraw their consent to our
processing of their information, which they can do at any time. We may continue
to process their information if we have another legitimate reason for doing so;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:14.4pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-14.4pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.1pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="
font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.1pt">in some circumstances, the right to receive certain information they
have provided to us in an electronic format and/or request that we transmit it
to a third party;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:21.6pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-14.4pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">the
right to request that we rectify their information if it’s inaccurate or
incomplete;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:7.2pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-14.4pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.1pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="
font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.1pt">in some circumstances, the right to request that we erase their
information. We may continue to retain their information if we’re entitled or
required to retain it;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:0cm;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-14.4pt;line-height:
11.9pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="
font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.05pt">the right to object to, and to request that we restrict, our processing
of their information in some circumstances. Again, there may be situations
where they object to, or ask us to restrict, our processing of their
information but we’re entitled to continue processing their information and/or
to refuse that request</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:0cm;margin-bottom:
0cm;margin-left:0cm;margin-bottom:.0001pt;line-height:11.9pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:-.05pt"><br>
</span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;
letter-spacing:-.15pt">Individuals can exercise their rights by contacting us
using the details set out in the ‘More details about your information’ section
below. Individuals also have a right to complain to the UK Information
Commissioner’s Office by visiting </span><span lang="EN-GB"><a href="http://www.ico.org.uk"><b><span style="
font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:blue;letter-spacing:-.15pt">ico.org.uk</span></b></a></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">, or to the data protection regulator in the country where they live or
work.</span></p>

` + pp2;
