import { history } from "util/history";
import { envConfig } from "env-config";
import newTerms from "assets/mock/terms.json";
import _ from "lodash";
import { changeIdleTimeOutValid, guid } from "util/service";
import axios from "axios";
import { locale } from "../store/configureStore";

const makeLogoutRequest = async journey => {
    await window.transmitSDK.logout();
};

const isLowerEnv =
    window.location.href.includes("localhost") ||
    window.location.href.includes("hkl20042377");

export const logonActions = {
    logon,
    logonHasErrored,
    logonFetchDataSuccess,
    logonFeachTerms,
    logonInit,
    disableTermsBtn,
    switchToGVLogin,
    gvLinkProfile,
    encryptedPsw,
    isMockLogin,
    isLastAttempt,
    isPendingResponse,
    isMockLoginOr,
    gvModel,
    caseError
};

function logonHasErrored(error) {
    return {
        type: "LOGON_SESSION_ERROR",
        error: error
    };
}

function logonFetchDataSuccess(data) {
    return {
        type: "LOGON_SESSION_SUCCESS",
        data
    };
}

function logonFeachTerms() {
    return {
        type: "LOGON_TERMS_FETCHED_SUC",
        payload: newTerms
    };
}

function logonInit(loggingIn) {
    return {
        type: "LOGON_INIT",
        loggingIn
    };
}

function disableTermsBtn(payload) {
    return {
        type: "DISABLE_TERMS_BUTTON",
        disableTermsBtn: payload
    };
}
function switchToGVLogin(bool) {
    return {
        type: "GV_LOGON",
        payload: bool
    };
}

function gvLinkProfile(url, RBtoken) {
    return {
        type: "GV_Linking_Profile",
        url,
        RBtoken
    };
}

function encryptedPsw(encryptedpsw) {
    return {
        type: "ENCRYTED_PSW",
        encryptedpsw
    };
}

function isMockLogin(isMockLogins) {
    return {
        type: "MOCK_LOGIN",
        isMockLogin: isMockLogins
    };
}

function isLastAttempt(isLastAttempts) {
    return {
        type: "IS_LAST_ATTEMPT",
        isLastAttempt: isLastAttempts
    };
}

function isPendingResponse(pending) {
    return {
        type: "IS_PENDING_RESPONSE",
        pending
    };
}

function existSdk() {
    const existingSession = _.get(
        window.transmitSDK,
        "_currentSession._sessionId",
        false
    );

    if (existingSession) {
        makeLogoutRequest("Logon");
    }
}

function isMockLoginOr(clientContext) {
    sessionStorage.removeItem("verifyAttempts");
    if (envConfig.multiTenancy) {
        document.cookie =
            `realm=${envConfig.realm}` + `; path=/; domain=${envConfig.id}`;
    }
    const isMockLogins = _.get(clientContext, "jsonData.flow", false);
    if (isMockLogins === "mock_login") {
        history.push({
            pathname: "/commonLockedAccount/genericWrongCredential",
            state: { errorCode: "genericWrongCredential" }
        });
        return false;
    }
    return true;
}

function gvModel(cookieVal, dispatch) {
    const uuid = guid();
    const timestamp = new Date().getTime();
    const opts = {
        method: "GET",
        headers: {
            AMSession: cookieVal,
            "Cache-Control": "no-cache"
        }
    };
    if (!isLowerEnv) {
        opts.headers["X-HSBC-Request-Correlation-Id"] = uuid;
    }
    axios(`/amsts/partnerssso/gv/linkprofile/link?data=${timestamp}`, opts)
        .then(function(response) {
            if (response.status === 200) {
                if (response.data && response.data.ref_data_url) {
                    dispatch(
                        gvLinkProfile(
                            response.data.ref_data_url,
                            response.data.issued_token
                        )
                    );
                    history.push("/gvResult");
                } else {
                    history.push("/gvError");
                }
            } else {
                dispatch(
                    gvLinkProfile(
                        response.data.ref_data_url,
                        response.data.issued_token
                    )
                );
                history.push("/gvError");
                console.log("GV Error", response);
            }
        })
        .catch(function(error) {
            dispatch(
                gvLinkProfile(
                    error.response.data.ref_data_url,
                    error.response.data.issued_token
                )
            );
            history.push("/gvError");
            console.log("GV Error Catch function", error.response);
        });
}

function caseError(err, failCallback, dispatch) {
    dispatch(logonInit(false));
    console.log("logon journey fail catch err: ", err.getData());
    const errorCode = err.getErrorCode();
    const locked = _.get(err.getData(), "additional_data.locked", false);
    const sanctioned = _.get(
        err.getData(),
        "failure_data.reason.data.reason",
        false
    );
    if (sanctioned === "LISTED_IN_SANCTION_COUNTRY") {
        history.push("/restrictedAccess");
        return;
    }
    const message = err.getMessage();

    if (err._errorCode === 11) {
        history.push("/logon");
    } else if (err._errorCode === 16) {
        history.push("/noContactDetails");
        return;
    } else if (
        err._errorCode &&
        err._errorCode === 7 &&
        err._message === "Operation cancelled by user."
    ) {
        console.log("user give up");
        return;
    } else if (
        err._errorCode === 9 &&
        _.get(err, "_data.failure_data.reason.data.reason", false) ===
            "APPROVAL DECLINED WITH FRAUD"
    ) {
        console.log("appsign fraud");
        history.push({
            pathname: "/commonLockedAccount/fullaccountLock",
            state: { errorCode: "fullaccountLock" }
        });
        return;
    } else {
        dispatch(
            logonHasErrored({
                errorCode: errorCode,
                locked: locked,
                message: message
            })
        );

        console.log(
            "logon journey fail catch err but DONT REACT TO IT, hand to failcallback"
        );
        if (failCallback) {
            failCallback(err);
        }
        return;
    }
}

function callSecurityContext(cookieVal, dispatch) {
    const uuid = guid();
    const opts = {
        method: "GET",
        headers: {
            OIDCFilter: true,
            AMSESSION: cookieVal,
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest"
        }
    };
    if (!isLowerEnv) {
        opts.headers["X-HSBC-Request-Correlation-Id"] = uuid;
    }
    if (window.hds != null) {
        if (opts.headers == null) {
            opts.headers = new Headers();
        }
        Object.keys(window.hds).forEach(function add(key) {
            opts.headers[key] = window.hds[key];
        });
    }

    axios(
        `${envConfig.protocol}://${envConfig.url}/rest/hsbc/SecurityContext`,
        opts
    ).then(
        data => {
            const localestr = locale();
            switch (localestr) {
                case "zh-trd-HK":
                case "zh-sim-HK":
                case "en-LU":
                case "fr-LU":
                    history.push("/onlyEnglishTips");
                    break;
                default:
                    window.location.href = `${
                        envConfig.protocol
                    }://${envConfig.url + envConfig.wvUri}`;
            }
            changeIdleTimeOutValid(false);
            window.transmitSDK
                .logout()
                .then(function(suc) {
                    console.log("logout transmit", suc);
                })
                .catch(function(err) {
                    console.log("logout transmit fail", err);
                });
        },
        error => {
            history.push({
                pathname: "/globalError/noConnection?step=008",
                state: { errorCode: "noConnection" }
            });
        }
    );
}

function logon(username, successCallback, failCallback) {
    changeIdleTimeOutValid(true);
    return (dispatch, getState) => {
        const policyId = "login_policy";
        const policyParams = {};
        const clientContext = {};
        if (username) {
            dispatch(logonInit(true));
            existSdk();
            dispatch(logonInit(true));
            window.transmitSDK
                .authenticate(username, policyId, policyParams, clientContext)
                .then(function(successfulAuth) {
                    if (!isMockLoginOr(clientContext)) {
                        return;
                    }
                    const cookieVal = clientContext.jsonData
                        ? clientContext.jsonData["OpenAM Session"]
                        : "";

                    if (getState().logon.gvMode === true) {
                        gvModel(cookieVal, dispatch);
                    } else {
                        callSecurityContext(cookieVal, dispatch);

                        dispatch(logonFetchDataSuccess(successfulAuth));
                        if (successCallback) {
                            successCallback(successfulAuth);
                        }
                    }
                })
                .catch(function(err) {
                    caseError(err, failCallback, dispatch);
                });
        }
    };
}
