import PrivacyPolicyLUX from "./registrationConditions/PP_LUX.js";
import TermsAndConditionLUX from "./registrationConditions/T&C_LUX.js";

import lang from "./lang.json";

export default {
    lang,
    registrationTermsLUX: [
        {
            label: "Conditions générales",
            html: TermsAndConditionLUX
        },
        {
            label: "Politique de confidentialité",
            html: PrivacyPolicyLUX
        }
    ]
};
